/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GeneratorFilterTypeRepresentation,
    GeneratorFilterTypeRepresentationFromJSON,
    GeneratorFilterTypeRepresentationToJSON,
} from '../models';

/**
 * 
 */
export class ConfigurationsApi extends runtime.BaseAPI {

    /**
     * Get all generator filter types
     * Get filter types
     */
    async getGeneratorFilterTypesRaw(): Promise<runtime.ApiResponse<Array<GeneratorFilterTypeRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/configurations/anon-generator-filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeneratorFilterTypeRepresentationFromJSON));
    }

    /**
     * Get all generator filter types
     * Get filter types
     */
    async getGeneratorFilterTypes(): Promise<Array<GeneratorFilterTypeRepresentation>> {
        const response = await this.getGeneratorFilterTypesRaw();
        return await response.value();
    }

}
