import type {
    LossOfProductionMetricsApi,
    LossOfProductionMetricRepresentation,
    LossOfProductionMetricUpdateRequest,
    LossOfProductionMetricCreateRequest,
    LossOfProductionMetricVariantCreateRequest,
    LossOfProductionMetricVariantUpdateRequest,
    LossOfProductionMetricVariantRepresentation,
    LocalizationApi,
} from '@/modules/shared/adapter';
import {
    LossOfProductionMetricApi as LossOfProductionMetricRestApi,
    Configuration,
} from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import { AuthApi } from '@/modules/shared/adapter';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '@/modules/shared/adapter/rest/middleware';
import type { DataChangedEvent } from '@/modules/shared/types';

export class CachedLossOfProductionMetricsRestRepository implements LossOfProductionMetricsApi {

    private readonly i18n: LocalizationApi;
    private readonly lossOfProductionMetricsApi: LossOfProductionMetricRestApi;
    private readonly lossOfProductionMetricsCache: IndexedDBRepository<string, LossOfProductionMetricRepresentation>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi, i18n: LocalizationApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.i18n = apis.i18n;
        this.lossOfProductionMetricsApi = new LossOfProductionMetricRestApi(restApiConfig);
        this.lossOfProductionMetricsCache = new IndexedDBRepository<string, LossOfProductionMetricRepresentation>(indexedDb, 'loss-of-production-metrics');
    }

    public onChange(cb: (event: DataChangedEvent) => void) {
        this.lossOfProductionMetricsCache.addChangedListener((evt) => cb({
            keys: evt.keys,
            action: evt.action,
        }));
    }

    public async createLossOfProductionMetric(createRequest: LossOfProductionMetricCreateRequest): Promise<LossOfProductionMetricRepresentation> {
        const newLossOfProductionMetric = await this.lossOfProductionMetricsApi.createLossOfProductionMetric(createRequest);
        await this.lossOfProductionMetricsCache.save(newLossOfProductionMetric);
        this.i18n.requestReload();
        return newLossOfProductionMetric;
    }

    public async createLossOfProductionMetricVariant(metricKey: string, createRequest: LossOfProductionMetricVariantCreateRequest): Promise<LossOfProductionMetricVariantRepresentation> {
        const newVariant = await this.lossOfProductionMetricsApi.createLossOfProductionMetricVariant(metricKey, createRequest);
        const cachedMetric = await this.lossOfProductionMetricsCache.findByKey(metricKey);
        if (cachedMetric) {
            cachedMetric.variants.push(newVariant);
            await this.lossOfProductionMetricsCache.save(cachedMetric);
        }
        return newVariant;
    }

    public async deleteLossOfProductionMetricVariant(metricKey: string, variantKey: string): Promise<void> {
        await this.lossOfProductionMetricsApi.deleteLossOfProductionMetricVariant(metricKey, variantKey);
        const cachedMetric = await this.lossOfProductionMetricsCache.findByKey(metricKey);
        if (cachedMetric) {
            // remove old variant
            cachedMetric.variants = cachedMetric.variants.filter((it) => it.key !== variantKey);
            await this.lossOfProductionMetricsCache.save(cachedMetric);
        }
    }

    public async getLossOfProductionMetrics(): Promise<LossOfProductionMetricRepresentation[]> {
        return await this.lossOfProductionMetricsCache.findAllIfAnyCached() || this.fetchLossOfProductionMetrics();
    }

    public async getLossOfProductionMetric(metricKey:string): Promise<LossOfProductionMetricRepresentation|undefined> {
        if (await this.lossOfProductionMetricsCache.count() === 0) {
            await this.fetchLossOfProductionMetrics();
        }
        return this.lossOfProductionMetricsCache.findByKey(metricKey);
    }

    public async updateLossOfProductionMetric(metricKey: string, updateRequest: LossOfProductionMetricUpdateRequest): Promise<LossOfProductionMetricRepresentation> {
        const updateLossOfProductionMetric = await this.lossOfProductionMetricsApi.updateLossOfProductionMetric(metricKey, updateRequest);
        await this.lossOfProductionMetricsCache.save(updateLossOfProductionMetric);
        this.i18n.requestReload();
        return updateLossOfProductionMetric;
    }

    public async updateLossOfProductionMetricVariant(metricKey: string, variantKey: string, updateRequest: LossOfProductionMetricVariantUpdateRequest): Promise<LossOfProductionMetricVariantRepresentation> {
        const updatedVariant = await this.lossOfProductionMetricsApi.updateLossOfProductionMetricVariant(metricKey, variantKey, updateRequest);
        const cachedMetric = await this.lossOfProductionMetricsCache.findByKey(metricKey);
        if (cachedMetric) {
            // remove old variant
            cachedMetric.variants = cachedMetric.variants.filter((it) => it.key !== variantKey);
            // push new variant
            cachedMetric.variants.push(updatedVariant);
            await this.lossOfProductionMetricsCache.save(cachedMetric);
        }
        return updatedVariant;
    }

    private async fetchLossOfProductionMetrics(): Promise<LossOfProductionMetricRepresentation[]> {
        return AsyncDebouncer.debounce('CachedLossOfProductionMetricsRestRepository.fetchLossOfProductionMetrics()', async () => {
            const metrics = await this.lossOfProductionMetricsApi.getLossOfProductionMetrics();
            await this.lossOfProductionMetricsCache.saveAll(metrics);
            return metrics;
        });
    }
}
