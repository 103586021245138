import type { ImporterRepository, GeneratorsRepository } from '@/modules/ctx-admin-data-imports/adapter';
import { MetricsRepository } from '@/modules/ctx-admin-data-imports/adapter/MetricsRepository';
import { ImporterService } from '@/modules/ctx-admin-data-imports/services/ImporterService';
import { GeneratorService } from '@/modules/ctx-admin-data-imports/services/GeneratorService';
import { MetricService } from '@/modules/ctx-admin-data-imports/services/MetricService';

export interface PortParams {
    importerRepository: ImporterRepository;
    generatorsRepository: GeneratorsRepository;
    metricsRepository: MetricsRepository;
}

export class Port {
    private static port: Port|null = null;

    private readonly importer: ImporterService;
    private readonly generators: GeneratorService;
    private readonly metrics: MetricService;

    constructor(params: PortParams) {
        this.importer = new ImporterService({
            importerRepository: params.importerRepository,
        });
        this.generators = new GeneratorService({
            generatorsRepository: params.generatorsRepository,
        });
        this.metrics = new MetricService({
            metricsRepository: params.metricsRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get importer(): ImporterService {
        return Port.instance.importer;
    }

    public static get generators(): GeneratorService {
        return Port.instance.generators;
    }

    public static get metrics(): MetricService {
        return Port.instance.metrics;
    }
}
