/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportReferenceDate {
    Current = 'current',
    Previous = 'previous',
    Preprevious = 'preprevious'
}

export function ReportReferenceDateFromJSON(json: any): ReportReferenceDate {
    return ReportReferenceDateFromJSONTyped(json, false);
}

export function ReportReferenceDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportReferenceDate {
    return json as ReportReferenceDate;
}

export function ReportReferenceDateToJSON(value?: ReportReferenceDate | null): any {
    return value as any;
}

