/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LicenseFeatureRepresentation,
    LicenseFeatureRepresentationFromJSON,
    LicenseFeatureRepresentationFromJSONTyped,
    LicenseFeatureRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LicenseRepresentation
 */
export interface LicenseRepresentation {
    /**
     * 
     * @type {string}
     * @memberof LicenseRepresentation
     */
    key: string;
    /**
     * 
     * @type {Array<LicenseFeatureRepresentation>}
     * @memberof LicenseRepresentation
     */
    features: Array<LicenseFeatureRepresentation>;
}

export function LicenseRepresentationFromJSON(json: any): LicenseRepresentation {
    return LicenseRepresentationFromJSONTyped(json, false);
}

export function LicenseRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'features': ((json['features'] as Array<any>).map(LicenseFeatureRepresentationFromJSON)),
    };
}

export function LicenseRepresentationToJSON(value?: LicenseRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'features': ((value.features as Array<any>).map(LicenseFeatureRepresentationToJSON)),
    };
}


