/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReImportType,
    ReImportTypeFromJSON,
    ReImportTypeFromJSONTyped,
    ReImportTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReImportMarkerRepresentation
 */
export interface ReImportMarkerRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ReImportMarkerRepresentation
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof ReImportMarkerRepresentation
     */
    timeFrom: number;
    /**
     * 
     * @type {number}
     * @memberof ReImportMarkerRepresentation
     */
    timeTo: number;
    /**
     * 
     * @type {string}
     * @memberof ReImportMarkerRepresentation
     */
    generatorKey?: string;
    /**
     * 
     * @type {Array<ReImportType>}
     * @memberof ReImportMarkerRepresentation
     */
    requestedMetricTypes: Array<ReImportType>;
    /**
     * 
     * @type {number}
     * @memberof ReImportMarkerRepresentation
     */
    pendingMetricsCount: number;
}

export function ReImportMarkerRepresentationFromJSON(json: any): ReImportMarkerRepresentation {
    return ReImportMarkerRepresentationFromJSONTyped(json, false);
}

export function ReImportMarkerRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReImportMarkerRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'timeFrom': json['timeFrom'],
        'timeTo': json['timeTo'],
        'generatorKey': !exists(json, 'generatorKey') ? undefined : json['generatorKey'],
        'requestedMetricTypes': ((json['requestedMetricTypes'] as Array<any>).map(ReImportTypeFromJSON)),
        'pendingMetricsCount': json['pendingMetricsCount'],
    };
}

export function ReImportMarkerRepresentationToJSON(value?: ReImportMarkerRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'timeFrom': value.timeFrom,
        'timeTo': value.timeTo,
        'generatorKey': value.generatorKey,
        'requestedMetricTypes': ((value.requestedMetricTypes as Array<any>).map(ReImportTypeToJSON)),
        'pendingMetricsCount': value.pendingMetricsCount,
    };
}


