/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LossOfProductionMetricUpdateRequest
 */
export interface LossOfProductionMetricUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof LossOfProductionMetricUpdateRequest
     */
    name?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LossOfProductionMetricUpdateRequest
     */
    lc?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LossOfProductionMetricUpdateRequest
     */
    licenseFeatures?: Array<string>;
}

export function LossOfProductionMetricUpdateRequestFromJSON(json: any): LossOfProductionMetricUpdateRequest {
    return LossOfProductionMetricUpdateRequestFromJSONTyped(json, false);
}

export function LossOfProductionMetricUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LossOfProductionMetricUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lc': !exists(json, 'lc') ? undefined : json['lc'],
        'licenseFeatures': !exists(json, 'licenseFeatures') ? undefined : json['licenseFeatures'],
    };
}

export function LossOfProductionMetricUpdateRequestToJSON(value?: LossOfProductionMetricUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lc': value.lc,
        'licenseFeatures': value.licenseFeatures,
    };
}


