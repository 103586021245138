import type { RouteConfig } from 'vue-router';
import { Role } from '@/modules/shared/types';

const route: RouteConfig = {
    path: '/:customer/portfolios/:portfolioKey/generators',
    name: 'portfolio-generators',
    component: () => import(/* webpackChunkName: "ctx-portfolios" */ './PortfolioDetails.vue'),
    meta: {
        role: Role.USER,
        license: [],
        authenticated: true,
        navbar: true,
        footer: true,
        settingsTabs: false,
        portfolioTabs: false,
        onboarding: true,
        notifications: true,
    },
};

export { route };
