import type { Portfolio, PortfolioRepository } from '@/modules/ctx-dashboard';
import type { GeneratorsApi, PortfoliosApi } from '@/modules/shared/adapter';
import { i18n } from '@/plugins/i18n';

export class PortfolioRepositoryImpl implements PortfolioRepository {

    private readonly portfoliosApi: PortfoliosApi;
    private readonly generatorsApi: GeneratorsApi;

    constructor(apis: {
        portfolios: PortfoliosApi,
        generators: GeneratorsApi,
    }) {
        this.portfoliosApi = apis.portfolios;
        this.generatorsApi = apis.generators;
    }

    public onChange(cb: () => Promise<void>): void {
        this.portfoliosApi.onChange(cb);
    }

    public async getPortfolio(portfolioKey?: string): Promise<Portfolio> {
        if (!portfolioKey) {
            return this.getMainPortfolio();
        }
        const representation = await this.portfoliosApi.getPortfolioByKey(portfolioKey);
        if (!representation) {
            throw new Error('404 - Portfolio not found');
        }
        return {
            key: representation.key,
            name: representation.name,
            generatorCount: representation.generatorKeys?.length || 0,
            generators: representation.generatorKeys || [],
        };
    }

    public async getPortfolios(): Promise<Portfolio[]> {
        const mainPortfolio = await this.getMainPortfolio();
        const representations = await this.portfoliosApi.getPortfolios();
        return [mainPortfolio].concat(representations.map((representation) => ({
            key: representation.key,
            name: representation.name,
            generatorCount: representation.generatorKeys?.length || 0,
            generators: representation.generatorKeys || [],
        })));
    }

    private async getMainPortfolio(): Promise<Portfolio> {
        const allGenerators = await this.generatorsApi.getGenerators()
            .then((gens) => gens.map((it) => it.key));
        return {
            key: undefined,
            name: i18n.t('mainportfolio').toString(),
            generatorCount: allGenerators.length,
            generators: allGenerators,
        };
    }
}
