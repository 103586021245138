/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PiePiece,
    PiePieceFromJSON,
    PiePieceFromJSONTyped,
    PiePieceToJSON,
} from './';

/**
 * 
 * @export
 * @interface PieDataRepresentation
 */
export interface PieDataRepresentation {
    /**
     * 
     * @type {Array<PiePiece>}
     * @memberof PieDataRepresentation
     */
    data?: Array<PiePiece>;
}

export function PieDataRepresentationFromJSON(json: any): PieDataRepresentation {
    return PieDataRepresentationFromJSONTyped(json, false);
}

export function PieDataRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PieDataRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(PiePieceFromJSON)),
    };
}

export function PieDataRepresentationToJSON(value?: PieDataRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(PiePieceToJSON)),
    };
}


