
import { Component, Vue } from 'vue-property-decorator';
import NotificationService, { Notification } from '@/assets/js/services/NotificationService';

@Component({
    components: { },
})
export default class Notifications extends Vue {

    private notifications: Notification[] = [];

    public created() {
        NotificationService.onNotificationAdded = (n: Notification) => this.notifications.push(n);
        NotificationService.onNotificationRemoved = (n: Notification) => this.notifications = this.notifications.filter((value) => value !== n);
    }
}
