/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardTemplateUpdateRequest
 */
export interface DashboardTemplateUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateUpdateRequest
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardTemplateUpdateRequest
     */
    requiredLicenseFeatures?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardTemplateUpdateRequest
     */
    global?: boolean;
}

export function DashboardTemplateUpdateRequestFromJSON(json: any): DashboardTemplateUpdateRequest {
    return DashboardTemplateUpdateRequestFromJSONTyped(json, false);
}

export function DashboardTemplateUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardTemplateUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'requiredLicenseFeatures': !exists(json, 'requiredLicenseFeatures') ? undefined : json['requiredLicenseFeatures'],
        'global': !exists(json, 'global') ? undefined : json['global'],
    };
}

export function DashboardTemplateUpdateRequestToJSON(value?: DashboardTemplateUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'requiredLicenseFeatures': value.requiredLicenseFeatures,
        'global': value.global,
    };
}


