import { i18n } from '@/plugins/i18n';
import { LicenseFeature, ResourceType } from '@/modules/shared/types';
import type {
    TabDataOptions,
    TabGeneralOptions,
    TabMetricsOptions,
    WidgetWizardStep,
} from '@/components/widget-wizard';
import { WidgetWizardTab } from '@/components/widget-wizard';
import { WidgetUtils } from '@/components/widgets';
import { WidgetConfig } from '@/modules/ctx-dashboard';

enum DistributionMode {
    DistributionOverMetrics,
    DistributionOverResources,
    DistributionOverValues,
}

function mode(widget: WidgetConfig): DistributionMode|undefined {
    const widgetMetrics = widget.axis.flatMap((it) => it.metrics);
    if (widgetMetrics.length > 1) {
        return DistributionMode.DistributionOverMetrics;
    }
    const widgetResources = widgetMetrics.flatMap((it) => it.resources);
    if (widgetResources.length > 1) {
        return DistributionMode.DistributionOverResources;
    }
    if (widgetMetrics.length === 1 && widgetResources.length === 1) {
        return DistributionMode.DistributionOverValues;
    }
    return undefined;
}

const metrics: WidgetWizardStep<TabMetricsOptions> = {
    name: 'widget-wizard.tab.metrics',
    tab: WidgetWizardTab.Metrics,
    features: [LicenseFeature.ADVANCED],
    params: (widget) => {
        const params: Partial<TabMetricsOptions> = {
            axisTitles: ['Metrik'],
            minMetrics: 1,
        };
        // If the user has any axis with more than one metric, we have to show the metric name in the legend.
        // E.g. 'Bad Berleburg - Windgeschwindigkeit (Durchschnitt, Durchschnitt)'
        // Therefore only one datasource is allowed in this case.
        if (WidgetUtils.hasMultipleDistinctResources(widget)) {
            params.maxMetrics = 1;
            params.notice = i18n.t('widget-wizard.tab.metrics.limit').toString();
        }
        return params;
    },
};

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.Time,
    visible: (widget) => mode(widget) === DistributionMode.DistributionOverValues,
};

const timeWithAggregation: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.TimeNumber,
    visible: (widget) => mode(widget) !== DistributionMode.DistributionOverValues,
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: (widget) => {
        const params: Partial<TabDataOptions> = {
            forceSingleResourceList: true,
            enableDuplicateResources: false,
            resourceTypes: [
                ResourceType.Portfolio,
                ResourceType.Park,
                ResourceType.Generator,
                ResourceType.Shareholder,
                ResourceType.Generatorgroup,
                ResourceType.Anonymous,
            ],
        };

        // If the user has any axis with more than one metric, we have to show the metric name in the legend.
        // E.g. 'Bad Berleburg - Windgeschwindigkeit (Durchschnitt, Durchschnitt)'
        // Therefore only one datasource is allowed in this case.
        if (WidgetUtils.hasAxisWithMultipleMetrics(widget)) {
            params.maxDataSourcesTotal = 1;
            params.notices = (params.notices || []).concat([i18n.t('widget-wizard.tab.data.limit').toString()]);
        }
        return params;
    },
};

const dataAggregation: WidgetWizardStep = {
    name: 'widget-wizard.tab.aggregation-datasources',
    tab: WidgetWizardTab.AggregationDatasources,
    visible: (widget) => {
        if (mode(widget) === DistributionMode.DistributionOverValues) {
            return false;
        }
        return WidgetUtils.getDataSourcesNeedingAggregation(widget).length > 0;
    },
};

const distribution: WidgetWizardStep = {
    name: 'widget-wizard.tab.distribution',
    tab: WidgetWizardTab.Distribution,
    // this tab is only available if we have exactly one metric and one resource
    visible: (widget) => mode(widget) === DistributionMode.DistributionOverValues,
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    metrics,
    data,
    time,
    timeWithAggregation,
    distribution,
    dataAggregation,
    general,
];
