export default class MathUtils {
    public static sum(numbers: (number|undefined)[]|undefined): number {
        if (!numbers || numbers.length === 0) {
            return 0;
        }
        return MathUtils.parseNumbers(numbers).reduce((a, b) => a + b, 0);
    }

    public static avg(numbers: (number|undefined)[]|undefined): number {
        if (!numbers || numbers.length === 0) {
            return 0;
        }
        return (MathUtils.sum(numbers) / numbers.length) || 0;
    }

    public static min(numbers: (number|undefined)[]|undefined): number {
        if (!numbers || numbers.length === 0) {
            return 0;
        }
        return MathUtils.parseNumbers(numbers).reduce((a, b) => Math.min(a, b));
    }

    public static max(numbers: (number|undefined)[]|undefined): number {
        if (!numbers || numbers.length === 0) {
            return 0;
        }
        return MathUtils.parseNumbers(numbers).reduce((a, b) => Math.max(a, b));
    }

    public static round(number: number|undefined, digits: number = 2): number|undefined {
        if (number === undefined || number === null) {
            return undefined;
        }
        const factor = 10 ** digits;
        return Math.round((number + Number.EPSILON) * factor) / factor;
    }

    private static parseNumbers(numbers: any[]): number[] {
        return numbers.filter((n: any) => n !== undefined).map((n: any) => parseFloat(n) || 0);
    }
}
