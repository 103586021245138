import Vue from 'vue';
import type VueRouter from 'vue-router';
import { Module, ModuleOptions } from '@/modules/ctx-admin-loss-of-production';
import DefaultI18nRepository from '@/plugins/modules/default/DefaultI18nRepository';
import { Port } from '@/modules/shared/Port';
import { LossOfProductionMetricsRepositoryImpl } from './LossOfProductionMetricsRepositoryImpl';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        router: router,
        lossOfProductionMetricsRepository: new LossOfProductionMetricsRepositoryImpl({
            lossOfProductionMetrics: Port.lossOfProductionMetrics,
            generators: Port.generators,
        }),
        localizationRepository: new DefaultI18nRepository({
            localizations: Port.localizations,
        }),
    });
}
