/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LossOfProductionMetricVariantRepresentation,
    LossOfProductionMetricVariantRepresentationFromJSON,
    LossOfProductionMetricVariantRepresentationFromJSONTyped,
    LossOfProductionMetricVariantRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LossOfProductionMetricRepresentation
 */
export interface LossOfProductionMetricRepresentation {
    /**
     * Unique uuid key of this loss-of-production category
     * @type {string}
     * @memberof LossOfProductionMetricRepresentation
     */
    key: string;
    /**
     * Unique uuid key mapping to an i18n entry
     * @type {string}
     * @memberof LossOfProductionMetricRepresentation
     */
    name: string;
    /**
     * variants of this loss of rev category, has at least one entry for the global default variant
     * @type {Array<LossOfProductionMetricVariantRepresentation>}
     * @memberof LossOfProductionMetricRepresentation
     */
    variants: Array<LossOfProductionMetricVariantRepresentation>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LossOfProductionMetricRepresentation
     */
    licenseFeatures: Array<string>;
}

export function LossOfProductionMetricRepresentationFromJSON(json: any): LossOfProductionMetricRepresentation {
    return LossOfProductionMetricRepresentationFromJSONTyped(json, false);
}

export function LossOfProductionMetricRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LossOfProductionMetricRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'variants': ((json['variants'] as Array<any>).map(LossOfProductionMetricVariantRepresentationFromJSON)),
        'licenseFeatures': json['licenseFeatures'],
    };
}

export function LossOfProductionMetricRepresentationToJSON(value?: LossOfProductionMetricRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'variants': ((value.variants as Array<any>).map(LossOfProductionMetricVariantRepresentationToJSON)),
        'licenseFeatures': value.licenseFeatures,
    };
}


