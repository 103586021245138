
import { en, de } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DateUtils from '@/assets/js/utils/DateUtils';
import Dropdown from '@/modules/shared/components/util/Dropdown.vue';

@Component({
    components: { Dropdown, Datepicker },
})
export default class InputDate extends Vue {

    @Prop({ default: 'Label' })
    public readonly label!: string;

    @Prop({ default: undefined })
    public readonly value?: Date|string|number;

    @Prop({ default: 'dd. MMMM yyyy' })
    public readonly format!: string;

    @Prop({ default: false })
    public readonly readonly!: boolean;

    @Prop({ default: null })
    public readonly errors!: string[]|null;

    @Prop({ default: true })
    public readonly showErrors!: boolean;

    @Prop({ default: false })
    public readonly selectTime!: boolean;

    @Prop({ default: false })
    public readonly disabled!: boolean;

    @Prop({ required: false, default: null })
    public readonly placeholder!: Date|number|string;

    @Prop({ required: false, default: 'day' })
    public readonly minimumView!: 'month'|'day'|'year';

    @Prop({ required: false, default: false })
    public readonly exclusive!: boolean;

    @Prop({ required: false, default: undefined })
    public readonly disabledDates!: object|undefined;

    @Prop({ default: '' })
    public readonly hint!: string;

    private showDatepicker: boolean = false;
    private hasBeenFocused: boolean = false;

    @Watch('readonly')
    private readonlyChanged() {
        this.$forceUpdate();
    }

    private get displayValue(): Date|undefined {
        if (!this.value) {
            return undefined;
        }
        let date = new Date(this.value || Date.now());
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        if (this.exclusive) {
            // map value to inclusive
            switch (this.minimumView) {
                case 'day': date = DateUtils.subtract(date, { days: 1 }); break;
                case 'month': date = DateUtils.subtract(date, { months: 1 }); break;
                case 'year': date = DateUtils.subtract(date, { years: 1 }); break;
                default: date = DateUtils.subtract(date, { days: 1 });
            }
        }
        return date;
    }

    private get lang(): any {
        if (this.$i18n.locale === 'de') {
            return de;
        }
        return en;
    }

    private get hasErrors(): boolean {
        const errorsVisible = this.showErrors || this.hasBeenFocused;
        return errorsVisible && this.errors !== null && this.errors.length > 0;
    }

    private get errorText(): string {
        if (!this.errors) {
            return '';
        }
        return this.errors.join(', ');
    }

    private clear() {
        this.$emit('input', null);
        this.$emit('change', null);
    }

    private onDateChanged(date: Date) {
        this.hasBeenFocused = true;
        this.showDatepicker = false;
        if (!this.selectTime) {
            // only pass on date
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        }
        if (this.exclusive) {
            // map value to exclusive
            switch (this.minimumView) {
                case 'day': date = DateUtils.add(date, { days: 1 }); break;
                case 'month': date = DateUtils.add(date, { months: 1 }); break;
                case 'year': date = DateUtils.add(date, { years: 1 }); break;
                default: date = DateUtils.add(date, { days: 1 });
            }
        }
        this.$emit('input', date);
        this.$emit('change', date);
    }
}
