import type { WidgetWizardStep, TabGeneralOptions } from '@/components/widget-wizard';
import { WidgetWizardTab } from '@/components/widget-wizard';

const data: WidgetWizardStep = {
    name: 'widget-wizard.tab.note',
    tab: WidgetWizardTab.Note,
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    data,
    general,
];
