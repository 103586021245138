import { Middleware, ResponseContext } from '@/clients/dashboardapi/v2/runtime';

/**
 * Retry requests that failed with a 'Connection reset' error message. These errors sometimes happen in the backend and
 * appear to be random. Retrying the same request usually fixes the issue.
 */
export default class ConnectionResetMiddleware implements Middleware {

    public async post(context: ResponseContext): Promise<Response | void> {
        if (context.response.status === 500) {
            const message = await context.response.clone().text();
            if (message === 'Connection reset') {
                console.info('Retrying request after Connection reset.');
                return context.fetch(context.url, context.init);
            }
        }
        return Promise.resolve(context.response);
    }
}
