
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GenericTable, { TableColumn } from '@/modules/shared/components/table/GenericTable.vue';
import { Port } from '@/modules/shared/Port';

interface ShareHolder {
    key: string;
    name: string;
    number: number;
    selected: boolean;
}

@Component({
    components: { GenericTable },
})
export default class ShareHolderList extends Vue {

    @Prop({ required: false, default: () => [] })
    public readonly selected!: string[];

    @Prop({ required: false, default: () => [] })
    public readonly whitelist!: string[];

    @Prop({ required: false, default: () => [] })
    public readonly blacklist!: string[];

    @Prop({ required: false, default: null })
    public readonly maxSelectable!: number|null;

    @Prop({ required: false, default: '' })
    public readonly filter!: string;

    private readonly tableColumns: TableColumn[] = [
        { field: 'name', title: 'name', class: 'w-full' },
    ];
    private shareholders: ShareHolder[] = [];

    public created(): void {
        this.fetchShareHolders();
    }

    private get selectedShareholders(): ShareHolder[] {
        return this.shareholders.filter((shareholder) => this.selected.includes(shareholder.key));
    }

    private get sortedShareholders(): ShareHolder[] {
        return this.shareholders.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }

    @Watch('whitelist')
    @Watch('blacklist')
    @Watch('filter')
    private async fetchShareHolders(): Promise<void> {
        let shareholders = await Port.accounting.getShareholders();
        if (this.whitelist.length > 0) {
            shareholders = shareholders.filter((shareholder) => this.whitelist.includes(shareholder.key));
        }
        if (this.blacklist.length > 0) {
            shareholders = shareholders.filter((shareholder) => !this.blacklist.includes(shareholder.key));
        }
        if (this.filter) {
            const filters = this.filter.trim().toLowerCase().split(/\s/g);
            shareholders = shareholders.filter((shareholder) => {
                // any of the entered filter is found in shareholder id
                if (filters.find((filter) => shareholder.number.toString().includes(filter))) {
                    return true;
                }
                const shareholderName = shareholder.name.replace(/\s/g, '').toLowerCase();
                // each filter must match at least one of the indexed fields
                return !filters.find((filter) => !shareholderName.includes(filter));
            });
        }
        this.shareholders = shareholders.map((shareholder) => ({
            key: shareholder.key,
            name: shareholder.name,
            number: shareholder.number,
            selected: this.selected.includes(shareholder.key),
        }));
        this.$emit('update:count', this.shareholders.length);
    }

    private updateSelected(shareholders: ShareHolder[]): void {
        this.$emit('update:selected', shareholders.map((shareholder) => shareholder.key));
    }
}
