import { I18nAdapter } from '@/plugins/i18n';
import type {
    Metric,
    MetricsRepository,
    RawMetric,
} from '@/modules/ctx-admin-metrics';
import {
    MetricResolution,
} from '@/modules/ctx-admin-metrics';

import type {
    MetricsApi,
    CreateMetricRequest,
    MetricRepresentation,
    RawMetricRepresentation,
} from '@/modules/shared/adapter/MetricsApi';
import { Resolution as ResolutionDTO } from '@/modules/shared/adapter/MetricsApi';
import { FeatureRepresentation, LicenseApi } from '@/modules/shared/adapter';
import { LicenseFeature } from '@/modules/shared/types';

export class MetricsRepositoryImpl implements MetricsRepository {

    private readonly metricsApi: MetricsApi;
    private readonly initialization: Promise<any>;
    private readonly licenseApi: LicenseApi;

    constructor(apis: {
        license: LicenseApi;
        metrics: MetricsApi,
    }) {
        this.licenseApi = apis.license;
        this.metricsApi = apis.metrics;
        this.initialization = Promise.allSettled(I18nAdapter.locales.map((lc) => I18nAdapter.loadMessages(lc)));
    }

    public async getMetrics(): Promise<Metric[]> {
        const features = await this.licenseApi.getFeatures();
        const metricRepresentations = await this.metricsApi.getMetrics(true);
        const rawMetricRepresentations = await this.metricsApi.getRawMetrics();
        const metrics = metricRepresentations.map((it) => MetricsRepositoryImpl.mapMetricToDomain(it, features));
        return metrics.map((metric) => {
            metric.providers = rawMetricRepresentations
                .filter((rawMetric) => rawMetric.assignedMetricKey === metric.key)
                .map((rawMetric) => rawMetric.dataProviderKey);
            return metric;
        });
    }

    public async getMetricByKey(metricKey: string): Promise<Metric|undefined> {
        const features = await this.licenseApi.getFeatures();
        const metric = await this.metricsApi.getMetricByKey(metricKey);
        if (metric !== undefined) {
            return MetricsRepositoryImpl.mapMetricToDomain(metric, features);
        }
        return undefined;
    }

    public async getRawMetrics(): Promise<RawMetric[]> {
        const rawMetricRepresentations = await this.metricsApi.getRawMetrics();
        return rawMetricRepresentations.map(MetricsRepositoryImpl.mapRawMetricToDomain);
    }

    public async createMetric(metric: Metric, rawMetric: RawMetric): Promise<void> {
        const createMetricRequest: CreateMetricRequest = {
            rawMetricKey: rawMetric.key,
            dataProviderKey: rawMetric.provider,
            name: Object.values(metric.name),
            lc: Object.keys(metric.name),
            sourceUnit: metric.sourceUnitKey,
            // unit: Object.keys(spec.name).map((lc) => spec.unit[lc] || ''),
            category: metric.category,
            resolution: MetricsRepositoryImpl.mapResolutionToRepresentation(metric.resolution),
            min: metric.min,
            max: metric.max,
            licenseFeatures: metric.licenseFeatures,
        };
        await this.metricsApi.createMetric(createMetricRequest);
    }

    public async updateMetric(metric: Metric): Promise<void> {
        await this.metricsApi.updateMetric(metric.key, {
            lc: Object.keys(metric.name),
            name: Object.values(metric.name),
            sourceUnit: metric.sourceUnitKey,
            licenseFeatures: metric.licenseFeatures,
            min: metric.min,
            max: metric.max,
            parentKey: metric.parentMetricKey ? metric.parentMetricKey : '',
            inactive: metric.inactive || metric.internal,
        });
    }

    public async assignMetric(metricKey: string, rawMetric: RawMetric): Promise<void> {
        await this.metricsApi.assignRawMetric(rawMetric.key, rawMetric.provider, {
            key: metricKey,
        });
    }

    private static mapMetricToDomain(metricRepresentation: MetricRepresentation, licenseFeatures: FeatureRepresentation[]): Metric {
        const unit = metricRepresentation.sourceUnit || metricRepresentation.unit;
        return {
            key: metricRepresentation.key,
            nameKey: metricRepresentation.name,
            name: I18nAdapter.translateForAllLoadedLanguages(metricRepresentation.name),
            unit: I18nAdapter.translateForAllLoadedLanguages(unit),
            sourceUnitKey: unit,
            category: metricRepresentation.category,
            resolution: MetricsRepositoryImpl.mapResolutionToDomain(metricRepresentation.minResolution),
            licenseFeatures: metricRepresentation.licenseFeatures as LicenseFeature[],
            min: metricRepresentation.min,
            max: metricRepresentation.max,
            providers: [],
            parentMetricKey: metricRepresentation.parentKey,
            internal: metricRepresentation.internal,
            inactive: metricRepresentation.inactive || metricRepresentation.internal,
        };
    }

    private static mapRawMetricToDomain(rawMetricRepresentation: RawMetricRepresentation): RawMetric {
        return {
            key: rawMetricRepresentation.key,
            unit: rawMetricRepresentation.unit,
            name: rawMetricRepresentation.name,
            assignedMetricKey: rawMetricRepresentation.assignedMetricKey,
            provider: rawMetricRepresentation.dataProviderKey,
            category: rawMetricRepresentation.category,
            resolution: MetricsRepositoryImpl.mapResolutionToDomain(rawMetricRepresentation.resolution),
        };
    }

    private static mapResolutionToDomain(resolution?: ResolutionDTO): MetricResolution {
        switch (resolution) {
            case ResolutionDTO.Min10: return MetricResolution.Min10;
            case ResolutionDTO.Daily: return MetricResolution.Daily;
            case ResolutionDTO.Monthly: return MetricResolution.Monthly;
            default: throw new Error('Invalid resolutions');
        }
    }

    private static mapResolutionToRepresentation(resolution?: MetricResolution): ResolutionDTO {
        switch (resolution) {
            case MetricResolution.Min10: return ResolutionDTO.Min10;
            case MetricResolution.Daily: return ResolutionDTO.Daily;
            case MetricResolution.Monthly: return ResolutionDTO.Monthly;
            default: throw new Error('Invalid resolutions');
        }
    }
}
