
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GenericTable, { TableColumn } from '@/modules/shared/components/table/GenericTable.vue';
import { Port } from '@/modules/shared/Port';

interface Park {
    key: string;
    name: string;
    zipCode: string;
    generatorCount: number;
    selected: boolean;
}

@Component({
    components: { GenericTable },
})
export default class ParkList extends Vue {

    @Prop({ required: false, default: () => [] })
    public readonly selected!: string[];

    @Prop({ required: false, default: '' })
    public readonly portfolioKey!: string;

    @Prop({ required: false, default: () => [] })
    public readonly whitelist!: string[];

    @Prop({ required: false, default: () => [] })
    public readonly blacklist!: string[];

    @Prop({ required: false, default: null })
    public readonly maxSelectable!: number|null;

    @Prop({ required: false, default: '' })
    public readonly filter!: string;

    private readonly tableColumns: TableColumn[] = [
        { field: 'name', title: 'name', class: 'w-full' },
        { field: 'zipCode', title: 'zipcode', class: 'w-full' },
        { field: 'generatorCount', title: 'generator', class: 'w-full' },
    ];
    private parks: Park[] = [];

    public created(): void {
        this.fetchParks();
    }

    private get selectedParks(): Park[] {
        return this.parks.filter((shareholder) => this.selected.includes(shareholder.key));
    }

    private get sortedParks(): Park[] {
        return this.parks.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }

    @Watch('whitelist')
    @Watch('blacklist')
    @Watch('filter')
    private async fetchParks(): Promise<void> {
        let generators = await Port.generators.getGenerators();
        if (this.portfolioKey) {
            const portfolio = await Port.portfolios.getPortfolioByKey(this.portfolioKey);
            generators = generators.filter((gen) => portfolio?.generatorKeys?.includes(gen.key));
        }
        let parks: Park[] = (await Port.parks.getParks()).map((park) => ({
            key: park.key,
            name: park.name,
            zipCode: park.zipCode || '',
            generatorCount: generators.filter((gen) => gen.parkKey === park.key).length,
            selected: this.selected.includes(park.key),
        }));
        if (this.whitelist.length > 0) {
            parks = parks.filter((park) => this.whitelist.includes(park.key));
        }
        if (this.blacklist.length > 0) {
            parks = parks.filter((park) => !this.blacklist.includes(park.key));
        }
        if (this.filter) {
            const filters = this.filter.trim().toLowerCase().split(/\s/g);
            parks = parks.filter((park) => {
                // any of the entered filter is found in zipcode
                if (filters.find((filter) => park.zipCode.toLowerCase().includes(filter))) {
                    return true;
                }
                const parkname = park.name.replace(/\s/g, '').toLowerCase();
                // each filter must match at least one of the indexed fields
                return !filters.find((filter) => !parkname.includes(filter));

            });
        }
        this.parks = parks;
        this.$emit('update:count', this.parks.length);
    }

    private updateSelected(parks: Park[]): void {
        this.$emit('update:selected', parks.map((park) => park.key));
    }
}
