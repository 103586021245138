import router from '@/plugins/router';
import { registerModule as registerCtxAdminMetrics } from './ctx-admin-metrics';
import { registerModule as registerCtxAdminAccounting } from './ctx-admin-accounting';
import { registerModule as registerCtxAdminCustomers } from './ctx-admin-customers';
import { registerModule as registerCtxAdminDataImports } from './ctx-admin-data-imports';
import { registerModule as registerCtxAdminFunctions } from './ctx-admin-functions';
import { registerModule as registerCtxAdminLossOfProduction } from './ctx-admin-loss-of-production';
import { registerModule as registerCtxDashboard } from './ctx-dashboard';
import { registerModule as registerCtxUsers } from './ctx-users';
import { registerModule as registerCtxPortfolioTemplates } from './ctx-portfolios';
import { registerModule as registerCtxReportPreview } from './ctx-report-preview';
import { registerModule as registerCtxDocuments } from './ctx-documents';

// user modules
registerCtxDashboard(router);
registerCtxUsers(router);
registerCtxPortfolioTemplates(router);
registerCtxDocuments(router);

// reporting
registerCtxReportPreview(router);

// admin modules
registerCtxAdminCustomers(router);
registerCtxAdminMetrics(router);
registerCtxAdminAccounting(router);
registerCtxAdminDataImports(router);
registerCtxAdminFunctions(router);
registerCtxAdminLossOfProduction(router);
