import type { AdminRepository } from '@/modules/ctx-admin-functions';
import type { DataApi } from '@/modules/shared/adapter';
import { UploadCSVDataTypeEnum } from '@/modules/shared/adapter';

export class AdminRepositoryImpl implements AdminRepository {

    private readonly dataApi: DataApi;

    constructor(apis: {
        data: DataApi,
    }) {
        this.dataApi = apis.data;
    }

    public async uploadPlanDataCSV(blob: Blob): Promise<void> {
        await this.dataApi.uploadDataCSV(UploadCSVDataTypeEnum.MonthlyPlan, blob);
    }

    public async uploadPlanBasicDataCSV(blob: Blob): Promise<void> {
        await this.dataApi.uploadDataCSV(UploadCSVDataTypeEnum.MonthlyPlanBasicData, blob);
    }

    public async uploadCommissionMarketvaluesCSV(blob: Blob): Promise<void> {
        await this.dataApi.uploadDataCSV(UploadCSVDataTypeEnum.CommissionMarketvalues, blob);
    }
}
