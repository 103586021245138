import { LocalizationApi } from '@/modules/shared/adapter';
import { i18n, I18nAdapter, I18nChangedEventType } from '@/plugins/i18n';

export default class DefaultI18nRepository {

    private readonly localizationsApi: LocalizationApi;

    constructor(apis: {
        localizations: LocalizationApi;
    }) {
        this.localizationsApi = apis.localizations;
    }

    /**
     * Event called when the active language was changed
     */
    public onLanguageChanged(cb: (lang: string) => void): void {
        I18nAdapter.onChange((event) => {
            if (event.type === I18nChangedEventType.LanguageChanged) {
                cb(event.lang);
            }
        });
    }

    /**
     * Event called when the language was loaded or reloaded
     */
    public onLanguageLoaded(cb: (lang: string) => void): void {
        I18nAdapter.onChange((event) => {
            if (event.type === I18nChangedEventType.LanguageLoaded) {
                cb(event.lang);
            }
        });
    }

    public requestReload(lc?: string): void {
        this.localizationsApi.requestReload(lc);
    }

    public t(key: string): string {
        return i18n.t(key).toString();
    }

    public tc(key: string, count: number): string {
        return i18n.tc(key, count).toString();
    }
}
