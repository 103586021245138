import type { WidgetError } from '@/components/widgets/components/WidgetErrorMessage.vue';
import { WidgetErrorType } from '@/components/widgets/components/WidgetErrorMessage.vue';
import type { WidgetConfig, WidgetConfigAxis, WidgetConfigMetric, WidgetConfigResource } from '@/modules/ctx-dashboard';
import { Port } from '@/modules/shared/Port';
import { markRaw } from 'vue';

export class WidgetValidationUtils {

    public static validateMaxTotalResources(widget: WidgetConfig, maxTotal?: number): boolean {
        if (maxTotal === undefined || maxTotal < 0) {
            return true;
        }
        const metricsCount = widget.axis.flatMap((axis) => axis.metrics).length;
        // It must be possible to select at least as many resources as there are metrics
        const maxResources = Math.max(metricsCount, maxTotal);
        return WidgetValidationUtils.getTotalResourceCount(widget) <= maxResources;
    }

    public static validateMaxResourcesPerMetric(widget: WidgetConfig, maxPerMetric?: number|number[]): boolean {
        if (maxPerMetric === undefined) {
            return true;
        }
        const metrics = widget.axis.flatMap((axis) => axis.metrics);
        const maxDataSourcesPerMetric = Array.isArray(maxPerMetric)
            ? maxPerMetric
            : new Array<number>().fill(maxPerMetric, 0, metrics.length);
        const metricsWithToManyResources = metrics
            .filter((metric, metricIndex) => metric.resources.length > maxDataSourcesPerMetric[metricIndex]);
        return metricsWithToManyResources.length === 0;
    }

    public static validateHasAnyResource(widget: WidgetConfig): WidgetError|null {
        const generators = WidgetValidationUtils.getTotalResourceCount(widget);
        if (generators === 0) {
            return { type: WidgetErrorType.NoDataSource };
        }
        return null;
    }

    public static validateMetricsHaveResources(widget: WidgetConfig, ignoredMetricIndices: number[] = []): WidgetError|null {
        const metricsWithoutResources = widget.axis
            .flatMap((axis) => axis.metrics)
            .filter((metric, index) => !ignoredMetricIndices.includes(index))
            .filter((metric) => metric.metricKey)
            .filter((metric) => metric.resources.length === 0);
        if (metricsWithoutResources.length > 0) {
            return { type: WidgetErrorType.IncompleteDataSources };
        }
        return null;
    }

    public static validateHasMetrics(widget: WidgetConfig): WidgetError|null {
        const metrics = widget.axis.flatMap((axis) => axis.metrics);
        if (metrics.length === 0) {
            return { type: WidgetErrorType.MissingMetrics };
        }
        return null;
    }

    public static validateMetricCount(widget: WidgetConfig, min: number, max: number): WidgetError|null {
        const metrics = widget.axis.flatMap((axis) => axis.metrics);
        if (metrics.length < min) {
            return { type: WidgetErrorType.InvalidConfig };
        }
        if (metrics.length > max) {
            return { type: WidgetErrorType.InvalidConfig };
        }
        return null;
    }

    public static async validateMetricPermissions(widget: WidgetConfig): Promise<WidgetError|null> {
        const availableMetricKeys = await Port.metrics.getMetrics(false)
            .then((metrics) => metrics.map((it) => it.key));
        const widgetMetricKeys = widget.axis
            .flatMap((axis) => axis.metrics)
            .map((metric) => metric.metricKey);
        const invalidMetrics = widgetMetricKeys.filter((metricKey: string) => metricKey && !availableMetricKeys.includes(metricKey));
        if (invalidMetrics.length > 0) {
            return { type: WidgetErrorType.NoPermissionForMetric };
        }
        return null;
    }

    public static validateHasLicenseFeature(widget: WidgetConfig): WidgetError|null {
        // TODO refactor with new preset loading
        // const preset = Presets.byName(widget.preset);
        // if (preset && preset.requireLicenseFeatures
        //     && !store.getters['User/hasAllFeatureLicenses'](preset.requireLicenseFeatures)) {
        //     return { type: WidgetErrorType.MissingLicenseFeature };
        // }
        return null;
    }

    private static getTotalResourceCount(widget: WidgetConfig): number {
        return widget.axis
            .flatMap((axis) => axis.metrics)
            .flatMap((metric) => metric.resources)
            .map(WidgetValidationUtils.mapToResourceCount)
            .reduce((sum, value) => value + sum, 0);
    }

    private static getAxisResourceCount(axis: WidgetConfigAxis): number {
        return axis.metrics
            .flatMap((metric) => metric.resources)
            .map(WidgetValidationUtils.mapToResourceCount)
            .reduce((sum, value) => value + sum, 0);
    }

    private static getMetricResourceCount(metric: WidgetConfigMetric): number {
        return metric.resources
            .map(WidgetValidationUtils.mapToResourceCount)
            .reduce((sum, value) => value + sum, 0);
    }

    private static mapToResourceCount(resource: WidgetConfigResource): number {
        // return resource.timeOverride ? 2 : 1;
        return 1;
    }
}
