// adapter definitions
import type {
    AccountingApi,
    LicenseApi,
    MetricsApi,
    UsersApi,
    CompaniesApi,
    GeneratorsApi,
    LocalizationApi,
    DashboardsApi,
    WidgetTemplatesApi,
    UserSettingsApi,
    PortfolioTemplatesApi,
    DashboardTemplatesApi,
    ImportsApi,
    ConfigApi,
    GeneratorStatusApi, LossOfProductionMetricsApi, DocumentsApi,
} from '@/modules/shared/adapter';

// utils
import { IndexedDB } from '@/modules/shared/indexeddb';

// adapter implementations
import { CachedCompaniesRestApi } from '@/modules/shared/adapter/rest/v2/CachedCompaniesRestApi';
import { CachedUsersRestApi } from '@/modules/shared/adapter/rest/v2/CachedUsersRestApi';
import { CachedGeneratorsRestApi } from '@/modules/shared/adapter/rest/v2/CachedGeneratorsRestApi';
import { CachedLicenseRestApi } from '@/modules/shared/adapter/rest/v2/CachedLicenseRestApi';
import { CachedMetricsRestApi } from '@/modules/shared/adapter/rest/v2/CachedMetricsRestApi';
import { CachedAccountingRestApi } from '@/modules/shared/adapter/rest/v2/CachedAccountingRestApi';
import { ParksApi } from '@/modules/shared/adapter/ParksApi';
import { CachedParksRestApi } from '@/modules/shared/adapter/rest/v2/CachedParksRestApi';
import { PortfoliosApi } from '@/modules/shared/adapter/PortfoliosApi';
import { CachedPortfoliosRestApi } from '@/modules/shared/adapter/rest/v2/CachedPortfoliosRestApi';
import { AuthApi } from '@/modules/shared/adapter/AuthApi';
import { CachedAuthRestApi } from '@/modules/shared/adapter/rest/v2/CachedAuthRestApi';
import { LocalizationRestApi } from '@/modules/shared/adapter/rest/v2/LocalizationRestApi';
import { WidgetsApi } from '@/modules/shared/adapter/WidgetsApi';
import { CachedWidgetsRestApi } from '@/modules/shared/adapter/rest/v2/CachedWidgetsRestApi';
import { CachedDashboardsRestApi } from '@/modules/shared/adapter/rest/v2/CachedDashboardsRestApi';
import { CachedWidgetTemplatesRestApi } from '@/modules/shared/adapter/rest/v2/CachedWidgetTemplatesRestApi';
import { UserSettingsLocalstorageApi } from '@/modules/shared/adapter/rest/v2/UserSettingsLocalstorageApi';
import { CachedPortfolioTemplatesRestApi } from '@/modules/shared/adapter/rest/v2/CachedPortfolioTemplatesRestApi';
import { CachedDashboardTemplatesRestApi } from '@/modules/shared/adapter/rest/v2/CachedDashboardTemplatesRestApi';
import { DataApi } from '@/modules/shared/adapter/DataApi';
import { CachedDataRestApi } from '@/modules/shared/adapter/rest/v2/CachedDataRestApi';
import { ImportsRestApi } from '@/modules/shared/adapter/rest/v2/ImportsRestApi';
import { ReportsApi } from '@/modules/shared/adapter/ReportsApi';
import { CachedReportingRestApi } from '@/modules/shared/adapter/rest/v2/CachedReportingRestApi';
import { ConfigRestApi } from '@/modules/shared/adapter/rest/v2/ConfigRestApi';
import { CachedGeneratorStatusRestApi } from '@/modules/shared/adapter/rest/v2/CachedGeneratorStatusRestApi';
import {
    CachedLossOfProductionMetricsRestRepository,
} from '@/modules/shared/adapter/rest/v2/CachedLossOfProductionMetricsRestRepository';
import { DocumentsRestApi } from '@/modules/shared/adapter/rest/v2/DocumentsRestApi';

export class Port {

    private static databaseName: string = 'Halvar';
    private static storeNames: string[] = [
        'accounts',
        'account-groups',
        'features',
        'metrics',
        'raw-metrics',
        'shareholder',
        'companies',
        'users',
        'admins',
        'generators',
        'parks',
        'portfolios',
        'widgets',
        'dashboards',
        'dashboard-templates',
        'widget-templates',
        'portfolio-templates',
        'data',
        'report-configs',
        'coverage',
        'generator-status',
        'loss-of-production-metrics',
    ];
    private static database: Promise<IDBDatabase> = IndexedDB.getConnection(Port.databaseName, Port.storeNames, 14);

    public static auth: AuthApi = new CachedAuthRestApi();
    public static localizations: LocalizationApi = new LocalizationRestApi({ auth: Port.auth });
    public static userSettings: UserSettingsApi = new UserSettingsLocalstorageApi();

    public static accounting: AccountingApi = new CachedAccountingRestApi(Port.database, { auth: Port.auth, i18n: Port.localizations });
    public static license: LicenseApi = new CachedLicenseRestApi(Port.database, { auth: Port.auth });
    public static metrics: MetricsApi = new CachedMetricsRestApi(Port.database, { auth: Port.auth, i18n: Port.localizations });
    public static companies: CompaniesApi = new CachedCompaniesRestApi(Port.database, { auth: Port.auth });
    public static users: UsersApi = new CachedUsersRestApi(Port.database, { auth: Port.auth });
    public static generators: GeneratorsApi = new CachedGeneratorsRestApi(Port.database, { auth: Port.auth });
    public static parks: ParksApi = new CachedParksRestApi(Port.database, { auth: Port.auth });
    public static widgets: WidgetsApi = new CachedWidgetsRestApi(Port.database, { auth: Port.auth, settings: Port.userSettings });
    public static widgetTemplates: WidgetTemplatesApi = new CachedWidgetTemplatesRestApi(Port.database, { auth: Port.auth });
    public static dashboards: DashboardsApi = new CachedDashboardsRestApi(Port.database, { auth: Port.auth });
    public static dashboardTemplates: DashboardTemplatesApi = new CachedDashboardTemplatesRestApi(Port.database, { auth: Port.auth });
    public static portfolios: PortfoliosApi = new CachedPortfoliosRestApi(Port.database, { auth: Port.auth });
    public static portfolioTemplates: PortfolioTemplatesApi = new CachedPortfolioTemplatesRestApi(Port.database, { auth: Port.auth });
    public static data: DataApi = new CachedDataRestApi(Port.database, { auth: Port.auth });
    public static imports: ImportsApi = new ImportsRestApi({ auth: Port.auth });
    public static reports: ReportsApi = new CachedReportingRestApi(Port.database, { auth: Port.auth });
    public static configApi: ConfigApi = new ConfigRestApi({ auth: Port.auth });
    public static generatorStatus: GeneratorStatusApi = new CachedGeneratorStatusRestApi(Port.database, { auth: Port.auth });
    public static lossOfProductionMetrics: LossOfProductionMetricsApi = new CachedLossOfProductionMetricsRestRepository(Port.database, { auth: Port.auth, i18n: Port.localizations });
    public static documents: DocumentsApi = new DocumentsRestApi({ auth: Port.auth });
}

// register cache invalidation hooks
// some data is linked together across apis, so if an item in API a gets changed the cache for API b must be invalidated

// remove dashboards of a deleted portfolio
Port.portfolios.onChange((event: any) => {
    if (event.action === 'remove' || event.action === 'clear') {
        return (Port.dashboards as CachedDashboardsRestApi).invalidate();
    }
    return Promise.resolve();
});

// remove favorites when a dashboard gets deleted
Port.dashboards.onChange((event: any) => {
    if (event.action === 'remove' || event.action === 'clear') {
        return (Port.widgets as CachedWidgetsRestApi).invalidateFavorites();
    }
    return Promise.resolve();
});

// reload metrics when a new loss of production metric is created
Port.lossOfProductionMetrics.onChange((event: any) => {
    if (event.action === 'update') {
        return (Port.metrics as CachedMetricsRestApi).invalidate();
    }
    return Promise.resolve();
});

// expose on window object for debugging access
(window as any).Port = Port;
