/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HistoricMeanSpan,
    HistoricMeanSpanFromJSON,
    HistoricMeanSpanFromJSONTyped,
    HistoricMeanSpanToJSON,
    SimpleDataRequestResource,
    SimpleDataRequestResourceFromJSON,
    SimpleDataRequestResourceFromJSONTyped,
    SimpleDataRequestResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface OperatorPrognosisMonetaryRequest
 */
export interface OperatorPrognosisMonetaryRequest {
    /**
     * 
     * @type {number}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    timeFrom: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    timeTo: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    referenceDate?: number;
    /**
     * 
     * @type {Array<SimpleDataRequestResource>}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    resources: Array<SimpleDataRequestResource>;
    /**
     * budget production metric key
     * @type {string}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    budgetProductionMetricKey: string;
    /**
     * optional forecast production metric key
     * @type {string}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    forecastProductionMetricKey?: string;
    /**
     * DFÜ production metric key
     * @type {string}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    dfueProductionMetricKey: string;
    /**
     * EVU production metric key
     * @type {string}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    evuProductionMetricKey: string;
    /**
     * Einsman percent value
     * @type {number}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    einsmanPercent: number;
    /**
     * 
     * @type {HistoricMeanSpan}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    historicMeanSpan?: HistoricMeanSpan;
    /**
     * optional manual value to fill future values of plan commission in ct/kWh
     * @type {number}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    manualCommissionPlan?: number;
    /**
     * optional manual value to fill future values of actual commission in ct/kWh
     * @type {number}
     * @memberof OperatorPrognosisMonetaryRequest
     */
    manualCommissionActual?: number;
}

export function OperatorPrognosisMonetaryRequestFromJSON(json: any): OperatorPrognosisMonetaryRequest {
    return OperatorPrognosisMonetaryRequestFromJSONTyped(json, false);
}

export function OperatorPrognosisMonetaryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorPrognosisMonetaryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeFrom': json['timeFrom'],
        'timeTo': json['timeTo'],
        'referenceDate': !exists(json, 'referenceDate') ? undefined : json['referenceDate'],
        'resources': ((json['resources'] as Array<any>).map(SimpleDataRequestResourceFromJSON)),
        'budgetProductionMetricKey': json['budgetProductionMetricKey'],
        'forecastProductionMetricKey': !exists(json, 'forecastProductionMetricKey') ? undefined : json['forecastProductionMetricKey'],
        'dfueProductionMetricKey': json['dfueProductionMetricKey'],
        'evuProductionMetricKey': json['evuProductionMetricKey'],
        'einsmanPercent': json['einsmanPercent'],
        'historicMeanSpan': !exists(json, 'historicMeanSpan') ? undefined : HistoricMeanSpanFromJSON(json['historicMeanSpan']),
        'manualCommissionPlan': !exists(json, 'manualCommissionPlan') ? undefined : json['manualCommissionPlan'],
        'manualCommissionActual': !exists(json, 'manualCommissionActual') ? undefined : json['manualCommissionActual'],
    };
}

export function OperatorPrognosisMonetaryRequestToJSON(value?: OperatorPrognosisMonetaryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeFrom': value.timeFrom,
        'timeTo': value.timeTo,
        'referenceDate': value.referenceDate,
        'resources': ((value.resources as Array<any>).map(SimpleDataRequestResourceToJSON)),
        'budgetProductionMetricKey': value.budgetProductionMetricKey,
        'forecastProductionMetricKey': value.forecastProductionMetricKey,
        'dfueProductionMetricKey': value.dfueProductionMetricKey,
        'evuProductionMetricKey': value.evuProductionMetricKey,
        'einsmanPercent': value.einsmanPercent,
        'historicMeanSpan': HistoricMeanSpanToJSON(value.historicMeanSpan),
        'manualCommissionPlan': value.manualCommissionPlan,
        'manualCommissionActual': value.manualCommissionActual,
    };
}


