/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * email of the user
     * @type {string}
     * @memberof UserUpdateRequest
     */
    email?: string;
    /**
     * optional users first name
     * @type {string}
     * @memberof UserUpdateRequest
     */
    firstname?: string;
    /**
     * optional users last name
     * @type {string}
     * @memberof UserUpdateRequest
     */
    lastname?: string;
}

export function UserUpdateRequestFromJSON(json: any): UserUpdateRequest {
    return UserUpdateRequestFromJSONTyped(json, false);
}

export function UserUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
    };
}

export function UserUpdateRequestToJSON(value?: UserUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstname': value.firstname,
        'lastname': value.lastname,
    };
}


