import type { RouteConfig } from 'vue-router';
import { Role } from '@/modules/shared/types';

const route: RouteConfig = {
    path: '/:customer/metrics',
    name: 'admin-metrics',
    component: () => import(/* webpackChunkName: "ctx-admin-metrics" */ './Metrics.vue'),
    meta: {
        role: Role.HALVAR_ADMIN,
        license: [],
        authenticated: true,
        settingsTabs: true,
        settingsTabsGroup: 'halvar-admin',
        portfolioTabs: false,
        navbar: true,
        footer: true,
        onboarding: true,
        notifications: true,
    },
};

export { route };
