
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Role } from '@/modules/shared/types';
import { Port } from '@/modules/shared/Port';

@Component({})
export default class RoleGuard extends Vue {

    @Prop({ required: true, default: Role.HALVAR_ADMIN })
    public readonly role!: Role;

    private isCompanyAdmin = false;
    private isHalvarAdmin = false;

    public created(): void {
        Port.auth.isCompanyAdmin().then((isAdmin) => (this.isCompanyAdmin = isAdmin));
        Port.auth.isAdmin().then((isAdmin) => (this.isHalvarAdmin = isAdmin));
    }

    private get hasRequiredRole(): boolean {
        switch (this.role) {
            case Role.HALVAR_ADMIN: return this.isHalvarAdmin;
            case Role.COMPANY_ADMIN: return this.isHalvarAdmin || this.isCompanyAdmin;
            case Role.USER: return true;
            default: return false;
        }
    }

}
