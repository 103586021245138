import { i18n } from '@/plugins/i18n';
import type {
    WidgetConfig,
    WidgetConfigAxis,
    WidgetConfigMetric,
    WidgetConfigResource,
    WidgetConfigResourceFilter,
} from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import {
    Aggregation,
    GeneratorRepresentation,
    MetricCategory,
    MetricRepresentation,
    ResourceFilterName,
    ResourceType,
} from '@/clients/dashboardapi/v2';
import { WidgetWizardStep, WidgetWizardTab } from '@/components/widget-wizard';
import { Port } from '@/modules/shared/Port';

export class WidgetUtils {

    public static hasAxisWithMultipleMetrics(widget: WidgetConfig): boolean {
        return widget.axis.find((it) => it.metrics.length > 1) !== undefined;
    }

    public static hasMultipleDistinctResources(widget: WidgetConfig): boolean {
        return this.getDistinctResources(widget).length > 1;
    }

    public static getDistinctResources(widget: WidgetConfig): WidgetConfigResource[] {
        return widget.axis
            .flatMap((a) => a.metrics)
            .flatMap((m) => m.resources)
            .filter((resource, index, array) => index === array
                .findIndex((it) => WidgetUtils.testResourcesAreEqual(it, resource)));
    }

    public static testResourcesAreEqual(a: WidgetConfigResource, b: WidgetConfigResource, matchAggregation: boolean = false): boolean {
        if (a.type !== b.type
            || (a.timeOverride || '') !== (b.timeOverride || '')
            || (a.resourceFilters || []).length !== (b.resourceFilters || []).length) {
            return false;
        }
        const resourceTypesWithUniqueKey = [ResourceType.Park, ResourceType.Portfolio, ResourceType.Generator, ResourceType.Shareholder];
        if (resourceTypesWithUniqueKey.includes(a.type) && a.resourceKey !== b.resourceKey) {
            return false;
        }
        const resourceTypesWithoutAggregation = [ResourceType.Generator, ResourceType.Anonymous];
        if (matchAggregation && !resourceTypesWithoutAggregation.includes(a.type) && a.aggregationOverGenerators !== b.aggregationOverGenerators) {
            return false;
        }
        // match filters ignoring order
        if (a.resourceFilters && b.resourceFilters) {
            const filterMap = new Map<string, string>();
            a.resourceFilters.map((it) => filterMap.set(it.filterName, it.filterValue));
            b.resourceFilters.map((it) => filterMap.set(it.filterName, it.filterValue));
            if (a.resourceFilters.length !== filterMap.size) {
                // resourceFilters have at least one different key
                return false;
            }
            if (a.resourceFilters.find((it) => filterMap.get(it.filterName) !== it.filterValue)) {
                // at least one filter value does not match
                return false;
            }
            if (b.resourceFilters.find((it) => filterMap.get(it.filterName) !== it.filterValue)) {
                // at least one filter value does not match
                return false;
            }
        }
        return true;
    }

    public static getDefaultAxisName(axis: WidgetConfigAxis): string {
        if (axis.metrics.length === 1) {
            const metric = axis.metrics[0];
            if (axis.unit) {
                return `${metric.metricName} [${axis.unit}]`;
            }
            return metric.metricName;
        }
        return axis.unit;
    }

    /**
     * Returns the default name for a widget series. If the axis has only one metric, the metric name can be shown on
     * the axis. In this case the series name will be the resource name. If the axis has multiple metrics, the series
     * name must contain the metric name, as we can no longer show it on the axis.
     */
    public static getDefaultSeriesName(widget: WidgetConfig, metric: WidgetConfigMetric, resource: WidgetConfigResource): string {
        let resourceName = resource.resourceName;
        if (resource.type === ResourceType.Generatorgroup) {
            resourceName = resource.seriesName || i18n.t('widget-wizard.resourcetype.generatorgroup').toString();
        }

        const uniqueResource = !WidgetUtils.hasMultipleDistinctResources(widget);
        const uniqueMetric = !WidgetUtils.hasAxisWithMultipleMetrics(widget);

        let name = '';
        if (uniqueMetric) {
            name = resourceName;
        } else if (uniqueResource) {
            name = metric.metricName;
        } else {
            name = `${metric.metricName} [${resourceName}]`;
        }

        if (resource.timeOverride) {
            name += ` (${i18n.t(`widgets.interval.${resource.timeOverride}`)})`;
        }
        return name;
    }

    public static getDefaultWidgetTitle(widget: WidgetConfig): string {
        // distinct list of resources
        const resourceNames: string[] = widget.axis
            .flatMap((axis) => axis.metrics)
            .flatMap((metric) => metric.resources)
            .map((resource) => resource.resourceName)
            .filter(ArrayUtils.removeDuplicates);

        // distinct list of metrics
        const metricNames: string[] = widget.axis
            .flatMap((axis) => axis.metrics)
            .map((metric) => metric.metricName)
            .filter(ArrayUtils.removeDuplicates);

        // distinct list of aggregations (only used when there is only one metric)
        let aggregations: string[] = [];
        if (widget.intervalName === 'newest') {
            aggregations = [i18n.t('widgets.title.aggregation.newest').toString()];
        } else if (metricNames.length === 1) {
            aggregations = widget.axis
                .flatMap((axis) => axis.metrics)
                .map((metric) => metric.aggregationOverTime)
                .filter((agg) => agg !== Aggregation.None)
                .filter(ArrayUtils.removeDuplicates)
                .map((aggregation) => i18n.t(`widgets.title.aggregation.${aggregation}`).toString());
        }

        // special widget titles
        if (widget.type === WidgetType.ScatterChart && metricNames.length === 2) {
            return i18n.t('widgets.title.with-metric.scatterchart', { metricX: metricNames[0], metricY: metricNames[1] }).toString();
        }

        // generate default widget title from preset name, metric and resource
        const params: any = {
            aggregation: aggregations.length === 1 ? aggregations[0] : '',
            metric: metricNames.length === 1 ? metricNames[0] : '',
            resource: resourceNames.length === 1 ? resourceNames[0] : '',
        };

        let title = WidgetUtils.tryI18nKey(`widgets.title.for-preset.${widget.preset}`) || 'Widget';
        if (params.metric) {
            title = WidgetUtils.tryI18nKey(`widgets.title.with-metric.${widget.type}`, params) || title;
        }
        if (params.resource) {
            title = `${title} [${params.resource}]`;
        }
        return title;
    }

    public static async getDefaultResourceName(resource: {
        resourceType: ResourceType,
        resourceKey?: string,
        resourceFilters?: WidgetConfigResourceFilter[],
        portfolioKey?: string,
        seriesName?: string,
    }): Promise<string> {
        switch (resource.resourceType) {
            case ResourceType.Generator: {
                if (resource.resourceKey === undefined) {
                    return '';
                }
                const generator: GeneratorRepresentation|undefined = await Port.generators.getGeneratorByKey(resource.resourceKey);
                if (!generator) {
                    return i18n.t('widget-wizard.resourcetype.generator').toString();
                }
                let hasMultipleParks = resource.portfolioKey === undefined;
                if (resource.portfolioKey) {
                    const portfolio = await Port.portfolios.getPortfolioByKey(resource.portfolioKey);
                    const generators = await Port.generators.getGeneratorsByKeys(portfolio?.generatorKeys || []);
                    const parks = generators
                        .map((gen) => gen.parkKey)
                        .filter(ArrayUtils.removeDuplicates);
                    hasMultipleParks = parks.length > 1;
                }
                if (hasMultipleParks) {
                    const park = await Port.parks.getParkByKey(generator?.parkKey || '');
                    if (park) {
                        return `${generator.name} - ${park.name}`;
                    }
                }
                return generator.name;
            }
            case ResourceType.Park: {
                if (resource.resourceKey === undefined) {
                    return '';
                }
                const park = await Port.parks.getParkByKey(resource.resourceKey);
                return park?.name || i18n.t('widget-wizard.resourcetype.park').toString();
            }
            case ResourceType.Portfolio: {
                if (resource.resourceKey === undefined) {
                    return '';
                }
                const portfolio = await Port.portfolios.getPortfolioByKey(resource.resourceKey);
                return portfolio?.name || i18n.t('widget-wizard.resourcetype.portfolio').toString();
            }
            case ResourceType.Shareholder: {
                if (resource.resourceKey === undefined) {
                    return '';
                }
                const shareholder = await Port.accounting.getShareholderByKey(resource.resourceKey);
                return shareholder?.name || i18n.t('widget-wizard.resourcetype.shareholder').toString();
            }
            case ResourceType.Generatorgroup: {
                return resource.seriesName || i18n.t('widget-wizard.resourcetype.generatorgroup').toString();
            }
            case ResourceType.Anonymous: {
                return WidgetUtils.getDefaultAnonymousResourceName(resource.resourceFilters);
            }
            default: return 'Unknown resource type';
        }
    }

    public static getDefaultAnonymousResourceName(resourceFilters?: WidgetConfigResourceFilter[]): string {
        const filters = resourceFilters?.filter((filter) => filter.filterValue && filter.filterValue !== '-');
        if (filters && filters.length === 1) {
            return i18n.t(`widget-wizard.tab.data.anon.${filters[0].filterName}.preview`, { value: filters[0].filterValue }).toString();
        }
        return i18n.t('widget-wizard.tab.data.anon.default-name').toString();
    }

    private static tryI18nKey(key: string, params?: any): string|undefined {
        const value = i18n.t(key, params).toString();
        if (value && value !== key && value.trim().length > 0) {
            return value;
        }
        return undefined;
    }

    public static async getMetricName(metricKey: string, includeUnit: boolean = false): Promise<string> {
        const metric: MetricRepresentation|undefined = await Port.metrics.getMetricByKey(metricKey);
        if (!metric) {
            return '';
        }
        if (includeUnit && metric.unit) {
            return `${i18n.t(metric.name).toString()} [${metric.unit}]`;
        }
        return i18n.t(metric.name).toString();
    }

    public static async getDistinctGeneratorsForWidget(widget: WidgetConfig): Promise<string[]> {
        const resourceResolvers: Promise<string[]>[] = widget.axis
            .flatMap((axis) => axis.metrics)
            .flatMap((metric) => metric.resources)
            .map((resource) => WidgetUtils.getGeneratorsForResource(widget, resource));
        const generators: string[][] = await Promise.all(resourceResolvers);
        return generators
            .flatMap((it) => it)
            .filter(ArrayUtils.removeDuplicates);
    }

    public static async getGeneratorsForResource(widget: WidgetConfig, resource: WidgetConfigResource): Promise<string[]> {
        const resourceKey = resource.resourceKey || '';
        switch (resource.type) {
            case ResourceType.Generator: return Promise.resolve([resourceKey]);
            case ResourceType.Portfolio: return Port.portfolios.getPortfolioByKey(resourceKey)
                .then((portfolio) => portfolio?.generatorKeys || []);
            case ResourceType.Park: return WidgetUtils.getGeneratorsByParkAndPortfolio(resourceKey, widget.portfolioKey);
            case ResourceType.Shareholder: return WidgetUtils.getGeneratorsByShareholderAndPortfolio(resourceKey, widget.portfolioKey);
            case ResourceType.Generatorgroup: return WidgetUtils.getGeneratorsByGeneratorGroupAndPortfolio(resource, widget.portfolioKey);
            default: return Promise.resolve([]);
        }
    }

    private static async getGeneratorsByGeneratorGroupAndPortfolio(resource: WidgetConfigResource, portfolioKey: string): Promise<string[]> {
        if (!resource.resourceFilters) {
            return [];
        }
        const filter = resource.resourceFilters.find((it) => it.filterName === ResourceFilterName.GeneratorKeys);
        if (!filter) {
            return [];
        }
        const generatorKeys = filter.filterValue.split(', ');
        if (!generatorKeys) {
            return [];
        }
        const portfolio = await Port.portfolios.getPortfolioByKey(portfolioKey);
        if (!portfolio?.generatorKeys) {
            return [];
        }
        return portfolio.generatorKeys.filter((it) => generatorKeys.includes(it));
    }

    private static async getGeneratorsByParkAndPortfolio(parkKey: string, portfolioKey: string): Promise<string[]> {
        const portfolioGeneratorKeys = (await Port.portfolios.getPortfolioByKey(portfolioKey))?.generatorKeys;
        const generatorsKeysByPark = (await Port.generators.getGeneratorsByPark(parkKey)).map((it) => it.key);

        if (!portfolioGeneratorKeys || !generatorsKeysByPark) {
            return [];
        }

        return generatorsKeysByPark.filter((it) => portfolioGeneratorKeys.includes(it));
    }

    private static async getGeneratorsByShareholderAndPortfolio(shareholderKey: string, portfolioKey: string): Promise<string[]> {
        const portfolio = await Port.portfolios.getPortfolioByKey(portfolioKey);
        const shareholder = await Port.accounting.getShareholderByKey(shareholderKey);
        if (!portfolio?.generatorKeys || !shareholder?.generatorKeys) {
            return [];
        }
        return portfolio.generatorKeys.filter((it) => shareholder.generatorKeys.includes(it));
    }

    public static getWizardStepsForWidgetType(widgetType: WidgetType|string): WidgetWizardStep[] {
        switch (widgetType) {
            case WidgetType.ScatterChart: return require('../components/scatter').Steps;
            case WidgetType.TimeChart: return require('../components/timechart').Steps;
            case WidgetType.StackedTimeChart: return require('../components/timechart-stacked').Steps;
            case WidgetType.GeneratorChart: return require('../components/generatorchart').Steps;
            case WidgetType.StackedGeneratorChart: return require('../components/generatorchart-stacked').Steps;
            case WidgetType.Gauge:
            case WidgetType.Number: return require('../components/number').Steps;
            case WidgetType.Distribution: return require('../components/distribution').Steps;
            case WidgetType.Logs: return require('../components/logs').Steps;
            case WidgetType.LatestLogs: return require('../components/latest-logs').Steps;
            case WidgetType.Availability: return require('../components/availability').Steps;
            case WidgetType.Commissions: return require('../components/commissions').Steps;
            case WidgetType.Table: return require('../components/table').Steps;
            case WidgetType.Map: return require('../components/map').Steps;
            case WidgetType.Windrose: return require('../components/windrose').Steps;
            case WidgetType.WeatherMap: return require('../components/map-weather').Steps;
            case WidgetType.Forecast: return require('../components/forecast').Steps;
            case WidgetType.Heatmap: return require('../components/heatmap').Steps;
            case WidgetType.PowerCurve: return require('../components/powercurve').Steps;
            case WidgetType.Note: return require('../components/note').Steps;
            case WidgetType.OperatorForecast: return require('../components/operator-forecast').Steps;
            default:
        }
        return [
            { name: 'widget-wizard.tab.data', tab: WidgetWizardTab.Data },
            { name: 'widget-wizard.tab.time', tab: WidgetWizardTab.Time },
            { name: 'widget-wizard.tab.general', tab: WidgetWizardTab.General },
        ];
    }

    public static supportsTable(widgetType: string): boolean {
        const types: string[] = [
            WidgetType.Availability,
            WidgetType.Commissions,
            WidgetType.TimeChart,
            WidgetType.GeneratorChart,
            WidgetType.Logs,
            WidgetType.LatestLogs,
            WidgetType.Table,
            WidgetType.Forecast,
            WidgetType.PowerCurve,
            WidgetType.OperatorForecast,
            WidgetType.StackedGeneratorChart,
            WidgetType.StackedTimeChart,
        ];
        return types.includes(widgetType);
    }

    public static supportsChart(widgetType: string): boolean {
        const types: string[] = [
            WidgetType.TimeChart,
            WidgetType.GeneratorChart,
            WidgetType.ScatterChart,
            WidgetType.Distribution,
            WidgetType.Gauge,
            WidgetType.Number,
            WidgetType.Map,
            WidgetType.Windrose,
            WidgetType.Forecast,
            WidgetType.PowerCurve,
            WidgetType.Note,
            WidgetType.OperatorForecast,
            WidgetType.StackedGeneratorChart,
            WidgetType.StackedTimeChart,
        ];
        return types.includes(widgetType);
    }

    public static getDataSourcesNeedingAggregation(widget: WidgetConfig): WidgetConfigResource[] {
        return widget.axis
            .flatMap((axis) => axis.metrics)
            .flatMap((metric) => metric.resources
                .filter((res) => WidgetUtils.doesDataSourceNeedAggregation(res, metric)));
    }

    public static doesDataSourceNeedAggregation(resource: WidgetConfigResource, metric: WidgetConfigMetric): boolean {
        return resource.type === ResourceType.Park
            || resource.type === ResourceType.Portfolio
            || (resource.type === ResourceType.Shareholder && metric.metricCategory !== MetricCategory.Accounting)
            || resource.type === ResourceType.Generatorgroup;
    }
}
