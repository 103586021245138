/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountingFormulaRepresentation,
    AccountingFormulaRepresentationFromJSON,
    AccountingFormulaRepresentationFromJSONTyped,
    AccountingFormulaRepresentationToJSON,
    ResultType,
    ResultTypeFromJSON,
    ResultTypeFromJSONTyped,
    ResultTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountingMetricRepresentation
 */
export interface AccountingMetricRepresentation {
    /**
     * 
     * @type {string}
     * @memberof AccountingMetricRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingMetricRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingMetricRepresentation
     */
    unit: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountingMetricRepresentation
     */
    visible: boolean;
    /**
     * all features that are assigned to the metric
     * @type {Array<string>}
     * @memberof AccountingMetricRepresentation
     */
    licenseFeatures: Array<string>;
    /**
     * 
     * @type {ResultType}
     * @memberof AccountingMetricRepresentation
     */
    resultType: ResultType;
    /**
     * 
     * @type {Array<AccountingFormulaRepresentation>}
     * @memberof AccountingMetricRepresentation
     */
    formulas?: Array<AccountingFormulaRepresentation>;
}

export function AccountingMetricRepresentationFromJSON(json: any): AccountingMetricRepresentation {
    return AccountingMetricRepresentationFromJSONTyped(json, false);
}

export function AccountingMetricRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingMetricRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'unit': json['unit'],
        'visible': json['visible'],
        'licenseFeatures': json['licenseFeatures'],
        'resultType': ResultTypeFromJSON(json['resultType']),
        'formulas': !exists(json, 'formulas') ? undefined : ((json['formulas'] as Array<any>).map(AccountingFormulaRepresentationFromJSON)),
    };
}

export function AccountingMetricRepresentationToJSON(value?: AccountingMetricRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'unit': value.unit,
        'visible': value.visible,
        'licenseFeatures': value.licenseFeatures,
        'resultType': ResultTypeToJSON(value.resultType),
        'formulas': value.formulas === undefined ? undefined : ((value.formulas as Array<any>).map(AccountingFormulaRepresentationToJSON)),
    };
}


