export class TableUtils {

    public static sortDataBy<T>(data: T[], sortBy: string, sortDirection: number, options?: { keepLastRow?: boolean }): T[] {
        let dataSorted = data;
        let lastRow: T|undefined;
        if (options?.keepLastRow) {
            lastRow = dataSorted.pop();
        }
        dataSorted = data.sort((a: T, b: T) => {
            const v1 = TableUtils.getValue(a, sortBy) || '';
            const v2 = TableUtils.getValue(b, sortBy) || '';
            // sort numeric if we have two numbers
            const v1Num = (v1 === '0' || v1 === '') ? 0 : parseFloat(v1);
            const v2Num = (v2 === '0' || v2 === '') ? 0 : parseFloat(v2);
            if (!Number.isNaN(v1Num) && !Number.isNaN(v2Num)) {
                return (v1Num - v2Num) * sortDirection;
            }

            // sort alphanumeric
            if (v1 > v2) return sortDirection;
            if (v2 > v1) return -1 * sortDirection;
            return 0;
        });
        if (lastRow) {
            dataSorted.push(lastRow);
        }
        return dataSorted;
    }

    public static getValue(object: any, key: string|string[]|undefined): any {
        if (!key) {
            return object;
        }
        if (Array.isArray(key)) {
            const obj = object[key[0]];
            if (!obj || key.length === 1) {
                return obj || undefined;
            }
            key.splice(0, 1);
            return this.getValue(obj, key);
        }
        if (key.includes('.')) {
            return this.getValue(object, key.split('.'));
        }
        return object[key];
    }
}
