import { ResourceType } from '@/modules/shared/types';

export interface TabDataOptions {
    enableSingleResourceList: boolean,
    forceSingleResourceList: boolean,
    enableDuplicateResources: boolean;
    maxDataSourcesTotal: number;
    maxDataSourcesPerMetric: number;
    maxDataSourcesPerAxis: number|number[];
    resourceTypes: ResourceType[];
    availableTimeOverrides: string[];
    resourceEditComponent?: any;
    notices: string[];
    hint?: string;
}

const defaultTabOptions: TabDataOptions = {
    enableSingleResourceList: false,
    forceSingleResourceList: false,
    enableDuplicateResources: true,
    maxDataSourcesTotal: 100,
    maxDataSourcesPerMetric: 100,
    maxDataSourcesPerAxis: 100,
    resourceTypes: [
        ResourceType.Portfolio,
        ResourceType.Generator,
        ResourceType.Generatorgroup,
        ResourceType.Park,
        ResourceType.Shareholder,
        ResourceType.Anonymous,
    ],
    availableTimeOverrides: [],
    notices: [],
};

export { defaultTabOptions };
