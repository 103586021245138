import type { AdminRepository } from '@/modules/ctx-admin-functions/adapter';
import NotificationService from '@/assets/js/services/NotificationService';

export class AdminService {

    private readonly adminRepository: AdminRepository;

    constructor(params: {
        adminRepository: AdminRepository,
    }) {
        this.adminRepository = params.adminRepository;
    }

    public async uploadPlanDataCSV(blob: Blob): Promise<void> {
        await this.safeRun(this.adminRepository.uploadPlanDataCSV(blob));
    }

    public async uploadPlanBasicDataCSV(blob: Blob): Promise<void> {
        await this.safeRun(this.adminRepository.uploadPlanBasicDataCSV(blob));
    }

    public async uploadCommissionMarketvaluesCSV(blob: Blob): Promise<void> {
        await this.safeRun(this.adminRepository.uploadCommissionMarketvaluesCSV(blob));
    }

    private async safeRun(promise: Promise<void>) {
        try {
            await promise;
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

}
