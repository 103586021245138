import { LicenseFeature, ResourceType } from '@/modules/shared/types';
import type {
    TabAxisOptions,
    TabDataOptions,
    TabGeneralOptions,
    TabMetricsOptions,
    TabAggregationTimeOptions,
    WidgetWizardStep,
    TabTableOptions,
} from '@/components/widget-wizard';
import { WidgetWizardTab } from '@/components/widget-wizard';
import { TimeCompareUtils } from '@/components/widgets/utils/TimeCompareUtils';
import { WidgetUtils } from '@/components/widgets';

const metrics: WidgetWizardStep<TabMetricsOptions> = {
    name: 'widget-wizard.tab.metrics',
    tab: WidgetWizardTab.Metrics,
    features: [LicenseFeature.ADVANCED],
    params: {
        axisTitles: ['Primäre Y-Achse', 'Sekundäre Y-Achse'],
        maxMetrics: 12,
    },
};

const axis: WidgetWizardStep<TabAxisOptions> = {
    name: 'widget-wizard.tab.axis',
    tab: WidgetWizardTab.Axis,
    features: [LicenseFeature.ADVANCED],
    params: (widgetConfig) => ({
        axisTitles: ['Primäre Y-Achse', 'Sekundäre Y-Achse'],
        enableChartType: false,
        enableGlobalScale: widgetConfig.axis.filter((a) => a.metrics.length > 0).length > 1,
    }),
};

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.TimeNumber,
    params: {
        useGlobalAggregation: false,
    },
};

const timeAggregation: WidgetWizardStep<TabAggregationTimeOptions> = {
    name: 'widget-wizard.tab.aggregation-time',
    tab: WidgetWizardTab.AggregationTime,
    params: {
        useGlobalAggregation: false,
    },
    visible: (widget) => widget.intervalName !== 'newest',
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: (widget) => {
        const params: Partial<TabDataOptions> = {
            forceSingleResourceList: true,
            maxDataSourcesTotal: 12,
        };
        params.availableTimeOverrides = TimeCompareUtils.getAvailableTimeOverrides(widget);
        return params;
    },
};

const dataAggregation: WidgetWizardStep = {
    name: 'widget-wizard.tab.aggregation-datasources',
    tab: WidgetWizardTab.AggregationDatasources,
    visible: (widget) => WidgetUtils.getDataSourcesNeedingAggregation(widget).length > 0,
    params: {
        resourceTypes: [
            ResourceType.Portfolio,
            ResourceType.Park,
            ResourceType.Generator,
            ResourceType.Shareholder,
            ResourceType.Generatorgroup,
            ResourceType.Anonymous,
        ],
    },
};

const table: WidgetWizardStep<TabTableOptions> = {
    name: 'widget-wizard.tab.table',
    tab: WidgetWizardTab.Table,
    params: (widget) => {
        const params: Partial<TabTableOptions> = {
            enableFormatting: true,
            defaultSorting: '',
            showTableViewHint: true,
        };
        params.dataSortingOptions = widget.axis
            .flatMap((it) => it.metrics)
            .map((it) => ({
                value: `${it.uuid}:`, // specific metric, any resource
                displayName: it.metricName,
            }));
        params.dataSortingOptions.unshift({ value: '', displayName: 'widgets.generator-chart.sort-by.datasource-alphabetically' });
        return params;
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    metrics,
    axis,
    time,
    timeAggregation,
    data,
    dataAggregation,
    table,
    general,
];
