import { LicenseFeature } from '@/modules/shared/types';
import type { WidgetWizardStep, TabDataOptions, TabMetricsOptions, TabGeneralOptions } from '@/components/widget-wizard';
import { WidgetWizardTab } from '@/components/widget-wizard';
import { TimeCompareUtils } from '@/components/widgets/utils/TimeCompareUtils';
import { ResourceType } from '@/clients/dashboardapi/v2';
import { WidgetUtils } from '@/components/widgets';

const metrics: WidgetWizardStep<TabMetricsOptions> = {
    name: 'widget-wizard.tab.metrics',
    tab: WidgetWizardTab.Metrics,
    features: [LicenseFeature.ADVANCED],
    params: {
        enableMixedUnits: true,
        minMetrics: 1,
        maxMetrics: 1,
        axisTitles: ['Metrik'],
    },
};

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time-number',
    tab: WidgetWizardTab.TimeNumber,
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: (widget) => {
        const params: Partial<TabDataOptions> = {
            maxDataSourcesTotal: 2,
        };
        params.availableTimeOverrides = TimeCompareUtils.getAvailableTimeOverrides(widget);
        return params;
    },
};

const dataAggregation: WidgetWizardStep = {
    name: 'widget-wizard.tab.aggregation-datasources',
    tab: WidgetWizardTab.AggregationDatasources,
    visible: (widget) => WidgetUtils.getDataSourcesNeedingAggregation(widget).length > 0,
    params: {
        resourceTypes: [
            ResourceType.Portfolio,
            ResourceType.Park,
            ResourceType.Generator,
            ResourceType.Shareholder,
            ResourceType.Generatorgroup,
            ResourceType.Anonymous,
        ],
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    metrics,
    time,
    data,
    dataAggregation,
    general,
];
