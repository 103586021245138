import type { UserSettings, UserSettingsApi } from '@/modules/shared/adapter';

export class UserSettingsLocalstorageApi implements UserSettingsApi {

    public getUserSettings(): UserSettings {
        let settings: UserSettings|null = JSON.parse(localStorage.getItem('halvar-settings') || 'null');
        if (settings === null) {
            const legacySettings = JSON.parse(localStorage.getItem('vuex') || '{}');
            settings = {
                onboardingProgress: legacySettings.App.onboardingProgress,
                insertNewWidgetsPosition: legacySettings.App.insertNewWidgetsPosition || 'start',
                presetCategory: legacySettings.App.presetCategory,
            };
            localStorage.setItem('halvar-settings', JSON.stringify(settings));
        }
        return settings;
    }

    public updateUserSettings(settings: Partial<UserSettings>): UserSettings {
        const fullSettings = this.getUserSettings();
        const updatedSettings = Object.assign(fullSettings, settings);
        localStorage.setItem('halvar-settings', JSON.stringify(updatedSettings));
        return updatedSettings;
    }
}
