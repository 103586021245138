import type { WidgetWizardStep, TabDataOptions, TabGeneralOptions } from '@/components/widget-wizard';
import { ResourceType } from '@/clients/dashboardapi/v2';
import { WidgetWizardTab } from '@/components/widget-wizard';
import { TabTableOptions } from '@/components/widget-wizard/components/tabs/table/TabTableOptions';

const powerCurve: WidgetWizardStep = {
    name: 'widget-wizard.tab.powercurve',
    tab: WidgetWizardTab.PowerCurve,
};

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.Time,
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: {
        resourceTypes: [ResourceType.Generator],
        maxDataSourcesTotal: 1,
    },
};

const table: WidgetWizardStep<TabTableOptions> = {
    name: 'widget-wizard.tab.table',
    tab: WidgetWizardTab.Table,
    params: {
        showTableViewHint: true,
        defaultSorting: 'windSpeedFrom',
        dataSortingOptions: [
            { value: 'windSpeedFrom', displayName: 'widgets.power-curve.table.windrange' },
            { value: 'measurements', displayName: 'widgets.power-curve.table.measurements' },
            { value: 'powerAct', displayName: 'widgets.power-curve.table.powerAct' },
            { value: 'powerRef', displayName: 'widgets.power-curve.table.powerRef' },
            { value: 'deviation', displayName: 'widgets.power-curve.table.deviation' },
        ],
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    powerCurve,
    time,
    data,
    table,
    general,
];
