
import { Component, Prop, Vue } from 'vue-property-decorator';
import TimeIntervals from '@/assets/js/utils/TimeIntervals';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import Formatter from '@/assets/js/utils/Formatter';

@Component({
    components: { },
})
export default class WidgetTimeChooser extends Vue {

    @Prop({ required: true })
    public readonly widget!: WidgetConfig;

    private readonly allIntervals: string[] = TimeIntervals.getDynamicIntervals();
    private showMenu: boolean = false;

    private readonly now = new Date();

    private get quickIntervals(): { key: string; value: string }[] {
        return TimeIntervals.getQickswitchIntervals(this.widget.resolution)
            .map((k) => ({ key: k, value: this.getShortDisplayNameForInterval(k) }));
    }

    private get displayName(): string {
        if (this.widget.intervalName === 'newest') {
            return this.$t('aggregation.newest').toString();
        }
        if (this.widget.intervalName === 'fixed') {
            return Formatter.formatTimeInterval(this.widget.intervalFrom, this.widget.intervalTo);
        }
        if (this.allIntervals.includes(this.widget.intervalName)) {
            return this.$t(`widgets.interval.${this.widget.intervalName}`).toString();
        }
        return this.intervalName || `${this.$formatDate(this.widget.intervalFrom)} - ${this.$formatDate(this.widget.intervalTo)}`;
    }

    private get intervalName(): string {
        return this.widget.intervalName || '';
    }

    private getShortDisplayNameForInterval(interval: string): string {
        const k = `widgets.interval.short.${interval}`;
        const t = this.$t(k).toString();
        if (t === k) {
            return interval;
        }
        return t;
    }

    private get isQuickSwitchInterval(): boolean {
        return this.quickIntervals.map((kv) => kv.key).includes(this.intervalName);
    }

    private selectInterval(intervalName: string) {
        this.showMenu = false;
        const interval = TimeIntervals.resolveInterval(intervalName, this.widget.resolution);
        this.$emit('update:interval', interval);
    }

}
