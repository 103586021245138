/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyRepresentation,
    CompanyRepresentationFromJSON,
    CompanyRepresentationFromJSONTyped,
    CompanyRepresentationToJSON,
    UserRepresentation,
    UserRepresentationFromJSON,
    UserRepresentationFromJSONTyped,
    UserRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface TokenRepresentation
 */
export interface TokenRepresentation {
    /**
     * 
     * @type {string}
     * @memberof TokenRepresentation
     */
    token?: string;
    /**
     * 
     * @type {UserRepresentation}
     * @memberof TokenRepresentation
     */
    user?: UserRepresentation;
    /**
     * 
     * @type {CompanyRepresentation}
     * @memberof TokenRepresentation
     */
    company?: CompanyRepresentation;
    /**
     * 
     * @type {Array<string>}
     * @memberof TokenRepresentation
     */
    roles?: Array<string>;
}

export function TokenRepresentationFromJSON(json: any): TokenRepresentation {
    return TokenRepresentationFromJSONTyped(json, false);
}

export function TokenRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'user': !exists(json, 'user') ? undefined : UserRepresentationFromJSON(json['user']),
        'company': !exists(json, 'company') ? undefined : CompanyRepresentationFromJSON(json['company']),
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function TokenRepresentationToJSON(value?: TokenRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'user': UserRepresentationToJSON(value.user),
        'company': CompanyRepresentationToJSON(value.company),
        'roles': value.roles,
    };
}


