/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetI18NForLanguageRequest {
    languagecode: string;
}

export interface UpdateI18nItemForLanguageRequest {
    languagecode: string;
    itemkey: string;
    body: string;
}

/**
 * 
 */
export class I18nApi extends runtime.BaseAPI {

    /**
     * Return localisations
     */
    async getI18NForLanguageRaw(requestParameters: GetI18NForLanguageRequest): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        if (requestParameters.languagecode === null || requestParameters.languagecode === undefined) {
            throw new runtime.RequiredError('languagecode','Required parameter requestParameters.languagecode was null or undefined when calling getI18NForLanguage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/i18n/{languagecode}`.replace(`{${"languagecode"}}`, encodeURIComponent(String(requestParameters.languagecode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Return localisations
     */
    async getI18NForLanguage(languagecode: string): Promise<{ [key: string]: string; }> {
        const response = await this.getI18NForLanguageRaw({ languagecode: languagecode });
        return await response.value();
    }

    /**
     * Update localisation
     */
    async updateI18nItemForLanguageRaw(requestParameters: UpdateI18nItemForLanguageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.languagecode === null || requestParameters.languagecode === undefined) {
            throw new runtime.RequiredError('languagecode','Required parameter requestParameters.languagecode was null or undefined when calling updateI18nItemForLanguage.');
        }

        if (requestParameters.itemkey === null || requestParameters.itemkey === undefined) {
            throw new runtime.RequiredError('itemkey','Required parameter requestParameters.itemkey was null or undefined when calling updateI18nItemForLanguage.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateI18nItemForLanguage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/i18n/{languagecode}/{itemkey}`.replace(`{${"languagecode"}}`, encodeURIComponent(String(requestParameters.languagecode))).replace(`{${"itemkey"}}`, encodeURIComponent(String(requestParameters.itemkey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update localisation
     */
    async updateI18nItemForLanguage(languagecode: string, itemkey: string, body: string): Promise<void> {
        await this.updateI18nItemForLanguageRaw({ languagecode: languagecode, itemkey: itemkey, body: body });
    }

}
