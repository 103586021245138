import { I18nAdapter } from '@/plugins/i18n';
import type {
    Account,
    AccountGroup,
    AccountingRepository,
    AccountingMetricFormula,
    AccountingMetric,
} from '@/modules/ctx-admin-accounting';
import type {
    AccountingApi,
    AccountGroupCreateRequest,
    AccountGroupRepresentation,
    AccountGroupUpdateRequest,
    AccountingFormulaRepresentation,
    AccountingFormulaValidationRequest,
    AccountingMetricCreateRequest,
    AccountingMetricRepresentation,
    AccountRepresentation,
} from '@/modules/shared/adapter/AccountingApi';
import { MetricPosition } from '@/modules/ctx-admin-accounting/types/MetricPosition';
import { FeatureRepresentation } from '@/clients/dashboardapi/v2';
import { LicenseApi } from '@/modules/shared/adapter';
import { LicenseFeature } from '@/modules/shared/types';

export class AccountingRepositoryImpl implements AccountingRepository {

    private readonly accountingApi: AccountingApi;
    private readonly licenseApi: LicenseApi;
    private readonly initialization: Promise<any>;

    constructor(apis: {
        accounting: AccountingApi,
        license: LicenseApi,
    }) {
        this.accountingApi = apis.accounting;
        this.licenseApi = apis.license;
        this.initialization = Promise.allSettled(I18nAdapter.locales.map((lc) => I18nAdapter.loadMessages(lc)));
    }

    public async getAccounts(): Promise<Account[]> {
        await this.initialization;
        const accounts: AccountRepresentation[] = await this.accountingApi.getAccounts();
        return accounts.map(AccountingRepositoryImpl.mapAccountToDomain);
    }

    public async getPositionsForFormula(formula: AccountingMetricFormula): Promise<MetricPosition[]> {
        await this.initialization;
        const request: AccountingFormulaValidationRequest = {
            expression: formula.expression,
            dataProviderKey: formula.dataProviderKey,
        };
        const parseResult = await this.accountingApi.parseFormula(request);
        const accounts: MetricPosition[] = (await this.accountingApi.getAccounts())
            .filter((acc) => parseResult.accounts.includes(acc.key))
            .map((acc) => ({
                key: acc.key,
                type: 'account',
                account: AccountingRepositoryImpl.mapAccountToDomain(acc),
            }));
        const features = await this.licenseApi.getFeatures();
        const metrics: MetricPosition[] = (await this.accountingApi.getAccountGroups())
            .flatMap((accg) => accg.metrics || [])
            .filter((metric) => parseResult.positions.includes(metric.key))
            .map((metric) => ({
                key: metric.key,
                type: 'metric',
                metric: AccountingRepositoryImpl.mapAccountingMetricToDomain(metric, features),
            }));
        return metrics.concat(accounts);
    }

    public async getAccountGroups(): Promise<AccountGroup[]> {
        await this.initialization;
        const accountGroups: AccountGroupRepresentation[] = await this.accountingApi.getAccountGroups();
        const features = await this.licenseApi.getFeatures();
        return accountGroups.map((group) => AccountingRepositoryImpl.mapAccountGroupToDomain(group, features));
    }

    public async createAccountGroup(accountGroup: AccountGroup): Promise<AccountGroup> {
        await this.initialization;
        const request: AccountGroupCreateRequest = {
            name: Object.values(accountGroup.name),
            lc: Object.keys(accountGroup.name),
        };
        const savedAccountGroup = await this.accountingApi.createAccountGroup(request);
        const features = await this.licenseApi.getFeatures();
        return AccountingRepositoryImpl.mapAccountGroupToDomain(savedAccountGroup, features);
    }

    public async updateAccountGroup(accountGroup: AccountGroup): Promise<AccountGroup> {
        await this.initialization;
        const representation: AccountGroupUpdateRequest = {
            name: Object.values(accountGroup.name),
            lc: Object.keys(accountGroup.name),
        };
        const savedAccountGroup = await this.accountingApi.updateAccountGroup(accountGroup.key, representation);
        const features = await this.licenseApi.getFeatures();
        return AccountingRepositoryImpl.mapAccountGroupToDomain(savedAccountGroup, features);
    }

    public async deleteAccountGroup(accountGroupKey: string): Promise<void> {
        await this.accountingApi.deleteAccountGroup(accountGroupKey);
    }

    public async createAccountingMetric(accountGroupKey: string, metric: AccountingMetric): Promise<AccountingMetric> {
        const representation: AccountingMetricCreateRequest = {
            name: Object.values(metric.name),
            lc: Object.keys(metric.name),
            unit: metric.unit,
            formulas: metric.formulas.map((formula) => ({
                expression: formula.expression,
                dataProviderKey: formula.dataProviderKey,
            })),
            visible: metric.licenseFeatures.length > 0,
            licenseFeatures: metric.licenseFeatures,
            resultType: metric.resultType,
        };
        const features = await this.licenseApi.getFeatures();
        const savedMetric = await this.accountingApi.createAccountingMetric(accountGroupKey, representation);
        return AccountingRepositoryImpl.mapAccountingMetricToDomain(savedMetric, features);
    }

    public async updateAccountingMetric(metric: AccountingMetric): Promise<AccountingMetric> {
        const representation: AccountingMetricCreateRequest = {
            name: Object.values(metric.name),
            lc: Object.keys(metric.name),
            unit: metric.unit,
            formulas: metric.formulas.map((formula) => ({
                expression: formula.expression,
                dataProviderKey: formula.dataProviderKey,
            })),
            visible: metric.licenseFeatures.length > 0,
            licenseFeatures: metric.licenseFeatures,
            resultType: metric.resultType,
        };
        const savedMetric = await this.accountingApi.updateAccountingMetric(metric.key, representation);
        const features = await this.licenseApi.getFeatures();
        return AccountingRepositoryImpl.mapAccountingMetricToDomain(savedMetric, features);
    }

    public async deleteAccountingMetric(metricKey: string, force?: boolean): Promise<void> {
        await this.accountingApi.deleteAccountingMetric(metricKey, force);
    }

    private static mapAccountToDomain(representation: AccountRepresentation): Account {
        return {
            key: representation.key,
            name: representation.name,
            type: representation.planType || 'IST',
            accountNumber: representation.number,
            subAccountNumber: representation.subnumber,
            dataProvider: representation.dataProviderKey,
        };
    }

    private static mapAccountGroupToDomain(representation: AccountGroupRepresentation, licenseFeatures: FeatureRepresentation[]): AccountGroup {
        return {
            key: representation.key,
            nameKey: representation.nameKey,
            name: I18nAdapter.translateForAllLoadedLanguages(representation.nameKey),
            metrics: (representation.metrics || [])
                .map((metric) => AccountingRepositoryImpl.mapAccountingMetricToDomain(metric, licenseFeatures)),
        };
    }

    private static mapAccountingMetricToDomain(metricRepresentation: AccountingMetricRepresentation, licenseFeatures: FeatureRepresentation[]): AccountingMetric {
        return {
            key: metricRepresentation.key,
            name: I18nAdapter.translateForAllLoadedLanguages(metricRepresentation.name),
            nameKey: metricRepresentation.name,
            licenseFeatures: metricRepresentation.licenseFeatures as LicenseFeature[],
            resultType: metricRepresentation.resultType,
            unit: metricRepresentation.unit,
            formulas: metricRepresentation.formulas?.map(AccountingRepositoryImpl.mapFormulaToDomain) || [],
        };
    }

    private static mapFormulaToDomain(formulaRepresentation: AccountingFormulaRepresentation): AccountingMetricFormula {
        return {
            expression: formulaRepresentation.expression,
            dataProviderKey: formulaRepresentation.dataProviderKey || '',
        };
    }
}
