import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { WidgetUtils } from '@/components/widgets';
import { i18n } from '@/plugins/i18n';
import type { WidgetType } from '@/modules/shared/types';
import {
    Aggregation,
    DashboardType,
    licenseFeatureFromString,
    ResourceType,
    TemplateVisibility,
} from '@/modules/shared/types';
import type { WidgetConfig, WidgetRepository, WidgetTemplate } from '@/modules/ctx-dashboard';
import type {
    AccountingApi,
    GeneratorsApi,
    LocalizationApi,
    MetricsApi,
    ParksApi,
    PortfoliosApi,
    WidgetsApi,
    WidgetTemplateRepresentation,
    WidgetTemplatesApi,
} from '@/modules/shared/adapter';
import { WidgetMapper } from './mapper/WidgetMapper';

export class WidgetRepositoryImpl implements WidgetRepository {

    private readonly i18nApi: LocalizationApi;
    private readonly metricsApi: MetricsApi;
    private readonly widgetsApi: WidgetsApi;
    private readonly templatesApi: WidgetTemplatesApi;
    private readonly widgetMapper: WidgetMapper;

    constructor(apis: {
        metrics: MetricsApi,
        widgets: WidgetsApi,
        portfolios: PortfoliosApi,
        generators: GeneratorsApi,
        parks: ParksApi,
        accounting: AccountingApi,
        templates: WidgetTemplatesApi,
        i18n: LocalizationApi,
    }) {
        this.i18nApi = apis.i18n;
        this.metricsApi = apis.metrics;
        this.widgetsApi = apis.widgets;
        this.templatesApi = apis.templates;
        this.widgetMapper = new WidgetMapper({
            metrics: apis.metrics,
            portfolios: apis.portfolios,
            generators: apis.generators,
            parks: apis.parks,
            accounting: apis.accounting,
        });
    }

    public async createWidget(widget: WidgetConfig): Promise<WidgetConfig> {
        const spec = this.widgetMapper.mapDomainToCreateRequest(widget);
        const updatedWidget = await this.widgetsApi.createWidget(widget.dashboardKey, spec);
        return this.widgetMapper.mapWidgetToDomain(updatedWidget);
    }

    public async deleteWidget(widget: WidgetConfig): Promise<void> {
        await this.widgetsApi.deleteWidget(widget.dashboardKey, widget.key);
    }

    public async getFavoriteWidgets(): Promise<WidgetConfig[]> {
        const widgetRepresentations = await this.widgetsApi.getFavoriteWidgets();
        return this.widgetMapper.mapWidgetsToDomain(widgetRepresentations, true);
    }

    public async getFavoriteWidgetsForPortfolio(portfolioKey: string): Promise<WidgetConfig[]> {
        const widgetRepresentations = await this.widgetsApi.getFavoriteWidgetsForPortfolio(portfolioKey);
        return Promise.all(widgetRepresentations.map((w) => this.widgetMapper.mapWidgetToDomain(w)));
    }

    public async getWidgetsForDashboard(dashboardKey: string): Promise<WidgetConfig[]> {
        const widgetRepresentations = await this.widgetsApi.getWidgetsForDashboard(dashboardKey);
        const widgets = await Promise.all(widgetRepresentations.map((w) => this.widgetMapper.mapWidgetToDomain(w)));
        widgets
            .filter((widget) => !widget.generatedTitle)
            .forEach((widget) => {
                console.log('Applying workaround to generate widget name');
                widget.generatedTitle = WidgetUtils.getDefaultWidgetTitle(widget);
            });
        return widgets;
    }

    public async updateWidget(widget: WidgetConfig): Promise<WidgetConfig> {
        const updatedWidget = await this.widgetsApi.updateWidget(widget.dashboardKey, widget.key, this.widgetMapper.mapDomainToUpdateRequest(widget));
        return this.widgetMapper.mapWidgetToDomain(updatedWidget);
    }

    public async copyWidget(widget: WidgetConfig, targetDashboardKey: string): Promise<void> {
        await this.widgetsApi.copyWidget(widget.dashboardKey, widget.key, { dashboardKey: targetDashboardKey });
    }

    public async getWidgetOrder(dashboardType: DashboardType, dashboardKey: string | null): Promise<string[]> {
        return this.widgetsApi.getWidgetOrder(dashboardType, dashboardKey);
    }

    public async setWidgetOrder(dashboardType: DashboardType, dashboardKey: string | null, widgetKeys: string[]): Promise<void> {
        await this.widgetsApi.setWidgetOrder(dashboardType, dashboardKey, widgetKeys);
    }

    public async setWidgetDisplayMode(widgetKey: string, mode: string): Promise<void> {
        await this.widgetsApi.setWidgetDisplayMode(widgetKey, mode);
    }

    public async getWidgetDisplayMode(widgetKey: string): Promise<string|undefined> {
        return this.widgetsApi.getWidgetDisplayMode(widgetKey);
    }

    public async addWidgetToFavorites(widgetKey: string): Promise<void> {
        await this.widgetsApi.addWidgetToFavorites(widgetKey);
    }

    public async removeWidgetFromFavorites(widgetKey: string): Promise<void> {
        await this.widgetsApi.removeWidgetFromFavorites(widgetKey);
    }

    public async isFavoriteWidget(widgetKey: string): Promise<boolean> {
        return this.widgetsApi.isFavoriteWidget(widgetKey);
    }

    public async getWidgetFromTemplate(widgetTemplate: WidgetTemplate): Promise<WidgetConfig> {
        const widgetCreateRequest = await this.templatesApi.getWidgetFromTemplate(widgetTemplate.key);
        return this.widgetMapper.mapWidgetCreateRequestToDomain(widgetCreateRequest);
    }

    public async getWidgetTemplates(): Promise<WidgetTemplate[]> {
        const templateRepresentations = await this.templatesApi.getWidgetTemplates();
        return templateRepresentations.map(WidgetRepositoryImpl.mapWidgetTemplateToDomain);
    }

    public async createWidgetTemplate(widgetKey: string, widgetTemplate: Partial<WidgetTemplate>): Promise<WidgetTemplate> {
        const isGlobal = widgetTemplate.isGlobal && widgetTemplate.category && widgetTemplate.category !== 'custom';
        const representation = await this.templatesApi.createTemplateFromWidget({
            widgetKey: widgetKey,
            name: widgetTemplate.title || '',
            category: isGlobal ? widgetTemplate.category! : 'custom',
            visibility: isGlobal ? TemplateVisibility.Global : TemplateVisibility.Private,
            requiredLicenseFeatures: widgetTemplate.requiredLicenseFeatures,
        });
        if (isGlobal && widgetTemplate.titleLocalized) {
            const i18nKey = `widget_template_name-${representation.key}`;
            await this.i18nApi.updateTexts(i18nKey, widgetTemplate.titleLocalized);
        }
        return WidgetRepositoryImpl.mapWidgetTemplateToDomain(representation, 1000);
    }

    public async deleteWidgetTemplate(widgetTemplate: WidgetTemplate): Promise<void> {
        await this.templatesApi.removeWidgetTemplate(widgetTemplate.key);
    }

    private static mapWidgetTemplateToDomain(representation: WidgetTemplateRepresentation, order: number): WidgetTemplate {
        const titleKey = `widget_template_name-${representation.key}`;
        let title = i18n.t(titleKey).toString();
        if (title === titleKey) {
            title = representation.title;
        }
        return {
            key: representation.key,
            title: title,
            type: representation.type as WidgetType,
            preset: representation.preset,
            metricKeys: representation.metricKeys,
            isDefaultTemplate: false,
            requiredLicenseFeatures: (representation.requiredLicenseFeatures || [])
                .map(licenseFeatureFromString)
                .filter(ArrayUtils.filterUndefined),
            isOneClickWidget: true, // widget can be added with 1 click if all tabs are valid
            category: representation.category,
            order: 1000 + order,
            isGlobal: representation.visibility === TemplateVisibility.Global,
        };
    }
}
