import type { DashboardTemplate, DashboardTemplatesRepository } from '@/modules/ctx-dashboard';
import type { AuthApi, DashboardsApi, DashboardTemplatesApi, MetricsApi, UsersApi } from '@/modules/shared/adapter';
import { DashboardTemplateMapper } from '@/plugins/modules/ctx-dashboard/mapper/DashboardTemplateMapper';

export class DashboardTemplateRepositoryImpl implements DashboardTemplatesRepository {

    private readonly dashboardsApi: DashboardsApi;
    private readonly templatesApi: DashboardTemplatesApi;
    private readonly dashboardTemplateMapper: DashboardTemplateMapper;

    constructor(apis: {
        dashboards: DashboardsApi,
        dashboardsTemplates: DashboardTemplatesApi,
        metrics: MetricsApi,
        auth: AuthApi,
        users: UsersApi;
    }) {
        this.dashboardsApi = apis.dashboards;
        this.templatesApi = apis.dashboardsTemplates;
        this.dashboardTemplateMapper = new DashboardTemplateMapper({
            metrics: apis.metrics,
            auth: apis.auth,
            users: apis.users,
        });
    }

    public onTemplatesChange(cb: () => Promise<void>): void {
        this.templatesApi.onDashboardTemplatesChange(cb);
    }

    public async createTemplateFromDashboard(dashboardKey: string, template: DashboardTemplate): Promise<DashboardTemplate> {
        const createdTemplate = await this.templatesApi.createDashboardTemplate({
            dashboardKey: dashboardKey,
            name: template.name,
            global: template.global,
            description: template.description,
            requiredLicenseFeatures: template.requiredLicenseFeatures,
        });
        return this.dashboardTemplateMapper.mapDashboardTemplateToDomain(createdTemplate);
    }

    public async updateDashboardTemplate(template: DashboardTemplate): Promise<DashboardTemplate> {
        const updatedTemplate = await this.templatesApi.updateDashboardTemplate(template.key, {
            name: template.name,
            description: template.description,
            requiredLicenseFeatures: template.requiredLicenseFeatures,
            global: template.global,
        });
        return this.dashboardTemplateMapper.mapDashboardTemplateToDomain(updatedTemplate);
    }

    public async deleteDashboardTemplate(template: DashboardTemplate): Promise<void> {
        await this.templatesApi.deleteDashboardTemplate(template.key);
    }

    public async getDashboardTemplates(): Promise<DashboardTemplate[]> {
        const representations = await this.templatesApi.getDashboardTemplates();
        return Promise.all(representations.map((representation) => this.dashboardTemplateMapper.mapDashboardTemplateToDomain(representation)));
    }
}
