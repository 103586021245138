import { v4 as generateUUID } from 'uuid';
import { Aggregation, Resolution, ResourceType, LicenseFeature } from '@/modules/shared/types';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';
import { Port } from '@/modules/shared/Port';
import Utils from '@/assets/js/utils/Utils';

export default class TimeChartProceedsAndCosts implements WidgetPreset {

    public readonly name: string = 'time-proceeds-and-costs';
    public readonly requireLicenseFeatures: LicenseFeature[] = [LicenseFeature.ACCOUNTING];
    public readonly type: WidgetType = WidgetType.TimeChart;
    public readonly quickAdd: boolean = true;
    public readonly order: number = 60;
    public readonly hidden: boolean = true;
    public readonly metrics: string[] = [
        'f8b79194-7e48-404b-ba0c-e3f42471f1d0',
        '73af3d34-0cd2-4b11-8f19-51a12516e870',
    ];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        // TODO should be shareholder
        const resources = [{
            uuid: generateUUID().substring(0, 8),
            seriesName: '',
            resourceName: (await Port.portfolios.getPortfolioByKey(params.portfolio))?.name || '',
            type: ResourceType.Portfolio,
            resourceKey: params.portfolio,
            aggregationOverGenerators: Aggregation.Sum,
            config: {},
        }];
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {},
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'this-year',
            intervalTo: undefined,
            intervalFrom: undefined,
            resolution: Resolution.Monthly,
            axis: [{
                unit: '',
                name: '',
                config: {
                    type: 'line',
                },
                metrics: this.metrics.map((metricKey) => ({
                    uuid: generateUUID().substring(0, 8),
                    metricKey: metricKey,
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Sum,
                    resources: Utils.deepCopy(resources),
                })),
            }, {
                unit: '',
                name: '',
                config: {
                    type: 'line',
                },
                metrics: [],
            }],
        };
    }
}
