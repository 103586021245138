// Vue Dependencies
import type { PluginObject } from 'vue';
import Vue from 'vue';
import type { RouteConfig } from 'vue-router';
import type VueRouter from 'vue-router';

// Module Dependencies
import { route } from './views/functions/Route';
import { Port } from './Port';
import type { PortParams } from './Port';

export interface Options extends PortParams {
    router: VueRouter;
    route?: Partial<RouteConfig>;
}

const plugin: PluginObject<Options> = {
    install(vue: typeof Vue, params?: Options) {
        if (!params) {
            console.error('Missing plugin params!');
            return;
        }
        Port.init({
            adminRepository: params.adminRepository,
        });

        // register route
        const mergedRoute = Object.assign(route, params.route);
        params.router.addRoute(mergedRoute);
    },
};

export { plugin };
