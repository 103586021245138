// Vue Dependencies
import type { PluginObject } from 'vue';
import Vue from 'vue';
import type { RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';

// Module Dependencies
import { Port, PortParams } from './Port';
import { route as RouteTemplates } from './views/portfolio-templates/Route';
import { route as RoutePortfolios } from './views/portfolios/Route';
import { route as RoutePortfolioGenerators } from './views/portfolio-generators/Route';

export interface Options extends PortParams {
    router: VueRouter;
    routes?: Partial<RouteConfig>[];
}

const plugin: PluginObject<Options> = {
    install(vue: typeof Vue, params?: Options) {
        if (!params) {
            console.error('Missing plugin params!');
            return;
        }
        Port.init(params);

        // register route
        const routes = [
            RouteTemplates,
            RoutePortfolios,
            RoutePortfolioGenerators,
        ];
        routes.forEach((route) => {
            const routeOverride = (params.routes || []).find((r) => r.name === route.name);
            const mergedRoute = Object.assign(route, routeOverride);
            params.router.addRoute(mergedRoute);
        });
    },
};

export { plugin };
