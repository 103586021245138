import { AuthApi as AuthRestApi, Configuration, LicenseFeatureRepresentation } from '@/clients/dashboardapi/v2';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { Events } from '@/modules/shared';
import { LicenseFeature, Role, licenseFeatureFromString } from '@/modules/shared/types';
import type {
    AuthApi,
    CompanyRepresentation,
    TokenRepresentation,
    UserRepresentation,
} from '@/modules/shared/adapter/AuthApi';
import { AsyncDebouncer, ConnectionResetMiddleware } from '../middleware';

export class CachedAuthRestApi implements AuthApi {

    private static readonly TOKEN_REFRESH_INTERVALL: number = 1000 * 60 * 2;
    private static readonly SERVICE_UNAVAILABLE_RETRY_INTERVALL: number = 500;
    private static readonly SERVICE_UNAVAILABLE_RETRY_TIMEOUT: number = 1000 * 60 * 2;
    private static readonly SERVICE_UNAVAILABLE_RETRY_LIMIT: number = CachedAuthRestApi.SERVICE_UNAVAILABLE_RETRY_TIMEOUT / CachedAuthRestApi.SERVICE_UNAVAILABLE_RETRY_TIMEOUT;

    private readonly authApi: AuthRestApi;
    private loginResponse: TokenRepresentation|null = null;
    private cachedLicenseFeatures: LicenseFeatureRepresentation[]|null = null;

    constructor() {
        const apiConfig = new Configuration({
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            accessToken: () => this.getAuthToken(),
            credentials: 'include',
            middleware: [new ConnectionResetMiddleware()],
        });
        this.authApi = new AuthRestApi(apiConfig);
        this.keepAlive();
    }

    public async getAuthToken(): Promise<string> {
        if (!this.loginResponse?.token) {
            this.loginResponse = await this.fetchToken();
        }
        return `Bearer ${this.loginResponse.token}`;
    }

    public async getUser(): Promise<UserRepresentation> {
        if (!this.loginResponse?.user) {
            this.loginResponse = await this.fetchToken();
        }
        return this.loginResponse.user!;
    }

    public async getCompany(): Promise<CompanyRepresentation> {
        if (!this.loginResponse?.company) {
            this.loginResponse = await this.fetchToken();
        }
        return this.loginResponse.company!;
    }

    public async getLicenseFeatures(): Promise<LicenseFeature[]> {
        if (await this.isAdmin()) {
            return Object.values(LicenseFeature) as LicenseFeature[];
        }
        if (!this.cachedLicenseFeatures) {
            this.cachedLicenseFeatures = await this.fetchLicenseFeatures();
        }
        return this.cachedLicenseFeatures
            .filter((it) => it.expiresAtSeconds * 1000 > Date.now())
            .map((it) => licenseFeatureFromString(it.key))
            .filter(ArrayUtils.filterUndefined);
    }

    public async hasLicenseFeature(feature: LicenseFeature): Promise<boolean> {
        const licenseFeatures = await this.getLicenseFeatures();
        return licenseFeatures.includes(feature);
    }

    public async getRoles(): Promise<string[]> {
        if (!this.loginResponse?.roles) {
            await this.authenticate();
        }
        return this.loginResponse?.roles || [];
    }

    public async authenticate(): Promise<boolean> {
        try {
            this.loginResponse = await this.fetchToken();
            Events.instance.publishEvent('authenticated');
            return true;
        } catch (e: any) {
            return false;
        }
    }

    public login(customer: string, redirectUrl?: string): void {
        let targetUrl = `${process.env.VUE_APP_SERVICE_LOGIN}${customer}`;
        if (redirectUrl) {
            targetUrl += `?redirect=${encodeURIComponent(CachedAuthRestApi.mapToAbsolutUrl(redirectUrl))}`;
        } else {
            targetUrl += `?redirect=${encodeURIComponent(window.location.href)}`;
        }
        console.log(`Redirecting to login: ${targetUrl}`);
        window.location.replace(targetUrl);
    }

    public async logout(): Promise<void> {
        const targetUrl = `${process.env.VUE_APP_SERVICE_LOGOUT}?redirect=${encodeURIComponent(CachedAuthRestApi.mapToAbsolutUrl())}`;
        window.location.replace(targetUrl);
        Events.instance.publishEvent('logout');
        // not yet implemented
        // return this.authApi.logout();
    }

    public getAccountSettingsUrl(lang: string): Promise<string> {
        return this.authApi.getAccountSettingsUrl(lang);
    }

    public async isCompanyAdmin(): Promise<boolean> {
        const roles = await this.getRoles();
        return roles.includes(Role.COMPANY_ADMIN) || roles.includes(Role.HALVAR_ADMIN);
    }

    public async isAdmin(): Promise<boolean> {
        const roles = await this.getRoles();
        return roles.includes(Role.HALVAR_ADMIN);
    }

    public isLoggedIn(): boolean {
        return this.loginResponse !== null && this.loginResponse.token !== undefined;
    }

    public async getUserRole(): Promise<Role> {
        const roles = await this.getRoles();
        if (roles.includes(Role.HALVAR_ADMIN)) {
            return Role.HALVAR_ADMIN;
        }
        if (roles.includes(Role.COMPANY_ADMIN)) {
            return Role.COMPANY_ADMIN;
        }
        return Role.USER;
    }

    private async fetchLicenseFeatures(): Promise<LicenseFeatureRepresentation[]> {
        const company = await this.getCompany();
        if (!company.license) {
            return [];
        }
        const licenseKey = company.license;
        const license = await AsyncDebouncer.debounce(`CachedAuthRestApi.getLicense(${company.license})`, () => this.authApi.getLicense(licenseKey));
        return license.features;
    }

    private async fetchToken(retryCounter: number = 0): Promise<TokenRepresentation> {
        try {
            const token = await AsyncDebouncer.debounce('CachedAuthRestApi.fetchToken', async () => {
                const accessKey = CachedAuthRestApi.getQueryParameter('accesskey');
                return this.authApi.getToken(accessKey);
            });
            if (this.loginResponse === null) {
                Events.instance.publishEvent('login');
            }
            const lastUser = localStorage.getItem('halvar-user');
            const currentUser = token.user?.key;
            if (lastUser !== currentUser) {
                Events.instance.publishEvent('user-changed');
                localStorage.setItem('halvar-user', currentUser || '');
            }
            this.loginResponse = token;
            return this.loginResponse;
        } catch (e: any) {
            const response: Response|undefined = e as Response;
            if (response?.status === 401 || response?.status === 403 || response?.status === 404) {
                if (this.loginResponse) {
                    // user was already logged in, send logout event
                    Events.instance.publishEvent('logout', response);
                }
                if (response.status !== 404 && this.loginResponse?.company) {
                    this.login(this.loginResponse!.company!.key);
                }
                throw e;
            }
            if (retryCounter > CachedAuthRestApi.SERVICE_UNAVAILABLE_RETRY_LIMIT) {
                if (response?.status === 500) {
                    Events.instance.publishEvent('service-unavailable', response);
                    throw response;
                }
                Events.instance.publishEvent('service-unavailable', e);
                throw e;
            }
            // retry request
            return new Promise(((resolve, reject) => {
                setTimeout(() => {
                    this.fetchToken(retryCounter + 1)
                        .then((res) => resolve(res))
                        .catch((err) => reject(err));
                }, CachedAuthRestApi.SERVICE_UNAVAILABLE_RETRY_INTERVALL);
            }));
        }
    }

    private keepAlive(): void {
        this.authenticate().finally(() => setTimeout(() => this.keepAlive(), CachedAuthRestApi.TOKEN_REFRESH_INTERVALL));
    }

    private static mapToAbsolutUrl(path: string = ''): string {
        if (path && path.startsWith('/')) {
            return `${window.location.protocol}//${window.location.host}${path}`;
        }
        return `${window.location.protocol}//${window.location.host}/${path}`;
    }

    private static getQueryParameter(param: string): string|undefined {
        const url = window.location.href;
        // eslint-disable-next-line no-useless-escape
        const name = param.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) {
            return undefined;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}
