/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FeatureRepresentation,
    FeatureRepresentationFromJSON,
    FeatureRepresentationToJSON,
    LicenseFeatureRequest,
    LicenseFeatureRequestFromJSON,
    LicenseFeatureRequestToJSON,
    LicenseRepresentation,
    LicenseRepresentationFromJSON,
    LicenseRepresentationToJSON,
    TokenRepresentation,
    TokenRepresentationFromJSON,
    TokenRepresentationToJSON,
} from '../models';

export interface AddLicenseFeatureRequest {
    key: string;
    licenseFeatureRequest?: LicenseFeatureRequest;
}

export interface GetAccountSettingsUrlRequest {
    lang: string;
}

export interface GetLicenseRequest {
    key: string;
}

export interface GetTokenRequest {
    stt?: string;
}

export interface RemoveLicenseRequest {
    key: string;
}

export interface RemoveLicenseFeatureRequest {
    key: string;
    featkey: string;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Add a feature to an existing license key
     * Add a feature
     */
    async addLicenseFeatureRaw(requestParameters: AddLicenseFeatureRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling addLicenseFeature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/licenses/{key}/features`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LicenseFeatureRequestToJSON(requestParameters.licenseFeatureRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a feature to an existing license key
     * Add a feature
     */
    async addLicenseFeature(key: string, licenseFeatureRequest?: LicenseFeatureRequest): Promise<void> {
        await this.addLicenseFeatureRaw({ key: key, licenseFeatureRequest: licenseFeatureRequest });
    }

    /**
     * Create a new license
     * Create a new license
     */
    async createLicenseRaw(): Promise<runtime.ApiResponse<LicenseRepresentation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/licenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LicenseRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new license
     * Create a new license
     */
    async createLicense(): Promise<LicenseRepresentation> {
        const response = await this.createLicenseRaw();
        return await response.value();
    }

    /**
     * Get the url for the account settings page
     * Get account settings url
     */
    async getAccountSettingsUrlRaw(requestParameters: GetAccountSettingsUrlRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getAccountSettingsUrl.');
        }

        const queryParameters: any = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/account/settingsurl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get the url for the account settings page
     * Get account settings url
     */
    async getAccountSettingsUrl(lang: string): Promise<string> {
        const response = await this.getAccountSettingsUrlRaw({ lang: lang });
        return await response.value();
    }

    /**
     * Get a list of all available features for licenses
     * Get all features
     */
    async getFeaturesRaw(): Promise<runtime.ApiResponse<Array<FeatureRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FeatureRepresentationFromJSON));
    }

    /**
     * Get a list of all available features for licenses
     * Get all features
     */
    async getFeatures(): Promise<Array<FeatureRepresentation>> {
        const response = await this.getFeaturesRaw();
        return await response.value();
    }

    /**
     * Get license data for a license key
     * Get license information
     */
    async getLicenseRaw(requestParameters: GetLicenseRequest): Promise<runtime.ApiResponse<LicenseRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/licenses/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LicenseRepresentationFromJSON(jsonValue));
    }

    /**
     * Get license data for a license key
     * Get license information
     */
    async getLicense(key: string): Promise<LicenseRepresentation> {
        const response = await this.getLicenseRaw({ key: key });
        return await response.value();
    }

    /**
     * Get a list of all licenses.
     * Get all licenses
     */
    async getLicensesRaw(): Promise<runtime.ApiResponse<Array<LicenseRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/licenses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LicenseRepresentationFromJSON));
    }

    /**
     * Get a list of all licenses.
     * Get all licenses
     */
    async getLicenses(): Promise<Array<LicenseRepresentation>> {
        const response = await this.getLicensesRaw();
        return await response.value();
    }

    /**
     * Exchange and refresh (if necessary) access token for secret provided as httpOnly cookie
     * Get or update token
     */
    async getTokenRaw(requestParameters: GetTokenRequest): Promise<runtime.ApiResponse<TokenRepresentation>> {
        const queryParameters: any = {};

        if (requestParameters.stt !== undefined) {
            queryParameters['stt'] = requestParameters.stt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRepresentationFromJSON(jsonValue));
    }

    /**
     * Exchange and refresh (if necessary) access token for secret provided as httpOnly cookie
     * Get or update token
     */
    async getToken(stt?: string): Promise<TokenRepresentation> {
        const response = await this.getTokenRaw({ stt: stt });
        return await response.value();
    }

    /**
     * Logout, delete a token and current active cookie
     * Logout
     */
    async logoutRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/token`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout, delete a token and current active cookie
     * Logout
     */
    async logout(): Promise<void> {
        await this.logoutRaw();
    }

    /**
     * Remove a license
     * Remove a license
     */
    async removeLicenseRaw(requestParameters: RemoveLicenseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling removeLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/licenses/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a license
     * Remove a license
     */
    async removeLicense(key: string): Promise<void> {
        await this.removeLicenseRaw({ key: key });
    }

    /**
     * Remove a feature from an existing license
     * Remove a feature
     */
    async removeLicenseFeatureRaw(requestParameters: RemoveLicenseFeatureRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling removeLicenseFeature.');
        }

        if (requestParameters.featkey === null || requestParameters.featkey === undefined) {
            throw new runtime.RequiredError('featkey','Required parameter requestParameters.featkey was null or undefined when calling removeLicenseFeature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/licenses/{key}/features/{featkey}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"featkey"}}`, encodeURIComponent(String(requestParameters.featkey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a feature from an existing license
     * Remove a feature
     */
    async removeLicenseFeature(key: string, featkey: string): Promise<void> {
        await this.removeLicenseFeatureRaw({ key: key, featkey: featkey });
    }

}
