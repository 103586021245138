/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateCreateFromPortfolioRequest
 */
export interface TemplateCreateFromPortfolioRequest {
    /**
     * key of the source portfolio
     * @type {string}
     * @memberof TemplateCreateFromPortfolioRequest
     */
    sourcekey: string;
    /**
     * name of the template to create
     * @type {string}
     * @memberof TemplateCreateFromPortfolioRequest
     */
    name: string;
    /**
     * optional description of the template
     * @type {string}
     * @memberof TemplateCreateFromPortfolioRequest
     */
    description?: string;
    /**
     * optional key to restrict the template access to the company
     * @type {string}
     * @memberof TemplateCreateFromPortfolioRequest
     */
    companyKey?: string;
}

export function TemplateCreateFromPortfolioRequestFromJSON(json: any): TemplateCreateFromPortfolioRequest {
    return TemplateCreateFromPortfolioRequestFromJSONTyped(json, false);
}

export function TemplateCreateFromPortfolioRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateCreateFromPortfolioRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourcekey': json['sourcekey'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'companyKey': !exists(json, 'companyKey') ? undefined : json['companyKey'],
    };
}

export function TemplateCreateFromPortfolioRequestToJSON(value?: TemplateCreateFromPortfolioRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourcekey': value.sourcekey,
        'name': value.name,
        'description': value.description,
        'companyKey': value.companyKey,
    };
}


