import Vue from 'vue';

Vue.component('halvar-logo', () => import(/* webpackChunkName: "icons" */ '../components/icons/logo.svg'));
Vue.component('halvar-logo-icon', () => import(/* webpackChunkName: "icons" */ '../components/icons/logo-icon.svg'));
// Vue.component('halvar-logo-text', () => import(/* webpackChunkName: "icons" */ '../components/icons/logo-text.svg'));

// general icons
// Vue.component('icon-light-add', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/add.svg'));
Vue.component('icon-light-retry', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/retry.svg'));
Vue.component('icon-light-crane', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/crane.svg'));
Vue.component('icon-light-arrow-left', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/arrow-left.svg'));
Vue.component('icon-light-menu', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/menu.svg'));
Vue.component('icon-light-menu-dots', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/menu-dots.svg'));
Vue.component('icon-light-close', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/close.svg'));
Vue.component('icon-light-delete', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/delete.svg'));
Vue.component('icon-light-settings', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/settings.svg'));
Vue.component('icon-light-edit', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/edit.svg'));
Vue.component('icon-light-check', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/check.svg'));
Vue.component('icon-light-search', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/search.svg'));
Vue.component('icon-light-check-circle', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/check-circle.svg'));
Vue.component('icon-light-undo', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/undo.svg'));
Vue.component('icon-light-download', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/download.svg'));
Vue.component('icon-light-users', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/users.svg'));
Vue.component('icon-light-home', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/home.svg'));
Vue.component('icon-light-view', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/view.svg'));
Vue.component('icon-light-share-document', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/common-file-text-share.svg'));
Vue.component('icon-light-task-checklist-check', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/task-checklist-check.svg'));

// login
Vue.component('icon-light-key-lock', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/login/key-lock.svg'));

// notification icons
Vue.component('icon-light-help', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/notifications/help.svg'));
Vue.component('icon-light-info', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/notifications/info.svg'));
Vue.component('icon-light-warn', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/notifications/warn.svg'));
Vue.component('icon-light-error', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/notifications/error.svg'));

// dashboard icons
Vue.component('icon-light-calendar', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/general/calendar-date.svg'));
Vue.component('icon-light-report', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/report.svg'));
Vue.component('icon-light-compare', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/compare.svg'));
Vue.component('icon-light-expand', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/expand.svg'));
Vue.component('icon-light-shrink', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/shrink.svg'));
Vue.component('icon-light-dashboard', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/dashboard.svg'));
Vue.component('icon-light-chart-pie', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/chart-pie.svg'));
Vue.component('icon-light-chart-mixed', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/chart-mixed.svg'));
Vue.component('icon-light-chart-gauge', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/chart-gauge.svg'));
Vue.component('icon-light-chart-bars', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/chart-bars.svg'));
Vue.component('icon-light-chart-3d', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/chart-3d.svg'));
Vue.component('icon-light-chart-windrose', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/compass.svg'));
Vue.component('icon-light-commissions', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/commissions.svg'));
Vue.component('icon-light-map', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/map.svg'));
Vue.component('icon-light-weather', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/weather.svg'));
Vue.component('icon-light-table', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/table.svg'));
Vue.component('icon-light-zoom-in', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/zoom-in.svg'));
Vue.component('icon-light-zoom-out', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/zoom-out.svg'));
Vue.component('icon-light-sum', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/dashboard/sum.svg'));

// portfolio icons
Vue.component('icon-light-portfolio', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/portfolio/portfolio.svg'));
Vue.component('icon-light-portfolios', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/portfolio/portfolios.svg'));
Vue.component('icon-light-solarpanel', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/portfolio/solarpanel.svg'));
Vue.component('icon-light-windturbine', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/portfolio/windturbine.svg'));
Vue.component('icon-light-waterdam', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/portfolio/waterdam.svg'));
Vue.component('icon-light-energyearth', () => import(/* webpackChunkName: "icons" */ '../components/icons/light/portfolio/energyearth.svg'));

// bold icons
Vue.component('icon-bold-menu', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/menu.svg'));
Vue.component('icon-bold-filter', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/filter.svg'));
Vue.component('icon-bold-circle', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/circle.svg'));
Vue.component('icon-bold-account', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/account.svg'));
Vue.component('icon-bold-user', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/user.svg'));
Vue.component('icon-bold-users', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/users.svg'));
Vue.component('icon-bold-key', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/key.svg'));
Vue.component('icon-bold-sort-asc', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/sort-asc.svg'));
Vue.component('icon-bold-carret', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/carret.svg'));
Vue.component('icon-bold-settings', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/settings.svg'));
Vue.component('icon-bold-delete', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/delete.svg'));
Vue.component('icon-bold-edit', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/edit.svg'));
Vue.component('icon-bold-dashboard', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/dashboard.svg'));
Vue.component('icon-bold-park', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/park.svg'));
Vue.component('icon-bold-shareholder', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/shareholder.svg'));
Vue.component('icon-bold-wind', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/wind.svg'));
Vue.component('icon-bold-generator', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/turbine.svg'));
Vue.component('icon-bold-water', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/waterdam.svg'));
Vue.component('icon-bold-solar', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/solar.svg'));
Vue.component('icon-bold-earth', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/earth.svg'));
Vue.component('icon-bold-chart-mixed', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/chart-mixed.svg'));
Vue.component('icon-bold-chart-gauge', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/chart-gauge.svg'));
Vue.component('icon-bold-chart-pie', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/chart-pie.svg'));
Vue.component('icon-bold-add', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/add.svg'));
Vue.component('icon-bold-menu-dots', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/menu-dots.svg'));
Vue.component('icon-bold-table', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/table.svg'));
Vue.component('icon-bold-check', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/check.svg'));
Vue.component('icon-bold-x-circle', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/x-circle.svg'));
Vue.component('icon-bold-star-filled', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/star-filled.svg'));
Vue.component('icon-bold-star-outline', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/star.svg'));
Vue.component('icon-bold-compare', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/compare.svg'));
Vue.component('icon-bold-time', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/time.svg'));
Vue.component('icon-bold-drag-to-sort', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/drag-to-sort.svg'));
Vue.component('icon-bold-info', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/info.svg'));
Vue.component('icon-bold-pdf', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/pdf.svg'));
Vue.component('icon-bold-download', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/download.svg'));
Vue.component('icon-bold-arrow-button', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/arrow-button.svg'));
Vue.component('icon-bold-calendar', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/calendar.svg'));
Vue.component('icon-bold-copy', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/copy.svg'));
Vue.component('icon-bold-power', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/power.svg'));
Vue.component('icon-bold-height', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/height.svg'));
Vue.component('icon-bold-zoom', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/expand.svg'));
Vue.component('icon-bold-save', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/save.svg'));
Vue.component('icon-bold-generatorgroup', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/vectors-anchor-circle.svg'));
Vue.component('icon-bold-alert', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/alert.svg'));
Vue.component('icon-bold-zoom-in', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/zoom-in.svg'));
Vue.component('icon-bold-zoom-out', () => import(/* webpackChunkName: "icons" */ '../components/icons/bold/zoom-out.svg'));

Vue.component('illustration-onboarding-1', () => import(/* webpackChunkName: "onboarding" */ '../components/illustrations/onboarding-1.svg'));
Vue.component('illustration-onboarding-2', () => import(/* webpackChunkName: "onboarding" */ '../components/illustrations/onboarding-2.svg'));
Vue.component('illustration-onboarding-3', () => import(/* webpackChunkName: "onboarding" */ '../components/illustrations/onboarding-3.svg'));
Vue.component('illustration-onboarding-4', () => import(/* webpackChunkName: "onboarding" */ '../components/illustrations/onboarding-4.svg'));

Vue.component('illustration-favorites', () => import(/* webpackChunkName: "illustrations" */ '../components/illustrations/favorties.svg'));
Vue.component('illustration-no-widget', () => import(/* webpackChunkName: "illustrations" */ '../components/illustrations/add-widget.svg'));
Vue.component('illustration-error', () => import(/* webpackChunkName: "illustrations" */ '../components/illustrations/error.svg'));
Vue.component('illustration-error-gray', () => import(/* webpackChunkName: "illustrations" */ '../components/illustrations/error-gray.svg'));

Vue.component('preview-chart-mixed', () => import(/* webpackChunkName: "chart-previews" */ '../components/illustrations/chart-previews/chart-mixed.svg'));
