/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentGeneratorRepresentation,
    DocumentGeneratorRepresentationFromJSON,
    DocumentGeneratorRepresentationFromJSONTyped,
    DocumentGeneratorRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocumentRepresentation
 */
export interface DocumentRepresentation {
    /**
     * Identifier of the document.
     * @type {string}
     * @memberof DocumentRepresentation
     */
    key: string;
    /**
     * An arbitrary user defined name for the document.
     * @type {string}
     * @memberof DocumentRepresentation
     */
    name: string;
    /**
     * An arbitrary category for the document.
     * @type {string}
     * @memberof DocumentRepresentation
     */
    category: string;
    /**
     * Timestamp of the document upload. This is the timestamp when the document has been added to Halvar.
     * @type {number}
     * @memberof DocumentRepresentation
     */
    uploadedAt: number;
    /**
     * Timestamp of the document creation. This is the timestamp when document has been created itself, not when it has been added to Halvar.
     * @type {number}
     * @memberof DocumentRepresentation
     */
    createdAt: number;
    /**
     * The year for which the content of document is generated
     * @type {number}
     * @memberof DocumentRepresentation
     */
    contentYear?: number;
    /**
     * The month for which the content of document is generated
     * @type {number}
     * @memberof DocumentRepresentation
     */
    contentMonth?: number;
    /**
     * Key of the user that created the document
     * @type {string}
     * @memberof DocumentRepresentation
     */
    createdBy: string;
    /**
     * Optional portfolio key. If set, the document has a relation to the portfolio.
     * @type {string}
     * @memberof DocumentRepresentation
     */
    portfolioKey?: string;
    /**
     * Optional dashboard key. If set, the document has a relation to the dashboard.
     * @type {string}
     * @memberof DocumentRepresentation
     */
    dashboardKey?: string;
    /**
     * List of generators related to this document. Might be an empty list.
     * @type {Array<DocumentGeneratorRepresentation>}
     * @memberof DocumentRepresentation
     */
    generators: Array<DocumentGeneratorRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentRepresentation
     */
    hasBeenShared: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentRepresentation
     */
    state: string;
}

export function DocumentRepresentationFromJSON(json: any): DocumentRepresentation {
    return DocumentRepresentationFromJSONTyped(json, false);
}

export function DocumentRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'category': json['category'],
        'uploadedAt': json['uploadedAt'],
        'createdAt': json['createdAt'],
        'contentYear': !exists(json, 'contentYear') ? undefined : json['contentYear'],
        'contentMonth': !exists(json, 'contentMonth') ? undefined : json['contentMonth'],
        'createdBy': json['createdBy'],
        'portfolioKey': !exists(json, 'portfolioKey') ? undefined : json['portfolioKey'],
        'dashboardKey': !exists(json, 'dashboardKey') ? undefined : json['dashboardKey'],
        'generators': ((json['generators'] as Array<any>).map(DocumentGeneratorRepresentationFromJSON)),
        'hasBeenShared': json['hasBeenShared'],
        'state': json['state'],
    };
}

export function DocumentRepresentationToJSON(value?: DocumentRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'category': value.category,
        'uploadedAt': value.uploadedAt,
        'createdAt': value.createdAt,
        'contentYear': value.contentYear,
        'contentMonth': value.contentMonth,
        'createdBy': value.createdBy,
        'portfolioKey': value.portfolioKey,
        'dashboardKey': value.dashboardKey,
        'generators': ((value.generators as Array<any>).map(DocumentGeneratorRepresentationToJSON)),
        'hasBeenShared': value.hasBeenShared,
        'state': value.state,
    };
}


