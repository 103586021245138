/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportConfigLayoutRepresentation,
    ReportConfigLayoutRepresentationFromJSON,
    ReportConfigLayoutRepresentationFromJSONTyped,
    ReportConfigLayoutRepresentationToJSON,
    ReportCreationInterval,
    ReportCreationIntervalFromJSON,
    ReportCreationIntervalFromJSONTyped,
    ReportCreationIntervalToJSON,
    ReportReferenceDate,
    ReportReferenceDateFromJSON,
    ReportReferenceDateFromJSONTyped,
    ReportReferenceDateToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportConfigRepresentation
 */
export interface ReportConfigRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ReportConfigRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigRepresentation
     */
    dashboardKey: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigRepresentation
     */
    reportType: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigRepresentation
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigRepresentation
     */
    subtitle: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportConfigRepresentation
     */
    createAutomatically: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportConfigRepresentation
     */
    createAt?: number;
    /**
     * 
     * @type {ReportCreationInterval}
     * @memberof ReportConfigRepresentation
     */
    creationInterval?: ReportCreationInterval;
    /**
     * 
     * @type {ReportReferenceDate}
     * @memberof ReportConfigRepresentation
     */
    referenceDate?: ReportReferenceDate;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportConfigRepresentation
     */
    sentToEmail?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigRepresentation
     */
    layoutGenerationPolicy: string;
    /**
     * 
     * @type {Array<ReportConfigLayoutRepresentation>}
     * @memberof ReportConfigRepresentation
     */
    layouts?: Array<ReportConfigLayoutRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof ReportConfigRepresentation
     */
    storeInDocuments: boolean;
    /**
     * The ISO 639 code for the language of the report
     * @type {string}
     * @memberof ReportConfigRepresentation
     */
    language?: string;
}

export function ReportConfigRepresentationFromJSON(json: any): ReportConfigRepresentation {
    return ReportConfigRepresentationFromJSONTyped(json, false);
}

export function ReportConfigRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportConfigRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'dashboardKey': json['dashboardKey'],
        'reportType': json['reportType'],
        'title': json['title'],
        'subtitle': json['subtitle'],
        'createAutomatically': json['createAutomatically'],
        'createAt': !exists(json, 'createAt') ? undefined : json['createAt'],
        'creationInterval': !exists(json, 'creationInterval') ? undefined : ReportCreationIntervalFromJSON(json['creationInterval']),
        'referenceDate': !exists(json, 'referenceDate') ? undefined : ReportReferenceDateFromJSON(json['referenceDate']),
        'sentToEmail': !exists(json, 'sentToEmail') ? undefined : json['sentToEmail'],
        'layoutGenerationPolicy': json['layoutGenerationPolicy'],
        'layouts': !exists(json, 'layouts') ? undefined : ((json['layouts'] as Array<any>).map(ReportConfigLayoutRepresentationFromJSON)),
        'storeInDocuments': json['storeInDocuments'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function ReportConfigRepresentationToJSON(value?: ReportConfigRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'dashboardKey': value.dashboardKey,
        'reportType': value.reportType,
        'title': value.title,
        'subtitle': value.subtitle,
        'createAutomatically': value.createAutomatically,
        'createAt': value.createAt,
        'creationInterval': ReportCreationIntervalToJSON(value.creationInterval),
        'referenceDate': ReportReferenceDateToJSON(value.referenceDate),
        'sentToEmail': value.sentToEmail,
        'layoutGenerationPolicy': value.layoutGenerationPolicy,
        'layouts': value.layouts === undefined ? undefined : ((value.layouts as Array<any>).map(ReportConfigLayoutRepresentationToJSON)),
        'storeInDocuments': value.storeInDocuments,
        'language': value.language,
    };
}


