
import { Vue, Component } from 'vue-property-decorator';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import MissingLicenseImage from '@/components/illustrations/onboarding-2.svg';

@Component({
    components: { BaseDialog, MissingLicenseImage },
})
export default class MissingLicenseDlg extends Vue {

    private readonly contactEmail: string = process.env.VUE_APP_SUPPORT_EMAIL || '';

    private close() {
        this.$emit('close');
    }
}
