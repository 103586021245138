import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import LoggingUtils from '@/assets/js/utils/LoggingUtils';

// store modules
import App from '@/store/app';
import User from '@/store/user';
import Benchmarking from '@/store/benchmarking';

Vue.use(Vuex);

const cacheInStore = process.env.VUE_APP_CACHE_IN_LOCALSTORAGE === 'true';
const cachedModules = [
    'App',
];
const vuexLocal = new VuexPersistence<any>({
    storage: window.localStorage,
    modules: cacheInStore ? cachedModules : [],
});

export default new Vuex.Store({
    state: {
        environment: process.env.VUE_APP_BUILD_TYPE || 'dev',
        updated: false,
        initialized: false,
    },

    getters: {
        /**
         * Check if the store is still loading data. Metric, generator park and portfolio names are used in chart
         * legends and must be loaded before any chart is painted.
         */
        loading(state, getters): boolean {
            return !getters['User/userLoaded'];
        },
    },

    mutations: {
        updated(state) {
            state.updated = true;
        },
    },

    actions: {
        async logout(context) {
            await context.dispatch('clearData');
            if (process.env.VUE_APP_CLEAR_CUSTOMER_ON_LOGOUT === 'true') {
                context.commit('setCustomer', null);
            }
        },
        async init(context, params?: { portfolioKey?: string }) {
            context.state.initialized = false;
            await Promise.allSettled([
                context.dispatch('App/init', params),
                context.dispatch('User/init', params),
                context.dispatch('Benchmarking/init', params),
            ]);
            context.state.initialized = true;
        },
        async clearData(context, params: any) {
            LoggingUtils.log('store', 'clearing stored data');
            await Promise.allSettled([
                context.dispatch('App/clear', params),
                context.dispatch('Benchmarking/clear', params),
                context.dispatch('User/clear', params),
            ]);
        },
    },

    modules: {
        User,
        Benchmarking,
        App,
    },

    plugins: [
        vuexLocal.plugin,
    ],
});
