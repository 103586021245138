
import { Vue, Component, Prop } from 'vue-property-decorator';
import { LicenseFeature } from '@/modules/shared/types';
import MissingLicenseDlg from '@/modules/shared/components/dialogs/generic/MissingLicenseDlg.vue';

@Component({
    components: { MissingLicenseDlg },
})
export default class LicenseGuard extends Vue {

    @Prop({ default: null })
    public readonly requiredFeatures!: LicenseFeature|LicenseFeature[]|null;

    private showInfoDlg: boolean = false;

    private get hasAllFeatureLicenses(): boolean {
        if (this.requiredFeatures === null) {
            return true;
        }
        if (Array.isArray(this.requiredFeatures)) {
            return this.$store.getters['User/hasAllFeatureLicenses'](this.requiredFeatures);
        }
        return this.$store.getters['User/hasFeatureLicense'](this.requiredFeatures);
    }

    private onClick(event: any) {
        if (this.hasAllFeatureLicenses) {
            this.$emit('click', event);
        } else {
            this.showInfoDlg = true;
        }
    }
}
