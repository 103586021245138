import { Middleware, ResponseContext } from '@/clients/dashboardapi/v2/runtime';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';

export default class AuthMiddleware implements Middleware {

    private readonly authApi: AuthApi;
    private retryCounter = new Map<string, number>();

    constructor(authApi: AuthApi) {
        this.authApi = authApi;
    }

    public async post(context: ResponseContext): Promise<Response | void> {
        if (context.response.status === 401 && context.init.headers && !context.url.endsWith('/api/v2/token')) {
            const retry = this.incrementRetries(context.url);
            if (retry > 3) {
                throw context.response;
            }
            try {
                // try to refresh the access token
                await this.authApi.authenticate();
                const token = await this.authApi.getAuthToken();
                if (token) {
                    (context.init.headers as any).Authorization = token;
                    console.info('Retrying failed request with refreshed access token.');
                    const response = await context.fetch(context.url, context.init);
                    // success!
                    this.resetRetries(context.url);
                    return response;
                }
            } catch (e: any) {
                console.error('Could not refresh access token');
            }
        }
        return Promise.resolve(context.response);
    }

    private incrementRetries(url: string): number {
        const current = this.retryCounter.get(url) || 0;
        this.retryCounter.set(url, current + 1);
        return current + 1;
    }

    private resetRetries(url: string) {
        this.retryCounter.delete(url);
    }
}
