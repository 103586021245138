import type { LicenseRepository } from '@/modules/ctx-documents';
import type { LicenseFeature } from '@/modules/shared/types';
import type { LicenseApi } from '@/modules/shared/adapter';

export class LicenseRepositoryImpl implements LicenseRepository {

    private readonly licenseApi: LicenseApi;

    constructor(apis: {
        license: LicenseApi;
    }) {
        this.licenseApi = apis.license;
    }

    public async getLicenseFeaturesForLicenseKey(licenseKey: string): Promise<LicenseFeature[]> {
        const license = await this.licenseApi.getLicense(licenseKey);
        return license.features.map((it) => it.key as LicenseFeature);
    }
}
