/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyAdminRepresentation,
    CompanyAdminRepresentationFromJSON,
    CompanyAdminRepresentationToJSON,
    CompanyCreateRequest,
    CompanyCreateRequestFromJSON,
    CompanyCreateRequestToJSON,
    CompanyRepresentation,
    CompanyRepresentationFromJSON,
    CompanyRepresentationToJSON,
    CompanyUpdateRequest,
    CompanyUpdateRequestFromJSON,
    CompanyUpdateRequestToJSON,
} from '../models';

export interface CreateCompanyRequest {
    companyCreateRequest?: CompanyCreateRequest;
}

export interface CreateCompanyAdminRequest {
    companyKey: string;
    userKey: string;
}

export interface DeleteCompanyRequest {
    key: string;
}

export interface DeleteCompanyAdminRequest {
    companyKey: string;
    userKey: string;
}

export interface GetCompaniesRequest {
    allGenerators?: Array<string>;
}

export interface GetCompanyRequest {
    key: string;
}

export interface GetCompanyAdminsRequest {
    key: string;
}

export interface UpdateCompanyRequest {
    key: string;
    companyUpdateRequest?: CompanyUpdateRequest;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     * Create a new Company
     * Create a new Company
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest): Promise<runtime.ApiResponse<CompanyRepresentation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyCreateRequestToJSON(requestParameters.companyCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new Company
     * Create a new Company
     */
    async createCompany(companyCreateRequest?: CompanyCreateRequest): Promise<CompanyRepresentation> {
        const response = await this.createCompanyRaw({ companyCreateRequest: companyCreateRequest });
        return await response.value();
    }

    /**
     * Create a company admin
     * Promote user
     */
    async createCompanyAdminRaw(requestParameters: CreateCompanyAdminRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyKey === null || requestParameters.companyKey === undefined) {
            throw new runtime.RequiredError('companyKey','Required parameter requestParameters.companyKey was null or undefined when calling createCompanyAdmin.');
        }

        if (requestParameters.userKey === null || requestParameters.userKey === undefined) {
            throw new runtime.RequiredError('userKey','Required parameter requestParameters.userKey was null or undefined when calling createCompanyAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/companies/{companyKey}/admins/{userKey}`.replace(`{${"companyKey"}}`, encodeURIComponent(String(requestParameters.companyKey))).replace(`{${"userKey"}}`, encodeURIComponent(String(requestParameters.userKey))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a company admin
     * Promote user
     */
    async createCompanyAdmin(companyKey: string, userKey: string): Promise<void> {
        await this.createCompanyAdminRaw({ companyKey: companyKey, userKey: userKey });
    }

    /**
     * Delete the company
     * Delete the company
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/companies/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the company
     * Delete the company
     */
    async deleteCompany(key: string): Promise<void> {
        await this.deleteCompanyRaw({ key: key });
    }

    /**
     * Removes the company admin role of a user
     * Degrade a company admin
     */
    async deleteCompanyAdminRaw(requestParameters: DeleteCompanyAdminRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyKey === null || requestParameters.companyKey === undefined) {
            throw new runtime.RequiredError('companyKey','Required parameter requestParameters.companyKey was null or undefined when calling deleteCompanyAdmin.');
        }

        if (requestParameters.userKey === null || requestParameters.userKey === undefined) {
            throw new runtime.RequiredError('userKey','Required parameter requestParameters.userKey was null or undefined when calling deleteCompanyAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/companies/{companyKey}/admins/{userKey}`.replace(`{${"companyKey"}}`, encodeURIComponent(String(requestParameters.companyKey))).replace(`{${"userKey"}}`, encodeURIComponent(String(requestParameters.userKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes the company admin role of a user
     * Degrade a company admin
     */
    async deleteCompanyAdmin(companyKey: string, userKey: string): Promise<void> {
        await this.deleteCompanyAdminRaw({ companyKey: companyKey, userKey: userKey });
    }

    /**
     * Return all companies
     * Return all companies
     */
    async getCompaniesRaw(requestParameters: GetCompaniesRequest): Promise<runtime.ApiResponse<Array<CompanyRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.allGenerators) {
            queryParameters['all_generators'] = requestParameters.allGenerators.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyRepresentationFromJSON));
    }

    /**
     * Return all companies
     * Return all companies
     */
    async getCompanies(allGenerators?: Array<string>): Promise<Array<CompanyRepresentation>> {
        const response = await this.getCompaniesRaw({ allGenerators: allGenerators });
        return await response.value();
    }

    /**
     * Return the company with the specified key
     * Return a company
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest): Promise<runtime.ApiResponse<CompanyRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/companies/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyRepresentationFromJSON(jsonValue));
    }

    /**
     * Return the company with the specified key
     * Return a company
     */
    async getCompany(key: string): Promise<CompanyRepresentation> {
        const response = await this.getCompanyRaw({ key: key });
        return await response.value();
    }

    /**
     * Return all company admins
     * Return all company admins
     */
    async getCompanyAdminsRaw(requestParameters: GetCompanyAdminsRequest): Promise<runtime.ApiResponse<Array<CompanyAdminRepresentation>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getCompanyAdmins.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/companies/{key}/admins`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyAdminRepresentationFromJSON));
    }

    /**
     * Return all company admins
     * Return all company admins
     */
    async getCompanyAdmins(key: string): Promise<Array<CompanyAdminRepresentation>> {
        const response = await this.getCompanyAdminsRaw({ key: key });
        return await response.value();
    }

    /**
     * Update the company
     * Update the company
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest): Promise<runtime.ApiResponse<CompanyRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/companies/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUpdateRequestToJSON(requestParameters.companyUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyRepresentationFromJSON(jsonValue));
    }

    /**
     * Update the company
     * Update the company
     */
    async updateCompany(key: string, companyUpdateRequest?: CompanyUpdateRequest): Promise<CompanyRepresentation> {
        const response = await this.updateCompanyRaw({ key: key, companyUpdateRequest: companyUpdateRequest });
        return await response.value();
    }

}
