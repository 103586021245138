enum Permission {
    PORTFOLIOS_READ = 'portfolios:read',
    PORTFOLIOS_WRITE = 'portfolios:write',
    PORTFOLIO_GENERATORS_WRITE = 'portfolios:manage_generators',

    DASHBOARDS_READ = 'dashboards:read',
    DASHBOARDS_WRITE = 'dashboards:write',

    REPORTS_WRITE = 'reports:manage',

    ANONDATA_READ = 'anonymousdata:read',
    DATA_READ = 'data:read',

    PORTFOLIO_USERS_READ = 'portfolios:read_users',
    PORTFOLIO_USERS_WRITE = 'portfolios:manage_users',

    USERS_WRITE = 'users:manage',
}

export default Permission;
