/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportConfigSlotRepresentation
 */
export interface ReportConfigSlotRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ReportConfigSlotRepresentation
     */
    widgetKey: string;
    /**
     * 
     * @type {number}
     * @memberof ReportConfigSlotRepresentation
     */
    slot: number;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigSlotRepresentation
     */
    displayMode: string;
}

export function ReportConfigSlotRepresentationFromJSON(json: any): ReportConfigSlotRepresentation {
    return ReportConfigSlotRepresentationFromJSONTyped(json, false);
}

export function ReportConfigSlotRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportConfigSlotRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'widgetKey': json['widgetKey'],
        'slot': json['slot'],
        'displayMode': json['displayMode'],
    };
}

export function ReportConfigSlotRepresentationToJSON(value?: ReportConfigSlotRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'widgetKey': value.widgetKey,
        'slot': value.slot,
        'displayMode': value.displayMode,
    };
}


