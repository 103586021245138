export enum WidgetType {
    ScatterChart = 'scatterchart',
    TimeChart = 'timechart',
    StackedTimeChart = 'timechart-stacked',
    GeneratorChart = 'generatorchart',
    StackedGeneratorChart = 'generatorchart-stacked',
    Gauge = 'gauge',
    Number = 'number',
    Distribution = 'distribution',
    Logs = 'logs',
    LatestLogs = 'latest-logs',
    Table = 'table',
    Map = 'map',
    Windrose = 'windrose',
    WeatherMap = 'weathermap',
    Forecast = 'forecast',
    Heatmap = 'heatmap',
    PowerCurve = 'powercurve',
    Note = 'note',
    Availability = 'availability',
    Commissions = 'commissions',
    OperatorForecast = 'operator-forecast',
}
