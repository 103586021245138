import { I18nRepository } from '@/modules/ctx-documents';

export class I18nService {
    private readonly i18nRepository: I18nRepository;

    constructor(params: {
        i18nRepository: I18nRepository;
    }) {
        this.i18nRepository = params.i18nRepository;
    }

    public onLanguageChanged(callback: (lang: string) => void): void {
        this.i18nRepository.onLanguageChanged(callback);
    }
}
