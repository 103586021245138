import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import NotificationService from '@/assets/js/services/NotificationService';
import FileUtils from '@/assets/js/utils/FileUtils';
import type { Company, Document, DocumentFilter, Page, Park } from '@/modules/ctx-documents/types';
import type { DocumentsRepository, GeneratorsRepository, I18nRepository, ParksRepository } from '@/modules/ctx-documents/adapter';
import { DocumentState } from '@/modules/ctx-documents/types/DocumentState';

export class DocumentsService {

    private readonly documentsRepository: DocumentsRepository;
    private readonly generatorsRepository: GeneratorsRepository;
    private readonly parksRepository: ParksRepository;
    private readonly i18nRepository: I18nRepository;

    constructor(params: {
        documentsRepository: DocumentsRepository;
        generatorsRepository: GeneratorsRepository;
        parksRepository: ParksRepository;
        i18nRepository: I18nRepository;

    }) {
        this.documentsRepository = params.documentsRepository;
        this.generatorsRepository = params.generatorsRepository;
        this.parksRepository = params.parksRepository;
        this.i18nRepository = params.i18nRepository;
    }

    public async getDocument(documentKey: string): Promise<Document> {
        try {
            return await this.documentsRepository.getDocumentByKey(documentKey);
        } catch (e) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async getNextDocumentsPage(documents: Document[], filter?: DocumentFilter, pageSize: number = 50): Promise<Document[]> {
        const nextPage: Page = {
            pageSize: pageSize,
            page: Math.ceil(documents.length / pageSize),
        };
        try {
            const nextDocuments = await this.documentsRepository.getDocuments(nextPage, filter);
            return documents.concat(nextDocuments);
        } catch (e) {
            NotificationService.serviceError(e);
            return documents;
        }
    }

    public async reloadPendingDocuments(documents: Document[]): Promise<Document[]> {
        const refreshedDocuments = await Promise.all(documents
            .filter((it) => it.state === DocumentState.Pending)
            .map((it) => this.documentsRepository.getDocumentByKey(it.key)));
        const refreshedDocumentsByKey = new Map<string, Document>();
        refreshedDocuments.forEach((it) => refreshedDocumentsByKey.set(it.key, it));
        return documents.map((it) => refreshedDocumentsByKey.get(it.key) || it);
    }

    public async deleteDocumentWarning(document: Document): Promise<void> {
        try {
            await this.documentsRepository.deleteDocumentWarning(document);
        } catch (e) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async deleteDocument(document: Document): Promise<void> {
        try {
            await this.documentsRepository.deleteDocument(document);
        } catch (e) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async shareDocumentWithCompany(docKey: string, companyKey: string): Promise<void> {
        try {
            await this.documentsRepository.shareDocumentWithCompany(docKey, companyKey);
        } catch (e) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async withdrawDocumentFromCompany(docKey: string, companyKey: string): Promise<void> {
        try {
            await this.documentsRepository.withdrawDocumentFromCompany(docKey, companyKey);
        } catch (e) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async getSharedWithCompanies(docKey: string): Promise<Company[]> {
        try {
            return await this.documentsRepository.getSharedWithCompanies(docKey);
        } catch (e) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async openDocument(document: Document): Promise<Blob> {
        return this.documentsRepository.downloadDocument(document.key);
    }

    public async downloadDocument(document: Document): Promise<void> {
        const data = await this.documentsRepository.downloadDocument(document.key);
        FileUtils.downloadFile(data, `${document.name}.pdf`);
    }

    public async downloadDocuments(documents: Document[]): Promise<void> {
        const keys = documents.map((document) => document.key);
        const data = await this.documentsRepository.downloadDocumentsZipped(keys);
        FileUtils.downloadFile(data, 'Halvar-Download.zip');
    }

    public async getParkFilterOptions(): Promise<Park[]> {
        const parks = await this.parksRepository.getParks();
        const filters: { [key: string]: Park } = {};
        parks.forEach((park) => {
            filters[park.key] = park;
        });
        const generators = await this.generatorsRepository.getGenerators();
        generators
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach((generator) => {
                if (filters[generator.parkKey]) {
                    const parkGenerators = filters[generator.parkKey].generators || [];
                    parkGenerators.push(generator);
                    filters[generator.parkKey].generators = parkGenerators;
                } else {
                    console.warn(`Got a generator without matching park: ${generator.name}`);
                }
            });
        return Object.values(filters)
            .filter((park) => park.generators && park.generators.length > 0)
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    public getGeneratorKeysForPark(parks: Park[], parkKey: string): string[] {
        return parks.find((it) => it.key === parkKey)?.generators?.map((it) => it.key) || [];
    }

    public async getDocumentTypeSelectBoxOptions(): Promise<SelectBoxOption[]> {
        const types = await this.documentsRepository.getDocumentTypes();
        const selectBoxOptions = types
            .map((it) => ({
                value: it as string|undefined,
                displayName: this.i18nRepository.tc(`ctx-documents.category.${it}`, 2),
            }))
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
        selectBoxOptions.unshift({ value: undefined, displayName: this.i18nRepository.t('ctx-documents.category.undefined') });
        return selectBoxOptions;
    }

    public async retryDocument(docKey: string): Promise<void> {
        try {
            await this.documentsRepository.retryDocument(docKey);
        } catch (e) {
            NotificationService.serviceError(e);
            throw e;
        }
    }
}
