import { Prop, Vue, Watch } from 'vue-property-decorator';
import type { WidgetConfig, Portfolio } from '@/modules/ctx-dashboard';
import type { DashboardRepresentation } from '@/clients/dashboardapi/v2';
import Utils from '@/assets/js/utils/Utils';

export default abstract class WizardTab<P = any> extends Vue {

    @Prop({ required: false, default: 'edit' })
    public readonly mode!: 'create'|'edit';

    @Prop({ required: true })
    public readonly widgetConfig!: WidgetConfig;

    @Prop({ required: true })
    public readonly widgetPortfolio!: Portfolio;

    @Prop({ required: true })
    public readonly widgetDashboard!: DashboardRepresentation;

    @Prop({ required: false, default: null })
    public readonly action!: string|null;

    @Prop({ required: false, default: () => ({}) })
    public readonly params!: Partial<P> | ((widget: WidgetConfig) => Partial<P>);

    protected tabConfig: P;
    protected valid: boolean = false;
    protected errors: any = {};

    private getRuntimeConfig: ((widget: WidgetConfig) => Partial<P>);
    private readonly defaultTabConfig: P|undefined;

    constructor(defaultConfig?: P) {
        super();
        this.defaultTabConfig = defaultConfig;
        this.tabConfig = defaultConfig || {} as P;
        this.getRuntimeConfig = () => defaultConfig || {};
    }

    public created(): void {
        // this cannot be done in constructor, as params will not yet be set there
        this.onParamChanged();
    }

    /**
     * Additional lifecycle function called by the parent component when this tab gets visible
     */
    public onShown(): void {
        this.onParamChanged();
        this.onChange();
    }

    @Watch('widgetConfig', { deep: true })
    private onChange(): void {
        this.applyTabConfig();
        this.testValidity();
        this.$emit('update:widgetConfig', this.widgetConfig);
    }

    @Watch('widgetConfig.axis', { deep: true })
    protected onMetricsOrResourcesChange(): void {
        // for usage in derived class
    }

    @Watch('params', { deep: true })
    private onParamChanged(): void {
        if (typeof this.params === 'function') {
            this.getRuntimeConfig = this.params;
        } else {
            this.getRuntimeConfig = () => this.params as Partial<P>;
        }
        this.tabConfig = this.applyTabConfig();
    }

    protected async testValidity(): Promise<boolean> {
        this.errors = await this.validate();
        this.valid = Object.keys(this.errors).length === 0;
        this.$emit('update:valid', this.valid);
        return this.valid;
    }

    public validate(): Promise<any> {
        // validation is defined in derived class
        return Promise.resolve({});
    }

    protected applyTabConfig(): P {
        const runtimeTabConfig = this.getRuntimeConfig(Utils.deepCopy(this.widgetConfig));
        if (this.defaultTabConfig) {
            this.tabConfig = Object.assign(Utils.deepCopy(this.defaultTabConfig), runtimeTabConfig);
        } else {
            this.tabConfig = runtimeTabConfig as P;
        }
        return this.tabConfig;
    }
}
