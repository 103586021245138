import type { Widget, ReportWidgetConfig, ReportConfig } from '@/modules/ctx-report-preview/types';
import type { WidgetRepository } from '@/modules/ctx-report-preview/adapter';
import { ReportConfigRepository } from '@/modules/ctx-report-preview/adapter';

export class WidgetService {

    private readonly widgetRepository: WidgetRepository;
    private readonly reportConfigRepository: ReportConfigRepository;

    constructor(params: {
        reportConfigRepository: ReportConfigRepository;
        widgetRepository: WidgetRepository;
    }) {
        this.reportConfigRepository = params.reportConfigRepository;
        this.widgetRepository = params.widgetRepository;
    }

    public async getWidgetsForReport(reportConfig: ReportConfig): Promise<ReportWidgetConfig[]> {
        const widgets: Widget[] = await this.widgetRepository.getWidgetsForDashboard(reportConfig.dashboardKey);
        return widgets.flatMap((widget) => {
            const reportWidgets: ReportWidgetConfig[] = [];
            if (widget.supportsChartView) {
                const position = this.findWidgetPosition(widget.key, 'chart', reportConfig);
                reportWidgets.push({
                    widgetKey: widget.key,
                    widgetName: widget.name,
                    displayMode: 'chart',
                    widgetType: widget.type,
                    page: position?.page,
                    position: position?.position,
                });
            }
            if (widget.supportsTableView) {
                const position = this.findWidgetPosition(widget.key, 'table', reportConfig);
                reportWidgets.push({
                    widgetKey: widget.key,
                    widgetName: widget.name,
                    displayMode: 'table',
                    widgetType: widget.type,
                    page: position?.page,
                    position: position?.position,
                });
            }
            return reportWidgets;
        });
    }

    private findWidgetPosition(widgetKey: string, displayMode: 'chart'|'table', report: ReportConfig): { page: number; position: number }|undefined {
        for (let page = 0; page < report.layouts.length; page++) {
            for (let slot = 0; slot < report.layouts[page].widgets.length; slot++) {
                const widgetInSlot = report.layouts[page].widgets[slot];
                if (widgetInSlot && widgetInSlot.widgetKey === widgetKey && widgetInSlot.displayMode === displayMode) {
                    return { page: page, position: slot };
                }
            }
        }
        return undefined;
    }
}
