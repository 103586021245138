import type { AdminRepository } from './adapter';
import { AdminService } from './services/AdminService';

export interface PortParams {
    adminRepository: AdminRepository;
}

export class Port {
    // services will be instantiated in plugin
    private static port: Port|null = null;

    private readonly admin: AdminService;

    constructor(params: PortParams) {
        this.admin = new AdminService({
            adminRepository: params.adminRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get admin(): AdminService {
        return Port.instance.admin;
    }
}
