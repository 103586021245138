/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParkRepresentation
 */
export interface ParkRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ParkRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ParkRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ParkRepresentation
     */
    zipCode?: string;
}

export function ParkRepresentationFromJSON(json: any): ParkRepresentation {
    return ParkRepresentationFromJSONTyped(json, false);
}

export function ParkRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParkRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
    };
}

export function ParkRepresentationToJSON(value?: ParkRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'zipCode': value.zipCode,
    };
}


