/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Resolution,
    ResolutionFromJSON,
    ResolutionFromJSONTyped,
    ResolutionToJSON,
    WidgetAxisRepresentation,
    WidgetAxisRepresentationFromJSON,
    WidgetAxisRepresentationFromJSONTyped,
    WidgetAxisRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetUpdateRequest
 */
export interface WidgetUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof WidgetUpdateRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetUpdateRequest
     */
    generatedTitle?: string;
    /**
     * 
     * @type {Resolution}
     * @memberof WidgetUpdateRequest
     */
    resolution?: Resolution;
    /**
     * 
     * @type {string}
     * @memberof WidgetUpdateRequest
     */
    config?: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetUpdateRequest
     */
    position?: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetUpdateRequest
     */
    intervalName?: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetUpdateRequest
     */
    intervalFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetUpdateRequest
     */
    intervalTo?: number;
    /**
     * 
     * @type {Array<WidgetAxisRepresentation>}
     * @memberof WidgetUpdateRequest
     */
    axis?: Array<WidgetAxisRepresentation>;
}

export function WidgetUpdateRequestFromJSON(json: any): WidgetUpdateRequest {
    return WidgetUpdateRequestFromJSONTyped(json, false);
}

export function WidgetUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'generatedTitle': !exists(json, 'generatedTitle') ? undefined : json['generatedTitle'],
        'resolution': !exists(json, 'resolution') ? undefined : ResolutionFromJSON(json['resolution']),
        'config': !exists(json, 'config') ? undefined : json['config'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'intervalName': !exists(json, 'intervalName') ? undefined : json['intervalName'],
        'intervalFrom': !exists(json, 'intervalFrom') ? undefined : json['intervalFrom'],
        'intervalTo': !exists(json, 'intervalTo') ? undefined : json['intervalTo'],
        'axis': !exists(json, 'axis') ? undefined : ((json['axis'] as Array<any>).map(WidgetAxisRepresentationFromJSON)),
    };
}

export function WidgetUpdateRequestToJSON(value?: WidgetUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'generatedTitle': value.generatedTitle,
        'resolution': ResolutionToJSON(value.resolution),
        'config': value.config,
        'position': value.position,
        'intervalName': value.intervalName,
        'intervalFrom': value.intervalFrom,
        'intervalTo': value.intervalTo,
        'axis': value.axis === undefined ? undefined : ((value.axis as Array<any>).map(WidgetAxisRepresentationToJSON)),
    };
}


