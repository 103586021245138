/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LossOfProductionMetricVariantRepresentation
 */
export interface LossOfProductionMetricVariantRepresentation {
    /**
     * Unique uuid key of this variant
     * @type {string}
     * @memberof LossOfProductionMetricVariantRepresentation
     */
    key: string;
    /**
     * Unique uuid key mapping to an i18n entry
     * @type {string}
     * @memberof LossOfProductionMetricVariantRepresentation
     */
    name: string;
    /**
     * true if this variant is the global default variant for all generators
     * @type {boolean}
     * @memberof LossOfProductionMetricVariantRepresentation
     */
    globalDefault: boolean;
    /**
     * List of generator keys for this variant
     * @type {Array<string>}
     * @memberof LossOfProductionMetricVariantRepresentation
     */
    generatorKeys: Array<string>;
    /**
     * List of generator status keys of this variant
     * @type {Array<string>}
     * @memberof LossOfProductionMetricVariantRepresentation
     */
    generatorStatusKeys: Array<string>;
}

export function LossOfProductionMetricVariantRepresentationFromJSON(json: any): LossOfProductionMetricVariantRepresentation {
    return LossOfProductionMetricVariantRepresentationFromJSONTyped(json, false);
}

export function LossOfProductionMetricVariantRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LossOfProductionMetricVariantRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'globalDefault': json['globalDefault'],
        'generatorKeys': json['generatorKeys'],
        'generatorStatusKeys': json['generatorStatusKeys'],
    };
}

export function LossOfProductionMetricVariantRepresentationToJSON(value?: LossOfProductionMetricVariantRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'globalDefault': value.globalDefault,
        'generatorKeys': value.generatorKeys,
        'generatorStatusKeys': value.generatorStatusKeys,
    };
}


