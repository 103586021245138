import type { UserRepository, PortfolioRepository, TemplatesRepository } from './adapter';
import { PortfolioService } from './services/PortfolioService';
import { TemplatesService } from './services/TemplatesService';

export interface PortParams {
    portfolioRepository: PortfolioRepository;
    templatesRepository: TemplatesRepository;
    userRepository: UserRepository;
}

export class Port {
    private static port: Port|null = null;

    private readonly portfolioService: PortfolioService;
    private readonly templatesService: TemplatesService;

    constructor(params: PortParams) {
        this.portfolioService = new PortfolioService({
            portfolioRepository: params.portfolioRepository,
        });
        this.templatesService = new TemplatesService({
            templatesRepository: params.templatesRepository,
            userRepository: params.userRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get portfolios(): PortfolioService {
        return Port.instance.portfolioService;
    }

    public static get templates(): TemplatesService {
        return Port.instance.templatesService;
    }
}
