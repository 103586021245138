/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateMetricRequest
 */
export interface UpdateMetricRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateMetricRequest
     */
    name?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateMetricRequest
     */
    sourceUnit?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateMetricRequest
     */
    lc?: Array<string>;
    /**
     * all features that should be assigned to the metric. an empty array removes all features, null will change nothing
     * @type {Array<string>}
     * @memberof UpdateMetricRequest
     */
    licenseFeatures?: Array<string>;
    /**
     * Minimum value filter
     * @type {number}
     * @memberof UpdateMetricRequest
     */
    min?: number;
    /**
     * Maximum value filter
     * @type {number}
     * @memberof UpdateMetricRequest
     */
    max?: number;
    /**
     * Optional link to a parent metric, if empty string, metric link gets cleared
     * @type {string}
     * @memberof UpdateMetricRequest
     */
    parentKey?: string;
    /**
     * Optional flag to set metric (in)active
     * @type {boolean}
     * @memberof UpdateMetricRequest
     */
    inactive?: boolean;
}

export function UpdateMetricRequestFromJSON(json: any): UpdateMetricRequest {
    return UpdateMetricRequestFromJSONTyped(json, false);
}

export function UpdateMetricRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMetricRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sourceUnit': !exists(json, 'sourceUnit') ? undefined : json['sourceUnit'],
        'lc': !exists(json, 'lc') ? undefined : json['lc'],
        'licenseFeatures': !exists(json, 'licenseFeatures') ? undefined : json['licenseFeatures'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'parentKey': !exists(json, 'parentKey') ? undefined : json['parentKey'],
        'inactive': !exists(json, 'inactive') ? undefined : json['inactive'],
    };
}

export function UpdateMetricRequestToJSON(value?: UpdateMetricRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'sourceUnit': value.sourceUnit,
        'lc': value.lc,
        'licenseFeatures': value.licenseFeatures,
        'min': value.min,
        'max': value.max,
        'parentKey': value.parentKey,
        'inactive': value.inactive,
    };
}


