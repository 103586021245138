/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportCreationInterval {
    Weekly = 'weekly',
    Monthly = 'monthly',
    Annual = 'annual'
}

export function ReportCreationIntervalFromJSON(json: any): ReportCreationInterval {
    return ReportCreationIntervalFromJSONTyped(json, false);
}

export function ReportCreationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCreationInterval {
    return json as ReportCreationInterval;
}

export function ReportCreationIntervalToJSON(value?: ReportCreationInterval | null): any {
    return value as any;
}

