
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import Checkbox from '@/modules/shared/components/input/Checkbox.vue';
import Dropdown from '@/modules/shared/components/util/Dropdown.vue';

interface MultiSelectBoxOption<T> extends SelectBoxOption<T> {
    selected?: boolean;
}

@Component({
    components: { Dropdown, Checkbox },
})
export default class MultiSelectBox<T> extends Vue {

    @Prop({ required: true, default: null })
    public readonly value!: T[];

    @Prop({ required: false, default: null })
    public readonly displayValue!: string|null;

    @Prop({ required: false, default: [] })
    public readonly options!: SelectBoxOption<T>[];

    @Prop({ required: false, default: '' })
    public readonly placeholder!: string;

    @Prop({ required: false, default: false })
    public readonly disabled!: boolean|string;

    @Ref('wrapper')
    private readonly wrapper!: HTMLElement;

    private showDropdown: boolean = false;
    private highlightIndex: number = -1;

    private get isDisabled(): boolean {
        return this.disabled === true || this.disabled === 'true' || this.options.length === 0;
    }

    private get selectedOptions(): SelectBoxOption[] {
        return this.options.filter((it) => this.value.includes(it.value));
    }

    private get multiSelectOptions(): MultiSelectBoxOption<T>[] {
        return this.options.map((it) => ({
            ...it,
            selected: this.value.includes(it.value),
        }));
    }

    private get allSelected(): boolean {
        return this.options.length === this.selectedOptions.length;
    }

    private toggleDropdown(show?: boolean) {
        if (this.isDisabled) {
            this.showDropdown = false;
        } else if (show !== undefined) {
            this.showDropdown = show;
        } else {
            this.showDropdown = !this.showDropdown;
        }
    }

    private toggleOption(option: SelectBoxOption) {
        const selected: SelectBoxOption<T>[] = this.selectedOptions
            .filter((it) => it.value !== option.value);
        // option to toggle was not present in the selected array before, so we have to add it
        if (selected.length === this.selectedOptions.length) {
            selected.push(option);
        }
        this.$emit('input', selected.map((it) => it.value));
    }

    private selectAll() {
        this.$emit('input', this.options.map((it) => it.value));
    }

    private selectNone() {
        this.$emit('input', []);
    }

    private get renderValue(): string {
        if (this.displayValue) {
            return this.displayValue;
        }
        return this.selectedOptions
            .map((it) => this.$t(it.displayName).toString())
            .join(', ');
    }
}
