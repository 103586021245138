import type { RouteConfig } from 'vue-router';
import { Role, LicenseFeature } from '@/modules/shared/types';

const route: RouteConfig = {
    path: '/:customer/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "ctx-users" */ './UsersView.vue'),
    meta: {
        role: Role.COMPANY_ADMIN,
        license: [LicenseFeature.ADVANCED],
        authenticated: true,
        navbar: true,
        footer: true,
        settingsTabs: true,
        // settingsTabsGroup: 'company-admin',
        settingsTabsOrder: 30,
        portfolioTabs: false,
        onboarding: true,
        notifications: true,
    },
};

export { route };
