
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { WidgetWizardTab } from '@/components/widget-wizard';
import ChartPreview from '@/components/illustrations/chart-previews/ChartPreview.vue';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import type { WidgetConfig } from '@/modules/ctx-dashboard';

export enum WidgetErrorType {
    Custom = 'custom',
    NoData = 'no-data',
    NoDataSource = 'no-data-source',
    IncompleteDataSources = 'incomplete-data-sources',
    NoPermissionForMetric = 'no-permission-for-metric',
    MissingLicenseFeature = 'missing-license-feature',
    MissingMetrics = 'missing-metrics',
    InvalidConfig = 'invalid-config',
    Service = 'service-error',
}

export interface WidgetError {
    type: WidgetErrorType;
    title?: string;
    message?: string;
    details?: string;
    image?: boolean;
    action?: string;
    actionCallback?: () => Promise<void>;
    actionText?: string;
    err?: Error|Response;
}

@Component({
    components: { LoadingAnimationSmall, ChartPreview },
})
export default class WidgetErrorMessage extends Vue {

    @Prop({ required: true })
    public readonly error!: WidgetError;

    @Prop({ default: null })
    public readonly widget!: WidgetConfig|null;

    @Prop({ default: false })
    public readonly report!: boolean;

    private actionLoading: boolean = false;

    private get metrics(): string[] {
        return this.widget?.axis
            .flatMap((axis) => axis.metrics)
            .map((metric) => metric.metricKey)
            .filter(ArrayUtils.removeDuplicates) || [];
    }

    private get widgetError(): WidgetError|null {
        if (!this.error) {
            return null;
        }
        const error: WidgetError = {
            type: this.error.type,
        };
        if (this.error.type === WidgetErrorType.NoData) {
            error.title = this.error.title || 'error-data.no-data.title';
            error.message = this.error.message || 'error-data.no-data.message';
            error.action = this.error.action || WidgetWizardTab.Time;
            error.actionText = this.error.actionText || 'error-data.no-data.action';
            error.image = this.error.image !== undefined ? this.error.image : true;
        } else if (this.error.type === WidgetErrorType.NoDataSource) {
            error.title = this.error.title || 'error-data.no-generators.title';
            error.message = this.error.message || 'error-data.no-generators.message';
            error.action = this.error.action || WidgetWizardTab.Data;
            error.actionText = this.error.actionText || 'error-data.no-generators.action';
            error.image = this.error.image !== undefined ? this.error.image : true;
        } else if (this.error.type === WidgetErrorType.IncompleteDataSources) {
            error.title = this.error.title || 'error-data.incomplete-data-sources.title';
            error.message = this.error.message || 'error-data.incomplete-data-sources.message';
            error.action = this.error.action || WidgetWizardTab.Data;
            error.actionText = this.error.actionText || 'error-data.incomplete-data-sources.action';
            error.image = this.error.image !== undefined ? this.error.image : true;
        } else if (this.error.type === WidgetErrorType.NoPermissionForMetric) {
            error.title = this.error.title || 'error-data.no-permission-for-metric.title';
            error.message = this.error.message || 'error-data.no-permission-for-metric.message';
            // error.action = WidgetWizardTab.General;
            // error.actionText = 'error-data.no-permission-for-metric.action';
            error.image = this.error.image !== undefined ? this.error.image : true;
        } else if (this.error.type === WidgetErrorType.InvalidConfig) {
            error.title = this.error.title || 'error-data.invalid-config.title';
            error.message = this.error.message || 'error-data.invalid-config.message';
            error.action = this.error.action || WidgetWizardTab.General;
            error.actionText = this.error.actionText || 'error-data.invalid-config.action';
            error.image = this.error.image !== undefined ? this.error.image : true;
        } else if (this.error.type === WidgetErrorType.MissingLicenseFeature) {
            error.title = this.error.title || 'error-data.missing-license-feature.title';
            error.message = this.error.message || 'error-data.missing-license-feature.message';
            error.actionText = this.error.actionText || 'error-data.missing-license-feature.action';
            error.action = this.error.action || 'license';
            error.image = this.error.image !== undefined ? this.error.image : true;
        } else if (this.error.type === WidgetErrorType.MissingMetrics) {
            error.title = this.error.title || 'error-data.missing-metrics.title';
            error.message = this.error.message || 'error-data.missing-metrics.message';
            error.image = this.error.image !== undefined ? this.error.image : true;
        } else if (this.error.type === WidgetErrorType.Service) {
            error.details = '';
            if (this.error.details) {
                console.error(this.error.details);
            }
        }
        return Object.assign(this.error, error);
    }

    private async onActionClicked(): Promise<void> {
        if (this.widgetError?.actionCallback) {
            this.actionLoading = true;
            await this.widgetError.actionCallback();
            this.actionLoading = false;
        } else if (this.widgetError?.action) {
            this.$emit('action', this.widgetError.action);
        }
    }
}
