import type { GeneratorRepository } from '@/modules/ctx-dashboard/adapter';
import NotificationService from '@/assets/js/services/NotificationService';
import { Generator, GeneratorGroup } from '@/modules/ctx-dashboard';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';

export class GeneratorService {

    private readonly generatorRepository: GeneratorRepository;

    constructor(params: {
        generatorRepository: GeneratorRepository;
    }) {
        this.generatorRepository = params.generatorRepository;
    }

    public onGeneratorsChange(cb: () => Promise<void>): void {
        this.generatorRepository.onGeneratorsChange(cb);
    }

    public async getGenerators(portfolio: string|null, filter: string) {
        try {
            const generators = await this.generatorRepository.getGenerators(portfolio);
            const filterLc = filter.trim().toLowerCase();
            return generators
                .filter((gen) => !filterLc
                    || gen.name.toLowerCase().includes(filterLc)
                    || gen.parkName.toLowerCase().includes(filterLc))
                .sort(GeneratorService.compareGenerators);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async getGeneratorsGrouped(portfolio: string|null, selectedGeneratorKey: string|null, filter: string): Promise<GeneratorGroup[]> {
        const generators = await this.getGenerators(portfolio, filter);
        // sort the active generator to the top, so the active park will also be on the top
        const selectedGeneratorIndex = generators.findIndex((gen) => gen.key === selectedGeneratorKey);
        if (selectedGeneratorIndex !== -1) {
            const selectedGenerator = generators.splice(selectedGeneratorIndex, 1);
            selectedGenerator.forEach((gen) => generators.unshift(gen));
        }
        const groups: { [key: string]: Generator[] } = ArrayUtils.groupBy(generators, (item) => item.parkName);
        return Object.keys(groups).map((parkName) => ({
            groupName: parkName,
            generators: groups[parkName].sort(GeneratorService.compareGenerators),
        }));
    }

    public async getGeneratorByKey(generatorKey: string): Promise<Generator|undefined> {
        return this.generatorRepository.getGeneratorByKey(generatorKey);
    }

    private static compareGenerators(a: Generator, b: Generator): number {
        return a.parkName.localeCompare(b.parkName) || a.name.localeCompare(b.name);
    }
}
