import DateUtils from '@/assets/js/utils/DateUtils';
import Utils from '@/assets/js/utils/Utils';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { Resolution } from '@/modules/shared/types';

export interface Interval {
    name: string;
    from: Date;
    to: Date;
    resolution?: Resolution;
}

enum IntervalType {
    FIXED = 'fixed',
    THIS_YEAR_TO_DATE = 'this-year',
    THIS_YEAR_COMPLETE = 'this-year-complete',
    LAST_YEAR = 'last-year',
    THIS_MONTH_TO_DATE = 'this-month',
    THIS_MONTH_COMPLETE = 'this-month-complete',
    LAST_MONTH = 'last-month',
    MONTH_BEFORE_LAST = 'penultimate-month',
    T_ONE_DAY = '1T',
    T_ONE_WEEK = '1W',
    T_ONE_MONTH = '1M',
    T_THREE_MONTHS = '3M',
    T_SIX_MONTHS = '6M',
    T_ONE_YEAR = '1J',
    T_TWO_YEARS = '2J',
    T_FIVE_YEARS = '5J',
    T_TEN_YEARS = '10J',
}

export default class TimeIntervals {

    public static getDynamicIntervals(resolution?: Resolution): string[] {
        if (resolution === Resolution.Monthly) {
            return [
                IntervalType.FIXED,
                IntervalType.THIS_YEAR_TO_DATE,
                IntervalType.THIS_YEAR_COMPLETE,
                IntervalType.LAST_YEAR,
                IntervalType.T_ONE_YEAR,
                IntervalType.T_TWO_YEARS,
            ];
        }
        if (resolution === Resolution.Daily) {
            return [
                IntervalType.FIXED,
                IntervalType.THIS_MONTH_TO_DATE,
                IntervalType.THIS_MONTH_COMPLETE,
                IntervalType.LAST_MONTH,
                IntervalType.MONTH_BEFORE_LAST,
                IntervalType.THIS_YEAR_TO_DATE,
                IntervalType.THIS_YEAR_COMPLETE,
                IntervalType.LAST_YEAR,
                IntervalType.T_ONE_WEEK,
                IntervalType.T_ONE_MONTH,
                IntervalType.T_THREE_MONTHS,
                IntervalType.T_SIX_MONTHS,
                IntervalType.T_ONE_YEAR,
                IntervalType.T_TWO_YEARS,
            ];
        }
        return [
            IntervalType.FIXED,
            IntervalType.THIS_MONTH_TO_DATE,
            IntervalType.THIS_MONTH_COMPLETE,
            IntervalType.LAST_MONTH,
            IntervalType.MONTH_BEFORE_LAST,
            IntervalType.THIS_YEAR_TO_DATE,
            IntervalType.THIS_YEAR_COMPLETE,
            IntervalType.LAST_YEAR,
            IntervalType.T_ONE_DAY,
            IntervalType.T_ONE_WEEK,
            IntervalType.T_ONE_MONTH,
            IntervalType.T_THREE_MONTHS,
            IntervalType.T_SIX_MONTHS,
            IntervalType.T_ONE_YEAR,
            IntervalType.T_TWO_YEARS,
        ];
    }

    public static getQickswitchIntervals(resolution?: Resolution): string[] {
        if (resolution === Resolution.Monthly) {
            const year = new Date().getFullYear();
            return [
                year.toString(),
                (year - 1).toString(),
                (year - 2).toString(),
                (year - 3).toString(),
                (year - 4).toString(),
                (year - 5).toString(),
            ];
        }
        return [
            IntervalType.T_ONE_DAY,
            IntervalType.T_ONE_WEEK,
            IntervalType.T_ONE_MONTH,
            IntervalType.T_THREE_MONTHS,
            IntervalType.T_SIX_MONTHS,
            IntervalType.T_ONE_YEAR,
            IntervalType.T_TWO_YEARS,
        ];
    }

    public static hasExclusiveEndDate(interval: string): boolean {
        const inclusiveIntervals: string[] = [
            IntervalType.THIS_MONTH_TO_DATE,
            IntervalType.THIS_YEAR_TO_DATE,
            IntervalType.T_ONE_DAY,
            IntervalType.T_ONE_WEEK,
            IntervalType.T_ONE_MONTH,
            IntervalType.T_THREE_MONTHS,
            IntervalType.T_SIX_MONTHS,
            IntervalType.T_ONE_YEAR,
            IntervalType.T_TWO_YEARS,
            IntervalType.T_FIVE_YEARS,
            IntervalType.T_TEN_YEARS,
        ];
        if (this.getReferenceDate().isOverwritten) {
            return true;
        }
        return !inclusiveIntervals.includes(interval);
    }

    public static resolveIntervalForWidget(widget: WidgetConfig): Interval {
        // resolved fixed interval
        if (widget.intervalName === IntervalType.FIXED && widget.intervalFrom && widget.intervalTo) {
            const referenceDate = TimeIntervals.getReferenceDate();
            const interval: Interval = {
                name: IntervalType.FIXED,
                resolution: widget?.resolution || Resolution.Automatic,
                from: new Date(widget.intervalFrom),
                to: new Date(widget.intervalTo),
            };
            if (Number.isNaN(interval.from.getTime())) {
                console.error('Got an invalid date in widget config!');
                // date is invalid
                interval.from = referenceDate.date;
            }
            if (Number.isNaN(interval.to.getTime())) {
                console.error('Got an invalid date in widget config!');
                // date is invalid
                interval.to = referenceDate.date;
            }
            // compatibility fix for old inclusive widget timestamps
            if (interval.to.getMinutes() === 59 && interval.to.getSeconds() === 59) {
                console.warn('Converting old inclusive end date to exclusive');
                interval.to.setMinutes(0);
                interval.to.setSeconds(0);
                // we cannot simply set the hours to 0, as we don't know for sure the
                // time was set in the current timezone. At least this code can run clientside in GMT+1 and
                // server side (report generation) in UTC
                interval.to = DateUtils.add(interval.to, { hours: 1 });
            }

            return interval;
        }

        // resolved named interval
        return TimeIntervals.resolveInterval(widget.intervalName, widget.resolution);
    }

    public static resolveInterval(intervalName: string, resolution?: Resolution): Interval {
        const referenceDate = TimeIntervals.getReferenceDate();
        const interval: Interval = {
            name: intervalName,
            from: referenceDate.date,
            to: referenceDate.date,
            resolution: resolution || Resolution.Automatic,
        };

        // interval name is a full year
        if (intervalName.length === 4) {
            const year = parseInt(intervalName, 10);
            if (!Number.isNaN(year)) {
                interval.name = IntervalType.FIXED;
                interval.from = new Date(year, 0, 1);
                interval.to = new Date(year + 1, 0, 1);
                return interval;
            }
        }

        // end date used for intervals 1T, 1W, 1M, ...
        // const pointReferenceDate = referenceDate.isOverwritten
        //     ? DateUtils.add(referenceDate.date, { months: 1 })
        //     : referenceDate.date;

        switch (intervalName) {
            case IntervalType.THIS_MONTH_TO_DATE:
                // this month until reference date
                interval.from = new Date(referenceDate.date.getFullYear(), referenceDate.date.getMonth(), 1);
                interval.to = referenceDate.isOverwritten
                    ? new Date(referenceDate.date.getFullYear(), referenceDate.date.getMonth() + 1, 1)
                    : referenceDate.date;
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            case IntervalType.THIS_YEAR_TO_DATE:
                // this year until reference date
                interval.from = new Date(referenceDate.date.getFullYear(), 0, 1);
                interval.to = referenceDate.isOverwritten
                    ? new Date(referenceDate.date.getFullYear(), referenceDate.date.getMonth() + 1, 1)
                    : referenceDate.date;
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            case IntervalType.THIS_MONTH_COMPLETE:
                // this complete month
                interval.from = new Date(referenceDate.date.getFullYear(), referenceDate.date.getMonth(), 1);
                interval.to = new Date(referenceDate.date.getFullYear(), referenceDate.date.getMonth() + 1, 1);
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            case IntervalType.LAST_MONTH:
                // last month
                interval.from = new Date(referenceDate.date.getFullYear(), referenceDate.date.getMonth() - 1, 1);
                interval.to = new Date(referenceDate.date.getFullYear(), referenceDate.date.getMonth(), 1);
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            case IntervalType.MONTH_BEFORE_LAST:
                // full month before last
                interval.from = new Date(referenceDate.date.getFullYear(), referenceDate.date.getMonth() - 2, 1);
                interval.to = new Date(referenceDate.date.getFullYear(), referenceDate.date.getMonth() - 1, 1);
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            case IntervalType.THIS_YEAR_COMPLETE:
                // this complete year
                interval.from = new Date(referenceDate.date.getFullYear(), 0, 1);
                interval.to = new Date(referenceDate.date.getFullYear() + 1, 0, 1);
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            case IntervalType.LAST_YEAR:
                // last complete year
                interval.from = new Date(referenceDate.date.getFullYear() - 1, 0, 1);
                interval.to = new Date(referenceDate.date.getFullYear(), 0, 1);
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            // ------------------------
            // intervals relative to reference date
            // ------------------------
            case IntervalType.T_ONE_DAY:
                // reference date - 1 day
                interval.to = referenceDate.date;
                if (referenceDate.isOverwritten) {
                    interval.to = DateUtils.add(interval.to, { months: 1 });
                }
                interval.from = DateUtils.subtract(interval.to, { hours: 24 });
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Min10;
                }
                return interval;
            case IntervalType.T_ONE_WEEK:
                // reference date - 1 week
                interval.to = referenceDate.date;
                if (referenceDate.isOverwritten) {
                    interval.to = DateUtils.add(interval.to, { months: 1 });
                }
                interval.from = DateUtils.subtract(interval.to, { days: 7 });
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            case IntervalType.T_ONE_MONTH:
                // reference date - 1 month
                interval.to = referenceDate.date;
                if (referenceDate.isOverwritten) {
                    interval.to = DateUtils.add(interval.to, { months: 1 });
                }
                interval.from = DateUtils.subtract(interval.to, { months: 1 });
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            case IntervalType.T_THREE_MONTHS:
                // reference date - 3 months
                interval.to = referenceDate.date;
                if (referenceDate.isOverwritten) {
                    interval.to = DateUtils.add(interval.to, { months: 1 });
                }
                interval.from = DateUtils.subtract(interval.to, { months: 3 });
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Daily;
                }
                return interval;
            case IntervalType.T_SIX_MONTHS:
                // reference date - 6 months
                interval.to = referenceDate.date;
                if (referenceDate.isOverwritten) {
                    interval.to = DateUtils.add(interval.to, { months: 1 });
                }
                interval.from = DateUtils.subtract(interval.to, { months: 6 });
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Monthly;
                }
                return interval;
            case IntervalType.T_ONE_YEAR:
                // reference date - 1 year
                interval.to = referenceDate.date;
                if (referenceDate.isOverwritten) {
                    interval.to = DateUtils.add(interval.to, { months: 1 });
                }
                interval.from = DateUtils.subtract(interval.to, { years: 1 });
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Monthly;
                }
                return interval;
            case IntervalType.T_TWO_YEARS:
                // reference date - 2 year
                interval.to = referenceDate.date;
                if (referenceDate.isOverwritten) {
                    interval.to = DateUtils.add(interval.to, { months: 1 });
                }
                interval.from = DateUtils.subtract(interval.to, { years: 2 });
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Monthly;
                }
                return interval;
            case IntervalType.T_FIVE_YEARS:
                // reference date - 5 year
                interval.to = referenceDate.date;
                if (referenceDate.isOverwritten) {
                    interval.to = DateUtils.add(interval.to, { months: 1 });
                }
                interval.from = DateUtils.subtract(interval.to, { years: 5 });
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Monthly;
                }
                return interval;
            case IntervalType.T_TEN_YEARS:
                // reference date - 10 year
                interval.to = referenceDate.date;
                if (referenceDate.isOverwritten) {
                    interval.to = DateUtils.add(interval.to, { months: 1 });
                }
                interval.from = DateUtils.subtract(interval.to, { years: 10 });
                if (interval.resolution === Resolution.Automatic) {
                    interval.resolution = Resolution.Monthly;
                }
                return interval;
            case IntervalType.FIXED:
            default:
                return interval;
        }
    }

    public static cropToReferenceDate(interval: Interval): Interval {
        if (interval.name === IntervalType.FIXED) {
            // dont crop fixed intervals
            return interval;
        }
        const referenceDate = TimeIntervals.getReferenceDate();
        const clone = Utils.deepCopy(interval);
        // from should never be after reference date, unless interval is fixed
        if (clone.from.getTime() > referenceDate.date.getTime()) {
            console.error('Invalid state: from date is after reference date!');
        }
        if (referenceDate.isOverwritten) {
            // consider the month as fully included in all intervals
            referenceDate.date = DateUtils.add(referenceDate.date, { months: 1 });
        }

        if (clone.to.getFullYear() > referenceDate.date.getFullYear()) {
            clone.to.setMonth(referenceDate.date.getMonth());
            clone.to.setFullYear(referenceDate.date.getFullYear());
            clone.to.setDate(referenceDate.date.getDate());
        } else if (clone.to.getFullYear() === referenceDate.date.getFullYear()
            && clone.to.getMonth() > referenceDate.date.getMonth()) {
            clone.to.setMonth(referenceDate.date.getMonth());
            clone.to.setDate(referenceDate.date.getDate());
        }
        return clone;
    }

    public static getReferenceDate(): { date: Date, isOverwritten: boolean } {
        const referenceDate = Utils.getQueryParameter('now');
        if (referenceDate) {
            const parts = referenceDate.split('-');
            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1] || '01', 10);
            const day = parseInt(parts[2] || '01', 10);
            const date = new Date(year, month - 1, day, 0, 0);
            const currentMonth = DateUtils.cropToPrecision(new Date(), 'months');
            if (date.getTime() < currentMonth.getTime()) {
                return {
                    date: date,
                    isOverwritten: true,
                };
            }
        }
        return {
            // crop current date to minutes
            date: DateUtils.cropToPrecision(new Date(), 'minutes'),
            isOverwritten: false,
        };
    }
}
