
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Checkbox extends Vue {

    @Prop()
    public readonly value?: boolean|undefined;

    @Prop({ default: false })
    public readonly radio!: boolean;
}
