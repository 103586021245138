import type { Customer } from '@/modules/ctx-users/types';
import type { CustomerRepository } from '@/modules/ctx-users/adapter';
import NotificationService from '@/assets/js/services/NotificationService';

export class CustomerService {

    private readonly customerRepository: CustomerRepository;

    constructor(params: {
        customerRepository: CustomerRepository;
    }) {
        this.customerRepository = params.customerRepository;
    }

    public async getCustomer(key: string): Promise<Customer|undefined> {
        try {
            return this.customerRepository.getCustomer(key);
        } catch (e: any) {
            NotificationService.serviceError(e);
            return undefined;
        }
    }
}
