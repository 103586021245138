/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeSeries,
    TimeSeriesFromJSON,
    TimeSeriesFromJSONTyped,
    TimeSeriesToJSON,
} from './';

/**
 * 
 * @export
 * @interface OperatorPrognosisMonetaryRepresentation
 */
export interface OperatorPrognosisMonetaryRepresentation {
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    budgetMonetary: TimeSeries;
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    forecastMonetary: TimeSeries;
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    actualGross: TimeSeries;
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    actualNet: TimeSeries;
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    einsman: TimeSeries;
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    actualPlusFC: TimeSeries;
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    actualVsBudget: TimeSeries;
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    actualVsFC: TimeSeries;
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    weightedPlanCommission: TimeSeries;
    /**
     * 
     * @type {TimeSeries}
     * @memberof OperatorPrognosisMonetaryRepresentation
     */
    weightedActualCommission: TimeSeries;
}

export function OperatorPrognosisMonetaryRepresentationFromJSON(json: any): OperatorPrognosisMonetaryRepresentation {
    return OperatorPrognosisMonetaryRepresentationFromJSONTyped(json, false);
}

export function OperatorPrognosisMonetaryRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorPrognosisMonetaryRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'budgetMonetary': TimeSeriesFromJSON(json['budgetMonetary']),
        'forecastMonetary': TimeSeriesFromJSON(json['forecastMonetary']),
        'actualGross': TimeSeriesFromJSON(json['actualGross']),
        'actualNet': TimeSeriesFromJSON(json['actualNet']),
        'einsman': TimeSeriesFromJSON(json['einsman']),
        'actualPlusFC': TimeSeriesFromJSON(json['actualPlusFC']),
        'actualVsBudget': TimeSeriesFromJSON(json['actualVsBudget']),
        'actualVsFC': TimeSeriesFromJSON(json['actualVsFC']),
        'weightedPlanCommission': TimeSeriesFromJSON(json['weightedPlanCommission']),
        'weightedActualCommission': TimeSeriesFromJSON(json['weightedActualCommission']),
    };
}

export function OperatorPrognosisMonetaryRepresentationToJSON(value?: OperatorPrognosisMonetaryRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'budgetMonetary': TimeSeriesToJSON(value.budgetMonetary),
        'forecastMonetary': TimeSeriesToJSON(value.forecastMonetary),
        'actualGross': TimeSeriesToJSON(value.actualGross),
        'actualNet': TimeSeriesToJSON(value.actualNet),
        'einsman': TimeSeriesToJSON(value.einsman),
        'actualPlusFC': TimeSeriesToJSON(value.actualPlusFC),
        'actualVsBudget': TimeSeriesToJSON(value.actualVsBudget),
        'actualVsFC': TimeSeriesToJSON(value.actualVsFC),
        'weightedPlanCommission': TimeSeriesToJSON(value.weightedPlanCommission),
        'weightedActualCommission': TimeSeriesToJSON(value.weightedActualCommission),
    };
}


