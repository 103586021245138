import type { GeneratorsRepository } from '@/modules/ctx-admin-data-imports/adapter';
import type { Generator } from '@/modules/ctx-admin-data-imports/types';
import { Park } from '@/modules/ctx-admin-data-imports/types/Park';
import { GeneratorRepresentation } from '@/clients/dashboardapi/v2';

export class GeneratorService {
    private readonly generatorsRepository: GeneratorsRepository;

    constructor(params: {
        generatorsRepository: GeneratorsRepository;
    }) {
        this.generatorsRepository = params.generatorsRepository;
    }

    public async getParks(): Promise<Park[]> {
        const generators = await this.generatorsRepository.getGenerators();
        const parks: Map<string, Park> = new Map<string, Park>();
        generators.forEach((generator) => {
            const park = parks.get(generator.parkKey) || {
                key: generator.parkKey,
                name: generator.parkName,
                activeGenerators: 0,
                generators: [],
            };
            park.generators.push(generator);
            if (generator.active) {
                park.activeGenerators++;
            }
            parks.set(generator.parkKey, park);
        });
        return [...parks.values()]
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    public async getInactiveGenerators(): Promise<Generator[]> {
        const generators = await this.generatorsRepository.getGenerators();
        return generators.filter((generator) => !generator.active);
    }

    public async resolveGenerators(keys: string[]): Promise<Generator[]> {
        return this.generatorsRepository.getGeneratorsByKeys(keys);
    }

    public async setGeneratorActiveStatus(generatorKey: string, active: boolean): Promise<GeneratorRepresentation | undefined> {
        const generator = await this.generatorsRepository.getGeneratorByKey(generatorKey);
        generator.active = active;
        return await this.generatorsRepository.setGeneratorActiveStatus(generator);
    }
}
