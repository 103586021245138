import type { GeneratorRepository } from '@/modules/ctx-report-preview/adapter';
import { Generator, GeneratorGroup, Park } from '@/modules/ctx-report-preview';
import { WidgetUtils } from '@/components/widgets';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { WidgetConfig } from '@/modules/ctx-dashboard';

export class GeneratorsService {

    private readonly generatorRepository: GeneratorRepository;

    constructor(params: {
        generatorRepository: GeneratorRepository;
    }) {
        this.generatorRepository = params.generatorRepository;
    }

    public async getGeneratorsForWidgets(widgets: WidgetConfig[]): Promise<Generator[]> {
        const widgetGenerators = await Promise.all(widgets.map(WidgetUtils.getDistinctGeneratorsForWidget));
        const generatorKeys = widgetGenerators.flatMap((it) => it).filter(ArrayUtils.removeDuplicates);
        const generators = await this.generatorRepository.getGeneratorsByKeys(generatorKeys);
        return generators.sort((a, b) => a.parkName.localeCompare(b.parkName) || a.name.localeCompare(b.name));
    }

    public groupGeneratorsByPark(generators: Generator[]): { [park: string]: Generator[] } {
        return generators.reduce((groups: any, item: Generator) => {
            const generatorsOfPark: Generator[] = groups[item.parkName] || [];
            generatorsOfPark.push(item);
            groups[item.parkName] = generatorsOfPark;
            return groups;
        }, {});
    }

    public groupGeneratorsByParkAndType(generators: Generator[]): { [park: string]: GeneratorGroup[] } {
        return generators.reduce((groups: any, item: Generator) => {
            const generatorsOfPark: GeneratorGroup[] = groups[item.parkName] || [];
            const match = generatorsOfPark.find((group) => group.hubHeight === item.hubHeight
                && group.model === item.model
                && group.power === item.power
                && group.installationDate?.getFullYear() === item.installationDate?.getFullYear());
            if (match) {
                match.count++;
            } else {
                generatorsOfPark.push({
                    count: 1,
                    hubHeight: item.hubHeight,
                    power: item.power,
                    model: item.model,
                    installationDate: item.installationDate,
                    zipcode: item.zipcode,
                });
            }
            groups[item.parkName] = generatorsOfPark;
            return groups;
        }, {});
    }

    public getParks(generators: Generator[]): Park[] {
        return generators.reduce((parks: Park[], generator: Generator) => {
            const parkIndex = parks.findIndex((it) => it.key === generator.parkKey);
            if (parkIndex !== -1) {
                parks[parkIndex].generatorCount++;
                parks[parkIndex].power += generator.power || 0;
            } else {
                parks.push({
                    key: generator.parkKey,
                    name: generator.parkName,
                    generatorCount: 1,
                    zipcode: generator.zipcode,
                    power: generator.power || 0,
                });
            }
            return parks;
        }, new Array<Park>());
    }

    public getInstalledPower(generators: Generator[]): number {
        return generators.reduce((total, generator) => total + (generator.power || 0), 0);
    }
}
