
import { Component, Prop, Vue } from 'vue-property-decorator';
import Checkbox from '@/modules/shared/components/input/Checkbox.vue';

@Component({
    components: { Checkbox },
})
export default class InputCheckbox extends Vue {

    @Prop({ default: false })
    public readonly value!: boolean;

    @Prop({ default: 'Label' })
    public readonly label!: string;

    @Prop({ default: false })
    public readonly disabled!: boolean|string;

    @Prop({ default: null })
    public readonly errors!: string[]|null;

    @Prop({ default: true })
    public readonly showErrors!: boolean;

    @Prop({ default: false })
    public readonly readonly!: boolean;

    @Prop({ default: '' })
    public readonly hint!: string;

    private hasBeenFocused: boolean = false;

    private get hasErrors(): boolean {
        const errorsVisible = this.showErrors || this.hasBeenFocused;
        return errorsVisible && this.errors !== null && this.errors.length > 0;
    }

    private get errorText(): string {
        if (!this.errors) {
            return '';
        }
        return this.errors.join(', ');
    }

    private toggle() {
        this.hasBeenFocused = true;
        this.$emit('input', !this.value);
    }
}
