import Vue from 'vue';
import PortalVue from 'portal-vue';

import LoggingUtils from '@/assets/js/utils/LoggingUtils';

import App from './App.vue';
import router from './plugins/router';
import store from './store/store';
import { i18n } from './plugins/i18n';

import './registerServiceWorker';

// openstreetmap css
import 'leaflet/dist/leaflet.css';

// load plugins
import './plugins/apexcharts';
import './plugins/icons';
import './plugins/formatter';
import './plugins/config';
import './plugins/modules';

Vue.use(PortalVue);

Vue.config.devtools = true;
Vue.config.productionTip = false;

// create key on window to access vue from console
(window as any).Vue = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

// set the environment in the title
if (process.env.VUE_APP_BUILD_TYPE !== 'prod') {
    document.title = `Halvar [${process.env.VUE_APP_BUILD_TYPE}]`;
}
if (window.location.href.includes('?headless-logging=true') || window.location.href.includes('&headless-logging=true')) {
    LoggingUtils.throwLogsForHeadlessChromeDebugging();
    window.addEventListener('beforeunload', () => {
        console.log('Received unload event, tab is probably being closed');
    });
}
