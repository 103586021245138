import type { UserRepository } from '@/modules/ctx-dashboard/adapter';
import type { Role, LicenseFeature } from '@/modules/shared/types';

export class UserService {
    private readonly userRepository: UserRepository;

    constructor(params: {
        userRepository: UserRepository,
    }) {
        this.userRepository = params.userRepository;
    }

    public async getUserRole(): Promise<Role> {
        return this.userRepository.getUserRole();
    }

    public async getUserLicenseFeatures(): Promise<LicenseFeature[]> {
        return this.userRepository.getUserLicenseFeatures();
    }

    public async getUserHasLicenseFeature(feature: LicenseFeature): Promise<boolean> {
        return this.userRepository.getUserHasLicenseFeature(feature);
    }
}
