import type { RouteConfig } from 'vue-router';
import { Role, LicenseFeature } from '@/modules/shared/types';

const route: RouteConfig = {
    path: '/:customer/portfolio-templates',
    name: 'portfolio-templates',
    component: () => import(/* webpackChunkName: "ctx-portfolios" */ './PortfolioTemplatesView.vue'),
    meta: {
        role: Role.COMPANY_ADMIN,
        license: [LicenseFeature.ADVANCED],
        authenticated: true,
        settingsTabs: true,
        // settingsTabsGroup: 'company-admin',
        settingsTabsOrder: 40,
        portfolioTabs: false,
        navbar: true,
        footer: true,
        onboarding: true,
        notifications: true,
    },
};

export { route };
