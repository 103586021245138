
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class InputText extends Vue {

    @Prop({ default: '' })
    public readonly value!: string;

    @Prop({ default: 'Label' })
    public readonly label!: string;

    @Prop({ default: 'text' })
    public readonly type!: string;

    @Prop({ default: '' })
    public readonly placeholder!: string;

    @Prop({ default: false })
    public readonly disabled!: boolean|string;

    @Prop({ default: false })
    public readonly inlineButtons!: boolean|string;

    @Prop({ default: false })
    public readonly readonly!: boolean|string;

    @Prop({ default: null })
    public readonly errors!: string[]|null;

    @Prop({ default: true })
    public readonly showErrors!: boolean;

    @Prop({ default: '' })
    public readonly hint!: string;

    @Ref('input')
    private readonly input!: HTMLInputElement|undefined;

    private hasBeenFocused: boolean = false;

    private get hasErrors(): boolean {
        const errorsVisible = this.showErrors || this.hasBeenFocused;
        return errorsVisible && this.errors !== null && this.errors.length > 0;
    }

    private get errorText(): string {
        if (!this.errors) {
            return '';
        }
        return this.errors.join(', ');
    }

    private onInput(event: any) {
        this.hasBeenFocused = true;
        let value: string|number = event.target.value;
        if (this.type === 'number') {
            value = value.toString();
            value = value.replace(',', '.');
            value = parseFloat(value);
            if (!Number.isNaN(value)) {
                this.$emit('input', value);
                this.$emit('change', value);
            }
        } else {
            this.$emit('input', value);
            this.$emit('change', value);
        }
    }

    public focus(): void {
        if (!this.disabled && !this.readonly && this.input) {
            this.input.focus();
        }
    }
}
