import type { WidgetConfig } from '@/modules/ctx-dashboard';
import type { Interval } from '@/assets/js/utils/TimeIntervals';
import TimeIntervals from '@/assets/js/utils/TimeIntervals';
import DateUtils, { DateDiff } from '@/assets/js/utils/DateUtils';
import { ChartDataRepresentation, Resolution } from '@/clients/dashboardapi/v2';
import { WidgetDataUtils } from '@/components/widgets/utils/WidgetDataUtils';

export enum TimeCompare {
    Years1 ='t-1Y',
    Years2 ='t-2Y',
    Years3 ='t-3Y',
    Years4 ='t-4Y',
    Months1 = 't-1M',
    Weeks1 = 't-1W',
}

export class TimeCompareUtils {

    public static getAvailableTimeOverrides(widget: WidgetConfig): string[] {
        switch (widget.resolution) {
            case Resolution.Automatic:
            case Resolution.Daily: return [
                TimeCompare.Years1,
                TimeCompare.Years2,
                TimeCompare.Years3,
                TimeCompare.Years4,
                TimeCompare.Months1,
                TimeCompare.Weeks1,
            ];
            case Resolution.Monthly: return [
                TimeCompare.Years1,
                TimeCompare.Years2,
                TimeCompare.Years3,
                TimeCompare.Years4,
            ];
            case Resolution.Min10:
            default: return [];
        }
    }

    public static applyTimeOverride(interval: Interval, override: string): Interval {
        let dateFrom = interval.from;
        let dateTo = interval.to;
        switch (override) {
            case TimeCompare.Years1:
                dateFrom = DateUtils.subtract(dateFrom, { years: 1 });
                dateTo = DateUtils.subtract(dateTo, { years: 1 });
                break;
            case TimeCompare.Years2:
                dateFrom = DateUtils.subtract(dateFrom, { years: 2 });
                dateTo = DateUtils.subtract(dateTo, { years: 2 });
                break;
            case TimeCompare.Years3:
                dateFrom = DateUtils.subtract(dateFrom, { years: 3 });
                dateTo = DateUtils.subtract(dateTo, { years: 3 });
                break;
            case TimeCompare.Years4:
                dateFrom = DateUtils.subtract(dateFrom, { years: 4 });
                dateTo = DateUtils.subtract(dateTo, { years: 4 });
                break;
            case TimeCompare.Months1:
                dateFrom = DateUtils.subtract(dateFrom, { months: 1 });
                dateTo = DateUtils.subtract(dateTo, { months: 1 });
                break;
            case TimeCompare.Weeks1:
                dateFrom = DateUtils.subtract(dateFrom, { days: 7 });
                dateTo = DateUtils.subtract(dateTo, { days: 7 });
                break;
            default:
        }
        return { name: interval.name, from: dateFrom, to: dateTo };
    }

    private static getOffsetForOverride(override: string, widgetFrom?: Date, offsetFrom?: Date, resolution?: Resolution): DateDiff|null {
        const days = widgetFrom && offsetFrom ? DateUtils.getDaysBetweenDates(widgetFrom, offsetFrom) : null;
        switch (override) {
            case TimeCompare.Years1: return { years: 1 };
            case TimeCompare.Years2: return { years: 2 };
            case TimeCompare.Years3: return { years: 3 };
            case TimeCompare.Years4: return { years: 4 };
            case TimeCompare.Weeks1: return { days: 7 };
            // when a month gets shifted it will most probably not match directly on the target month
            // eg February on March will result in 29th, 30th and 31st of February overflowing into March
            // If March is also plotted this will result in 1st, 2nd and 3rd being plotted twice
            // This can be prevented by shifting a fixed amount of days
            case TimeCompare.Months1: return resolution === Resolution.Daily && days ? { days } : { months: 1 };
            default: return null;
        }
    }

    public static mapToOverlappingSeries(data: ChartDataRepresentation[], widget: WidgetConfig): ChartDataRepresentation[] {
        const widgetFrom = TimeIntervals.resolveIntervalForWidget(widget).from;
        const resources = WidgetDataUtils.flattenResources(widget);
        return data.map((series, index) => {
            const resource = resources[index];
            if (resource.timeOverride) {
                const interval = TimeIntervals.resolveIntervalForWidget(widget);
                const overrideFrom = TimeCompareUtils.applyTimeOverride(interval, resource.timeOverride).from;
                const offset: DateDiff|null = TimeCompareUtils.getOffsetForOverride(resource.timeOverride, widgetFrom, overrideFrom, widget.resolution);
                if (offset) {
                    const seriesX = series.x.map((x) => DateUtils.add(new Date(x), offset).getTime());
                    // make sure not to edit the series reference
                    return { ...series, x: seriesX };
                }
            }
            return series;
        });
    }
}
