import { i18n } from '@/plugins/i18n';
import { LicenseFeature } from '@/modules/shared/types';
import type { WidgetWizardStep, TabDataOptions, TabGeneralOptions } from '@/components/widget-wizard';
import { ResourceType } from '@/clients/dashboardapi/v2';
import { WidgetWizardTab } from '@/components/widget-wizard';
import { DataGroupType } from '@/components/widgets/types/DataGroupType';
import { TabTableOptions } from '@/components/widget-wizard/components/tabs/table/TabTableOptions';

const logevents: WidgetWizardStep = {
    name: 'widget-wizard.tab.log-events',
    tab: WidgetWizardTab.LogEvents,
    features: [LicenseFeature.ADVANCED],
};

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.Time,
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: {
        resourceTypes: [ResourceType.Portfolio, ResourceType.Park, ResourceType.Generator, ResourceType.Generatorgroup, ResourceType.Shareholder],
        hint: i18n.t('widget-wizard.tab.data.hint-distinct').toString(),
    },
};

const table: WidgetWizardStep<TabTableOptions> = {
    name: 'widget-wizard.tab.table',
    tab: WidgetWizardTab.Table,
    params: {
        defaultGroupingType: DataGroupType.GENERATOR,
        dataGroupingTypes: [
            DataGroupType.NONE,
            DataGroupType.DATASOURCE,
            DataGroupType.GENERATOR,
        ],
        defaultSorting: 'endDate',
        defaultSortingDirection: 'desc',
        dataSortingOptions: [
            { value: 'generatorName', displayName: 'widgets.logs.sort-by.generator' },
            { value: 'event', displayName: 'widgets.logs.sort-by.event' },
            { value: 'startDate', displayName: 'widgets.logs.sort-by.from' },
            { value: 'endDate', displayName: 'widgets.logs.sort-by.to' },
            { value: 'durationInSeconds', displayName: 'widgets.logs.sort-by.duration' },
            { value: 'windspeed', displayName: 'widgets.logs.sort-by.wind' },
            { value: 'prodLoss', displayName: 'widgets.logs.sort-by.prodLoss' },
        ],
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    logevents,
    time,
    data,
    table,
    general,
];
