/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WidgetCopyRequest
 */
export interface WidgetCopyRequest {
    /**
     * 
     * @type {string}
     * @memberof WidgetCopyRequest
     */
    dashboardKey: string;
}

export function WidgetCopyRequestFromJSON(json: any): WidgetCopyRequest {
    return WidgetCopyRequestFromJSONTyped(json, false);
}

export function WidgetCopyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetCopyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboardKey': json['dashboardKey'],
    };
}

export function WidgetCopyRequestToJSON(value?: WidgetCopyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboardKey': value.dashboardKey,
    };
}


