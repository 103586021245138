/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DashboardCreateFromTemplateRequest,
    DashboardCreateFromTemplateRequestFromJSON,
    DashboardCreateFromTemplateRequestToJSON,
    DashboardRepresentation,
    DashboardRepresentationFromJSON,
    DashboardRepresentationToJSON,
    DashboardTemplateRepresentation,
    DashboardTemplateRepresentationFromJSON,
    DashboardTemplateRepresentationToJSON,
    DashboardTemplateUpdateRequest,
    DashboardTemplateUpdateRequestFromJSON,
    DashboardTemplateUpdateRequestToJSON,
    PortfolioCreateFromTemplateRequest,
    PortfolioCreateFromTemplateRequestFromJSON,
    PortfolioCreateFromTemplateRequestToJSON,
    PortfolioRepresentation,
    PortfolioRepresentationFromJSON,
    PortfolioRepresentationToJSON,
    PortfolioTemplateRepresentation,
    PortfolioTemplateRepresentationFromJSON,
    PortfolioTemplateRepresentationToJSON,
    TemplateCreateFromDashboardRequest,
    TemplateCreateFromDashboardRequestFromJSON,
    TemplateCreateFromDashboardRequestToJSON,
    TemplateCreateFromPortfolioRequest,
    TemplateCreateFromPortfolioRequestFromJSON,
    TemplateCreateFromPortfolioRequestToJSON,
    TemplateCreateFromWidgetRequest,
    TemplateCreateFromWidgetRequestFromJSON,
    TemplateCreateFromWidgetRequestToJSON,
    WidgetCreateRequest,
    WidgetCreateRequestFromJSON,
    WidgetCreateRequestToJSON,
    WidgetTemplateRepresentation,
    WidgetTemplateRepresentationFromJSON,
    WidgetTemplateRepresentationToJSON,
} from '../models';

export interface CreateDashboardFromTemplateRequest {
    key: string;
    dashboardCreateFromTemplateRequest: DashboardCreateFromTemplateRequest;
}

export interface CreatePortfolioFromTemplateRequest {
    key: string;
    portfolioCreateFromTemplateRequest: PortfolioCreateFromTemplateRequest;
}

export interface CreateTemplateFromDashboardRequest {
    templateCreateFromDashboardRequest: TemplateCreateFromDashboardRequest;
}

export interface CreateTemplateFromPortfolioRequest {
    templateCreateFromPortfolioRequest: TemplateCreateFromPortfolioRequest;
}

export interface CreateTemplateFromWidgetRequest {
    templateCreateFromWidgetRequest: TemplateCreateFromWidgetRequest;
}

export interface GetWidgetStubFromTemplateRequest {
    key: string;
}

export interface RemoveDashboardTemplateRequest {
    key: string;
}

export interface RemoveWidgetTemplateRequest {
    key: string;
}

export interface UpdateDashboardTemplateRequest {
    key: string;
    dashboardTemplateUpdateRequest: DashboardTemplateUpdateRequest;
}

/**
 * 
 */
export class TemplatesApi extends runtime.BaseAPI {

    /**
     * Create a new dashboard from given template
     * Create dashboard from template
     */
    async createDashboardFromTemplateRaw(requestParameters: CreateDashboardFromTemplateRequest): Promise<runtime.ApiResponse<DashboardRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling createDashboardFromTemplate.');
        }

        if (requestParameters.dashboardCreateFromTemplateRequest === null || requestParameters.dashboardCreateFromTemplateRequest === undefined) {
            throw new runtime.RequiredError('dashboardCreateFromTemplateRequest','Required parameter requestParameters.dashboardCreateFromTemplateRequest was null or undefined when calling createDashboardFromTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/template/dashboards/{key}/instances`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardCreateFromTemplateRequestToJSON(requestParameters.dashboardCreateFromTemplateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new dashboard from given template
     * Create dashboard from template
     */
    async createDashboardFromTemplate(key: string, dashboardCreateFromTemplateRequest: DashboardCreateFromTemplateRequest): Promise<DashboardRepresentation> {
        const response = await this.createDashboardFromTemplateRaw({ key: key, dashboardCreateFromTemplateRequest: dashboardCreateFromTemplateRequest });
        return await response.value();
    }

    /**
     * Create a new portfolio instance from a template
     * Create portfolio from template
     */
    async createPortfolioFromTemplateRaw(requestParameters: CreatePortfolioFromTemplateRequest): Promise<runtime.ApiResponse<PortfolioRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling createPortfolioFromTemplate.');
        }

        if (requestParameters.portfolioCreateFromTemplateRequest === null || requestParameters.portfolioCreateFromTemplateRequest === undefined) {
            throw new runtime.RequiredError('portfolioCreateFromTemplateRequest','Required parameter requestParameters.portfolioCreateFromTemplateRequest was null or undefined when calling createPortfolioFromTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:write"]);
        }

        const response = await this.request({
            path: `/portfolios/by-key/{key}/instances`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioCreateFromTemplateRequestToJSON(requestParameters.portfolioCreateFromTemplateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new portfolio instance from a template
     * Create portfolio from template
     */
    async createPortfolioFromTemplate(key: string, portfolioCreateFromTemplateRequest: PortfolioCreateFromTemplateRequest): Promise<PortfolioRepresentation> {
        const response = await this.createPortfolioFromTemplateRaw({ key: key, portfolioCreateFromTemplateRequest: portfolioCreateFromTemplateRequest });
        return await response.value();
    }

    /**
     * Create a new template from existing dashboard
     * Create template from dashboard
     */
    async createTemplateFromDashboardRaw(requestParameters: CreateTemplateFromDashboardRequest): Promise<runtime.ApiResponse<DashboardTemplateRepresentation>> {
        if (requestParameters.templateCreateFromDashboardRequest === null || requestParameters.templateCreateFromDashboardRequest === undefined) {
            throw new runtime.RequiredError('templateCreateFromDashboardRequest','Required parameter requestParameters.templateCreateFromDashboardRequest was null or undefined when calling createTemplateFromDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/template/dashboards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateCreateFromDashboardRequestToJSON(requestParameters.templateCreateFromDashboardRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new template from existing dashboard
     * Create template from dashboard
     */
    async createTemplateFromDashboard(templateCreateFromDashboardRequest: TemplateCreateFromDashboardRequest): Promise<DashboardTemplateRepresentation> {
        const response = await this.createTemplateFromDashboardRaw({ templateCreateFromDashboardRequest: templateCreateFromDashboardRequest });
        return await response.value();
    }

    /**
     * Create a portfolio template from a source portfolio
     * Create portfolio template
     */
    async createTemplateFromPortfolioRaw(requestParameters: CreateTemplateFromPortfolioRequest): Promise<runtime.ApiResponse<PortfolioTemplateRepresentation>> {
        if (requestParameters.templateCreateFromPortfolioRequest === null || requestParameters.templateCreateFromPortfolioRequest === undefined) {
            throw new runtime.RequiredError('templateCreateFromPortfolioRequest','Required parameter requestParameters.templateCreateFromPortfolioRequest was null or undefined when calling createTemplateFromPortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:write"]);
        }

        const response = await this.request({
            path: `/portfolios/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateCreateFromPortfolioRequestToJSON(requestParameters.templateCreateFromPortfolioRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioTemplateRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a portfolio template from a source portfolio
     * Create portfolio template
     */
    async createTemplateFromPortfolio(templateCreateFromPortfolioRequest: TemplateCreateFromPortfolioRequest): Promise<PortfolioTemplateRepresentation> {
        const response = await this.createTemplateFromPortfolioRaw({ templateCreateFromPortfolioRequest: templateCreateFromPortfolioRequest });
        return await response.value();
    }

    /**
     * Create a widget template from a widget
     * Create widget template
     */
    async createTemplateFromWidgetRaw(requestParameters: CreateTemplateFromWidgetRequest): Promise<runtime.ApiResponse<WidgetTemplateRepresentation>> {
        if (requestParameters.templateCreateFromWidgetRequest === null || requestParameters.templateCreateFromWidgetRequest === undefined) {
            throw new runtime.RequiredError('templateCreateFromWidgetRequest','Required parameter requestParameters.templateCreateFromWidgetRequest was null or undefined when calling createTemplateFromWidget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/template/widgets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateCreateFromWidgetRequestToJSON(requestParameters.templateCreateFromWidgetRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetTemplateRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a widget template from a widget
     * Create widget template
     */
    async createTemplateFromWidget(templateCreateFromWidgetRequest: TemplateCreateFromWidgetRequest): Promise<WidgetTemplateRepresentation> {
        const response = await this.createTemplateFromWidgetRaw({ templateCreateFromWidgetRequest: templateCreateFromWidgetRequest });
        return await response.value();
    }

    /**
     * Return all available dashboard templates for current user
     * Get dashboard templates
     */
    async getAllDashboardTemplatesRaw(): Promise<runtime.ApiResponse<Array<DashboardTemplateRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/template/dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DashboardTemplateRepresentationFromJSON));
    }

    /**
     * Return all available dashboard templates for current user
     * Get dashboard templates
     */
    async getAllDashboardTemplates(): Promise<Array<DashboardTemplateRepresentation>> {
        const response = await this.getAllDashboardTemplatesRaw();
        return await response.value();
    }

    /**
     * Get all widget templates the user has access to
     * Get widget templates
     */
    async getAllWidgetTemplatesRaw(): Promise<runtime.ApiResponse<Array<WidgetTemplateRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/template/widgets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WidgetTemplateRepresentationFromJSON));
    }

    /**
     * Get all widget templates the user has access to
     * Get widget templates
     */
    async getAllWidgetTemplates(): Promise<Array<WidgetTemplateRepresentation>> {
        const response = await this.getAllWidgetTemplatesRaw();
        return await response.value();
    }

    /**
     * Get a temporary widget stub from a template
     * Get widget stub
     */
    async getWidgetStubFromTemplateRaw(requestParameters: GetWidgetStubFromTemplateRequest): Promise<runtime.ApiResponse<WidgetCreateRequest>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getWidgetStubFromTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/template/widgets/{key}/instances`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetCreateRequestFromJSON(jsonValue));
    }

    /**
     * Get a temporary widget stub from a template
     * Get widget stub
     */
    async getWidgetStubFromTemplate(key: string): Promise<WidgetCreateRequest> {
        const response = await this.getWidgetStubFromTemplateRaw({ key: key });
        return await response.value();
    }

    /**
     * Delete a dashboard template
     * Delete dashboard template
     */
    async removeDashboardTemplateRaw(requestParameters: RemoveDashboardTemplateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling removeDashboardTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/template/dashboards/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a dashboard template
     * Delete dashboard template
     */
    async removeDashboardTemplate(key: string): Promise<void> {
        await this.removeDashboardTemplateRaw({ key: key });
    }

    /**
     * Delete widget template
     * Delete widget template
     */
    async removeWidgetTemplateRaw(requestParameters: RemoveWidgetTemplateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling removeWidgetTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/template/widgets/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete widget template
     * Delete widget template
     */
    async removeWidgetTemplate(key: string): Promise<void> {
        await this.removeWidgetTemplateRaw({ key: key });
    }

    /**
     * Update a dashboard template
     * Update dashboard template
     */
    async updateDashboardTemplateRaw(requestParameters: UpdateDashboardTemplateRequest): Promise<runtime.ApiResponse<DashboardTemplateRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDashboardTemplate.');
        }

        if (requestParameters.dashboardTemplateUpdateRequest === null || requestParameters.dashboardTemplateUpdateRequest === undefined) {
            throw new runtime.RequiredError('dashboardTemplateUpdateRequest','Required parameter requestParameters.dashboardTemplateUpdateRequest was null or undefined when calling updateDashboardTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/template/dashboards/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardTemplateUpdateRequestToJSON(requestParameters.dashboardTemplateUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardTemplateRepresentationFromJSON(jsonValue));
    }

    /**
     * Update a dashboard template
     * Update dashboard template
     */
    async updateDashboardTemplate(key: string, dashboardTemplateUpdateRequest: DashboardTemplateUpdateRequest): Promise<DashboardTemplateRepresentation> {
        const response = await this.updateDashboardTemplateRaw({ key: key, dashboardTemplateUpdateRequest: dashboardTemplateUpdateRequest });
        return await response.value();
    }

}
