
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import type SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import Tabs from '@/components/basics/Tabs.vue';
import Dropdown from '@/modules/shared/components/util/Dropdown.vue';

type KeyedObject = { key?: string|number; id?: string|number };

@Component({
    components: {
        Dropdown,
        Tabs,
    },
})
export default class AutocompleteSelectBox<T> extends Vue {

    @Prop({ required: true })
    public readonly value!: T;

    @Prop({ default: [] })
    public readonly options!: SelectBoxOption<T|undefined>[];

    @Prop({ default: '' })
    public readonly placeholder!: string;

    @Prop({ default: 'Err: Invalid value!' })
    public readonly invalidPlaceholder!: string;

    @Prop({ default: false })
    public readonly disabled!: boolean|string;

    @Prop({ default: null })
    public readonly errors!: string[]|null;

    @Prop({ default: true })
    public readonly showErrors!: boolean;

    @Prop({ default: false })
    public readonly loading!: boolean;

    @Ref('input')
    private refInput?: HTMLInputElement;

    @Ref('inputDropdown')
    private refInputDropdown?: HTMLInputElement;

    private hasBeenFocused: boolean = false;
    private debug: boolean = false;
    private filter: string = '';
    private selectedCategory: string = 'all';
    private showDropdown: boolean = false;
    private selectedOptionInvalid: boolean = false;

    private selectedOption: SelectBoxOption<T|undefined>|null = null;

    private highlightIndex: number = -1;

    public mounted(): void {
        window.addEventListener('resize', this.closeDropdown, false);
        this.onValueChanged();
    }

    public destroyed(): void {
        window.removeEventListener('resize', this.closeDropdown, false);
    }

    private get hasErrors(): boolean {
        const errorsVisible = this.showErrors || this.hasBeenFocused;
        return errorsVisible && this.errors !== null && this.errors.length > 0;
    }

    private get errorText(): string {
        if (!this.errors) {
            return '';
        }
        return this.errors.join(', ');
    }

    @Watch('value')
    @Watch('options')
    private onValueChanged(): void {
        this.selectedOptionInvalid = false;
        if (!this.value) {
            this.selectedOption = null;
            this.filter = '';
        } else {
            const keyedValue: KeyedObject|undefined = this.value as KeyedObject;
            const selectedOption = this.options.find((option) => {
                const keyedOption: KeyedObject|undefined = option.value as KeyedObject;
                // if we have an object as T, simple comparison may not be enough, so first check if we have on object
                // with a key or id field for comparison
                if ((keyedValue?.key !== undefined && keyedOption?.key !== undefined && keyedValue?.key === keyedOption?.key)
                    || (keyedValue?.id !== undefined && keyedOption?.id !== undefined && keyedValue?.id === keyedOption?.id)) {
                    return true;
                }
                return this.value === option.value;
            });
            if (!selectedOption) {
                if (this.loading) {
                    this.selectedOptionInvalid = false;
                    this.selectedOption = { value: this.value, displayName: '...' };
                } else {
                    this.selectedOptionInvalid = true;
                    this.selectedOption = { value: this.value, displayName: this.invalidPlaceholder };
                }
            } else {
                this.selectedOption = selectedOption;
            }
        }
    }

    private get isDisabled(): boolean {
        return this.disabled === true || this.disabled === 'true' || this.options.length === 0;
    }

    private get displayValue(): string {
        return this.selectedOption?.displayName || '';
    }

    private get categoriesWithResults(): string[] {
        return Object.entries(this.optionsByCategory)
            .filter((value) => value[1].length > 0)
            .map((value) => value[0]);
    }

    private get optionsByCategory(): {[key: string]: SelectBoxOption<T|undefined>[]} {
        const optionsByCategory: {[key: string]: SelectBoxOption<T|undefined>[]} = {};
        this.allCategories.forEach((cat) => optionsByCategory[cat] = []);
        optionsByCategory.all = this.matchingOptions;
        this.matchingOptions
            .filter((opt) => opt.category)
            .forEach((opt) => {
                const key = opt.category!;
                const group = optionsByCategory[key] || [];
                group.push(opt);
                optionsByCategory[key] = group;
            });
        return optionsByCategory;
    }

    private get matchingOptions(): SelectBoxOption<T|undefined>[] {
        if (this.selectedOption && this.selectedOption.displayName === this.filter) {
            return this.options;
        }

        // compare on lowercase without whitespaces
        const filter = this.trimForSearchCompare(this.filter);
        return this.options
            .filter((option: SelectBoxOption) => this.trimForSearchCompare(option.displayName).includes(filter))
            // sort results: exact match > starts with > includes
            .sort((optionA: SelectBoxOption, optionB: SelectBoxOption) => {
                const a: string = this.trimForSearchCompare(optionA.displayName);
                const b: string = this.trimForSearchCompare(optionB.displayName);
                // eslint-disable-next-line no-nested-ternary
                const scoreA = (a === filter) ? 10 : a.startsWith(filter) ? 5 : 0;
                // eslint-disable-next-line no-nested-ternary
                const scoreB = (b === filter) ? 10 : b.startsWith(filter) ? 5 : 0;
                return scoreB - scoreA;
            });
    }

    private get allCategories(): string[] {
        const categories = ['all']
            .concat(this.options.map((option) => option.category || ''))
            .filter(ArrayUtils.filterUndefined)
            .filter(ArrayUtils.removeDuplicates);

        if (this.selectedCategory === '') {
            this.selectedCategory = categories[0];
        }
        return categories;
    }

    private toggleDropdown(show?: boolean): void {
        if (this.isDisabled) {
            this.showDropdown = false;
        } else if (show !== undefined) {
            this.showDropdown = show;
        } else {
            this.showDropdown = !this.showDropdown;
        }
        this.$nextTick(this.focusInput);
    }

    private closeDropdown(): void {
        if (this.showDropdown) {
            this.showDropdown = false;
        }
        this.focusInput();
    }

    private focusInput(): void {
        if (this.refInputDropdown) {
            this.refInputDropdown.focus();
        } else if (this.refInput) {
            this.refInput.focus();
        }
    }

    private highlightNextOption(): void {
        this.highlightIndex += 1;
        if (this.highlightIndex >= this.matchingOptions.length) {
            this.highlightIndex = 0;
        }
        this.showDropdown = true;
        this.scrollIntoView();
    }

    private highlightPreviousOption(): void {
        this.highlightIndex -= 1;
        if (this.highlightIndex < 0) {
            this.highlightIndex = this.matchingOptions.length - 1;
        }
        this.showDropdown = true;
        this.scrollIntoView();
    }

    private selectHighlightedOption(): void {
        const option = this.highlightedOption;
        if (option) {
            this.selectOption(option);
        }
    }

    private scrollIntoView(): void {
        const scrollpane = this.$refs.results as HTMLElement;
        if (scrollpane) {
            const highlightedElement = scrollpane.childNodes[this.highlightIndex] as HTMLElement;
            if (highlightedElement) {
                highlightedElement.scrollIntoView(false);
            }
        }
    }

    private get highlightedOption(): SelectBoxOption|null {
        if (this.highlightIndex > 0) {
            return this.matchingOptions[this.highlightIndex] || null;
        }
        return null;
    }

    private selectOption(option: SelectBoxOption) {
        this.selectedOption = option;
        this.filter = option.displayName;
        this.showDropdown = false;
        this.$emit('input', option.value);
    }

    private filterOptions(): void {
        if (!this.showDropdown && this.filter.length > 3) {
            this.showDropdown = true;
        }
    }

    private trimForSearchCompare(value?: string): string {
        if (value === undefined) {
            return '';
        }
        return value
            .toLowerCase()
            .trim()
            .replace(/\s/g, '');
    }
}
