/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Resolution,
    ResolutionFromJSON,
    ResolutionFromJSONTyped,
    ResolutionToJSON,
    WidgetAxisRepresentation,
    WidgetAxisRepresentationFromJSON,
    WidgetAxisRepresentationFromJSONTyped,
    WidgetAxisRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetCreateRequest
 */
export interface WidgetCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof WidgetCreateRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetCreateRequest
     */
    generatedTitle: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetCreateRequest
     */
    preset: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetCreateRequest
     */
    type: string;
    /**
     * 
     * @type {Resolution}
     * @memberof WidgetCreateRequest
     */
    resolution?: Resolution;
    /**
     * 
     * @type {string}
     * @memberof WidgetCreateRequest
     */
    config?: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetCreateRequest
     */
    position?: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetCreateRequest
     */
    intervalName: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetCreateRequest
     */
    intervalFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetCreateRequest
     */
    intervalTo?: number;
    /**
     * 
     * @type {Array<WidgetAxisRepresentation>}
     * @memberof WidgetCreateRequest
     */
    axis: Array<WidgetAxisRepresentation>;
}

export function WidgetCreateRequestFromJSON(json: any): WidgetCreateRequest {
    return WidgetCreateRequestFromJSONTyped(json, false);
}

export function WidgetCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'generatedTitle': json['generatedTitle'],
        'preset': json['preset'],
        'type': json['type'],
        'resolution': !exists(json, 'resolution') ? undefined : ResolutionFromJSON(json['resolution']),
        'config': !exists(json, 'config') ? undefined : json['config'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'intervalName': json['intervalName'],
        'intervalFrom': !exists(json, 'intervalFrom') ? undefined : json['intervalFrom'],
        'intervalTo': !exists(json, 'intervalTo') ? undefined : json['intervalTo'],
        'axis': ((json['axis'] as Array<any>).map(WidgetAxisRepresentationFromJSON)),
    };
}

export function WidgetCreateRequestToJSON(value?: WidgetCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'generatedTitle': value.generatedTitle,
        'preset': value.preset,
        'type': value.type,
        'resolution': ResolutionToJSON(value.resolution),
        'config': value.config,
        'position': value.position,
        'intervalName': value.intervalName,
        'intervalFrom': value.intervalFrom,
        'intervalTo': value.intervalTo,
        'axis': ((value.axis as Array<any>).map(WidgetAxisRepresentationToJSON)),
    };
}


