/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountingFormulaRepresentation,
    AccountingFormulaRepresentationFromJSON,
    AccountingFormulaRepresentationFromJSONTyped,
    AccountingFormulaRepresentationToJSON,
    ResultType,
    ResultTypeFromJSON,
    ResultTypeFromJSONTyped,
    ResultTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountingMetricUpdateRequest
 */
export interface AccountingMetricUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingMetricUpdateRequest
     */
    name?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingMetricUpdateRequest
     */
    lc?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AccountingMetricUpdateRequest
     */
    unit?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountingMetricUpdateRequest
     */
    visible?: boolean;
    /**
     * all features that are assigned to the metric
     * @type {Array<string>}
     * @memberof AccountingMetricUpdateRequest
     */
    licenseFeatures?: Array<string>;
    /**
     * 
     * @type {ResultType}
     * @memberof AccountingMetricUpdateRequest
     */
    resultType?: ResultType;
    /**
     * 
     * @type {Array<AccountingFormulaRepresentation>}
     * @memberof AccountingMetricUpdateRequest
     */
    formulas?: Array<AccountingFormulaRepresentation>;
}

export function AccountingMetricUpdateRequestFromJSON(json: any): AccountingMetricUpdateRequest {
    return AccountingMetricUpdateRequestFromJSONTyped(json, false);
}

export function AccountingMetricUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingMetricUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lc': !exists(json, 'lc') ? undefined : json['lc'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'visible': !exists(json, 'visible') ? undefined : json['visible'],
        'licenseFeatures': !exists(json, 'licenseFeatures') ? undefined : json['licenseFeatures'],
        'resultType': !exists(json, 'resultType') ? undefined : ResultTypeFromJSON(json['resultType']),
        'formulas': !exists(json, 'formulas') ? undefined : ((json['formulas'] as Array<any>).map(AccountingFormulaRepresentationFromJSON)),
    };
}

export function AccountingMetricUpdateRequestToJSON(value?: AccountingMetricUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lc': value.lc,
        'unit': value.unit,
        'visible': value.visible,
        'licenseFeatures': value.licenseFeatures,
        'resultType': ResultTypeToJSON(value.resultType),
        'formulas': value.formulas === undefined ? undefined : ((value.formulas as Array<any>).map(AccountingFormulaRepresentationToJSON)),
    };
}


