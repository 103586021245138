import type {
    LossOfProductionMetric,
    LossOfProductionMetricsRepository,
    LossOfProductionMetricVariant,
} from '@/modules/ctx-admin-loss-of-production';
import type {
    GeneratorsApi,
    LossOfProductionMetricRepresentation,
    LossOfProductionMetricsApi, LossOfProductionMetricVariantRepresentation,
} from '@/modules/shared/adapter';
import { I18nAdapter } from '@/plugins/i18n';
import { LicenseFeature } from '@/modules/shared/types';

export class LossOfProductionMetricsRepositoryImpl implements LossOfProductionMetricsRepository {

    private readonly lossOfProductionMetricsApi: LossOfProductionMetricsApi;
    private readonly generatorsApi: GeneratorsApi;

    constructor(apis: {
        lossOfProductionMetrics: LossOfProductionMetricsApi;
        generators: GeneratorsApi;
    }) {
        this.lossOfProductionMetricsApi = apis.lossOfProductionMetrics;
        this.generatorsApi = apis.generators;
    }

    public onLossOfProductionMetricsChange(cb: () => void): void {
        this.lossOfProductionMetricsApi.onChange(() => cb());
    }

    public async createLossOfProductionMetric(newMetric: LossOfProductionMetric): Promise<LossOfProductionMetric> {
        const representation = await this.lossOfProductionMetricsApi.createLossOfProductionMetric({
            name: Object.values(newMetric.name || {}),
            lc: Object.keys(newMetric.name || {}),
            licenseFeatures: newMetric.licenseFeatures,
            generatorStatusKeys: newMetric.variants[0].generatorStatusKeys,
        });
        return this.mapLossOfProductionMetricToDomain_async(representation);
    }

    public async createLossOfProductionMetricVariant(metricKey: string, newVariant: LossOfProductionMetricVariant): Promise<LossOfProductionMetric> {
        await this.lossOfProductionMetricsApi.createLossOfProductionMetricVariant(metricKey, {
            name: newVariant.name,
            generatorKeys: newVariant.generatorKeys,
            generatorStatusKeys: newVariant.generatorStatusKeys,
        });
        const representation = await this.lossOfProductionMetricsApi.getLossOfProductionMetric(metricKey);
        if (!representation) {
            throw new Error('Loss of production metric not found!');
        }
        return this.mapLossOfProductionMetricToDomain_async(representation);
    }

    public async deleteLossOfProductionMetricVariant(metricKey: string, variantKey: string): Promise<void> {
        await this.lossOfProductionMetricsApi.deleteLossOfProductionMetricVariant(metricKey, variantKey);
    }

    public async getLossOfProductionMetrics(): Promise<LossOfProductionMetric[]> {
        const representations = await this.lossOfProductionMetricsApi.getLossOfProductionMetrics();
        const allGeneratorKeys = await this.generatorsApi.getGenerators().then((generators) => generators.map((it) => it.key));
        return representations.map((representation) => this.mapLossOfProductionMetricToDomain(representation, allGeneratorKeys));
    }

    public async updateLossOfProductionMetric(metricKey: string, updatedMetric: Partial<LossOfProductionMetric>): Promise<LossOfProductionMetric> {
        const representation = await this.lossOfProductionMetricsApi.updateLossOfProductionMetric(metricKey, {
            name: Object.values(updatedMetric.name || {}),
            lc: Object.keys(updatedMetric.name || {}),
            licenseFeatures: updatedMetric.licenseFeatures,
        });
        return this.mapLossOfProductionMetricToDomain_async(representation);
    }

    public async updateLossOfProductionMetricVariant(metricKey: string, variantKey: string, updateRequest: Partial<LossOfProductionMetricVariant>): Promise<LossOfProductionMetric> {
        if (updateRequest.isGlobalDefault && updateRequest.generatorKeys !== undefined) {
            // global variant generators are implicit, dont save
            updateRequest.generatorKeys = [];
        }
        await this.lossOfProductionMetricsApi.updateLossOfProductionMetricVariant(metricKey, variantKey, {
            name: updateRequest.name,
            generatorKeys: updateRequest.generatorKeys,
            generatorStatusKeys: updateRequest.generatorStatusKeys,
        });
        const representation = await this.lossOfProductionMetricsApi.getLossOfProductionMetric(metricKey);
        if (!representation) {
            throw new Error('Loss of production metric not found!');
        }
        return this.mapLossOfProductionMetricToDomain_async(representation);
    }

    private async mapLossOfProductionMetricToDomain_async(representation: LossOfProductionMetricRepresentation): Promise<LossOfProductionMetric> {
        const allGeneratorKeys = await this.generatorsApi.getGenerators().then((generators) => generators.map((it) => it.key));
        return this.mapLossOfProductionMetricToDomain(representation, allGeneratorKeys);
    }

    private mapLossOfProductionMetricToDomain(representation: LossOfProductionMetricRepresentation, generatorKeys: string[]): LossOfProductionMetric {
        const variantGenerators = representation.variants
            .filter((it) => !it.globalDefault)
            .flatMap((it) => it.generatorKeys);
        const globalVariantGenerators = generatorKeys.filter((key) => !variantGenerators.includes(key));
        return {
            key: representation.key,
            variants: representation.variants.map((it) => this.mapLossOfProductionMetricVariantToDomain(it, globalVariantGenerators)),
            name: I18nAdapter.translateForAllLoadedLanguages(representation.name),
            licenseFeatures: representation.licenseFeatures as LicenseFeature[],
        };
    }

    private mapLossOfProductionMetricVariantToDomain(representation: LossOfProductionMetricVariantRepresentation, globalVariantGenerators: string[]): LossOfProductionMetricVariant {
        return {
            key: representation.key,
            generatorStatusKeys: representation.generatorStatusKeys,
            generatorKeys: representation.globalDefault ? globalVariantGenerators : representation.generatorKeys,
            isGlobalDefault: representation.globalDefault,
            name: representation.name,
        };
    }
}
