
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Resolution } from '@/modules/shared/types';
import Utils from '@/assets/js/utils/Utils';
import { Port } from '@/modules/ctx-dashboard/Port';
import type { Dashboard, Portfolio, WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import { WidgetUtils } from '@/components/widgets';
import { WidgetWizardTab } from '@/components/widget-wizard/types/WidgetWizardTab';
import PresetSelection from './PresetSelection.vue';
import type WizardTab from './WizardTab';
import type { WidgetWizardStepConfig } from '../types/WidgetWizardStep';

interface WidgetWizardStep<P = any> extends WidgetWizardStepConfig<P> {
    valid?: boolean;
    enabled?: boolean;
}

@Component({
    components: {
        LoadingAnimationSmall,
        PresetSelection,
        BaseDialog,
    },
})
export default class WidgetWizard extends Vue {

    @Prop({ required: false, default: null })
    public readonly portfolioKey!: string|null;

    @Prop({ required: false, default: null })
    public readonly dashboardKey!: string|null;

    @Prop({ required: false, default: null })
    public readonly value!: WidgetConfig|null;

    @Prop({ required: false, default: null })
    public readonly entrypoint!: WidgetWizardTab|null;

    @Prop({ required: false, default: null })
    public readonly entrypointAction!: string|null;

    private disableOneClickAdd: boolean = false;
    private widget: WidgetConfig = this.createDefaultWidget();
    private skipValidWizardTabs: boolean = false;
    private wizard: boolean = false;

    private action: string|null = null;
    private stepsLoading: Promise<void>|null = null;
    private steps: WidgetWizardStep[] = [];
    private activeStep: number = 0;
    private loading: boolean = false;
    private dashboard: Dashboard|null = null;
    private portfolio: Portfolio|null = null;

    public created(): void {
        this.widget = Utils.deepCopy(this.value || this.createDefaultWidget());
        this.wizard = this.value === null;
        this.activeStep = this.wizard ? -1 : 0;

        this.fetchDashboard();
        this.fetchPortfolio();
    }

    public mounted(): void {
        this.action = this.entrypointAction;
        this.setWidgetFromPreset(this.widget)
            .then(() => this.$nextTick(this.jumpToEntrypoint));

    }

    @Watch('widget.portfolioKey')
    private async fetchPortfolio(): Promise<void> {
        const key = this.widget.portfolioKey;
        this.portfolio = await Port.portfolios.getPortfolio(key);
    }

    @Watch('widget.dashboardKey')
    private async fetchDashboard(): Promise<void> {
        const key = this.widget.dashboardKey;
        this.dashboard = await Port.dashboards.getDashboard(key);
    }

    private get invalidTabs(): string[] {
        return this.steps
            // step.valid may be undefined, which is ok for tabs without validation or invisible tabs
            .filter((step) => step.valid === false)
            .map((step) => step.name);
    }

    private get currentTab(): WidgetWizardStep|undefined {
        return this.steps[this.activeStep];
    }

    private get currentTabValid(): boolean {
        return this.currentTab?.valid !== false;
    }

    private get allTabsValid(): boolean {
        return this.invalidTabs.length === 0;
    }

    @Watch('activeStep')
    public async onActiveStepChanged(): Promise<void> {
        for (let i = 0; i <= this.activeStep; i++) {
            const step = this.steps[i];
            if (!step.enabled) {
                step.enabled = true;
                Vue.set(this.steps, i, step);
            }
        }
        this.initCurrentTab();
    }

    private get tabs(): { [key: string]: any } {
        const tabs: { [key: string]: any } = {};
        tabs[WidgetWizardTab.Metrics] = () => import('./tabs/metrics/TabMetrics.vue');
        tabs[WidgetWizardTab.Axis] = () => import('./tabs/axis/TabAxis.vue');
        tabs[WidgetWizardTab.Data] = () => import('./tabs/data/TabData.vue');
        tabs[WidgetWizardTab.Resolution] = () => import('./tabs/resolution/TabResolution.vue');
        tabs[WidgetWizardTab.AggregationTime] = () => import('./tabs/aggregation-time/TabAggregationTime.vue');
        tabs[WidgetWizardTab.AggregationDatasources] = () => import('./tabs/aggregation-datasources/TabAggregationDatasources.vue');
        tabs[WidgetWizardTab.Time] = () => import('./tabs/time/TabTime.vue');
        tabs[WidgetWizardTab.General] = () => import('./tabs/general/TabGeneral.vue');
        tabs[WidgetWizardTab.Availability] = () => import('./tabs/availability/TabAvailability.vue');
        tabs[WidgetWizardTab.Commissions] = () => import('./tabs/commissions/TabCommissions.vue');
        tabs[WidgetWizardTab.Distribution] = () => import('./tabs/distribution/TabDistribution.vue');
        tabs[WidgetWizardTab.TimeNumber] = () => import('./tabs/time-number/TabTimeNumber.vue');
        tabs[WidgetWizardTab.PowerCurve] = () => import('./tabs/powercurve/TabPowerCurve.vue');
        tabs[WidgetWizardTab.LogEvents] = () => import('./tabs/log-events/TabLogEvents.vue');
        tabs[WidgetWizardTab.Note] = () => import('./tabs/note/TabNote.vue');
        tabs[WidgetWizardTab.OperatorForecast] = () => import('./tabs/operator-forecast/TabOperatorForecast.vue');
        tabs[WidgetWizardTab.Map] = () => import('./tabs/map/TabMap.vue');
        tabs[WidgetWizardTab.Table] = () => import('./tabs/table/TabTable.vue');
        return tabs;
    }

    private async awaitLoadingSteps(): Promise<void> {
        if (this.stepsLoading) {
            await this.stepsLoading;
            await Utils.wait(10);
        }
    }

    private async back(): Promise<void> {
        await this.awaitLoadingSteps();
        let i = this.activeStep;
        i--;
        while (!this.isStepVisible(i)) {
            // tab is not visible or not enabled
            i--;
        }
        const minimumStep = this.wizard ? -1 : 0;
        this.activeStep = Math.max(i, minimumStep);
    }

    private async startWizard(): Promise<void> {
        if (this.skipValidWizardTabs) {
            await this.awaitLoadingSteps();
            let i = this.activeStep;
            i++;
            while (!this.isStepVisible(i) || this.steps[i].valid) {
                // tab is not visible or not enabled
                i++;
            }
            const maximumStep = this.steps.length - 1;
            this.activeStep = Math.min(i, maximumStep);
        } else {
            await this.next();
        }
    }

    private async next(): Promise<void> {
        await this.awaitLoadingSteps();
        let i = this.activeStep;
        i++;
        while (!this.isStepVisible(i)) {
            // tab is not visible or not enabled
            i++;
        }
        const maximumStep = this.steps.length - 1;
        this.activeStep = Math.min(i, maximumStep);
    }

    private isStepVisible(index: number): boolean {
        if (index === -1) {
            // preset selection
            return true;
        }
        const step = this.steps[index];
        if (!step) {
            // possibly loading
            return true;
        }
        return !step.visible || step.visible(this.widget);

    }

    private async jumpToEntrypoint(): Promise<void> {
        await this.awaitLoadingSteps();
        if (this.entrypoint) {
            const tabFound = await this.jumpToTab(this.entrypoint);
            if (!tabFound && this.entrypoint === WidgetWizardTab.Time) {
                await this.jumpToTab(WidgetWizardTab.TimeNumber);
            }
        }
        this.$nextTick(this.initCurrentTab);
    }

    private async jumpToTab(tab: WidgetWizardTab): Promise<boolean> {
        await this.awaitLoadingSteps();
        const index = this.steps.findIndex((step) => step.tab === tab);
        const step = this.steps[index];
        if (step) {
            if (step.visible !== undefined && !step.visible(this.widget)) {
                return false;
            }
            if (!step.enabled) {
                return false;
            }
            this.jump(index);
            return true;
        }
        return false;
    }

    private jump(step: number) {
        if (this.steps[step].enabled) {
            this.activeStep = step;
        }
    }

    private async setWidgetFromPreset(widget: WidgetConfig): Promise<void> {
        this.widget = widget;
        this.stepsLoading = this.loadSteps(widget);
        await this.stepsLoading;
    }

    private async loadSteps(widget: WidgetConfig): Promise<void> {
        this.steps = [];
        const userLicenseFeatures = await Port.user.getUserLicenseFeatures();
        this.steps = WidgetUtils.getWizardStepsForWidgetType(widget.type)
            .filter((step) => !step.features || step.features.length === 0
                || !step.features.find((feature) => !userLicenseFeatures.includes(feature)))
            .map((step, index) => ({
                ...step,
                valid: undefined,
                enabled: this.wizard && index > this.activeStep ? undefined : true,
            }));
    }

    private updateValidation(index: number, valid: boolean) {
        const step = this.steps[index];
        step.valid = valid;
        Vue.set(this.steps, index, step);
        this.$forceUpdate();
        // debug validation over all tabs
        // if (!valid) {
        //     console.log(`${step.name} is invalid!`);
        // }
    }

    private initCurrentTab(tryCounter: number = 0) {
        const step = this.steps[this.activeStep];
        const component = this.getComponentForStep(step);
        if (component) {
            component.onShown();
        } else if (tryCounter < 10) {
            setTimeout(() => this.initCurrentTab(tryCounter + 1), 10);
        }
    }

    private getComponentForStep(step?: WidgetWizardStep): WizardTab|undefined {
        const componentName = step?.name;
        if (!componentName) {
            // error shows a few times while loading the components, so just ignore
            // console.error('component is undefined');
            return undefined;
        }
        let componentRef = this.$refs[componentName] as WizardTab;
        if (Array.isArray(componentRef)) {
            componentRef = componentRef[0];
        }
        if (!componentRef) {
            console.error(`component with name ${componentName} not defined`);
            return undefined;
        }
        if (!componentRef.onShown) {
            console.error(`component with name ${componentName} has no onShown function`);
            return undefined;
        }
        return componentRef;
    }

    /**
     * Creates an empty default widget, to prevent null errors, this is not intended to be actually used!
     */
    private createDefaultWidget(): WidgetConfig {
        return {
            key: '',
            dashboardKey: this.dashboardKey || '',
            portfolioKey: this.portfolioKey || '',
            order: 0,
            preset: '',
            config: {},
            intervalName: '1M',
            intervalTo: new Date(),
            intervalFrom: new Date(),
            resolution: Resolution.Min10,
            title: '',
            generatedTitle: '',
            type: WidgetType.TimeChart,
            axis: [],
        };
    }

    private save() {
        this.loading = true;
        this.$emit('submit', this.widget);
    }

    private cancel() {
        this.$emit('close');
    }
}
