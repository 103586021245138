import { Events } from '@/modules/shared';
import { Port } from '@/modules/shared/Port';
import { MessageProvider, LocalMessageProvider, ServiceMessageProvider } from './provider';
import { i18n } from './plugin';

export enum I18nChangedEventType {
    LanguageChanged = 'lang-changed',
    LanguageLoaded = 'lang-loaded',
}

export interface I18nChangedEvent {
    type: I18nChangedEventType;
    lang: string;
}

export class Adapter {

    public locales: string[] = (process.env.VUE_APP_I18N_LOCALES || i18n.locale).split(',');
    private loadedLanguages: Map<string, Promise<void>|undefined> = new Map<string, Promise<void>|undefined>();

    private localMessageProvider = new LocalMessageProvider();
    private serviceMessageProvider = new ServiceMessageProvider();

    private dynamicProviders: MessageProvider[] = [];
    private changeListeners: ((event: I18nChangedEvent) => void)[] = [];

    constructor() {
        // local messages are always loaded completely
        this.locales.forEach((lc) => this.localMessageProvider.loadMessages(lc));
        // dynamic messages are loaded when needed
        this.dynamicProviders = [this.serviceMessageProvider];
        Port.localizations.onChange((event) => this.reloadAll());
    }

    public onChange(cb: (event: I18nChangedEvent) => void): void {
        this.changeListeners.push(cb);
    }

    public findKeysForMessage(message: string, lang: string): string[] {
        return Object.entries(i18n.getLocaleMessage(lang))
            .filter((entry) => entry[1] === message)
            .map((entry) => entry[0]);
    }

    public async reloadAll(): Promise<void> {
        const languages = Array.from(this.loadedLanguages.keys());
        this.loadedLanguages.clear();
        await Promise.all(languages.map((lc) => this.loadMessages(lc)));
    }

    public async loadMessages(lang: string): Promise<void> {
        // fallback locale has not been loaded yet
        const fallback = i18n.fallbackLocale as string;
        if (lang !== fallback && this.loadedLanguages.get(fallback) === undefined) {
            this.loadedLanguages.set(fallback, this.loadMessagesInternal(fallback));
        }
        let promise = this.loadedLanguages.get(lang);
        if (!promise) {
            // langauge not loaded yet
            promise = this.loadMessagesInternal(lang);
            this.loadedLanguages.set(lang, promise);
        }
        return promise;
    }

    private async loadMessagesInternal(lang: string): Promise<void> {
        const requests = this.dynamicProviders.map((provider) => provider.loadMessages(lang));
        await Promise.allSettled(requests);
        Events.instance.publishEvent('language-loaded', lang);
        this.notifyObservers({ type: I18nChangedEventType.LanguageLoaded, lang: lang });
    }

    public async changeLanguage(lang: string): Promise<void> {
        // current language already equals request language, nothing to do
        if (i18n.locale === lang) {
            return;
        }
        await this.loadMessages(lang);
        i18n.locale = lang;
        document.querySelector('html')!.setAttribute('lang', lang);
        localStorage.setItem('lang', lang);
        Events.instance.publishEvent('language-changed', lang);
        this.notifyObservers({ type: I18nChangedEventType.LanguageChanged, lang: lang });
    }

    public translateForAllLoadedLanguages(key?: string): { [key: string]: string } {
        const translations: { [key: string]: string } = {};
        Array.from(this.loadedLanguages.keys()).forEach((lc) => {
            if (key) {
                translations[lc] = i18n.t(key, lc).toString();
            } else {
                translations[lc] = '';
            }
        });
        return translations;
    }

    private notifyObservers(event: I18nChangedEvent) {
        this.changeListeners.forEach((cb) => cb(event));
    }
}
