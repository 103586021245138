
import { Component, Prop, Vue } from 'vue-property-decorator';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import MultiSelectBox from '@/modules/shared/components/input/MultiSelectBox.vue';

@Component({
    components: { MultiSelectBox },
})
export default class InputMultiSelect<T> extends Vue {

    @Prop({ required: true, default: () => [] })
    public readonly value!: T[];

    @Prop({ required: false, default: null })
    public readonly displayValue!: string|null;

    @Prop({ required: false, default: 'Label' })
    private readonly label!: string;

    @Prop({ required: true, default: () => [] })
    public readonly options!: SelectBoxOption[];

    @Prop({ required: false, default: '' })
    public readonly placeholder!: string;

    @Prop({ required: false, default: false })
    public readonly disabled!: boolean|string;

    @Prop({ required: false, default: null })
    private readonly errors!: string[]|null;

    @Prop({ default: true })
    public readonly showErrors!: boolean;

    @Prop({ default: '' })
    public readonly hint!: string;

    private hasBeenFocused: boolean = false;

    private get hasErrors(): boolean {
        const errorsVisible = this.showErrors || this.hasBeenFocused;
        return errorsVisible && this.errors !== null && this.errors.length > 0;
    }

    private get errorText(): string {
        if (!this.errors) {
            return '';
        }
        return this.errors.join(', ');
    }

    private onInput(event: any) {
        this.hasBeenFocused = true;
        this.$emit('input', event);
        this.$emit('change', event);
    }
}
