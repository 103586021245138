/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WindroseDirectionBucket,
    WindroseDirectionBucketFromJSON,
    WindroseDirectionBucketFromJSONTyped,
    WindroseDirectionBucketToJSON,
} from './';

/**
 * 
 * @export
 * @interface WindroseDataRepresentation
 */
export interface WindroseDataRepresentation {
    /**
     * 
     * @type {Array<WindroseDirectionBucket>}
     * @memberof WindroseDataRepresentation
     */
    directionBuckets?: Array<WindroseDirectionBucket>;
}

export function WindroseDataRepresentationFromJSON(json: any): WindroseDataRepresentation {
    return WindroseDataRepresentationFromJSONTyped(json, false);
}

export function WindroseDataRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WindroseDataRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'directionBuckets': !exists(json, 'directionBuckets') ? undefined : ((json['directionBuckets'] as Array<any>).map(WindroseDirectionBucketFromJSON)),
    };
}

export function WindroseDataRepresentationToJSON(value?: WindroseDataRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'directionBuckets': value.directionBuckets === undefined ? undefined : ((value.directionBuckets as Array<any>).map(WindroseDirectionBucketToJSON)),
    };
}


