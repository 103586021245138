
import { Component, Prop, Vue } from 'vue-property-decorator';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import SelectBox from '@/modules/shared/components/input/SelectBox.vue';

@Component({
    components: { SelectBox },
})
export default class InputSelect extends Vue {

    @Prop({})
    public readonly value: any;

    @Prop({ default: 'Label' })
    public readonly label!: string;

    @Prop({ default: [] })
    public readonly options!: SelectBoxOption[];

    @Prop({ default: '' })
    public readonly placeholder!: string;

    @Prop({ default: false })
    public readonly disabled!: boolean|string;

    @Prop({ default: null })
    public readonly errors!: string[]|null;

    @Prop({ default: true })
    public readonly showErrors!: boolean;

    @Prop({ default: '' })
    public readonly hint!: string;

    private hasBeenFocused: boolean = false;

    private get hasErrors(): boolean {
        const errorsVisible = this.showErrors || this.hasBeenFocused;
        return errorsVisible && this.errors !== null && this.errors.length > 0;
    }

    private get errorText(): string {
        if (!this.errors) {
            return '';
        }
        return this.errors.join(', ');
    }

    private onInput(event: any) {
        this.hasBeenFocused = true;
        this.$emit('input', event);
        this.$emit('change', event);
    }
}
