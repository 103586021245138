import type { RouteConfig } from 'vue-router';
import { Role } from '@/modules/shared/types';

const route: RouteConfig = {
    path: '/:customer/portfolios/:portfolioKey',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "dashboard" */ './PortfolioFavoritesView.vue'),
    // component: () => import(/* webpackChunkName: "dashboard" */ './PortfolioView.vue'),
    meta: {
        role: Role.USER,
        license: [],
        authenticated: true,
        navbar: true,
        footer: true,
        settingsTabs: false,
        portfolioTabs: true,
        onboarding: true,
        notifications: true,
    },
};

export { route };
