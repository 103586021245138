
import { Component, Vue } from 'vue-property-decorator';
import BubbleMenu from '@/components/basics/BubbleMenu.vue';
import { DashboardRepresentation, PortfolioRepresentation } from '@/clients/dashboardapi/v2';
import { LicenseFeature } from '@/modules/shared/types';
import { Port } from '@/modules/shared/Port';

interface Dashboard {
    name: string;
    key: string;
}

interface Portfolio {
    name: string;
    key: string;
    dashboards: Dashboard[];
}

@Component({
    components: { BubbleMenu },
})
export default class MenuPortfolios extends Vue {

    private readonly LicenseFeature = LicenseFeature;
    private showBubble: boolean = true;
    private portfolios: PortfolioRepresentation[] = [];
    private dashboards: DashboardRepresentation[] = [];

    public async created(): Promise<void> {
        await this.fetchPortfolios();
        await this.fetchDashboards();
        Port.portfolios.onChange(() => this.fetchPortfolios());
        Port.dashboards.onChange(() => this.fetchDashboards());
    }

    public async fetchPortfolios(): Promise<void> {
        this.portfolios = await Port.portfolios.getPortfolios();
    }

    public async fetchDashboards(): Promise<void> {
        this.dashboards = await Port.dashboards.getDashboards();
    }

    private createReport(dashboardKey: string) {
        this.$emit('report', dashboardKey);
    }

    private close() {
        this.$emit('close');
    }

    private get portfolioItems(): Portfolio[] {
        return this.portfolios.map((p) => ({
            key: p.key,
            name: p.name,
            dashboards: this.dashboards
                .filter((d) => d.portfolioKey === p.key)
                .map((d) => ({
                    key: d.key,
                    name: d.name,
                })),
        }));
    }
}
