/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Aggregation,
    AggregationFromJSON,
    AggregationFromJSONTyped,
    AggregationToJSON,
    AnonRequestFilter,
    AnonRequestFilterFromJSON,
    AnonRequestFilterFromJSONTyped,
    AnonRequestFilterToJSON,
    HistoricMeanSpan,
    HistoricMeanSpanFromJSON,
    HistoricMeanSpanFromJSONTyped,
    HistoricMeanSpanToJSON,
    Resolution,
    ResolutionFromJSON,
    ResolutionFromJSONTyped,
    ResolutionToJSON,
    ResourceType,
    ResourceTypeFromJSON,
    ResourceTypeFromJSONTyped,
    ResourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface DataRequestResource
 */
export interface DataRequestResource {
    /**
     * 
     * @type {string}
     * @memberof DataRequestResource
     */
    metricKey: string;
    /**
     * 
     * @type {string}
     * @memberof DataRequestResource
     */
    name: string;
    /**
     * 
     * @type {Aggregation}
     * @memberof DataRequestResource
     */
    timeAggregation: Aggregation;
    /**
     * 
     * @type {Aggregation}
     * @memberof DataRequestResource
     */
    generatorAggregation: Aggregation;
    /**
     * 
     * @type {number}
     * @memberof DataRequestResource
     */
    dataLimit?: number;
    /**
     * 
     * @type {ResourceType}
     * @memberof DataRequestResource
     */
    resourceType: ResourceType;
    /**
     * 
     * @type {string}
     * @memberof DataRequestResource
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRequestResource
     */
    portfolioKey: string;
    /**
     * 
     * @type {Array<AnonRequestFilter>}
     * @memberof DataRequestResource
     */
    filter?: Array<AnonRequestFilter>;
    /**
     * 
     * @type {number}
     * @memberof DataRequestResource
     */
    timeFrom: number;
    /**
     * 
     * @type {number}
     * @memberof DataRequestResource
     */
    timeTo: number;
    /**
     * 
     * @type {Resolution}
     * @memberof DataRequestResource
     */
    resolution?: Resolution;
    /**
     * 
     * @type {HistoricMeanSpan}
     * @memberof DataRequestResource
     */
    historicMeanSpan?: HistoricMeanSpan;
}

export function DataRequestResourceFromJSON(json: any): DataRequestResource {
    return DataRequestResourceFromJSONTyped(json, false);
}

export function DataRequestResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataRequestResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metricKey': json['metricKey'],
        'name': json['name'],
        'timeAggregation': AggregationFromJSON(json['timeAggregation']),
        'generatorAggregation': AggregationFromJSON(json['generatorAggregation']),
        'dataLimit': !exists(json, 'dataLimit') ? undefined : json['dataLimit'],
        'resourceType': ResourceTypeFromJSON(json['resourceType']),
        'resourceKey': !exists(json, 'resourceKey') ? undefined : json['resourceKey'],
        'portfolioKey': json['portfolioKey'],
        'filter': !exists(json, 'filter') ? undefined : ((json['filter'] as Array<any>).map(AnonRequestFilterFromJSON)),
        'timeFrom': json['timeFrom'],
        'timeTo': json['timeTo'],
        'resolution': !exists(json, 'resolution') ? undefined : ResolutionFromJSON(json['resolution']),
        'historicMeanSpan': !exists(json, 'historicMeanSpan') ? undefined : HistoricMeanSpanFromJSON(json['historicMeanSpan']),
    };
}

export function DataRequestResourceToJSON(value?: DataRequestResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metricKey': value.metricKey,
        'name': value.name,
        'timeAggregation': AggregationToJSON(value.timeAggregation),
        'generatorAggregation': AggregationToJSON(value.generatorAggregation),
        'dataLimit': value.dataLimit,
        'resourceType': ResourceTypeToJSON(value.resourceType),
        'resourceKey': value.resourceKey,
        'portfolioKey': value.portfolioKey,
        'filter': value.filter === undefined ? undefined : ((value.filter as Array<any>).map(AnonRequestFilterToJSON)),
        'timeFrom': value.timeFrom,
        'timeTo': value.timeTo,
        'resolution': ResolutionToJSON(value.resolution),
        'historicMeanSpan': HistoricMeanSpanToJSON(value.historicMeanSpan),
    };
}


