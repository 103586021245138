import type { Customer, CustomerRepository } from '@/modules/ctx-users';
import type { CompaniesApi, CompanyRepresentation } from '@/modules/shared/adapter/CompaniesApi';

export class CustomerRepositoryImpl implements CustomerRepository {

    private readonly companiesApi: CompaniesApi;

    constructor(apis: {
        companiesApi: CompaniesApi;
    }) {
        this.companiesApi = apis.companiesApi;
    }

    public async getCustomer(key: string): Promise<Customer|undefined> {
        const company = await this.companiesApi.getCompany(key);
        if (!company) {
            return undefined;
        }
        return CustomerRepositoryImpl.mapCustomerToDomain(company);
    }

    private static mapCustomerToDomain(company: CompanyRepresentation): Customer {
        return {
            key: company.key,
            name: company.name,
        };
    }
}
