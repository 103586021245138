import type {
    GeneratorRepository,
    DashboardRepository,
    DashboardTemplatesRepository,
    LicenseRepository,
    PortfolioRepository,
    UserRepository,
    WidgetRepository,
} from '@/modules/ctx-dashboard/adapter';
import { WidgetService } from '@/modules/ctx-dashboard/services/WidgetService';
import { PortfolioService } from '@/modules/ctx-dashboard/services/PortfolioService';
import { DashboardService } from '@/modules/ctx-dashboard/services/DashboardService';
import { TemplatesService } from '@/modules/ctx-dashboard/services/TemplatesService';
import { GeneratorService } from '@/modules/ctx-dashboard/services/GeneratorService';
import { UserService } from '@/modules/ctx-dashboard/services/UserService';
import { LicenseService } from '@/modules/ctx-dashboard/services/LicenseService';

export interface PortParams {
    widgetRepository: WidgetRepository;
    portfolioRepository: PortfolioRepository;
    dashboardRepository: DashboardRepository;
    licenseRepository: LicenseRepository;
    dashboardTemplatesRepository: DashboardTemplatesRepository;
    userRepository: UserRepository;
    generatorRepository: GeneratorRepository;
}

export class Port {
    private static port: Port|null = null;

    private readonly widgets: WidgetService;
    private readonly portfolios: PortfolioService;
    private readonly dashboards: DashboardService;
    private readonly templates: TemplatesService;
    private readonly generators: GeneratorService;
    private readonly user: UserService;
    private readonly license: LicenseService;

    constructor(params: PortParams) {
        this.widgets = new WidgetService({
            widgetRepository: params.widgetRepository,
            userRepository: params.userRepository,
            licenseRepository: params.licenseRepository,
        });
        this.portfolios = new PortfolioService({
            portfolioRepository: params.portfolioRepository,
        });
        this.dashboards = new DashboardService({
            dashboardRepository: params.dashboardRepository,
            licenseRepository: params.licenseRepository,
        });
        this.templates = new TemplatesService({
            dashboardTemplateRepository: params.dashboardTemplatesRepository,
            dashboardRepository: params.dashboardRepository,
            userRepository: params.userRepository,
        });
        this.user = new UserService({
            userRepository: params.userRepository,
        });
        this.generators = new GeneratorService({
            generatorRepository: params.generatorRepository,
        });
        this.license = new LicenseService({
            licenseRepository: params.licenseRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get widgets(): WidgetService {
        return Port.instance.widgets;
    }

    public static get portfolios(): PortfolioService {
        return Port.instance.portfolios;
    }

    public static get dashboards(): DashboardService {
        return Port.instance.dashboards;
    }

    public static get templates(): TemplatesService {
        return Port.instance.templates;
    }

    public static get user(): UserService {
        return Port.instance.user;
    }

    public static get generators(): GeneratorService {
        return Port.instance.generators;
    }

    public static get license(): LicenseService {
        return Port.instance.license;
    }
}
