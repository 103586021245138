/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CoverageRepresentation
 */
export interface CoverageRepresentation {
    /**
     * 
     * @type {string}
     * @memberof CoverageRepresentation
     */
    metricKey: string;
    /**
     * 
     * @type {number}
     * @memberof CoverageRepresentation
     */
    coverageFrom: number;
    /**
     * 
     * @type {number}
     * @memberof CoverageRepresentation
     */
    coverageTo: number;
}

export function CoverageRepresentationFromJSON(json: any): CoverageRepresentation {
    return CoverageRepresentationFromJSONTyped(json, false);
}

export function CoverageRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoverageRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metricKey': json['metricKey'],
        'coverageFrom': json['coverageFrom'],
        'coverageTo': json['coverageTo'],
    };
}

export function CoverageRepresentationToJSON(value?: CoverageRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metricKey': value.metricKey,
        'coverageFrom': value.coverageFrom,
        'coverageTo': value.coverageTo,
    };
}


