
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import NotificationService from '@/assets/js/services/NotificationService';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import type { Generator } from '@/store/generators';
import type { Portfolio, Dashboard, WidgetConfig } from '@/modules/ctx-dashboard';
import { Port } from '@/modules/ctx-dashboard/Port';

@Component({
    components: {
        LoadingAnimationSmall,
        BaseDialog,
    },
})
export default class CopyWidgetDlg extends Vue {

    @Prop({ required: true })
    public readonly widget!: WidgetConfig;

    private portfolios: Portfolio[] = [];
    private dashboards: Dashboard[] = [];
    private selectedPortfolio: Portfolio|null = null;
    private selectedDashboard: Dashboard|null = null;
    private loading: boolean = false;
    private sameGenerators: boolean = true;

    // TODO convert to enum
    private step: number = 0;

    public async created(): Promise<void> {
        await this.fetchPortfolios();
        await this.fetchDashboards();
        this.autoSelect();
    }

    private autoSelect(): void {
        // we only got 1 portfolio, auto select it
        if (this.availablePortfolios.length === 1 && this.step === 0) {
            this.selectedPortfolio = this.availablePortfolios[0];
            this.step = 1;
        }
        if (this.availableDashboards.length === 1 && this.step === 1) {
            this.selectedDashboard = this.availableDashboards[0];
            this.step = 2;
        }
    }

    private get availablePortfolios(): Portfolio[] {
        return this.portfolios.filter((portfolio) => this.dashboards.find((dashboard) => dashboard.portfolioKey === portfolio.key));
    }

    private get availableDashboards(): Dashboard[] {
        if (this.selectedPortfolio) {
            return this.dashboards.filter((dashboard) => dashboard.portfolioKey === this.selectedPortfolio?.key);
        }
        return [];
    }

    @Watch('widget.portfolioKey')
    @Watch('selectedPortfolio')
    private async testSameGenerators(): Promise<void> {
        const portfolioAKey = this.widget.portfolioKey;
        const portfolioBKey = this.selectedPortfolio?.key;
        if (portfolioAKey === portfolioBKey || !portfolioBKey) {
            this.sameGenerators = true;
            return;
        }

        const portfolioA = await Port.portfolios.getPortfolio(portfolioAKey);
        const portfolioB = await Port.portfolios.getPortfolio(portfolioBKey);
        // compare generator list
        const generatorsA: string[] = portfolioA.generators.sort();
        const generatorsB: string[] = portfolioB.generators.sort();
        this.sameGenerators = generatorsA.find((key, index) => generatorsB[index] !== key) === undefined;
    }

    private selectPortfolio(porfolio: Portfolio|null) {
        this.selectedPortfolio = porfolio;
        this.step = this.selectedPortfolio !== null ? 1 : 0;
    }

    private selectDashboard(dashboard: Dashboard|null) {
        this.selectedDashboard = dashboard;
        this.step = this.selectedDashboard !== null ? 2 : 1;
    }

    private async fetchPortfolios(): Promise<void> {
        this.portfolios = await Port.portfolios.getPortfolios();
    }

    private async fetchDashboards(): Promise<void> {
        this.dashboards = await Port.dashboards.getDashboards();
    }

    private async submit() {
        if (!this.selectedDashboard?.key
            || !this.widget.dashboardKey
            || !this.widget.key) {
            return;
        }
        this.loading = true;
        try {
            await Port.widgets.copyWidget(this.widget, this.selectedDashboard.key);
            this.close();
        } catch (e: any) {
            NotificationService.serviceError(e);
        }
        this.loading = false;
    }

    private close() {
        this.$emit('close');
    }
}
