import { v4 as generateUUID } from 'uuid';
import { Aggregation, Resolution } from '@/clients/dashboardapi/v2';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';
import { LicenseFeature } from '@/modules/shared/types';

export default class OperatorForecast implements WidgetPreset {

    public readonly name: string = 'operator-forecast';
    public readonly requireLicenseFeatures: LicenseFeature[] = [LicenseFeature.TECHNICAL];
    public readonly type: WidgetType = WidgetType.OperatorForecast;
    public readonly quickAdd: boolean = false;
    public readonly order: number = 0;
    public readonly metrics: string[] = [];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {
                operatorForecastConfig: {
                    budgetModel: '',
                    forecastModel: '',
                    eismanFactorPercent: 0,
                    monetary: false,
                    futureCommissionModelBudget: 'extend',
                    fixedCommissionRateBudget: 0.09,
                    futureCommissionModelForecast: 'extend',
                    fixedCommissionRateForecast: 0.09,
                },
            },
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'this-year-complete',
            intervalTo: new Date(),
            intervalFrom: new Date(),
            resolution: Resolution.Monthly,
            axis: [{
                unit: '',
                name: '',
                config: {},
                metrics: [{
                    uuid: generateUUID().substring(0, 8),
                    metricKey: '',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.None,
                    resources: [],
                }],
            }],
        };
    }
}
