import { v4 as generateUUID } from 'uuid';
import { Aggregation, Resolution, ResourceType } from '@/clients/dashboardapi/v2';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';
import { Port } from '@/modules/shared/Port';
import Utils from '@/assets/js/utils/Utils';
import { LicenseFeature } from '@/modules/shared/types';

export default class DistributionCosts implements WidgetPreset {

    public readonly name: string = 'distribution-costs';
    public readonly requireLicenseFeatures: LicenseFeature[] = [LicenseFeature.ACCOUNTING];
    public readonly type: WidgetType = WidgetType.Distribution;
    public readonly quickAdd: boolean = true;
    public readonly order: number = 60;
    public readonly hidden: boolean = true;
    public readonly metrics: string[] = [
        '3a64ca19-ead7-4cc4-b440-ea4424946865',
        '4c1d22ee-7495-49c7-b084-3ca952870323',
        '911692f3-4725-4701-b58e-f8e32178b38a',
        '73d3e2c1-7d7d-46a2-bedd-1fbf6231b519',
        '4d53956f-3b72-43a6-8174-f642a7d5af76',
        '50c8ff27-83da-4f63-ab0d-da5b6c6d4f8f',
        'cd1916c7-4975-4fd9-8067-312c6cf597c7',
        '77aecc36-d4b4-4703-a98f-c5d8c0cf4730',
    ];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        const resources = [{
            uuid: generateUUID().substring(0, 8),
            seriesName: '',
            resourceName: (await Port.portfolios.getPortfolioByKey(params.portfolio))?.name || '',
            type: ResourceType.Portfolio,
            resourceKey: params.portfolio,
            aggregationOverGenerators: Aggregation.Sum,
            config: {},
        }];
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {},
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'last-year',
            intervalTo: undefined,
            intervalFrom: undefined,
            resolution: Resolution.Automatic,
            axis: [{
                unit: '',
                name: '',
                config: {},
                metrics: this.metrics.map((metricKey) => ({
                    uuid: generateUUID().substring(0, 8),
                    metricKey: metricKey,
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Sum,
                    resources: Utils.deepCopy(resources),
                })),
            }],
        };
    }
}
