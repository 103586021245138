
import { Component, Prop, Vue } from 'vue-property-decorator';
import InputMultiSelect from '@/modules/shared/components/form/InputMultiSelect.vue';
import type { LicenseFeature } from '@/modules/shared/types';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import { Port } from '@/modules/shared/Port';
import type { FeatureRepresentation } from '@/modules/shared/adapter';

@Component({
    components: { InputMultiSelect },
})
export default class LicenseFeatureCmb extends Vue {

    @Prop({ required: true, default: () => [] })
    public readonly value!: LicenseFeature[];

    @Prop({ required: false, default: null })
    public readonly label!: string|null;

    @Prop({ required: false, default: null })
    public readonly placeholder!: string|null;

    @Prop({ required: false, default: null })
    public readonly errors!: string[]|null;

    @Prop({ default: true })
    public readonly showErrors!: boolean;

    private licenseFeatures: FeatureRepresentation[] = [];

    public created(): void {
        this.fetchLicenseFeatures();
    }

    private async fetchLicenseFeatures(): Promise<void> {
        this.licenseFeatures = await Port.license.getFeatures();
    }

    private get licenseFeatureOptions(): SelectBoxOption[] {
        return this.licenseFeatures.map((feature) => ({
            value: feature.key as LicenseFeature,
            displayName: feature.name,
        }));
    }
}
