import type { UserRepository, CustomerRepository } from './adapter';
import { UserService } from './services/UserService';
import { CustomerService } from './services/CustomerService';

export interface PortParams {
    userRepository: UserRepository;
    customerRepository: CustomerRepository;
}

export class Port {
    // services will be instantiated in plugin
    private static port: Port|null = null;

    private readonly userService: UserService;
    private readonly customerService: CustomerService;

    constructor(params: PortParams) {
        this.userService = new UserService({
            userRepository: params.userRepository,
        });
        this.customerService = new CustomerService({
            customerRepository: params.customerRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get users(): UserService {
        return Port.instance.userService;
    }

    public static get customers(): CustomerService {
        return Port.instance.customerService;
    }
}
