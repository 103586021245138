/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimpleDataRequestResource,
    SimpleDataRequestResourceFromJSON,
    SimpleDataRequestResourceFromJSONTyped,
    SimpleDataRequestResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface LatestLogDataRequest
 */
export interface LatestLogDataRequest {
    /**
     * 
     * @type {string}
     * @memberof LatestLogDataRequest
     */
    locale: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LatestLogDataRequest
     */
    generatorStatus?: Array<string>;
    /**
     * 
     * @type {Array<SimpleDataRequestResource>}
     * @memberof LatestLogDataRequest
     */
    resources: Array<SimpleDataRequestResource>;
}

export function LatestLogDataRequestFromJSON(json: any): LatestLogDataRequest {
    return LatestLogDataRequestFromJSONTyped(json, false);
}

export function LatestLogDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LatestLogDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locale': json['locale'],
        'generatorStatus': !exists(json, 'generatorStatus') ? undefined : json['generatorStatus'],
        'resources': ((json['resources'] as Array<any>).map(SimpleDataRequestResourceFromJSON)),
    };
}

export function LatestLogDataRequestToJSON(value?: LatestLogDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locale': value.locale,
        'generatorStatus': value.generatorStatus,
        'resources': ((value.resources as Array<any>).map(SimpleDataRequestResourceToJSON)),
    };
}


