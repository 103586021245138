import { MessageProvider } from './MessageProvider';

export class LocalMessageProvider extends MessageProvider {

    public async loadMessages(lang: string): Promise<void> {
        try {
            const locales = require.context('@', true, /messages.(de|en|es).json$/i);
            locales.keys().forEach((path) => {
                let locale = path;
                locale = locale.substring(0, locale.lastIndexOf('.'));
                locale = locale.substring(locale.lastIndexOf('.') + 1);
                if (locale === lang) {
                    this.appendMessages(lang, locales(path));
                }
            });
        } catch (e: any) {
            console.warn(`Failed to load local messages for language ${lang}`);
        }
    }
}
