/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TemplateVisibility,
    TemplateVisibilityFromJSON,
    TemplateVisibilityFromJSONTyped,
    TemplateVisibilityToJSON,
} from './';

/**
 * 
 * @export
 * @interface TemplateCreateFromWidgetRequest
 */
export interface TemplateCreateFromWidgetRequest {
    /**
     * 
     * @type {string}
     * @memberof TemplateCreateFromWidgetRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCreateFromWidgetRequest
     */
    widgetKey: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCreateFromWidgetRequest
     */
    category: string;
    /**
     * 
     * @type {TemplateVisibility}
     * @memberof TemplateCreateFromWidgetRequest
     */
    visibility?: TemplateVisibility;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateCreateFromWidgetRequest
     */
    requiredLicenseFeatures?: Array<string>;
}

export function TemplateCreateFromWidgetRequestFromJSON(json: any): TemplateCreateFromWidgetRequest {
    return TemplateCreateFromWidgetRequestFromJSONTyped(json, false);
}

export function TemplateCreateFromWidgetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateCreateFromWidgetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'widgetKey': json['widgetKey'],
        'category': json['category'],
        'visibility': !exists(json, 'visibility') ? undefined : TemplateVisibilityFromJSON(json['visibility']),
        'requiredLicenseFeatures': !exists(json, 'requiredLicenseFeatures') ? undefined : json['requiredLicenseFeatures'],
    };
}

export function TemplateCreateFromWidgetRequestToJSON(value?: TemplateCreateFromWidgetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'widgetKey': value.widgetKey,
        'category': value.category,
        'visibility': TemplateVisibilityToJSON(value.visibility),
        'requiredLicenseFeatures': value.requiredLicenseFeatures,
    };
}


