import { v4 as generateUUID } from 'uuid';
import { Aggregation, Resolution, ResourceType } from '@/clients/dashboardapi/v2';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';
import { Port } from '@/modules/shared/Port';

export default class NumberKmSaving implements WidgetPreset {

    public readonly name: string = 'supplied-households';
    public readonly type: WidgetType = WidgetType.Number;
    public readonly quickAdd: boolean = true;
    public readonly order: number = 0;
    public readonly metrics: string[] = ['virt-supplied-households'];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {},
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'this-month-complete',
            intervalTo: undefined,
            intervalFrom: undefined,
            resolution: Resolution.Automatic,
            axis: [{
                unit: '',
                name: '',
                config: {},
                metrics: [{
                    uuid: generateUUID().substring(0, 8),
                    metricKey: 'virt-supplied-households',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Sum,
                    resources: [{
                        uuid: generateUUID().substring(0, 8),
                        seriesName: '',
                        resourceName: (await Port.portfolios.getPortfolioByKey(params.portfolio))?.name || '',
                        type: ResourceType.Portfolio,
                        resourceKey: params.portfolio,
                        aggregationOverGenerators: Aggregation.Sum,
                        config: {},
                    }],
                }],
            }],
        };
    }
}
