/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LossOfProductionMetricVariantUpdateRequest
 */
export interface LossOfProductionMetricVariantUpdateRequest {
    /**
     * Unique uuid key mapping to an i18n entry
     * @type {string}
     * @memberof LossOfProductionMetricVariantUpdateRequest
     */
    name?: string;
    /**
     * List of generator keys for this variant
     * @type {Array<string>}
     * @memberof LossOfProductionMetricVariantUpdateRequest
     */
    generatorKeys?: Array<string>;
    /**
     * List of generator status keys of this variant
     * @type {Array<string>}
     * @memberof LossOfProductionMetricVariantUpdateRequest
     */
    generatorStatusKeys?: Array<string>;
}

export function LossOfProductionMetricVariantUpdateRequestFromJSON(json: any): LossOfProductionMetricVariantUpdateRequest {
    return LossOfProductionMetricVariantUpdateRequestFromJSONTyped(json, false);
}

export function LossOfProductionMetricVariantUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LossOfProductionMetricVariantUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'generatorKeys': !exists(json, 'generatorKeys') ? undefined : json['generatorKeys'],
        'generatorStatusKeys': !exists(json, 'generatorStatusKeys') ? undefined : json['generatorStatusKeys'],
    };
}

export function LossOfProductionMetricVariantUpdateRequestToJSON(value?: LossOfProductionMetricVariantUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'generatorKeys': value.generatorKeys,
        'generatorStatusKeys': value.generatorStatusKeys,
    };
}


