import { Configuration, CompaniesApi as CompaniesRestApi, UsersApi as UsersRestApi } from '@/clients/dashboardapi/v2';
import type { CompanyAdminRepresentation } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type {
    UsersApi,
    UserCreateRequest,
    UserRepresentation,
    UserUpdateRequest,
} from '@/modules/shared/adapter/UsersApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

interface CompanyAdminRepresentationCacheEntry {
    key: string;
    admins: CompanyAdminRepresentation[];
}

export class CachedUsersRestApi implements UsersApi {

    private readonly usersApi: UsersRestApi;
    private readonly companiesApi: CompaniesRestApi;
    private readonly usersCache: IndexedDBRepository<string, UserRepresentation>;
    private readonly adminsCache: IndexedDBRepository<string, CompanyAdminRepresentationCacheEntry>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.usersApi = new UsersRestApi(restApiConfig);
        this.companiesApi = new CompaniesRestApi(restApiConfig);
        this.usersCache = new IndexedDBRepository<string, UserRepresentation>(indexedDb, 'users');
        this.adminsCache = new IndexedDBRepository<string, CompanyAdminRepresentationCacheEntry>(indexedDb, 'admins');
    }

    public async getUser(companyKey: string, userKey: string): Promise<UserRepresentation|undefined> {
        const users = await this.getUsers(companyKey);
        return users.find((it) => it.key === userKey);
    }

    public async getUsers(companyKey: string): Promise<UserRepresentation[]> {
        let users = (await this.usersCache.findAll())
            .filter((user) => user.companyKey === companyKey);
        if (users.length === 0) {
            users = await this.fetchUsers(companyKey);
        }
        return users;
    }

    private fetchUsers(companyKey: string): Promise<UserRepresentation[]> {
        return AsyncDebouncer.debounce<UserRepresentation[]>(`CachedUsersRestRepository.fetchUsers(${companyKey})`, async () => {
            const users = await this.usersApi.getUsers(companyKey);
            await this.usersCache.saveAll(users);
            return users;
        });
    }

    public async createUser(userCreateSpec: UserCreateRequest): Promise<UserRepresentation> {
        const user = await this.usersApi.createUser(userCreateSpec);
        await this.usersCache.save(user);
        return user;
    }

    public async updateUser(userKey: string, userUpdateSpec: UserUpdateRequest): Promise<UserRepresentation> {
        const user = await this.usersApi.updateUser(userKey, userUpdateSpec);
        await this.usersCache.save(user);
        return user;
    }

    public async deleteUser(userKey: string): Promise<void> {
        await this.usersApi.deleteUser(userKey);
        await this.usersCache.removeByKey(userKey);
    }

    public async getAdmins(companyKey: string): Promise<UserRepresentation[]> {
        const users = await this.getUsers(companyKey);
        const admins: CompanyAdminRepresentation[] = await this.adminsCache.findByKey(companyKey).then((cache) => cache?.admins)
            || await this.fetchAdmins(companyKey);
        return users.filter((user) => admins.find((admin) => admin.key === user.key));
    }

    private async fetchAdmins(companyKey: string): Promise<CompanyAdminRepresentation[]> {
        return AsyncDebouncer.debounce<CompanyAdminRepresentation[]>(`CachedUsersRestRepository.fetchAdmins(${companyKey})`, async () => {
            const admins = await this.companiesApi.getCompanyAdmins(companyKey);
            await this.adminsCache.save({ key: companyKey, admins: admins });
            return admins;
        });
    }

    public async createCompanyAdmin(companyKey: string, userKey: string): Promise<void> {
        await this.companiesApi.createCompanyAdmin(companyKey, userKey);
        const cache = await this.adminsCache.findByKey(companyKey);
        if (cache) {
            cache.admins.push({ key: userKey });
            await this.adminsCache.save(cache);
        }
    }

    public async removeCompanyAdmin(companyKey: string, userKey: string): Promise<void> {
        await this.companiesApi.deleteCompanyAdmin(companyKey, userKey);
        const cache = await this.adminsCache.findByKey(companyKey);
        if (cache) {
            cache.admins = cache.admins.filter((it) => it.key !== userKey);
            await this.adminsCache.save(cache);
        }
    }
}
