
import { Component, Vue, Watch } from 'vue-property-decorator';
import Tabs from '@/components/basics/Tabs.vue';
import LicenseGuard from '@/modules/shared/components/util/LicenseGuard.vue';
import { LicenseFeature } from '@/modules/shared/types';
import type { Portfolio } from '@/modules/ctx-dashboard/types';
import { Port } from '@/modules/ctx-dashboard/Port';
import { CreatePortfolioDlg } from '@/modules/ctx-portfolios';

@Component({
    components: { LicenseGuard, CreatePortfolioDlg, Tabs },
})
export default class PortfolioTabs extends Vue {

    private readonly LicenseFeature = LicenseFeature;
    private portfolios: Portfolio[] = [];
    private updating: boolean = false;
    private showCreatePortfolioDlg: boolean = false;

    public mounted(): void {
        this.fetchPortfolios();
        Port.portfolios.onChange(() => this.fetchPortfolios());
    }

    private get activePortfolio(): Portfolio|null {
        const portfolioKey: string|undefined = this.$route.params.portfolioKey;
        return this.portfolios.find((portfolio: Portfolio) => portfolio.key === portfolioKey) || null;
    }

    @Watch('$route.params.portfolioKey')
    private onRouteChanged() {
        this.showCreatePortfolioDlg = false;
    }

    private async fetchPortfolios(): Promise<void> {
        if (!this.updating) {
            this.updating = true;
            this.portfolios = await Port.portfolios.getPortfolios();
            this.updating = false;
        }
    }

    private switchPortfolio(portfolio: Portfolio) {
        if (portfolio && portfolio.key) {
            this.$router.push({ name: 'portfolio', params: { portfolioKey: portfolio.key } });
        } else {
            this.$router.push({ name: 'home' });
        }
    }
}
