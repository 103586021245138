
import { Component, Vue } from 'vue-property-decorator';
import HalvarLogoText from '@/components/icons/logo-text.svg';

@Component({
    components: {
        HalvarLogoText,
    },
})
export default class HalvarFooter extends Vue {

    private get build(): string {
        const time = parseInt(process.env.VUE_APP_BUILD || '0', 10);
        if (time) {
            return this.$formatDate(time, 'dd.MM.yyyy hh:mm');
        }
        return '';
    }

    private get version(): string {
        const packageJson = require('../../../package.json');
        return packageJson.version.replace(/.[0-9]*$/, '');
    }
}
