import { Configuration, DashboardsApi as DashboardsRestApi, TemplatesApi as TemplatesRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { DataChangedEvent } from '@/modules/shared/types';
import type {
    DashboardCreateFromTemplateRequest,
    DashboardCreateRequest,
    DashboardRepresentation,
    DashboardsApi,
    DashboardUpdateRequest,
} from '@/modules/shared/adapter/DashboardsApi';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedDashboardsRestApi implements DashboardsApi {

    private readonly dashboardsApi: DashboardsRestApi;
    private readonly templatesApi: TemplatesRestApi;
    private readonly dashboardsCache: IndexedDBRepository<string, DashboardRepresentation>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.dashboardsApi = new DashboardsRestApi(restApiConfig);
        this.templatesApi = new TemplatesRestApi(restApiConfig);
        this.dashboardsCache = new IndexedDBRepository<string, DashboardRepresentation>(indexedDb, 'dashboards');
    }

    public onChange(cb: (event: DataChangedEvent) => Promise<void>): void {
        this.dashboardsCache.addChangedListener((evt) => cb(evt));
    }

    public async invalidate(): Promise<void> {
        await this.dashboardsCache.removeAll();
        await this.fetchDashboards();
    }

    public async getDashboardByKey(dashboardKey: string): Promise<DashboardRepresentation | undefined> {
        return await this.dashboardsCache.findByKey(dashboardKey) || this.fetchDashboard(dashboardKey);
    }

    public async getDashboardsForPortfolio(portfolioKey: string): Promise<DashboardRepresentation[]> {
        const cachedDashboards = await this.getCachedDashboardsForPortfolio(portfolioKey);
        if (cachedDashboards.length > 0) {
            return cachedDashboards;
        }
        // we don't have any dashboard cached for this portfolio, try fetching dashboards, as the user might have just
        // created the portfolio using a template
        await this.fetchDashboards();
        return this.getCachedDashboardsForPortfolio(portfolioKey);
    }

    private async getCachedDashboardsForPortfolio(portfolioKey: string): Promise<DashboardRepresentation[]> {
        const allDashboards = await this.getDashboards();
        return allDashboards.filter((dashboard) => dashboard.portfolioKey === portfolioKey);
    }

    public async getDashboards(): Promise<DashboardRepresentation[]> {
        return await this.dashboardsCache.findAllIfAnyCached() || this.fetchDashboards();
    }

    public async updateDashboard(dashboardKey: string, dashboardUpdateSpec: DashboardUpdateRequest): Promise<DashboardRepresentation> {
        await this.dashboardsApi.updateDashboard(dashboardKey, dashboardUpdateSpec);
        const updatedDashboard = await this.dashboardsApi.getDashboardByKey(dashboardKey);
        return this.dashboardsCache.save(updatedDashboard);
    }

    public async deleteDashboard(dashboardKey: string): Promise<void> {
        await this.dashboardsApi.deleteDashboard(dashboardKey);
        await this.dashboardsCache.removeByKey(dashboardKey);
    }

    public async createDashboard(createDashboardSpec: DashboardCreateRequest): Promise<DashboardRepresentation> {
        const dashboard = await this.dashboardsApi.createDashboard(createDashboardSpec);
        await this.dashboardsCache.save(dashboard);
        return dashboard;
    }

    public async createDashboardFromTemplate(createDashboardSpec: DashboardCreateFromTemplateRequest, templateKey: string): Promise<DashboardRepresentation> {
        const dashboard = await this.templatesApi.createDashboardFromTemplate(templateKey, createDashboardSpec);
        await this.dashboardsCache.save(dashboard);
        return dashboard;
    }

    private async fetchDashboards(portfolioKey?: string): Promise<DashboardRepresentation[]> {
        return AsyncDebouncer.debounce(`CachedDashboardsRestApi.fetchDashboardsForPortfolio(${portfolioKey})`, async () => {
            const dashboards = await this.dashboardsApi.getDashboards(portfolioKey);
            await this.dashboardsCache.saveAll(dashboards);
            return dashboards;
        });
    }

    private async fetchDashboard(dashboardKey: string): Promise<DashboardRepresentation|undefined> {
        return AsyncDebouncer.debounce(
            `CachedDashboardsRestApi.fetchDashboard(${dashboardKey})`,
            () => this.dashboardsApi.getDashboardByKey(dashboardKey),
        );
    }
}
