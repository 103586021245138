import { i18n } from '@/plugins/i18n';
import { LicenseFeature } from '@/modules/shared/types';
import type {
    TabAxisOptions,
    TabDataOptions,
    TabGeneralOptions,
    TabMetricsOptions,
    WidgetWizardStep,
} from '@/components/widget-wizard';
import { WidgetWizardTab } from '@/components/widget-wizard';
import { TimeCompareUtils } from '@/components/widgets/utils/TimeCompareUtils';
import { ResourceType } from '@/clients/dashboardapi/v2';
import { DataGroupType } from '@/components/widgets/types/DataGroupType';
import { WidgetDataUtils } from '@/components/widgets/utils/WidgetDataUtils';
import { TabTableOptions } from '@/components/widget-wizard/components/tabs/table/TabTableOptions';
import { WidgetUtils } from '../../utils/WidgetUtils';

const metrics: WidgetWizardStep<TabMetricsOptions> = {
    name: 'widget-wizard.tab.metrics',
    tab: WidgetWizardTab.Metrics,
    features: [LicenseFeature.ADVANCED],
    params: (widget) => {
        const params: Partial<TabMetricsOptions> = {
            axisTitles: ['Primäre Y-Achse', 'Sekundäre Y-Achse'],
        };
        // If the user has any axis with more than one metric, we have to show the metric name in the legend.
        // E.g. 'Bad Berleburg - Windgeschwindigkeit (Durchschnitt, Durchschnitt)'
        // Therefore only one datasource is allowed in this case.
        if (WidgetUtils.hasMultipleDistinctResources(widget)) {
            params.maxMetrics = [1, 1];
            params.notice = i18n.t('widget-wizard.tab.metrics.limit').toString();
        } else {
            params.maxMetrics = [10, 1];
        }
        return params;
    },
};

const axis: WidgetWizardStep<TabAxisOptions> = {
    name: 'widget-wizard.tab.axis',
    tab: WidgetWizardTab.Axis,
    features: [LicenseFeature.ADVANCED],
    params: (widgetConfig) => ({
        axisTitles: ['Primäre Y-Achse', 'Sekundäre Y-Achse'],
        fixedChartTypes: ['bar', 'line'],
        enableGlobalScale: widgetConfig.axis.filter((a) => a.metrics.length > 0).length > 1,
    }),
};

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.Time,
};

const resolution: WidgetWizardStep = {
    name: 'widget-wizard.tab.resolution',
    tab: WidgetWizardTab.Resolution,
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: (widget) => {
        const params: Partial<TabDataOptions> = {
            maxDataSourcesPerAxis: [10, 1],
            enableSingleResourceList: false,
            resourceTypes: [
                ResourceType.Portfolio,
                ResourceType.Park,
                ResourceType.Generator,
                ResourceType.Shareholder,
                ResourceType.Generatorgroup,
                ResourceType.Anonymous,
            ],
        };

        // If the user has any axis with more than one metric, we have to show the metric name in the legend.
        // E.g. 'Bad Berleburg - Windgeschwindigkeit (Durchschnitt, Durchschnitt)'
        // Therefore only one datasource is allowed in this case.
        if (WidgetUtils.hasAxisWithMultipleMetrics(widget)) {
            params.maxDataSourcesTotal = 1;
            params.forceSingleResourceList = true;
            params.notices = (params.notices || []).concat([i18n.t('widget-wizard.tab.data.limit').toString()]);
        }
        params.availableTimeOverrides = TimeCompareUtils.getAvailableTimeOverrides(widget);
        return params;
    },
};

const dataAggregation: WidgetWizardStep = {
    name: 'widget-wizard.tab.aggregation-datasources',
    tab: WidgetWizardTab.AggregationDatasources,
    visible: (widget) => WidgetUtils.getDataSourcesNeedingAggregation(widget).length > 0,
};

const table: WidgetWizardStep<TabTableOptions> = {
    name: 'widget-wizard.tab.table',
    tab: WidgetWizardTab.Table,
    params: (widget) => {
        const params: Partial<TabTableOptions> = {
            showTableViewHint: true,
            enableFormatting: true,
            dataGroupingTypes: [
                DataGroupType.NONE,
                DataGroupType.DATASOURCE,
                DataGroupType.METRIC,
            ],
        };
        params.defaultSorting = '';
        params.dataSortingOptions = WidgetDataUtils.getTableSortingOptions(widget);
        return params;
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    metrics,
    axis,
    time,
    resolution,
    data,
    dataAggregation,
    table,
    general,
];
