import { i18n } from '@/plugins/i18n';

export enum NotificationType {
    ERROR = 'ERROR',
    WARN = 'WARN',
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
}

export interface Notification {
    title: string;
    text: string;
    type: NotificationType;
}

export default class NotificationService {

    public static onNotificationAdded: (notification: Notification) => void;
    public static onNotificationRemoved: (notification: Notification) => void;

    public static success(title: string, text: string) {
        this.showNotification(title, text, NotificationType.SUCCESS);
    }

    public static info(title: string, text: string) {
        this.showNotification(title, text, NotificationType.INFO);
    }

    public static warn(title: string, text: string) {
        this.showNotification(title, text, NotificationType.WARN);
    }

    public static error(title: string, error: string|any) {
        console.error(error);
        if (error && error.status) {
            this.showNotification(error.status, title, NotificationType.ERROR);
        } else if (error) {
            this.showNotification(title, error, NotificationType.ERROR);
        } else {
            this.showNotification('Unbekannter Fehler', title, NotificationType.ERROR);
        }
    }

    public static serviceError(error: any) {
        const key = `error.${error?.status}`;
        let message: string = i18n.t(key).toString();
        if (message === key) {
            message = i18n.t('error.undefined').toString();
        }
        this.error(message, error);
    }

    public static showNotification(title: string, text: string, type: NotificationType = NotificationType.INFO) {
        const n: Notification = { title, text, type };
        // setTimeout(() => this.hideNotification(n), 6000);
        this.onNotificationAdded(n);
    }

    public static hideNotification(n: Notification) {
        this.onNotificationRemoved(n);
    }
}
