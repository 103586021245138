import Vue from 'vue';
import type VueRouter from 'vue-router';
import { Module, ModuleOptions } from '@/modules/ctx-admin-accounting';
import { Port } from '@/modules/shared/Port';
import { AccountingRepositoryImpl } from './AccountingRepositoryImpl';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        router: router,
        accountingRepository: new AccountingRepositoryImpl({ accounting: Port.accounting, license: Port.license }),
    });
}
