import { v4 as generateUUID } from 'uuid';
import { Aggregation, Resolution, ResourceType } from '@/clients/dashboardapi/v2';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';
import { Port } from '@/modules/shared/Port';
import { LicenseFeature } from '@/modules/shared/types';

export default class ForecastPower implements WidgetPreset {

    public readonly name: string = 'forecast-power';
    public readonly type: WidgetType = WidgetType.Forecast;
    public readonly quickAdd: boolean = true;
    public readonly order: number = 20;
    public readonly requireLicenseFeatures: LicenseFeature[] = [LicenseFeature.PROGNOSIS];
    public readonly metrics: string[] = ['halvar-leistung', 'halvar-windgeschwindigkeit'];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {},
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: '1W',
            intervalFrom: new Date(),
            intervalTo: new Date(),
            resolution: Resolution.Daily,
            axis: [{
                unit: 'kW',
                name: '',
                config: {
                    type: 'bar',
                },
                metrics: [{
                    uuid: generateUUID().substring(0, 8),
                    metricKey: 'halvar-leistung',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Avg,
                    resources: [{
                        uuid: generateUUID().substring(0, 8),
                        seriesName: '',
                        resourceName: (await Port.portfolios.getPortfolioByKey(params.portfolio))?.name || '',
                        type: ResourceType.Portfolio,
                        resourceKey: params.portfolio,
                        aggregationOverGenerators: Aggregation.Avg,
                        config: {},
                    }],
                }],
            }, {
                unit: 'm/s',
                name: '',
                config: {
                    type: 'line',
                },
                metrics: [{
                    uuid: generateUUID().substring(0, 8),
                    metricKey: 'halvar-windgeschwindigkeit',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Avg,
                    resources: [{
                        uuid: generateUUID().substring(0, 8),
                        seriesName: '',
                        resourceName: (await Port.portfolios.getPortfolioByKey(params.portfolio))?.name || '',
                        type: ResourceType.Portfolio,
                        resourceKey: params.portfolio,
                        aggregationOverGenerators: Aggregation.Avg,
                        config: {},
                    }],
                }],
            }],
        };
    }
}
