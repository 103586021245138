export interface TabAxisOptions {
    axisTitles: string[];
    enableChartType: boolean;
    fixedChartTypes: ('line'|'bar'|'area')[];
    enableGlobalScale: boolean;
}

const defaultTabOptions: TabAxisOptions = {
    axisTitles: ['Y-Achse 1', 'Y-Achse 2'],
    enableChartType: true,
    fixedChartTypes: [],
    enableGlobalScale: false,
};

export { defaultTabOptions };
