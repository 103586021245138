/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserRepresentation
 */
export interface UserRepresentation {
    /**
     * the unique identifier for the user within the company
     * @type {string}
     * @memberof UserRepresentation
     */
    key: string;
    /**
     * the unique username for the user within the company
     * @type {string}
     * @memberof UserRepresentation
     */
    username: string;
    /**
     * email of the user
     * @type {string}
     * @memberof UserRepresentation
     */
    email: string;
    /**
     * the unique identifier for the users company
     * @type {string}
     * @memberof UserRepresentation
     */
    companyKey: string;
    /**
     * optional users first name
     * @type {string}
     * @memberof UserRepresentation
     */
    firstname?: string;
    /**
     * optional users last name
     * @type {string}
     * @memberof UserRepresentation
     */
    lastname?: string;
    /**
     * optional avatar URI
     * @type {string}
     * @memberof UserRepresentation
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    portfolioKey?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    companyName?: string;
}

export function UserRepresentationFromJSON(json: any): UserRepresentation {
    return UserRepresentationFromJSONTyped(json, false);
}

export function UserRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'username': json['username'],
        'email': json['email'],
        'companyKey': json['companyKey'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'portfolioKey': !exists(json, 'portfolioKey') ? undefined : json['portfolioKey'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
    };
}

export function UserRepresentationToJSON(value?: UserRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'username': value.username,
        'email': value.email,
        'companyKey': value.companyKey,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'image': value.image,
        'portfolioKey': value.portfolioKey,
        'companyName': value.companyName,
    };
}


