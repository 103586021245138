/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MetricCategory {
    Accounting = 'accounting',
    Calculated = 'calculated',
    Technical = 'technical',
    Commercial = 'commercial',
    Availability = 'availability',
    Commission = 'commission',
    Prognosis = 'prognosis',
    Plan = 'plan',
    LossOfProduction = 'loss-of-production',
    LossOfRevenue = 'loss-of-revenue'
}

export function MetricCategoryFromJSON(json: any): MetricCategory {
    return MetricCategoryFromJSONTyped(json, false);
}

export function MetricCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricCategory {
    return json as MetricCategory;
}

export function MetricCategoryToJSON(value?: MetricCategory | null): any {
    return value as any;
}

