import type { Portfolio, PortfolioRepository } from '@/modules/ctx-portfolios';
import type { DashboardsApi, PortfolioRepresentation, PortfoliosApi } from '@/modules/shared/adapter';

export class PortfolioRepositoryImpl implements PortfolioRepository {

    private readonly portfoliosApi: PortfoliosApi;
    private readonly dashboardsApi: DashboardsApi;

    constructor(apis: {
        portfolios: PortfoliosApi,
        dashboards: DashboardsApi,
    }) {
        this.portfoliosApi = apis.portfolios;
        this.dashboardsApi = apis.dashboards;
    }

    public onChange(cb: () => Promise<void>): void {
        this.portfoliosApi.onChange(cb);
        this.dashboardsApi.onChange(cb);
    }

    public async getPortfolios(): Promise<Portfolio[]> {
        const portfolios = await this.portfoliosApi.getPortfolios();
        return Promise.all(portfolios.map((p) => this.mapPortfolioToDomain(p)));
    }

    public async createPortfolio(name: string, order: number): Promise<Portfolio> {
        const portfolio = await this.portfoliosApi.createPortfolio({ name: name, order: order });
        return this.mapPortfolioToDomain(portfolio);
    }

    public async createPortfolioFromTemplate(templateKey: string, name: string, order: number): Promise<Portfolio> {
        const portfolio = await this.portfoliosApi.createPortfolioFromTemplate(templateKey, { name: name, order: order });
        return this.mapPortfolioToDomain(portfolio);
    }

    public async getPortfolioByKey(portfolioKey: string): Promise<Portfolio | undefined> {
        const portfolio = await this.portfoliosApi.getPortfolioByKey(portfolioKey);
        if (portfolio) {
            return this.mapPortfolioToDomain(portfolio);
        }
        return undefined;
    }

    public async updatePortfolio(portfolio: Portfolio): Promise<Portfolio> {
        const updatedPortfolio = await this.portfoliosApi.updatePortfolio(portfolio.key, {
            name: portfolio.name,
            order: portfolio.order,
        });
        return this.mapPortfolioToDomain(updatedPortfolio);
    }

    public async deletePortfolio(portfolio: Portfolio): Promise<void> {
        await this.portfoliosApi.deletePortfolioByKey(portfolio.key);
    }

    private async mapPortfolioToDomain(representation: PortfolioRepresentation): Promise<Portfolio> {
        const dashboards = await this.dashboardsApi.getDashboardsForPortfolio(representation.key);
        return {
            key: representation.key,
            order: representation.order,
            created: representation.created,
            updated: representation.updated,
            name: representation.name,
            generators: representation.generatorKeys || [],
            dashboards: dashboards.map((it) => it.key),
        };
    }
}
