import { MetricsRepositoryImpl } from '@/plugins/modules/ctx-admin-data-imports/MetricsRepositoryImpl';
import Vue from 'vue';
import type VueRouter from 'vue-router';
import { Module, ModuleOptions } from '@/modules/ctx-admin-data-imports';
import { Port } from '@/modules/shared/Port';
import { ImporterRepositoryImpl } from './ImporterRepositoryImpl';
import { GeneratorsRepositoryImpl } from './GeneratorsRepositoryImpl';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        router: router,
        importerRepository: new ImporterRepositoryImpl({ imports: Port.imports, generators: Port.generators, parks: Port.parks }),
        generatorsRepository: new GeneratorsRepositoryImpl({ generators: Port.generators, parks: Port.parks }),
        metricsRepository: new MetricsRepositoryImpl({ metricsApi: Port.metrics }),
    });
}
