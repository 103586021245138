
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import LoadingAnimation from '@/modules/shared/components/loading-animation/LoadingAnimation.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';

@Component({
    components: { LoadingAnimationSmall, LoadingAnimation, BaseDialog },
})
export default class InfoDlg extends Vue {

    @Prop({ required: false, default: null })
    public readonly title!: string|null;

    @Prop({ required: false, default: null })
    public readonly message!: string|null;

    @Prop({ required: false, default: null })
    public readonly image!: string|null;

    @Prop({ required: false, default: null })
    public readonly imageComp!: string|null;

    @Prop({ required: false, default: false })
    public readonly loading!: boolean;

    private loadingInternal: boolean = false;

    public created() {
        this.setLoading();
    }

    @Watch('loading')
    private setLoading() {
        this.loadingInternal = this.loading;
    }

    private submit() {
        if (!this.loadingInternal) {
            this.loadingInternal = true;
            this.$emit('submit');
        }
    }

    private close() {
        this.$emit('close');
    }
}
