import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

/**
 * Store module for generic app settings like the last selected preset category.
 */
interface AppState {
    onboardingProgress: number;
}

const moduleState: AppState = {
    onboardingProgress: 0,
};

const moduleGetters: GetterTree<AppState, any> = {
    onboardingProgress(state): number {
        return state.onboardingProgress;
    },
};

const moduleMutations: MutationTree<AppState> = {
    setOnboardingProgress(state, params?: { progress: number }) {
        state.onboardingProgress = params?.progress || 0;
    },
    reset(state: AppState) {
        // nothing to reset
    },
};

const moduleActions: ActionTree<AppState, any> = {
    /**
     * Initialize module
     * @param context
     */
    async init(context): Promise<void> {
        await Promise.allSettled([]);
    },

    /**
     * Reset module state
     * @param context
     */
    async clear(context): Promise<void> {
        context.commit('reset');
    },
};

const module: Module<AppState, any> = {
    namespaced: true,
    state: moduleState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions,
};
export default module;
