import { v4 as generateUUID } from 'uuid';
import { Aggregation, Resolution, ResourceType } from '@/clients/dashboardapi/v2';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';
import { Port } from '@/modules/shared/Port';

export default class Logs implements WidgetPreset {

    public readonly name: string = 'logs';
    public readonly type: WidgetType = WidgetType.Logs;
    public readonly quickAdd: boolean = false;
    public readonly order: number = 10;
    public readonly metrics: string[] = [];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {
                logConfig: {
                    minimum: 5,
                    eventNamesBlacklist: [
                        'Anlage fehlerfrei',
                        'Information',
                        'System OK',
                        'Turbine OK',
                        'Warning',
                        'Warnmeldung',
                        'Warnung ',
                        'Warnung (kritisch)',
                        'Warnung kritisch',
                    ],
                },
            },
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'this-month-complete',
            intervalFrom: new Date(),
            intervalTo: new Date(),
            resolution: Resolution.Automatic,
            axis: [{
                name: '',
                unit: '',
                config: {},
                metrics: [{
                    uuid: generateUUID().substring(0, 8),
                    metricKey: '',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Avg,
                    resources: [{
                        uuid: generateUUID().substring(0, 8),
                        seriesName: '',
                        resourceName: (await Port.portfolios.getPortfolioByKey(params.portfolio))?.name || '',
                        type: ResourceType.Portfolio,
                        resourceKey: params.portfolio,
                        aggregationOverGenerators: Aggregation.Avg,
                        config: {},
                    }],
                }],
            }],
        };
    }
}
