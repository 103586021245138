import DefaultI18nRepository from '@/plugins/modules/default/DefaultI18nRepository';
import { I18nService } from '@/modules/ctx-report-preview/services/I18nService';
import type {
    DashboardRepository,
    GeneratorRepository,
    ReportConfigRepository,
    ReportLayoutGenerator,
    WidgetRepository,
    UserRepository,
} from './adapter';
import { ReportService } from './services/ReportService';
import { GeneratorsService } from './services/GeneratorsService';
import { WidgetService } from './services/WidgetService';
import { EditorService } from './services/EditorService';
import { UserService } from './services/UserService';
import { SimpleReportLayoutGenerator } from './adapter/impl/SimpleReportLayoutGenerator';

export interface PortParams {
    reportConfigRepository: ReportConfigRepository;
    generatorRepository: GeneratorRepository;
    widgetRepository: WidgetRepository;
    dashboardRepository: DashboardRepository;
    reportLayoutGenerator?: ReportLayoutGenerator;
    userRepository: UserRepository;
    i18nRepository: DefaultI18nRepository;
}

export class Port {
    private static port: Port|null = null;

    private readonly reportsService: ReportService;
    private readonly generatorsService: GeneratorsService;
    private readonly widgetService: WidgetService;
    private readonly editorService: EditorService;
    private readonly userService: UserService;
    private readonly i18nService: I18nService;

    constructor(params: PortParams) {
        const layoutGenerator = params.reportLayoutGenerator || new SimpleReportLayoutGenerator({ widgetsRepository: params.widgetRepository });
        this.reportsService = new ReportService({
            reportConfigRepository: params.reportConfigRepository,
            reportLayoutGenerator: layoutGenerator,
        });
        this.generatorsService = new GeneratorsService({
            generatorRepository: params.generatorRepository,
        });
        this.widgetService = new WidgetService({
            reportConfigRepository: params.reportConfigRepository,
            widgetRepository: params.widgetRepository,
        });
        this.editorService = new EditorService();
        this.userService = new UserService({
            userRepository: params.userRepository,
        });
        this.i18nService = new I18nService({
            i18nRepository: params.i18nRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get reports(): ReportService {
        return Port.instance.reportsService;
    }

    public static get generators(): GeneratorsService {
        return Port.instance.generatorsService;
    }

    public static get widgets(): WidgetService {
        return Port.instance.widgetService;
    }

    public static get editor(): EditorService {
        return Port.instance.editorService;
    }

    public static get user(): UserService {
        return Port.instance.userService;
    }

    public static get i18n(): I18nService {
        return Port.instance.i18nService;
    }
}
