import Vue from 'vue';
import VueRouter from 'vue-router';
import type { RouteConfig } from 'vue-router';
import { LicenseFeature, Role } from '@/modules/shared/types';
import { Port } from '@/modules/shared/Port';

Vue.use(VueRouter);

export const routes: RouteConfig[] = [

    /**
     * ---------------------------------------------------------------------------------------------
     * Login / Logout
     * ---------------------------------------------------------------------------------------------
     */
    {
        path: '/',
        alias: '/login',
        name: 'company-select',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            role: undefined,
            license: [],
            authenticated: false,
            navbar: false,
            footer: true,
            settingsTabs: false,
            portfolioTabs: false,
            onboarding: false,
            notifications: false,
        },
    },
    {
        path: '/:customer/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            role: undefined,
            license: [],
            authenticated: false,
            navbar: false,
            footer: true,
            settingsTabs: false,
            portfolioTabs: false,
            onboarding: false,
            notifications: false,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
        meta: {
            role: undefined,
            license: [],
            authenticated: false,
            navbar: false,
            footer: true,
            settingsTabs: false,
            portfolioTabs: false,
            onboarding: false,
            notifications: false,
        },
    },
    {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "error-404" */ '../views/Login.vue'),
        meta: {
            role: undefined,
            license: [],
            authenticated: false,
            navbar: false,
            footer: true,
            settingsTabs: false,
            portfolioTabs: false,
            onboarding: false,
            notifications: false,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
});

router.beforeResolve(async (to, from, next) => {
    // always allow accessing non authenticated pages
    if (!to?.meta?.authenticated) {
        next();
        return;
    }

    // redirect user to login
    if (!Port.auth.isLoggedIn()) {
        const loginSuccessful = await Port.auth.authenticate();
        if (!loginSuccessful) {
            const redirectFrom = encodeURI(to?.fullPath);
            const customer = to?.params?.customer;
            next({ name: 'login', params: { customer: customer }, query: { redirect: redirectFrom } });
            return;
        }
    }

    // update customer name
    if (to?.params?.customer && to?.name) {
        const customerKey = (await Port.auth.getCompany()).key;
        if (to.params.customer !== customerKey) {
            console.warn('[Router] Replacing incorrect customer key in url');
            next({ name: to.name, params: { ...to.params, customer: customerKey }, query: to.query });
            return;
        }
    }
    // admins may visit all routes
    if (await Port.auth.isAdmin()) {
        next();
        return;
    }
    // prevent access to admin pages
    if (to?.meta?.role && to.meta.role === Role.HALVAR_ADMIN) {
        console.warn('[Router] Trying to access a admin route without required role, redirecting to home');
        next({ name: 'home', params: { customer: to.params.customer } });
        return;
    }
    if (to?.meta?.role && to.meta.role === Role.COMPANY_ADMIN && !await Port.auth.isCompanyAdmin()) {
        console.warn('[Router] Trying to access a admin route without required role, redirecting to home');
        next({ name: 'home', params: { customer: to.params.customer } });
        return;
    }
    // prevent access to pages where use is missing a license feature
    if (to?.meta?.license && Array.isArray(to.meta.license)) {
        const requiredLicenseFeatures = to.meta.license as LicenseFeature[];
        const companyLicenseFeatures = await Port.auth.getLicenseFeatures();
        if (!requiredLicenseFeatures.every((it) => companyLicenseFeatures.includes(it))) {
            if (to.meta.missingLicenseRedirect) {
                console.warn('[Router] Trying to access a route without required license features, redirecting to fallback');
                next({ name: to.meta.missingLicenseRedirect, params: to.params, query: to.query, replace: true });
            } else {
                console.warn('[Router] Trying to access a route without required license features, redirecting to home');
                next({ name: 'home', params: { customer: to.params.customer } });
            }
            return;
        }
    }
    next();
});

export default router;
