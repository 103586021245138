import type { LicenseFeature } from '@/modules/ctx-admin-customers/types';
import type { LicenseRepository } from '@/modules/ctx-admin-customers/adapter';

export class LicenseService {

    private readonly licenseRepository: LicenseRepository;

    constructor(params: { licenseRepository: LicenseRepository }) {
        this.licenseRepository = params.licenseRepository;
    }

    public async getLicenseFeatures(): Promise<LicenseFeature[]> {
        return this.licenseRepository.getLicenseFeatures();
    }
}
