import type { RouteConfig } from 'vue-router';
import { Role } from '@/modules/shared/types';

const route: RouteConfig = {
    path: '/:customer/customer/:customerKey/users',
    name: 'admin-customer-users',
    component: () => import(/* webpackChunkName: "ctx-admin-customers" */ './CustomerUsersView.vue'),
    meta: {
        role: Role.HALVAR_ADMIN,
        license: [],
        authenticated: true,
        navbar: true,
        footer: true,
        settingsTabs: false,
        portfolioTabs: false,
        onboarding: true,
        notifications: true,
    },
};

export { route };
