import { Configuration, ConfigurationsApi as ConfigurationsRestApi } from '@/clients/dashboardapi/v2';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type { ConfigApi, GeneratorFilterTypeRepresentation } from '@/modules/shared/adapter/ConfigApi';
import { AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class ConfigRestApi implements ConfigApi {

    private readonly configApi: ConfigurationsRestApi;

    constructor(apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.configApi = new ConfigurationsRestApi(restApiConfig);
    }

    public async getGeneratorFilterTypes(): Promise<GeneratorFilterTypeRepresentation[]> {
        return this.configApi.getGeneratorFilterTypes();
    }
}
