
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import Validator, { Rules } from '@/assets/js/utils/Validator';
import InputText from '@/modules/shared/components/form/InputText.vue';
import InputSelect from '@/modules/shared/components/form/InputSelect.vue';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import PortfolioGenerators from '@/components/portfolios/PortfolioGenerators.vue';
import Tabs from '@/components/basics/Tabs.vue';
import type { User } from '@/modules/ctx-users';
import { Port } from '@/modules/ctx-users/Port';

@Component({
    components: {
        Tabs,
        PortfolioGenerators,
        LoadingAnimationSmall,
        InputSelect,
        InputText,
        BaseDialog,
    },
})
export default class UserDetailsDlg extends Vue {

    @Prop({ default: null })
    public readonly user!: User|null;

    @Prop({ required: true })
    public readonly companyKey!: string;

    @Prop({ default: false })
    public readonly onlyAdmins!: boolean|string|null;

    @Ref('form')
    private readonly form!: HTMLElement;

    private readonly roles: SelectBoxOption[] = [
        { displayName: 'roles.user', value: 'user' },
        { displayName: 'roles.admin', value: 'admin' },
    ];

    private userEdit: User = {} as User;

    private saving: boolean = false;
    private validationErrors: {[key: string]: string[]} = {};

    public created(): void {
        this.userEdit = this.createEditCopy();
    }

    public mounted(): void {
        const firstInput: HTMLElement|null = this.form.querySelector('input');
        if (firstInput) {
            firstInput.focus();
        }
    }

    private createEditCopy(): User {
        const defaultRole = this.onlyAdmins !== false ? 'admin' : 'user';
        return {
            key: this.user?.key || '',
            companyKey: this.companyKey,
            email: this.user?.email || '',
            username: this.user?.username || '',
            lastname: this.user?.lastname || '',
            firstname: this.user?.firstname || '',
            role: this.user?.role || defaultRole,
            companyName: this.user?.companyName || '',
            portfolioKey: this.user?.portfolioKey || '',
            password: Port.users.generateRandomPassword(),
        };
    }

    private close() {
        this.$emit('close');
    }

    @Watch('userEdit', { deep: true })
    private async validate(): Promise<void> {
        this.validationErrors = await Port.users.validateUser(this.userEdit, this.user || undefined);
    }

    private get isValid(): boolean {
        return Object.keys(this.validationErrors).length === 0;
    }

    private setRandomPassword() {
        Vue.set(this.userEdit, 'password', Port.users.generateRandomPassword());
    }

    private async submit() {
        this.saving = true;
        try {
            if (this.userEdit.key) {
                await Port.users.updateUser(this.userEdit);
                if (this.user?.role === 'user' && this.userEdit.role === 'admin') {
                    await Port.users.promoteToAdmin(this.userEdit);
                }
                this.close();
            } else {
                await Port.users.createUser(this.userEdit);
                this.close();
            }
        } catch (e) {
            this.saving = false;
        }
    }
}
