// Vue Dependencies
import type { PluginObject } from 'vue';
import Vue from 'vue';
import type { RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';

// Module Dependencies
import { Port, PortParams } from './Port';
import { route as RouteCustomers } from './views/customers/Route';
import { route as RouteCustomerPortfolio } from './views/customer-generators/Route';
import { route as RouteCustomerUsers } from './views/customer-users/Route';
import { route as RouteCustomerUserGenerators } from './views/customer-user-generators/Route';

export interface Options extends PortParams {
    router: VueRouter;
    routes?: Partial<RouteConfig>[];
}

const plugin: PluginObject<Options> = {
    install(vue: typeof Vue, params?: Options) {
        if (!params) {
            console.error('Missing plugin params!');
            return;
        }
        Port.init({
            customerRepository: params.customerRepository,
            licenseRepository: params.licenseRepository,
            userRepository: params.userRepository,
        });

        // register route
        const routes = [
            RouteCustomers,
            RouteCustomerPortfolio,
            RouteCustomerUsers,
            RouteCustomerUserGenerators,
        ];
        routes.forEach((route) => {
            const routeOverride = (params.routes || []).find((r) => r.name === route.name);
            const mergedRoute = Object.assign(route, routeOverride);
            params.router.addRoute(mergedRoute);
        });
    },
};

export { plugin };
