/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Resolution {
    Automatic = 'automatic',
    Min10 = 'min10',
    Daily = 'daily',
    Monthly = 'monthly'
}

export function ResolutionFromJSON(json: any): Resolution {
    return ResolutionFromJSONTyped(json, false);
}

export function ResolutionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resolution {
    return json as Resolution;
}

export function ResolutionToJSON(value?: Resolution | null): any {
    return value as any;
}

