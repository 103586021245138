export interface TabMetricsOptions {
    enableMixedUnits: boolean;
    axisTitles: string[];
    minMetrics: number|number[],
    maxMetrics: number|number[];
    notice?: string;
}

const defaultTabOptions: TabMetricsOptions = {
    enableMixedUnits: false,
    axisTitles: ['Y-Achse 1', 'Y-Achse 2'],
    minMetrics: 1,
    maxMetrics: 100,
};

export { defaultTabOptions };
