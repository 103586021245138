/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioTemplateRepresentation
 */
export interface PortfolioTemplateRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PortfolioTemplateRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTemplateRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTemplateRepresentation
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioTemplateRepresentation
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioTemplateRepresentation
     */
    updated: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTemplateRepresentation
     */
    companyKey?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PortfolioTemplateRepresentation
     */
    generatorKeys?: Array<string>;
}

export function PortfolioTemplateRepresentationFromJSON(json: any): PortfolioTemplateRepresentation {
    return PortfolioTemplateRepresentationFromJSONTyped(json, false);
}

export function PortfolioTemplateRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioTemplateRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'created': json['created'],
        'updated': json['updated'],
        'companyKey': !exists(json, 'companyKey') ? undefined : json['companyKey'],
        'generatorKeys': !exists(json, 'generatorKeys') ? undefined : json['generatorKeys'],
    };
}

export function PortfolioTemplateRepresentationToJSON(value?: PortfolioTemplateRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'description': value.description,
        'created': value.created,
        'updated': value.updated,
        'companyKey': value.companyKey,
        'generatorKeys': value.generatorKeys,
    };
}


