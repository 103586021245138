/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReImportType,
    ReImportTypeFromJSON,
    ReImportTypeFromJSONTyped,
    ReImportTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReImportRequest
 */
export interface ReImportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReImportRequest
     */
    timeFrom: number;
    /**
     * 
     * @type {number}
     * @memberof ReImportRequest
     */
    timeTo: number;
    /**
     * 
     * @type {string}
     * @memberof ReImportRequest
     */
    generatorKey: string;
    /**
     * 
     * @type {ReImportType}
     * @memberof ReImportRequest
     */
    type: ReImportType;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReImportRequest
     */
    metricKeys?: Array<string>;
}

export function ReImportRequestFromJSON(json: any): ReImportRequest {
    return ReImportRequestFromJSONTyped(json, false);
}

export function ReImportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReImportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeFrom': json['timeFrom'],
        'timeTo': json['timeTo'],
        'generatorKey': json['generatorKey'],
        'type': ReImportTypeFromJSON(json['type']),
        'metricKeys': !exists(json, 'metricKeys') ? undefined : json['metricKeys'],
    };
}

export function ReImportRequestToJSON(value?: ReImportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeFrom': value.timeFrom,
        'timeTo': value.timeTo,
        'generatorKey': value.generatorKey,
        'type': ReImportTypeToJSON(value.type),
        'metricKeys': value.metricKeys,
    };
}


