
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GenericTable, { TableColumn } from '@/modules/shared/components/table/GenericTable.vue';
import ConfirmDlg from '@/modules/shared/components/dialogs/generic/ConfirmDlg.vue';
import LoadingAnimation from '@/modules/shared/components/loading-animation/LoadingAnimation.vue';
import UserDetailsDlg from '@/modules/ctx-users/components/UserDetailsDlg.vue';
import BubbleMenu from '@/components/basics/BubbleMenu.vue';
import Permission from '@/assets/js/services/enums/Permission';
import type { User } from '@/modules/ctx-users/types';
import { Port } from '@/modules/ctx-users/Port';
import { Events } from '@/modules/shared';

@Component({
    components: {
        BubbleMenu,
        UserDetailsDlg,
        LoadingAnimation,
        ConfirmDlg,
        GenericTable,
    },
})
export default class Users extends Vue {

    @Prop({ default: null })
    public readonly title!: string;

    @Prop({ default: null })
    public readonly customerKey!: string;

    @Prop({ default: false })
    public readonly onlyAdmins!: boolean|string|null;

    private users: User[]|null = null;
    private updating: boolean = false;
    private selectedUsers: User[] = [];
    private usersToDelete: User[] = [];
    private userEdit: User|null = null;

    private showDeleteUsersDlg: boolean = false;
    private showEditUsersDlg: boolean = false;
    private showUserDropdown: boolean = false;
    private dropdownAnchor: HTMLElement|null = null;

    public created(): void {
        this.fetchUsers();
        Events.instance.on('users--changed', () => this.fetchUsers());
        Events.instance.on('admins--changed', () => this.fetchUsers());
    }

    private get tableColumns(): TableColumn[] {
        const columns = [];
        columns.push({ title: 'name', field: 'firstname', placeholder: '???', class: 'max-w-px w-full md:w-1/2 md:pl-8' });
        if (this.customerKey !== null) {
            columns.push({ title: 'role', field: 'role', class: 'hidden md:table-cell w-1/3', render: (value: any) => this.$t(`roles.${value}`).toString() });
        } else {
            columns.push({ title: 'company', field: 'companyKey', class: 'hidden md:table-cell w-1/3' });
        }
        columns.push({ title: 'email', field: 'email', class: 'hidden xl:table-cell w-1/3' });
        columns.push({ title: 'actions', field: 'actions', class: 'w-64', sortable: false });
        return columns;
    }

    private get enableUserPortfolio(): boolean {
        return this.userEdit?.role === 'user'
            && this.$store.getters['User/hasPermission'](Permission.USERS_WRITE);
    }

    private showDropdownForUser(user: User) {
        this.userEdit = user;
        this.dropdownAnchor = this.$refs[`buttonDropdown${user.key}`] as HTMLElement;
        this.showUserDropdown = true;
    }

    private updateSelected(selectedUsers: User[]) {
        this.selectedUsers = selectedUsers;
    }

    private openPortfolio(user: User) {
        this.userEdit = user;
        this.showUserDropdown = false;
        this.$emit('edit-generators', user.key);
    }

    private async editUser(user: User) {
        this.userEdit = user;
        this.showEditUsersDlg = true;
        this.showUserDropdown = false;
    }

    private async createUser() {
        this.userEdit = null;
        this.showEditUsersDlg = true;
    }

    private deleteUsers(users: User[]) {
        this.usersToDelete = users;
        this.showDeleteUsersDlg = true;
        this.showUserDropdown = false;
    }

    private async deleteSelectedUsers() {
        await Port.users.deleteUsers(this.usersToDelete);
        this.usersToDelete = [];
        this.showDeleteUsersDlg = false;
    }

    @Watch('customerKey')
    private async fetchUsers(): Promise<void> {
        if (!this.updating) {
            this.updating = true;
            this.users = await Port.users.getUsersForCompany(this.customerKey, this.onlyAdmins !== false);
            this.updating = false;
        }
    }
}
