/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCreateRequest
 */
export interface ReportCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportCreateRequest
     */
    reportConfigKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreateRequest
     */
    storeInDocuments?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportCreateRequest
     */
    referenceDate?: number;
    /**
     * The ISO 639 code for the language of the report
     * @type {string}
     * @memberof ReportCreateRequest
     */
    language?: string;
}

export function ReportCreateRequestFromJSON(json: any): ReportCreateRequest {
    return ReportCreateRequestFromJSONTyped(json, false);
}

export function ReportCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportConfigKey': json['reportConfigKey'],
        'storeInDocuments': !exists(json, 'storeInDocuments') ? undefined : json['storeInDocuments'],
        'referenceDate': !exists(json, 'referenceDate') ? undefined : json['referenceDate'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function ReportCreateRequestToJSON(value?: ReportCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportConfigKey': value.reportConfigKey,
        'storeInDocuments': value.storeInDocuments,
        'referenceDate': value.referenceDate,
        'language': value.language,
    };
}


