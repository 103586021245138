/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneratorRepresentation
 */
export interface GeneratorRepresentation {
    /**
     * 
     * @type {string}
     * @memberof GeneratorRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratorRepresentation
     */
    parkKey?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratorRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratorRepresentation
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratorRepresentation
     */
    serialNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneratorRepresentation
     */
    power?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorRepresentation
     */
    hubHeight?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneratorRepresentation
     */
    manufacturer?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneratorRepresentation
     */
    installationDate?: number;
    /**
     * 
     * @type {object}
     * @memberof GeneratorRepresentation
     */
    type?: GeneratorRepresentationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GeneratorRepresentation
     */
    typeName?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneratorRepresentation
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorRepresentation
     */
    latitude?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratorRepresentation
     */
    active?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum GeneratorRepresentationTypeEnum {
    Wind = 'wind',
    Solar = 'solar'
}

export function GeneratorRepresentationFromJSON(json: any): GeneratorRepresentation {
    return GeneratorRepresentationFromJSONTyped(json, false);
}

export function GeneratorRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'parkKey': !exists(json, 'parkKey') ? undefined : json['parkKey'],
        'name': json['name'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'power': !exists(json, 'power') ? undefined : json['power'],
        'hubHeight': !exists(json, 'hubHeight') ? undefined : json['hubHeight'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'installationDate': !exists(json, 'installationDate') ? undefined : json['installationDate'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function GeneratorRepresentationToJSON(value?: GeneratorRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'parkKey': value.parkKey,
        'name': value.name,
        'model': value.model,
        'serialNumber': value.serialNumber,
        'power': value.power,
        'hubHeight': value.hubHeight,
        'manufacturer': value.manufacturer,
        'installationDate': value.installationDate,
        'type': value.type,
        'typeName': value.typeName,
        'longitude': value.longitude,
        'latitude': value.latitude,
        'active': value.active,
    };
}


