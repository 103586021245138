/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyUpdateRequest
 */
export interface CompanyUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateRequest
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateRequest
     */
    heroImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateRequest
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateRequest
     */
    license?: string;
}

export function CompanyUpdateRequestFromJSON(json: any): CompanyUpdateRequest {
    return CompanyUpdateRequestFromJSONTyped(json, false);
}

export function CompanyUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'heroImage': !exists(json, 'heroImage') ? undefined : json['heroImage'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'license': !exists(json, 'license') ? undefined : json['license'],
    };
}

export function CompanyUpdateRequestToJSON(value?: CompanyUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'logo': value.logo,
        'heroImage': value.heroImage,
        'color': value.color,
        'license': value.license,
    };
}


