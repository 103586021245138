import type { DashboardTemplate, LocalizedLicenseFeature } from '@/modules/ctx-dashboard/types';
import type { DashboardRepository, DashboardTemplatesRepository, UserRepository } from '@/modules/ctx-dashboard/adapter';
import { i18n } from '@/plugins/i18n';
import { LicenseFeature, Role } from '@/modules/shared/types';

export class TemplatesService {

    private readonly dashboardRepository: DashboardRepository;
    private readonly dashboardTemplateRepository: DashboardTemplatesRepository;
    private readonly userRepository: UserRepository;

    constructor(params: {
        dashboardRepository: DashboardRepository,
        dashboardTemplateRepository: DashboardTemplatesRepository,
        userRepository: UserRepository,
    }) {
        this.dashboardRepository = params.dashboardRepository;
        this.dashboardTemplateRepository = params.dashboardTemplateRepository;
        this.userRepository = params.userRepository;
    }

    public onTemplatesChange(cb: () => Promise<void>): void {
        this.dashboardTemplateRepository.onTemplatesChange(cb);
    }

    public async getEditableDashboardTemplates(): Promise<DashboardTemplate[]> {
        const templates = await this.dashboardTemplateRepository.getDashboardTemplates();
        // halvar admins can create and edit global templates
        // every other user can only create private templates
        const role = await this.userRepository.getUserRole();
        if (role === Role.HALVAR_ADMIN) {
            return templates.sort((a, b) => {
                if (a.global && !b.global) return -1;
                if (!a.global && b.global) return 1;
                return a.name.localeCompare(b.name);
            });
        }
        return templates.filter((template: DashboardTemplate) => !template.global);
    }

    public async getDashboardTemplates(): Promise<DashboardTemplate[]> {
        const templates = await this.dashboardTemplateRepository.getDashboardTemplates();
        // users without advanced license can only create dashboards from templates
        const userLicenseFeatures = await this.userRepository.getUserLicenseFeatures();
        if (!userLicenseFeatures.includes(LicenseFeature.ADVANCED)) {
            return templates;
        }
        const noTemplate: DashboardTemplate = this.emptyTemplate();
        noTemplate.name = i18n.t('ctx-dashboard.create-dashboard.no-template').toString();
        noTemplate.description = i18n.t('ctx-dashboard.create-dashboard.no-template.desc').toString();
        return [noTemplate].concat(templates);
    }

    public emptyTemplate(): DashboardTemplate {
        return {
            key: '',
            name: '',
            description: '',
            widgetsCount: 0,
            global: false,
            requiredLicenseFeatures: [],
            createdBy: '',
            isOwner: true,
            created: 0,
        };
    }

    public async createDashboardTemplate(dashboardKey: string, template: DashboardTemplate): Promise<DashboardTemplate> {
        return this.dashboardTemplateRepository.createTemplateFromDashboard(dashboardKey, template);
    }

    public async updateDashboardTemplate(dashboardTemplate: DashboardTemplate): Promise<DashboardTemplate> {
        return this.dashboardTemplateRepository.updateDashboardTemplate(dashboardTemplate);
    }

    public async deleteDashboardTemplate(dashboardTemplate: DashboardTemplate): Promise<void> {
        await this.dashboardTemplateRepository.deleteDashboardTemplate(dashboardTemplate);
    }

    public async validateDashboardTemplate(dashboard: Partial<DashboardTemplate>): Promise<{[key: string]: string[]}> {
        const errors: {[key: string]: string[]} = {};
        if (!dashboard.name || dashboard.name.trim().length === 0) {
            errors.name = [i18n.t('validation.not-null').toString()];
        }
        const dashboards = await this.getDashboardTemplates();
        if (dashboards.find((it) => it.key !== dashboard.key && it.name === dashboard.name)) {
            errors.name = [i18n.t('dialogs.dashboard.create.conflict', { name: dashboard.name }).toString()];
        }
        return errors;
    }
}
