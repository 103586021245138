type EventListener = (params: any) => void;

export class Events {
    private static inst: Events = new Events();
    private listeners: Map<string, EventListener[]> = new Map();

    public static get instance(): Events {
        return Events.inst;
    }

    public on<T>(eventName: string, callback: (params: T) => void): void {
        const listeners = this.listeners.get(eventName) || [];
        listeners.push(callback);
        this.listeners.set(eventName, listeners);
    }

    public publishEvent(eventName: string, params?: any): void {
        const listeners = this.listeners.get(eventName) || [];
        listeners.forEach((fn) => fn(params));
    }
}
