
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import Checkbox from '@/modules/shared/components/input/Checkbox.vue';
import IntersectionObserver from '@/modules/shared/components/util/IntersectionObserver.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import Utils from '@/assets/js/utils/Utils';
import { TableUtils } from '@/assets/js/utils/TableUtils';

export interface TableColumn {
    title: string;
    field?: string;
    placeholder?: string|undefined;
    render?: (value: any, row: any) => string;
    renderHTML?: (value: any, row: any, rowIndex: number, colIndex: number) => string;
    sortable?: boolean;
    alignment?: 'right'|'left';
    class?: string;
}

@Component({
    components: { LoadingAnimationSmall, IntersectionObserver, Checkbox, draggable },
})
export default class GenericTable<T = any> extends Vue {

    @Prop({ default: () => ([]) })
    public readonly data!: T[];

    @Prop({ default: null })
    public readonly columns!: TableColumn[];

    @Prop({ default: false })
    public readonly multiselect!: boolean|string|undefined;

    @Prop({ default: null })
    public readonly maxSelectable!: number|null;

    @Prop({ default: false })
    public readonly sortable!: boolean|string|undefined;

    @Prop({ default: false })
    public readonly interactive!: boolean|string|undefined;

    @Prop({ default: false })
    public readonly aggregationRow!: boolean|string|undefined;

    @Prop({ default: false })
    public readonly noheader!: boolean|string|undefined;

    @Prop({ default: null })
    public readonly noDataMessage!: string|null;

    @Prop({ default: false })
    public readonly loading!: boolean|string|undefined;

    @Prop({ default: null })
    public readonly loadingMessage!: string|null;

    @Prop({ required: false, default: () => [] })
    public readonly selected!: any[]|undefined;

    @Prop({ required: false, default: () => [] })
    public readonly disabled!: T[];

    @Prop({ default: null })
    public readonly lazyRender!: number|null;

    @Prop({ default: false })
    public readonly paged!: boolean|string|undefined;

    @Prop({ default: true })
    public readonly selectRowOnClick!: boolean;

    @Prop({ default: false })
    public readonly pagedExt!: boolean|string|undefined;

    @Prop({ default: '' })
    public readonly defaultSortBy!: string;

    @Prop({ default: 'asc' })
    public readonly defaultSortDirection!: 'asc' | 'desc';

    private readonly isSafari: boolean = Utils.isSafari();

    private limit = 10000;

    private selectedRows: T[] = [];

    private selectAllCheckboxes: boolean = false;
    private sortBy: string = '';
    private sortDirection: number = 1;

    public created(): void {
        this.limit = this.lazyRender || 10000;
        this.sortDirection = this.defaultSortDirection === 'asc' ? 1 : -1;
        if (this.defaultSortBy) {
            this.sortBy = this.defaultSortBy;
        } else if (this.defaultSortDirection !== 'asc') {
            this.sortBy = this.columns[0].field || '0';
        }
    }

    public mounted(): void {
        this.onSelectedChanged();
        this.onDataChanged();
    }

    public get showLoadingMessage(): boolean {
        const overlayEnabled = this.loadingMessage !== null || this.$slots.loading !== undefined;
        return this.loading !== false && (!this.data || this.data.length === 0) && overlayEnabled;
    }

    private get dataPaged(): any[] {
        if (this.paged) {
            return this.dataSorted.slice(0, this.limit);
        }
        return this.dataSorted;
    }

    private renderNextRows(limit: number) {
        this.limit += this.lazyRender || 50;
        this.limit = Math.max(limit, this.limit);
    }

    @Watch('selected')
    private onSelectedChanged() {
        this.selectedRows = this.selected || [];
        this.selectAllCheckboxes = this.selectedRows?.length === this.data?.length;
    }

    @Watch('data')
    private onDataChanged(): void {
        if (this.loading) {
            return;
        }
        this.selectedRows = this.selectedRows.filter((row) => this.data.includes(row));
        this.$emit('selectionChanged', this.selectedRows);
        this.$emit('update:selected', this.selectedRows);
    }

    @Watch('columns')
    public resetSorting(): void {
        if (this.defaultSortBy) {
            this.sortBy = this.defaultSortBy;
            this.sortDirection = this.defaultSortDirection === 'asc' ? 1 : -1;
        } else {
            this.sortBy = '';
            this.sortDirection = 1;
        }
    }

    private get dataSorted(): T[] {
        const data = [...this.data || []];
        if (!this.columns) {
            data.splice(0, 1);
        }
        let aggregationRow: T|undefined;
        if (this.hasAggregationRow) {
            aggregationRow = data.pop();
        }
        const dataSorted = TableUtils.sortDataBy(data, this.sortBy, this.sortDirection);
        if (aggregationRow) {
            dataSorted.push(aggregationRow);
        }
        return dataSorted;
    }

    private get hasMultiselect(): boolean {
        return this.multiselect === true || this.multiselect === '' || this.multiselect === 'true';
    }

    private get isSortable(): boolean {
        return this.sortable === true || this.sortable === '' || this.sortable === 'true';
    }

    private get isInteractive(): boolean {
        return this.interactive === true || this.interactive === '' || this.interactive === 'true';
    }

    private get hasAggregationRow(): boolean {
        return this.aggregationRow === true || this.aggregationRow === '' || this.aggregationRow === 'true';
    }

    private classForRow(row: any): string {
        const classes: string[] = [];
        if (this.hasMultiselect || this.isInteractive) {
            classes.push('cursor-pointer');
        }
        if (row.selected) {
            classes.push('selected');
        }
        if (row.class && typeof row.class === 'string') {
            classes.push(row.class);
        }
        return classes.join(' ');
    }

    private get tableColumns(): TableColumn[] {
        if (this.columns) {
            return this.columns;
        }
        if (this.data && this.data[0] && Array.isArray(this.data[0])) {
            const dataArray = this.data[0] as unknown as any[];
            return dataArray.map((title: string, index: number) => ({
                title: title,
                field: index.toString(),
                alignment: index === 0 ? 'left' : 'right',
            } as TableColumn));
        }
        return [];
    }

    private renderValue(row: any, col: TableColumn, rowIndex: number, colIndex: number): string {
        const value = this.getValue(row, col.field);
        if (value !== undefined) {
            if (col.renderHTML) {
                return col.renderHTML(value, row, rowIndex, colIndex);
            }
            if (col.render) {
                return col.render(value, row);
            }
            return value;
        }
        return col.placeholder || '';
    }

    private onOrderChanged(param: any) {
        this.$emit('reorder', param);
    }

    private sort(sortby: TableColumn) {
        if (sortby.field !== undefined && sortby.sortable !== false) {
            if (this.sortBy === sortby.field) {
                this.sortDirection *= -1;
            } else {
                this.sortBy = sortby.field;
                this.sortDirection = 1;
            }
        }
    }

    private getValue(object: any, key: string|string[]|undefined): any {
        return TableUtils.getValue(object, key);
    }

    private rowClicked(row: T): void {
        this.$emit('click', row);
        if (this.hasMultiselect && this.selectRowOnClick) {
            this.toggleRowSelection(row);
        }
    }

    private toggleRowSelection(row: T): void {
        this.$forceUpdate();
        if (this.isDisabled(row)) {
            return;
        }
        if (this.isSelected(row)) {
            this.selectedRows = this.selectedRows.filter((r) => r !== row);
        } else if (this.maxSelectable === 1) {
            this.selectedRows.push(row);
            if (this.selectedRows.length > this.maxSelectable) {
                this.selectedRows.shift();
            }
        } else if (!this.maxSelectable || this.selectedRows.length < this.maxSelectable) {
            this.selectedRows.push(row);
        }
        const firstUnselected = this.data.find((dataRow) => !this.selectedRows.find((selectedRow) => selectedRow === dataRow));
        this.selectAllCheckboxes = firstUnselected === undefined;
        this.$emit('selectionChanged', this.selectedRows);
        this.$emit('update:selected', this.selectedRows);
    }

    private toggleSelectAll() {
        this.selectAllCheckboxes = !this.selectAllCheckboxes;
        if (this.selectAllCheckboxes) {
            if (this.maxSelectable && this.maxSelectable < this.data.length) {
                this.selectedRows = this.data
                    .filter((t) => !this.isDisabled(t))
                    .slice(0, this.maxSelectable);
            } else {
                this.selectedRows = this.data.filter((t) => !this.isDisabled(t));
            }
        } else {
            this.selectedRows = [];
        }
        this.$emit('selectionChanged', this.selectedRows);
        this.$emit('update:selected', this.selectedRows);
    }

    private isSelected(row: T): boolean {
        return this.selectedRows.includes(row);
    }

    private isDisabled(row: T): boolean {
        return this.disabled.includes(row);
    }

    private generateRowKey(row: any, index: number): string {
        const id = row.rowId || row.id || row.key || Utils.hash(row);
        return `${index}-${id}`;
    }
}
