import type { PortfolioRepository } from '@/modules/ctx-portfolios/adapter';
import type { Portfolio } from '@/modules/ctx-portfolios/types';
import NotificationService from '@/assets/js/services/NotificationService';
import { i18n } from '@/plugins/i18n';
import MathUtils from '@/assets/js/utils/MathUtils';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';

export class PortfolioService {

    private readonly portfolioRepository: PortfolioRepository;

    constructor(params: { portfolioRepository: PortfolioRepository }) {
        this.portfolioRepository = params.portfolioRepository;
    }

    public onChange(cb: () => Promise<void>): void {
        this.portfolioRepository.onChange(cb);
    }

    public async getPortfolios(): Promise<Portfolio[]> {
        try {
            const portfolios = await this.portfolioRepository.getPortfolios();
            return portfolios.sort((a, b) => a.order - b.order);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async getPortfolioByKey(portfolioKey: string): Promise<Portfolio|undefined> {
        try {
            return await this.portfolioRepository.getPortfolioByKey(portfolioKey);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async createPortfolio(name: string, templateKey?: string): Promise<Portfolio> {
        try {
            const allPortfolios = await this.getPortfolios();
            const order = allPortfolios
                .map((it) => it.order)
                .reduce(ArrayUtils.reduceToHighest, 0) + 10;
            if (templateKey) {
                return await this.portfolioRepository.createPortfolioFromTemplate(templateKey, name, order);
            }
            return await this.portfolioRepository.createPortfolio(name, order);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async updatePortfolio(portfolio: Portfolio): Promise<Portfolio> {
        try {
            return await this.portfolioRepository.updatePortfolio(portfolio);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async validatePortfolio(portfolio: Partial<Portfolio>): Promise<{[key: string]: string[]}> {
        const errors: {[key: string]: string[]} = {};
        if (!portfolio.name || portfolio.name.trim().length === 0) {
            errors.name = [i18n.t('ctx-portfolios.validation.portfolio.name-required').toString()];
        }
        const portfolios = await this.getPortfolios();
        if (portfolios.find((it) => it.name === portfolio.name && it.key !== portfolio.key)) {
            errors.name = [i18n.t('ctx-portfolios.validation.portfolio.name-duplicate', { name: portfolio.name }).toString()];
        }
        return errors;
    }

    public async reorderPortfolios(portfolios: Portfolio[]): Promise<Portfolio[]> {
        try {
            const portfolioKeys = portfolios.map((it) => it.key);
            const changedPortfolios: Portfolio[] = [];
            portfolios.forEach((it) => {
                const newOrder = portfolioKeys.indexOf(it.key) * 10;
                if (it.order !== newOrder) {
                    it.order = newOrder;
                    changedPortfolios.push(it);
                }
            });
            return Promise.all(changedPortfolios.map((it) => this.updatePortfolio(it)));
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async deletePortfolio(portfolio: Portfolio): Promise<void> {
        try {
            await this.portfolioRepository.deletePortfolio(portfolio);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }
}
