
import { Vue, Component, Prop } from 'vue-property-decorator';
import HalvarFooter from '@/components/partials/HalvarFooter.vue';

@Component({
    components: { HalvarFooter },
})
export default class FullscreenErrorMessage extends Vue {

    @Prop({})
    private error!: any;

    private status: string|number = 500;
    private message: string|null = null;

    public async created() {
        if (this.error && this.error.status) {
            this.status = this.error.status;
            if (this.error.clone) {
                this.message = await this.error.clone().text();
            }
        } else {
            this.status = '503';
        }
    }
}
