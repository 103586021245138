/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetricCategory,
    MetricCategoryFromJSON,
    MetricCategoryFromJSONTyped,
    MetricCategoryToJSON,
    Resolution,
    ResolutionFromJSON,
    ResolutionFromJSONTyped,
    ResolutionToJSON,
} from './';

/**
 * 
 * @export
 * @interface MetricRepresentation
 */
export interface MetricRepresentation {
    /**
     * 
     * @type {string}
     * @memberof MetricRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof MetricRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MetricRepresentation
     */
    unit: string;
    /**
     * 
     * @type {string}
     * @memberof MetricRepresentation
     */
    sourceUnit?: string;
    /**
     * 
     * @type {MetricCategory}
     * @memberof MetricRepresentation
     */
    category: MetricCategory;
    /**
     * 
     * @type {number}
     * @memberof MetricRepresentation
     */
    priority?: number;
    /**
     * 
     * @type {Resolution}
     * @memberof MetricRepresentation
     */
    minResolution: Resolution;
    /**
     * all features that are assigned to the metric
     * @type {Array<string>}
     * @memberof MetricRepresentation
     */
    licenseFeatures?: Array<string>;
    /**
     * Minimum value filter
     * @type {number}
     * @memberof MetricRepresentation
     */
    min?: number;
    /**
     * Maximum value filter
     * @type {number}
     * @memberof MetricRepresentation
     */
    max?: number;
    /**
     * 
     * @type {string}
     * @memberof MetricRepresentation
     */
    parentKey?: string;
    /**
     * this flag indicates if the metric should be available for data display or only for internal configuration purposes
     * @type {boolean}
     * @memberof MetricRepresentation
     */
    internal?: boolean;
    /**
     * this flag indicates if the metric should be available for widgets
     * @type {boolean}
     * @memberof MetricRepresentation
     */
    inactive?: boolean;
}

export function MetricRepresentationFromJSON(json: any): MetricRepresentation {
    return MetricRepresentationFromJSONTyped(json, false);
}

export function MetricRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'unit': json['unit'],
        'sourceUnit': !exists(json, 'sourceUnit') ? undefined : json['sourceUnit'],
        'category': MetricCategoryFromJSON(json['category']),
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'minResolution': ResolutionFromJSON(json['minResolution']),
        'licenseFeatures': !exists(json, 'licenseFeatures') ? undefined : json['licenseFeatures'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'parentKey': !exists(json, 'parentKey') ? undefined : json['parentKey'],
        'internal': !exists(json, 'internal') ? undefined : json['internal'],
        'inactive': !exists(json, 'inactive') ? undefined : json['inactive'],
    };
}

export function MetricRepresentationToJSON(value?: MetricRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'unit': value.unit,
        'sourceUnit': value.sourceUnit,
        'category': MetricCategoryToJSON(value.category),
        'priority': value.priority,
        'minResolution': ResolutionToJSON(value.minResolution),
        'licenseFeatures': value.licenseFeatures,
        'min': value.min,
        'max': value.max,
        'parentKey': value.parentKey,
        'internal': value.internal,
        'inactive': value.inactive,
    };
}


