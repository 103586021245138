import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=2ab81260"
import script from "./Tabs.vue?vue&type=script&lang=ts"
export * from "./Tabs.vue?vue&type=script&lang=ts"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=2ab81260&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports