
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';

@Component({
    name: 'ConfirmDlg',
    components: { LoadingAnimationSmall, BaseDialog },
})
export default class ConfirmDlg extends Vue {

    @Prop({ default: null })
    public readonly title!: string|null;

    @Prop({ default: null })
    public readonly message!: string|null;

    @Prop({ default: null })
    public readonly buttontext!: string|null;

    @Prop({ default: false })
    public readonly loading!: boolean;

    @Prop({ default: true })
    public readonly destructive!: boolean;

    private loadingInternal: boolean = false;

    public created(): void {
        this.loadingInternal = this.loading;
    }

    @Watch('loading')
    private setLoading() {
        this.loadingInternal = this.loading;
    }

    private submit() {
        if (!this.loadingInternal) {
            this.$emit('submit');
            this.loadingInternal = true;
        }
    }

    private close() {
        this.$emit('close');
    }
}
