import { i18n } from '@/plugins/i18n';
import type {
    WidgetWizardStep,
    TabDataOptions,
    TabGeneralOptions,
    TabLogEventsOptions,
    TabMapOptions,
} from '@/components/widget-wizard';
import { ResourceType } from '@/clients/dashboardapi/v2';
import { WidgetWizardTab } from '@/components/widget-wizard';

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: {
        enableDuplicateResources: false,
        resourceTypes: [ResourceType.Portfolio, ResourceType.Park, ResourceType.Generator],
        hint: i18n.t('widget-wizard.tab.data.hint-distinct').toString(),
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

const logevents: WidgetWizardStep<TabLogEventsOptions> = {
    name: 'widget-wizard.tab.log-events',
    tab: WidgetWizardTab.LogEvents,
    params: {
        enableMinimumDurationFilter: false,
    },
    visible: (widget) => widget.config.mapConfig?.showStatus !== false,
};

const map: WidgetWizardStep<TabMapOptions> = {
    name: 'widget-wizard.tab.map',
    tab: WidgetWizardTab.Map,
    params: {
        enableShowStatusOption: true,
    },
};

export const Steps: WidgetWizardStep[] = [
    map,
    logevents,
    data,
    general,
];
