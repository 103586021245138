import Vue from 'vue';
import type VueRouter from 'vue-router';
import { Module, ModuleOptions } from '@/modules/ctx-users';
import { Port } from '@/modules/shared/Port';
import { UserRepositoryImpl } from './UserRepositoryImpl';
import { CustomerRepositoryImpl } from './CustomerRepositoryImpl';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        router: router,
        userRepository: new UserRepositoryImpl({
            users: Port.users,
            auth: Port.auth,
        }),
        customerRepository: new CustomerRepositoryImpl({
            companiesApi: Port.companies,
        }),
    });
}
