import type { RouteConfig } from 'vue-router';
import { LicenseFeature, Role } from '@/modules/shared/types';

const route: RouteConfig = {
    path: '/:customer/users/:userKey/generators',
    name: 'user-generators',
    component: () => import(/* webpackChunkName: "ctx-users" */ './UserGeneratorsView.vue'),
    meta: {
        role: Role.COMPANY_ADMIN,
        license: [LicenseFeature.ADVANCED],
        authenticated: true,
        navbar: true,
        footer: true,
        settingsTabs: false,
        portfolioTabs: false,
        onboarding: true,
        notifications: true,
    },
};

export { route };
