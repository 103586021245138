
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Port } from '@/modules/ctx-portfolios/Port';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import InputText from '@/modules/shared/components/form/InputText.vue';

interface Template {
    key?: string;
    name: string;
    description: string;
    generators: number;
}

@Component({
    components: {
        InputText,
        LoadingAnimationSmall,
        BaseDialog,
    },
})
export default class CreatePortfolioDlg extends Vue {

    @Prop({ required: true, default: false })
    public readonly open!: boolean;

    @Ref('input')
    private readonly input!: any;

    private templates: Template[] = [];
    private showTemplateSelection: boolean|null = null;
    private template: Template|null = null;
    private name: string = '';
    private loading: boolean = false;
    private errors: {[key: string]: string[]} = {};

    public created(): void {
        this.fetchTemplates();
        Port.templates.onChange(() => this.fetchTemplates());
        this.validate();
    }

    private get hasErrors(): boolean {
        return Object.keys(this.errors).length > 0;
    }

    private get templateName(): string {
        return this.template?.name || this.$t('ctx-portfolios.create-portfolio.no-template').toString();
    }

    private close(): void {
        this.$emit('update:open', false);
        this.name = '';
        this.template = this.templates[0] || null;
    }

    private async submit(): Promise<void> {
        if (this.hasErrors) {
            return;
        }
        this.loading = true;
        const template = this.template?.key;
        const portfolio = await Port.portfolios.createPortfolio(this.name, template)
            .finally(() => this.loading = false);
        this.close();
        if (template) {
            await this.$router.push({ name: 'portfolio', params: { portfolioKey: portfolio.key } });
        } else {
            await this.$router.push({ name: 'portfolio-generators', params: { portfolioKey: portfolio.key } });
        }
    }

    @Watch('open')
    private onOpened(): void {
        if (this.open) {
            this.$nextTick(() => this.input.focus());
        }
    }

    @Watch('name')
    @Watch('template')
    private async validate(): Promise<void> {
        this.errors = await Port.portfolios.validatePortfolio({ name: this.name });
    }

    private async fetchTemplates(): Promise<void> {
        const templates = await Port.templates.getPortfolioTemplates();
        const noTemplate: Template = {
            key: undefined,
            name: this.$t('ctx-portfolios.create-portfolio.no-template').toString(),
            description: this.$t('ctx-portfolios.create-portfolio.no-template.desc').toString(),
            generators: 0,
        };
        this.templates = [noTemplate].concat(templates.map((t) => ({
            key: t.key,
            name: t.name,
            description: t.description || this.$t('ctx-portfolios.create-portfolio.template.no-desc').toString(),
            generators: t.generators.length,
        })));
        this.template = this.templates[0];
    }
}
