
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class NoteBox extends Vue {

    @Prop({ required: false, default: 'info' })
    public readonly type!: 'info'|'warning'|'error';

    @Prop({ required: false, default: false })
    public readonly showIcon!: boolean;

    @Prop({ required: false, default: '' })
    public readonly message!: string;

    private get backgroundClasses(): string {
        switch (this.type) {
            case 'warning': return 'bg-yellow-500 bg-opacity-20 border-yellow-500 text-series-yellow-400';
            case 'info': return 'bg-primary-500 bg-opacity-10 border-primary-500 text-primary-500';
            case 'error': return 'bg-red-500 bg-opacity-20 border-red-500 text-red-600';
            default: return '';
        }
    }
}
