import { LicenseFeature, ResourceType } from '@/modules/shared/types';
import type { WidgetWizardStep, TabDataOptions, TabMetricsOptions, TabGeneralOptions } from '@/components/widget-wizard';
import { WidgetWizardTab } from '@/components/widget-wizard';
import { WidgetUtils } from '@/components/widgets';
import { DataGroupType } from '@/components/widgets/types/DataGroupType';
import { WidgetDataUtils } from '@/components/widgets/utils/WidgetDataUtils';
import MathUtils from '@/assets/js/utils/MathUtils';
import { i18n } from '@/plugins/i18n';
import { TabTableOptions } from '@/components/widget-wizard/components/tabs/table/TabTableOptions';

const commission: WidgetWizardStep = {
    name: 'widget-wizard.tab.commissions',
    tab: WidgetWizardTab.Commissions,
};

const metrics: WidgetWizardStep<TabMetricsOptions> = {
    name: 'widget-wizard.tab.columns',
    tab: WidgetWizardTab.Metrics,
    features: [LicenseFeature.ADVANCED],
    params: {
        enableMixedUnits: true,
        axisTitles: ['Spalten'],
    },
};

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.Time,
};

const resolution: WidgetWizardStep = {
    name: 'widget-wizard.tab.resolution',
    tab: WidgetWizardTab.Resolution,
    features: [LicenseFeature.ADVANCED],
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: {
        enableSingleResourceList: true,
        forceSingleResourceList: true,
        enableDuplicateResources: false,
        resourceTypes: [
            ResourceType.Portfolio,
            ResourceType.Park,
            ResourceType.Generator,
            ResourceType.Shareholder,
            ResourceType.Generatorgroup,
        ],
    },
};

const dataAggregation: WidgetWizardStep = {
    name: 'widget-wizard.tab.aggregation-datasources',
    tab: WidgetWizardTab.AggregationDatasources,
    visible: (widget) => WidgetUtils.getDataSourcesNeedingAggregation(widget).length > 0,
};

const table: WidgetWizardStep<TabTableOptions> = {
    name: 'widget-wizard.tab.table',
    tab: WidgetWizardTab.Table,
    params: (widget) => {
        const params: Partial<TabTableOptions> = {
            enableFormatting: true,
            dataGroupingTypes: [
                DataGroupType.NONE,
                DataGroupType.DATASOURCE,
                DataGroupType.METRIC,
            ],
        };
        const maxColumns = parseInt(process.env.VUE_APP_MAX_TABLE_COLUMNS_FOR_REPORT || '20', 10);
        const columnCounts: number[] = WidgetDataUtils.getTableColumnCount(widget);
        if (MathUtils.max(columnCounts) > maxColumns) {
            params.dataGroupingInfo = i18n.t('widget-wizard.tab.general.max-table-columns').toString();
        }

        params.defaultSorting = '';
        params.dataSortingOptions = WidgetDataUtils.getTableSortingOptions(widget);
        return params;
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    commission,
    metrics,
    time,
    resolution,
    data,
    dataAggregation,
    table,
    general,
];
