import type { User, UserRepository } from '@/modules/ctx-admin-customers';
import { UsersApi } from '@/modules/shared/adapter';

export class UserRepositoryImpl implements UserRepository {

    private readonly usersApi: UsersApi;

    constructor(apis: {
        users: UsersApi,
    }) {
        this.usersApi = apis.users;
    }

    public async getUser(companyKey: string, userKey: string): Promise<User|undefined> {
        const users = await this.usersApi.getUsers(companyKey);
        const user = users.find((it) => it.key === userKey);
        if (user) {
            return {
                key: user.key,
                firstname: user.firstname || '',
                lastname: user.lastname || '',
                portfolioKey: user.portfolioKey || '',
            };
        }
        return undefined;
    }
}
