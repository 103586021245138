/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HistoricMeanSpan {
    TwoYears = 'two_years',
    ThreeYears = 'three_years',
    FourYears = 'four_years',
    FiveYears = 'five_years',
    UnitRuntime = 'unit_runtime'
}

export function HistoricMeanSpanFromJSON(json: any): HistoricMeanSpan {
    return HistoricMeanSpanFromJSONTyped(json, false);
}

export function HistoricMeanSpanFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoricMeanSpan {
    return json as HistoricMeanSpan;
}

export function HistoricMeanSpanToJSON(value?: HistoricMeanSpan | null): any {
    return value as any;
}

