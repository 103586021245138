
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Resolution, ResourceType } from '@/clients/dashboardapi/v2';
import BubbleMenu from '@/components/basics/BubbleMenu.vue';
import type { WidgetConfig, WidgetConfigAxis, WidgetConfigMetric, WidgetConfigResource } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import Utils from '@/assets/js/utils/Utils';
import { WidgetUtils } from '@/components/widgets';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import TimeIntervals from '@/assets/js/utils/TimeIntervals';

@Component({
    components: {
        BubbleMenu,
    },
})
export default class DashboardWidgetInfo extends Vue {

    @Prop({ default: {} })
    public readonly widget!: WidgetConfig;

    @Prop({ default: null })
    public readonly anchor!: HTMLElement|null;

    @Prop({ default: '' })
    public readonly info!: string;

    private readonly ResourceType = ResourceType;
    private readonly Resolution = Resolution;

    private get extendedWidget(): WidgetConfig {
        const copy = Utils.deepCopy(this.widget);
        return copy;
    }

    private get timeinterval(): string {
        const interval = TimeIntervals.resolveIntervalForWidget(this.extendedWidget);
        return `${this.$formatDate(interval.from)} - ${this.$formatDate(interval.to)}`;
    }

    private get showTime(): boolean {
        switch (this.extendedWidget.type) {
            case WidgetType.WeatherMap:
            case WidgetType.Map: return false;
            default: return true;
        }
    }

    private get metrics(): string[] {
        return this.extendedWidget.axis
            .flatMap((axis: WidgetConfigAxis) => axis.metrics)
            .map((metric: WidgetConfigMetric) => metric.metricName)
            .filter(ArrayUtils.removeDuplicates);
    }

    private get resources(): WidgetConfigResource[] {
        return WidgetUtils.getDistinctResources(this.extendedWidget);
    }

    private get note(): string|null {
        const metrics = this.extendedWidget.axis
            .flatMap((axis: WidgetConfigAxis) => axis.metrics);
        if (metrics.length === 1) {
            const noteKey = `widget.info.${metrics[0].metricKey}`;
            const note = this.$t(noteKey).toString();
            if (note !== noteKey) {
                return note;
            }
        }
        return null;
    }
}
