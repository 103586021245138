import { Configuration, MetricCategory, MetricsApi as MetricsRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type { LocalizationApi } from '@/modules/shared/adapter/LocalizationApi';
import type {
    AssignRawMetricRequest,
    CoverageRepresentation,
    CoverageRequest,
    CreateMetricRequest,
    MetricRepresentation,
    MetricsApi,
    RawMetricRepresentation,
    UpdateMetricRequest,
} from '@/modules/shared/adapter/MetricsApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

interface RawMetricRepresentationWrapper {
    // the key of a raw metric is not unique, we must use a combined key of the dataprovider and the raw metric
    key: string;
    metric: RawMetricRepresentation;
}

interface CoverageWrapper {
    key: string;
    coverage: CoverageRepresentation[];
}

export class CachedMetricsRestApi implements MetricsApi {

    private readonly i18nApi: LocalizationApi;
    private readonly metricsApi: MetricsRestApi;
    private readonly metricsCache: IndexedDBRepository<string, MetricRepresentation>;
    private readonly rawMetricsCache: IndexedDBRepository<string, RawMetricRepresentationWrapper>;
    private readonly coverageCache: IndexedDBRepository<string, CoverageWrapper>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi, i18n: LocalizationApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.i18nApi = apis.i18n;
        this.metricsApi = new MetricsRestApi(restApiConfig);
        this.metricsCache = new IndexedDBRepository<string, MetricRepresentation>(indexedDb, 'metrics');
        this.rawMetricsCache = new IndexedDBRepository<string, RawMetricRepresentationWrapper>(indexedDb, 'raw-metrics');
        this.coverageCache = new IndexedDBRepository<string, CoverageWrapper>(indexedDb, 'coverage', { invalidateOnInterval: 1000 * 60 * 60 });
    }

    public async getMetrics(includeInactive: boolean = true): Promise<MetricRepresentation[]> {
        const allMetrics = await this.metricsCache.findAllIfAnyCached() || await this.fetchMetrics();
        if (includeInactive) {
            return allMetrics;
        }
        return allMetrics.filter((m) => !m.internal && !m.inactive);
    }

    public async getMetricsWithCoverage(generatorKeys: string[]): Promise<MetricRepresentation[]> {
        const allMetrics = await this.getMetrics(false);
        const coverage = await this.getCoverage({ generatorKeys: generatorKeys });
        const coveredMetricKeys = coverage.map((it) => it.metricKey);
        const alwaysCoveredMetricsKeys = allMetrics
            // calculated metrics don't have a normal coverage and are always available
            .filter(CachedMetricsRestApi.isCalculated)
            .map((it) => it.key);
        return allMetrics.filter((it) => coveredMetricKeys.includes(it.key) || alwaysCoveredMetricsKeys.includes(it.key));
    }

    public async getMetricByKey(key: string): Promise<MetricRepresentation|undefined> {
        if (!key) {
            return undefined;
        }
        const cached = await this.metricsCache.findByKey(key);
        if (cached) {
            return cached;
        }
        const allMetrics = await this.fetchMetrics();
        return allMetrics.find((metric) => metric.key === key);
    }

    private async fetchMetrics(): Promise<MetricRepresentation[]> {
        return AsyncDebouncer.debounce<MetricRepresentation[]>('CachedMetricsRestApi.fetchMetrics', async () => {
            const metrics = await this.metricsApi.getAllMetrics();
            await this.metricsCache.saveAll(metrics);
            return metrics;
        });
    }

    public async getRawMetrics(): Promise<RawMetricRepresentation[]> {
        const cached = await this.rawMetricsCache.findAllIfAnyCached();
        if (cached) {
            return cached.map((it) => it.metric);
        }
        return this.fetchRawMetrics();
    }

    private async fetchRawMetrics(): Promise<RawMetricRepresentation[]> {
        return AsyncDebouncer.debounce<RawMetricRepresentation[]>('CachedMetricsRestApi.fetchRawMetrics', async () => {
            const rawMetrics = await this.metricsApi.getAllRawMetrics();
            await this.rawMetricsCache.saveAll(rawMetrics.map(CachedMetricsRestApi.mapToCacheWrapper));
            return rawMetrics;
        });
    }

    public async assignRawMetric(rawMetricKey: string, dataProviderKey: string, assignRawMetricRequest: AssignRawMetricRequest): Promise<RawMetricRepresentation> {
        const savedRawMetric = await this.metricsApi.assignRawMetric(rawMetricKey, dataProviderKey, assignRawMetricRequest);
        await this.rawMetricsCache.save(CachedMetricsRestApi.mapToCacheWrapper(savedRawMetric));
        return savedRawMetric;
    }

    public async updateMetric(key: string, spec: UpdateMetricRequest): Promise<MetricRepresentation> {
        const savedMetric = await this.metricsApi.updateMetric(key, spec);
        this.i18nApi.requestReload(spec.lc);
        await this.metricsCache.save(savedMetric);
        return savedMetric;
    }

    public async createMetric(spec: CreateMetricRequest): Promise<MetricRepresentation> {
        const savedMetric = await this.metricsApi.createNewMetric(spec);
        this.i18nApi.requestReload(spec.lc);
        await this.metricsCache.save(savedMetric);
        // refresh raw metrics cache
        await this.rawMetricsCache.removeAll();
        await this.fetchRawMetrics();
        return savedMetric;
    }

    public async getCoverage(coverageRequest: CoverageRequest): Promise<CoverageRepresentation[]> {
        const hash = this.hash(coverageRequest.generatorKeys.sort()).toString();
        const cached = await this.coverageCache.findByKey(hash);
        if (cached) {
            return cached.coverage;
        }
        return this.fetchCoverage(coverageRequest, hash);
    }

    public async invalidate(): Promise<void> {
        await this.metricsCache.removeAll();
        await this.fetchMetrics();
    }

    private hash(object: any): string {
        const str = JSON.stringify(object);
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << 5) - hash) + char;
            // eslint-disable-next-line no-bitwise
            hash &= hash; // Convert to 32bit integer
        }
        if (hash < 0) {
            return `1${(hash * -1).toString(16)}`;
        }
        return hash.toString(16);
    }

    private async fetchCoverage(coverageRequest: CoverageRequest, hash: string): Promise<CoverageRepresentation[]> {
        return AsyncDebouncer.debounce(`CachedMetricsRestApi.fetchCoverage(${hash})`, async () => {
            const coverage = await this.metricsApi.getCoverageForAllMetrics(coverageRequest);
            await this.coverageCache.save({ key: hash, coverage: coverage });
            return coverage;
        });
    }

    private static isCalculated(metric: MetricRepresentation): boolean {
        return [
            MetricCategory.Calculated,
            // calculated metrics, that run an accounting formula
            MetricCategory.Accounting,
            // calculated metrics, return loss of production based on log entries
            MetricCategory.LossOfProduction,
            // calculated metrics, return loss of revenue based on loss of production
            MetricCategory.LossOfRevenue,
        ].includes(metric.category);
    }

    private static mapToCacheWrapper(metric: RawMetricRepresentation): RawMetricRepresentationWrapper {
        return {
            key: `${metric.dataProviderKey}-${metric.key}`,
            metric: metric,
        };
    }
}
