/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Aggregation,
    AggregationFromJSON,
    AggregationFromJSONTyped,
    AggregationToJSON,
    WidgetResourceRepresentation,
    WidgetResourceRepresentationFromJSON,
    WidgetResourceRepresentationFromJSONTyped,
    WidgetResourceRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetMetricRepresentation
 */
export interface WidgetMetricRepresentation {
    /**
     * 
     * @type {string}
     * @memberof WidgetMetricRepresentation
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetMetricRepresentation
     */
    metricKey: string;
    /**
     * 
     * @type {Aggregation}
     * @memberof WidgetMetricRepresentation
     */
    aggregationOverTime: Aggregation;
    /**
     * 
     * @type {number}
     * @memberof WidgetMetricRepresentation
     */
    position: number;
    /**
     * 
     * @type {Array<WidgetResourceRepresentation>}
     * @memberof WidgetMetricRepresentation
     */
    resources: Array<WidgetResourceRepresentation>;
}

export function WidgetMetricRepresentationFromJSON(json: any): WidgetMetricRepresentation {
    return WidgetMetricRepresentationFromJSONTyped(json, false);
}

export function WidgetMetricRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetMetricRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'metricKey': json['metricKey'],
        'aggregationOverTime': AggregationFromJSON(json['aggregationOverTime']),
        'position': json['position'],
        'resources': ((json['resources'] as Array<any>).map(WidgetResourceRepresentationFromJSON)),
    };
}

export function WidgetMetricRepresentationToJSON(value?: WidgetMetricRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'metricKey': value.metricKey,
        'aggregationOverTime': AggregationToJSON(value.aggregationOverTime),
        'position': value.position,
        'resources': ((value.resources as Array<any>).map(WidgetResourceRepresentationToJSON)),
    };
}


