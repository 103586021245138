import type { CustomerRepository, LicenseRepository, UserRepository } from '@/modules/ctx-admin-customers';
import { CustomerService } from '@/modules/ctx-admin-customers/services/CustomerService';
import { LicenseService } from '@/modules/ctx-admin-customers/services/LicenseService';
import { UserService } from '@/modules/ctx-admin-customers/services/UserService';

export interface PortParams {
    customerRepository: CustomerRepository;
    licenseRepository: LicenseRepository;
    userRepository: UserRepository;
}

export class Port {
    // services will be instantiated in plugin
    private static port: Port|null = null;

    private readonly customerService: CustomerService;
    private readonly licenseService: LicenseService;
    private readonly userService: UserService;

    constructor(params: PortParams) {
        this.customerService = new CustomerService({
            customerRepository: params.customerRepository,
        });
        this.licenseService = new LicenseService({
            licenseRepository: params.licenseRepository,
        });
        this.userService = new UserService({
            userRepository: params.userRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get customers(): CustomerService {
        return Port.instance.customerService;
    }

    public static get license(): LicenseService {
        return Port.instance.licenseService;
    }

    public static get users(): UserService {
        return Port.instance.userService;
    }
}
