
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import ParkList from './ParkList.vue';

@Component({
    components: { ParkList },
})
export default class GeneratorChooser extends Vue {

    @Prop({ required: false, default: () => [] })
    public readonly selected!: string[];

    @Prop({ required: false, default: null })
    public readonly portfolioKey!: string|null;

    @Prop({ required: false, default: () => [] })
    public readonly whitelist!: string[];

    @Prop({ required: false, default: () => [] })
    public readonly blacklist!: string[];

    @Prop({ required: false, default: null })
    public readonly maxSelectable!: number|null;

    @Ref('filterinput')
    private readonly filterinput: HTMLInputElement|undefined;

    private totalCount: number = -1;
    private count: number = -1;
    private selectedMutable: string[] = [];
    private filter: string = '';

    @Watch('count')
    private onCountChanged() {
        if (this.totalCount === -1) {
            this.totalCount = this.count;
            this.$nextTick(() => this.filterinput?.focus());
        }
    }

    @Watch('selected')
    private updateSelected(): void {
        this.selectedMutable = [...this.selected];
    }

    private submit(): void {
        this.$emit('submit', this.selectedMutable);
    }

    private cancel(): void {
        this.$emit('cancel');
    }
}
