/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ParkRepresentation,
    ParkRepresentationFromJSON,
    ParkRepresentationToJSON,
} from '../models';

export interface GetGeneratorsForParkRequest {
    key: string;
}

/**
 * 
 */
export class ParksApi extends runtime.BaseAPI {

    /**
     * Get all the keys of all generators in the park
     * Get generators in park
     */
    async getGeneratorsForParkRaw(requestParameters: GetGeneratorsForParkRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getGeneratorsForPark.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:read"]);
        }

        const response = await this.request({
            path: `/parks/{key}/generators`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all the keys of all generators in the park
     * Get generators in park
     */
    async getGeneratorsForPark(key: string): Promise<Array<string>> {
        const response = await this.getGeneratorsForParkRaw({ key: key });
        return await response.value();
    }

    /**
     * Get all parks the current user has access to
     * Get parks
     */
    async getParksRaw(): Promise<runtime.ApiResponse<Array<ParkRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:read"]);
        }

        const response = await this.request({
            path: `/parks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParkRepresentationFromJSON));
    }

    /**
     * Get all parks the current user has access to
     * Get parks
     */
    async getParks(): Promise<Array<ParkRepresentation>> {
        const response = await this.getParksRaw();
        return await response.value();
    }

}
