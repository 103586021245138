import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import type { GeneratorRepresentation, ParkRepresentation } from '@/clients/dashboardapi/v2';
import { v4 as uuid } from 'uuid';

export default class Utils {

    /**
     * Create a real deep copy of the object
     * @param object
     */
    public static deepCopy<T>(value: T): T {
        // null / undefined
        if (value === null || value === undefined) {
            return value;
        }
        // recursively clone array
        if (Array.isArray(value)) {
            return value.map((item) => this.deepCopy(item)) as unknown as T;
        }
        // create wrapper for function
        if (typeof value === 'function') {
            // create a wrapper for the function so the original function cannot be overriten by assigning the
            // function in the copy
            return ((...args: any[]) => value(...args)) as unknown as T;
        }
        // clone date
        if (value instanceof Date) {
            return new Date(value.getTime()) as unknown as T;
        }
        // recursively clone object
        if (typeof value === 'object') {
            // should also work for regex
            // if (value instanceof RegExp) {
            //     console.log('value is a regex');
            // }
            const copy: any = {};
            Object.entries(value).forEach((kv: [string, any]) => {
                copy[kv[0]] = this.deepCopy(kv[1]);
            });
            return copy;
        }
        // primitives (string, number, boolean, ...) that are call by value
        return value;
    }

    public static async wait(millis: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, millis);
        });
    }

    /**
     * Simple object check.
     * https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
     * @param item
     * @returns {boolean}
     */
    public static isObject(item: any): boolean {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    /**
     * Deep merge two objects.
     * https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
     * @param target
     * @param sources
     */
    public static deepMerge(target: any, ...sources: any): any {
        if (!sources.length) return target;
        const source = sources.shift();

        if (Utils.isObject(target) && Utils.isObject(source)) {
            Object.keys(source).forEach((key) => {
                if (Utils.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, { [key]: {} });
                    }
                    Utils.deepMerge(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            });
        }
        return Utils.deepMerge(target, ...sources);
    }

    public static anyValueMatchesSearchTerm(object: any, searchTerm: string): boolean {
        return Object.values(object)
            .filter((value) => value !== undefined && value !== null)
            .map((value: any) => value.toString().toLowerCase().trim())
            .filter((value: string) => value.includes(searchTerm))
            .length >= 1;
    }

    /**
     * Returns an object c that has all key/value pairs, that both a and b have.
     *
     * <br> Example:
     * <br> const foo = { a: 'value1', b: 'value2', c: 'value3' }
     * <br> const bar = { a: 'value1', b: 'other value', c: 'value3' }
     * <br> Utils.overlap(foo, bar)
     * <br> -> { a: 'value1', c: 'value3' }
     *
     * @param a
     * @param b
     */
    public static overlap(a: any, b: any): any {
        const result: any = {};
        const keys = ArrayUtils.distinct(Object.keys(a).concat(Object.keys(b)));
        keys.forEach((key: string) => {
            if (a[key] === b[key]) {
                result[key] = a[key];
            }
        });
        return result;
    }

    public static getQueryParameter(param: string): string|undefined {
        const url = window.location.href;
        // eslint-disable-next-line no-useless-escape
        const name = param.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) {
            return undefined;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    public static hash(object: any): string {
        const stringValue = JSON.stringify(object) || '';
        let hashStr = '';
        let hash = 0;
        for (let i = 0; i < stringValue.length; i++) {
            const char = stringValue.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << 5) - hash) + char;
            // eslint-disable-next-line no-bitwise
            hash &= hash; // Convert to 32bit integer
        }
        if (hash < 0) {
            hashStr = `1${(hash * -1).toString(16)}`;
        } else {
            hashStr = hash.toString(16);
        }
        return hashStr;
    }

    public static convertTimeZone(date: Date, timeZone: string): Date {
        return new Date(date.toLocaleString('en-US', { timeZone }));
    }

    public static maskGenerator(generator: GeneratorRepresentation): GeneratorRepresentation {
        generator.name = `${process.env.VUE_APP_DEMO_GENERATORNAME}${Utils.hash(generator.key)}`;
        return generator;
    }

    public static maskPark(park: ParkRepresentation): ParkRepresentation {
        const hash = Utils.hash(park.name);
        park.name = `${process.env.VUE_APP_DEMO_PARKNAME}${hash}`;
        park.zipCode = `123${hash}`;
        return park;
    }

    public static disableScrolling(): string {
        const scrollY = window.scrollY.toString();
        document.body.dataset.scrollY = scrollY;
        document.body.style.marginTop = `-${scrollY}px`;
        document.documentElement.classList.add('disable-scrolling');
        return scrollY;

    }

    public static enableScrolling(): void {
        document.documentElement.classList.remove('disable-scrolling');
        document.body.style.marginTop = '0';
        const scrollY = parseInt(document.body.dataset.scrollY || '0', 10);
        window.scrollTo(0, scrollY);
    }

    public static scrollToTop(): void {
        // For Safari
        if (document.body) {
            // document.body.scrollTop = 0;
        }
        // For Chrome, Firefox, IE and Opera
        if (document.documentElement) {
            // document.documentElement.scrollTop = 0;
        }
    }

    public static isSafari(): boolean {
        return navigator.vendor !== undefined
            && navigator.vendor.includes('Apple')
            && navigator.userAgent !== undefined
            && !navigator.userAgent.includes('CriOS')
            && !navigator.userAgent.includes('FxiOS');
    }

    public static generateUUID(): string {
        return uuid();
    }
}
