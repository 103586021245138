/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetricCategory,
    MetricCategoryFromJSON,
    MetricCategoryFromJSONTyped,
    MetricCategoryToJSON,
    Resolution,
    ResolutionFromJSON,
    ResolutionFromJSONTyped,
    ResolutionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateMetricRequest
 */
export interface CreateMetricRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMetricRequest
     */
    name: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateMetricRequest
     */
    sourceUnit?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMetricRequest
     */
    lc: Array<string>;
    /**
     * 
     * @type {MetricCategory}
     * @memberof CreateMetricRequest
     */
    category: MetricCategory;
    /**
     * 
     * @type {Resolution}
     * @memberof CreateMetricRequest
     */
    resolution?: Resolution;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMetricRequest
     */
    licenseFeatures?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateMetricRequest
     */
    rawMetricKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMetricRequest
     */
    dataProviderKey: string;
    /**
     * Minimum value filter
     * @type {number}
     * @memberof CreateMetricRequest
     */
    min?: number;
    /**
     * Maximum value filter
     * @type {number}
     * @memberof CreateMetricRequest
     */
    max?: number;
}

export function CreateMetricRequestFromJSON(json: any): CreateMetricRequest {
    return CreateMetricRequestFromJSONTyped(json, false);
}

export function CreateMetricRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMetricRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'sourceUnit': !exists(json, 'sourceUnit') ? undefined : json['sourceUnit'],
        'lc': json['lc'],
        'category': MetricCategoryFromJSON(json['category']),
        'resolution': !exists(json, 'resolution') ? undefined : ResolutionFromJSON(json['resolution']),
        'licenseFeatures': !exists(json, 'licenseFeatures') ? undefined : json['licenseFeatures'],
        'rawMetricKey': json['rawMetricKey'],
        'dataProviderKey': json['dataProviderKey'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
    };
}

export function CreateMetricRequestToJSON(value?: CreateMetricRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'sourceUnit': value.sourceUnit,
        'lc': value.lc,
        'category': MetricCategoryToJSON(value.category),
        'resolution': ResolutionToJSON(value.resolution),
        'licenseFeatures': value.licenseFeatures,
        'rawMetricKey': value.rawMetricKey,
        'dataProviderKey': value.dataProviderKey,
        'min': value.min,
        'max': value.max,
    };
}


