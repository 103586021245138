export interface DateDiff {
    seconds?: number;
    minutes?: number;
    hours?: number;
    days?: number;
    months?: number;
    years?: number;
}

export default class DateUtils {

    public static add(date: Date|number, diff: DateDiff): Date {
        const time = new Date(date);
        const year = time.getFullYear() + (diff.years || 0);
        const month = time.getMonth() + (diff.months || 0);
        const day = time.getDate() + (diff.days || 0);
        const hours = time.getHours() + (diff.hours || 0);
        const minutes = time.getMinutes() + (diff.minutes || 0);
        const seconds = time.getSeconds() + (diff.seconds || 0);
        return new Date(year, month, day, hours, minutes, seconds);
    }

    public static subtract(date: Date|number, diff: DateDiff): Date {
        const time = new Date(date);
        const year = time.getFullYear() - (diff.years || 0);
        const month = time.getMonth() - (diff.months || 0);
        const day = time.getDate() - (diff.days || 0);
        const hours = time.getHours() - (diff.hours || 0);
        const minutes = time.getMinutes() - (diff.minutes || 0);
        const seconds = time.getSeconds() - (diff.seconds || 0);
        return new Date(year, month, day, hours, minutes, seconds);
    }

    public static cropToPrecision(date: Date, precision: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds'): Date {
        switch (precision) {
            case 'years': return new Date(date.getFullYear(), 0, 1, 0, 0, 0);
            case 'months': return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
            case 'days': return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
            case 'hours': return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), 0, 0);
            case 'minutes': return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0);
            case 'seconds': return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
            default: return date;
        }
    }

    public static isMonthComplete(date?: Date|number, referenceDate?: Date): boolean {
        if (date === undefined) {
            return false;
        }
        let now = new Date();
        if (referenceDate) {
            now = DateUtils.add(referenceDate, { months: 1 });
        }
        const then = new Date(date);
        return then.getFullYear() < now.getFullYear()
            || (then.getFullYear() === now.getFullYear() && then.getMonth() < now.getMonth());
    }

    public static getDaysBetweenDates(a: Date, b: Date): number {
        const millisDiff = Math.abs(a.getTime() - b.getTime());
        const millisPerDay = 1000 * 60 * 60 * 24;
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round(millisDiff / millisPerDay);
    }

    public static getDaysInMonth(month: number, year: number = new Date().getFullYear()): number {
        const months31 = [0, 2, 4, 6, 7, 9, 11];
        const months30 = [3, 5, 8, 10];
        if (months31.includes(month)) {
            return 31;
        }
        if (months30.includes(month)) {
            return 30;
        }
        if (new Date(year, 1, 29).getDate() === 29) {
            return 29;
        }
        return 28;
    }

    public static isMultipleFullMonths(from: Date, to: Date): boolean {
        // to date is always exclusive for days
        return from.getDate() === 1
            && to.getDate() === 1
            && !this.isSingleFullYear(from, to);
    }

    public static isSingleFullMonth(from: Date, to: Date): boolean {
        // to date is always exclusive for days
        if (from.getDate() !== 1 || to.getDate() !== 1) {
            return false;
        }
        return (from.getFullYear() === to.getFullYear() && from.getMonth() + 1 === to.getMonth())
            || (from.getFullYear() + 1 === to.getFullYear() && from.getMonth() === 11 && to.getMonth() === 0);
    }

    public static isSingleFullYear(from: Date, to: Date): boolean {
        // to date is always exclusive for days
        if (from.getDate() !== 1 || to.getDate() !== 1 || from.getMonth() !== 0 || to.getMonth() !== 0) {
            return false;
        }
        return to.getFullYear() === from.getFullYear() + 1;
    }

    /**
     * This method creates a Date object if month and year are provided.
     *
     * @param month the month of the Date object with 1 = January
     * @param year the year of the Date object
     */
    public static fromMonthAndYear(month?: number, year?: number): Date | undefined {
        if (!month || !year || month < 1) {
            return undefined;
        }

        // we convert 1 = January to 0 = January for date objects
        return new Date(year, month - 1);
    }
}
