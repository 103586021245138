import type { Portfolio } from '@/modules/ctx-dashboard/types';
import type { PortfolioRepository } from '@/modules/ctx-dashboard/adapter';

export class PortfolioService {

    private readonly portfolioRepository: PortfolioRepository;

    constructor(params: {
        portfolioRepository: PortfolioRepository;
    }) {
        this.portfolioRepository = params.portfolioRepository;
    }

    public onChange(cb: () => Promise<void>): void {
        this.portfolioRepository.onChange(cb);
    }

    public async getPortfolios(): Promise<Portfolio[]> {
        return this.portfolioRepository.getPortfolios();
    }

    public async getPortfolio(portfolioKey: string): Promise<Portfolio> {
        return this.portfolioRepository.getPortfolio(portfolioKey);
    }

    public async hasPortfolios(): Promise<boolean> {
        const portfolios = await this.portfolioRepository.getPortfolios();
        return portfolios.length > 0;
    }
}
