import Utils from '@/assets/js/utils/Utils';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { Layout, LayoutConfig } from '@/modules/ctx-report-preview/types';
import type { ReportConfig, ReportWidgetConfig } from '@/modules/ctx-report-preview/types';

export interface Slot {
    page: number;
    position: number;
}

export class EditorService {

    public updateWidget(reportConfig: ReportConfig, targetSlot: Slot|null, widget: ReportWidgetConfig|null): ReportConfig {
        if (!targetSlot) {
            console.warn('Missing target page!');
            return reportConfig;
        }
        const updatedLayouts: number[] = [];
        if (widget) {
            // remove widget from all old positions
            // checking all layouts here is a more robust solution than just relying on the old index
            for (let layoutIndex = 0; layoutIndex < reportConfig.layouts.length; layoutIndex++) {
                const layout = reportConfig.layouts[layoutIndex];
                for (let slotIndex = 0; slotIndex < layout.widgets.length; slotIndex++) {
                    const slot = layout.widgets[slotIndex];
                    if (slot && slot.widgetKey === widget.widgetKey && slot.displayMode === widget?.displayMode) {
                        layout.widgets[slotIndex] = undefined;
                        layout.layoutComponent = this.getLayoutComponentForPage(layout);
                        layout.hash = Utils.hash(layout);
                        if (!updatedLayouts.includes(layoutIndex)) {
                            updatedLayouts.push(layoutIndex);
                        }
                    }
                }
            }
        }

        // update target page
        const page = reportConfig.layouts[targetSlot.page];
        if (widget) {
            // insert widget at new position
            widget.page = targetSlot.page;
            widget.position = targetSlot.position;
            page.widgets[targetSlot.position] = widget;
            // enforce full page layout for tables
            if (widget.displayMode === 'table') {
                page.layout = Layout.Layout1;
            }
        } else {
            // remove widget at slot
            page.widgets[targetSlot.position] = undefined;
        }
        page.layoutComponent = this.getLayoutComponentForPage(page);
        page.hash = Utils.hash(page);

        // check if one of the updated layouts is now empty
        // must be done last, to ensure insertion index does not change
        updatedLayouts.sort().reverse()
            .filter((index) => !reportConfig.layouts[index].widgets.find((w) => w !== undefined))
            .forEach((index) => reportConfig.layouts.splice(index, 1));
        return reportConfig;
    }

    public getLayoutComponentForPage(page: LayoutConfig): any {
        if (page.widgets.find((w) => w?.displayMode === 'table')) {
            return () => import('@/modules/ctx-report-preview/components/layouts/ReportLayoutTable.vue');
        }
        return this.mapToLayoutComponent(page.layout);
    }

    public mapToLayoutComponent(layout: Layout): any {
        switch (layout) {
            case Layout.CoverPage: return () => import('@/modules/ctx-report-preview/components/layouts/ReportTitlePage.vue');
            case Layout.TableOfContent: return () => import('@/modules/ctx-report-preview/components/layouts/ReportTableOfContent.vue');
            case Layout.Summary: return () => import('@/modules/ctx-report-preview/components/layouts/ReportSummary.vue');
            case Layout.Layout2a: return () => import('@/modules/ctx-report-preview/components/layouts/ReportLayout2a.vue');
            case Layout.Layout2b: return () => import('@/modules/ctx-report-preview/components/layouts/ReportLayout2b.vue');
            case Layout.Layout3a: return () => import('@/modules/ctx-report-preview/components/layouts/ReportLayout3a.vue');
            case Layout.Layout3b: return () => import('@/modules/ctx-report-preview/components/layouts/ReportLayout3b.vue');
            case Layout.Layout3c: return () => import('@/modules/ctx-report-preview/components/layouts/ReportLayout3c.vue');
            case Layout.Layout3d: return () => import('@/modules/ctx-report-preview/components/layouts/ReportLayout3d.vue');
            case Layout.Layout4: return () => import('@/modules/ctx-report-preview/components/layouts/ReportLayout4.vue');
            case Layout.Layout1:
            default: return () => import('@/modules/ctx-report-preview/components/layouts/ReportLayout1.vue');
        }
    }

    public movePage(reportConfig: ReportConfig, pageIndex: number, to: 'up'|'down'|'top'|'end'): ReportConfig {
        const layout = reportConfig.layouts[pageIndex];
        if (!layout) {
            return reportConfig;
        }
        switch (to) {
            // first 3 layouts (0, 10, 20) are fixed and cannot be moved or edited, so the first non generic page has
            // a position of 30
            case 'top': layout.position = 25; break;
            case 'up': layout.position -= 12; break;
            case 'down': layout.position += 12; break;
            case 'end': layout.position = reportConfig.layouts.length * 10 + 10; break;
            default:
        }
        return this.updatePositions(reportConfig);
    }

    public deletePage(reportConfig: ReportConfig, pageIndex: number): ReportConfig {
        reportConfig.layouts.splice(pageIndex, 1);
        return this.updatePositions(reportConfig);
    }

    public insertPage(reportConfig: ReportConfig, afterIndex: number): ReportConfig {
        const newLayout: LayoutConfig = {
            position: afterIndex * 10 + 5,
            key: Utils.generateUUID(),
            layout: Layout.Layout1,
            layoutComponent: this.mapToLayoutComponent(Layout.Layout1),
            widgets: [],
            editable: true,
        };
        newLayout.hash = Utils.hash(newLayout);
        reportConfig.layouts.splice(afterIndex, 0, newLayout);
        return this.updatePositions(reportConfig);
    }

    public changeLayout(reportConfig: ReportConfig, pageIndex: number, layout: Layout): ReportConfig {
        const layoutRef = reportConfig.layouts[pageIndex];
        if (!layoutRef || layoutRef.layout === layout) {
            return reportConfig;
        }
        layoutRef.widgets = layoutRef.widgets.filter(ArrayUtils.filterUndefined);
        layoutRef.layout = layout;
        layoutRef.layoutComponent = this.mapToLayoutComponent(layout);
        return reportConfig;
    }

    public updatePositions(reportConfig: ReportConfig): ReportConfig {
        reportConfig.layouts = reportConfig.layouts.sort((a, b) => a.position - b.position);
        reportConfig.layouts.forEach((a, index) => a.position = index * 10);
        return reportConfig;
    }
}
