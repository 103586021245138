import { AccountingRepository } from '@/modules/ctx-admin-accounting/adapter';
import type {
    Account,
    AccountGroup,
    AccountingMetric,
    AccountingMetricFormula,
    MetricPosition,
} from '@/modules/ctx-admin-accounting';
import { ResultType } from '@/clients/dashboardapi/v2';
import Utils from '@/assets/js/utils/Utils';
import { i18n, I18nAdapter } from '@/plugins/i18n';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import NotificationService from '@/assets/js/services/NotificationService';

export interface AccountFilter {
    dataProvider?: string;
    type?: string;
    filterString?: string;
}

export class AccountingService {

    private readonly accountingRepository: AccountingRepository;

    constructor(params: {
        accountingRepository: AccountingRepository;
    }) {
        this.accountingRepository = params.accountingRepository;
    }

    /**
     * Returns a list of all accounts
     */
    public async getAccounts(filter?: AccountFilter): Promise<Account[]> {
        let accounts: Account[] = await this.accountingRepository.getAccounts();
        if (filter) {
            accounts = accounts
                .filter((acc) => !filter.dataProvider || acc.dataProvider === filter.dataProvider)
                .filter((acc) => !filter.type || acc.type.toLowerCase() === filter.type.toLowerCase())
                .filter((acc) => !filter.filterString
                    || acc.name.includes(filter.filterString)
                    || (acc.accountNumber && acc.accountNumber.toString().includes(filter.filterString))
                    || (acc.subAccountNumber && acc.subAccountNumber.toString().includes(filter.filterString)));
        }
        // return accounts.sort((a, b) => a.name.localeCompare(b.name));
        return accounts.sort((a, b) => a.accountNumber - b.accountNumber);
    }

    public async getDataProviders(): Promise<string[]> {
        return (await this.getAccounts())
            .map((acc) => acc.dataProvider)
            .filter(ArrayUtils.removeDuplicates);
    }

    /**
     * Returns a list of all accounts for this dataprovider
     */
    public async getAccountsForDataProvider(dataProviderKey: string): Promise<Account[]> {
        return (await this.getAccounts())
            .filter((acc) => acc.dataProvider === dataProviderKey);
    }

    /**
     * Returns a list of all accounts for this formula
     */
    public async getPositionsForFormula(formula: AccountingMetricFormula): Promise<MetricPosition[]> {
        return this.accountingRepository.getPositionsForFormula(formula);
    }

    /**
     * Returns a list of all accounting metrics
     */
    public async getAccountingMetrics(): Promise<AccountingMetric[]> {
        const groups = await this.accountingRepository.getAccountGroups();
        return groups.flatMap((group) => group.metrics);
    }

    /**
     * Returns a list of all accounting metrics that have a formula for the given data provider
     */
    public async getAccountingMetricsByDataProvider(dataProviderKey: string, filter?: string): Promise<AccountingMetric[]> {
        let metrics = await this.getAccountingMetrics();
        metrics = metrics.filter((metric) => metric.formulas.find((formula) => formula.dataProviderKey === dataProviderKey));
        if (filter) {
            const filterLc = filter.trim().toLowerCase();
            metrics = metrics.filter((metric) => Object.values(metric.name).find((name) => name && name.toLowerCase().includes(filterLc)));
        }
        return metrics;
    }

    public async getAccountingMetricByKey(key: string): Promise<AccountingMetric|undefined> {
        const groups = await this.accountingRepository.getAccountGroups();
        return groups
            .flatMap((group) => group.metrics)
            .find((metric) => metric.key === key);
    }

    public async getAccountingMetricByName(name: string): Promise<AccountingMetric|undefined> {
        const groups = await this.accountingRepository.getAccountGroups();
        return groups
            .flatMap((group) => group.metrics)
            .find((metric) => metric.name[i18n.locale] === name);
    }

    /**
     * Returns a list of all account groups
     */
    public async getAccountGroups(): Promise<AccountGroup[]> {
        const accountGroups = await this.accountingRepository.getAccountGroups();
        return accountGroups.sort((a, b) => (a.name[i18n.locale] || '').localeCompare(b.name[i18n.locale] || ''));
    }

    public async saveAccountGroup(accountGroup: AccountGroup): Promise<AccountGroup> {
        if (accountGroup.key) {
            return this.accountingRepository.updateAccountGroup(accountGroup);
        }
        return this.accountingRepository.createAccountGroup(accountGroup);
    }

    public async deleteAccountGroup(accountGroup: AccountGroup): Promise<void> {
        try {
            await this.accountingRepository.deleteAccountGroup(accountGroup.key);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async validateAccountGroup(accountGroup: AccountGroup): Promise< { [key: string]: string[] }> {
        const errors: { [key: string]: string[] } = {};
        if (Object.values(accountGroup.name).find((translation) => translation.trim().length === 0)) {
            // validate missing translations
            errors.name = [i18n.t('admin-accounting.validation.required').toString()];
        } else {
            // validate duplicate names
            const accountGroups = await this.accountingRepository.getAccountGroups();
            const translations = Object.entries(accountGroup.name).map((kv) => ({ locale: kv[0], value: kv[1] }));
            translations.forEach((translation) => {
                const nameErrors = errors[`name.${translation.locale}`] || [];
                // validate missing translations
                if (translation.value.trim().length === 0) {
                    nameErrors.push(i18n.t('admin-accounting.validation.required').toString());
                }
                // validate duplicate names
                if (accountGroups.find((it) => it.key !== accountGroup.key && it.name[translation.locale] === translation.value)) {
                    nameErrors.push(i18n.t('admin-accounting.validation.account-group.duplicate').toString());
                }
                if (nameErrors.length > 0) {
                    errors[`name.${translation.locale}`] = nameErrors;
                }
            });
        }
        return errors;
    }

    public async saveAccountingMetric(group: AccountGroup, metric: AccountingMetric): Promise<AccountingMetric> {
        if (metric.key) {
            return this.accountingRepository.updateAccountingMetric(metric);
        }
        return this.accountingRepository.createAccountingMetric(group.key, metric);
    }

    public async deleteAccountingMetric(metric: AccountingMetric, force: boolean = false): Promise<void> {
        await this.accountingRepository.deleteAccountingMetric(metric.key, force);
    }

    public async validateAccountingMetric(metric: AccountingMetric): Promise<{ [key: string]: string[] } > {
        const errors: { [key: string]: string[] } = {};
        // validate unit
        if (!metric.unit) {
            errors.unit = ['Missing unit'];
        }

        const accountingMetrics = await this.getAccountingMetrics();
        const translations = Object.entries(metric.name).map((kv) => ({ locale: kv[0], value: kv[1] }));
        translations.forEach((translation) => {
            const nameErrors = errors[`name.${translation.locale}`] || [];
            // validate missing translations
            if (translation.value.trim().length === 0) {
                nameErrors.push(i18n.t('admin-accounting.validation.required').toString());
            }
            // validate invalid characters
            if (translation.value.includes('\'')) {
                nameErrors.push(i18n.t('admin-accounting.validation.invalid-characters').toString());
            }
            // validate duplicate names
            if (accountingMetrics.find((it) => it.key !== metric.key && it.name[translation.locale] === translation.value)) {
                nameErrors.push(i18n.t('admin-accounting.validation.metric.duplicate').toString());
            }
            if (nameErrors.length > 0) {
                errors[`name.${translation.locale}`] = nameErrors;
            }
        });
        // validate formulas
        const error: string|null = await Promise.all(metric.formulas.map(((it) => this.getPositionsForFormula(it))))
            .then((e) => null)
            .catch((e) => e.toString());
        if (error) {
            errors.formulas = [error];
        }
        return errors;
    }

    public addMetricToExpression(expression: string, metricKey: string, operation: '+'|'-'|'*'|'/' = '+'): string {
        let exp = expression.trim();
        if (exp !== '') {
            exp = `${exp.trim()} ${operation}`;
        } else if (operation === '-') {
            exp = '-';
        }
        exp = `${exp} pos('${metricKey}')`;
        return exp.trim();
    }

    public addAccountToExpression(expression: string, account: Account, bookingType: 'credit'|'debit', operation: '+'|'-'|'*'|'/' = '+'): string {
        let exp = expression.trim();
        if (account.accountNumber) {
            if (exp !== '') {
                exp = `${exp.trim()} ${operation}`;
            } else if (operation === '-') {
                exp = '-';
            }
            const isNotDefaultType = account.type && account.type.toLowerCase() !== 'ist';
            exp = `${exp} ${bookingType}(${account.accountNumber}`;
            if (account.subAccountNumber) {
                exp = `${exp}, ${account.subAccountNumber}`;
            } else if (isNotDefaultType) {
                // exp = `${exp}, 0`;
            }
            if (isNotDefaultType) {
                exp = `${exp}, '${account.type}'`;
            }
            exp = `${exp})`;
        }
        return exp.trim();
    }

    public async renderExpression(expression: string): Promise<string> {
        const parts = expression.split(/(pos\('.*?'\))/g); // split at every pos( without removing the position;
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].startsWith('pos(\'')) {
                const key = parts[i].split('\'')[1];
                // eslint-disable-next-line no-await-in-loop
                const metric = await this.getAccountingMetricByKey(key);
                if (metric) {
                    parts[i] = parts[i].replace(key, metric.name[i18n.locale] || key);
                }
            }
        }
        return parts.join('');
    }

    public async updateExpressionFromInput(input: string): Promise<string> {
        const parts = input.split(/(pos\('.*?'\))/g); // split at every pos( without removing the position;
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].startsWith('pos(\'')) {
                const name = parts[i].split('\'')[1];
                // eslint-disable-next-line no-await-in-loop
                const metric = await this.getAccountingMetricByName(name);
                if (metric) {
                    parts[i] = parts[i].replace(name, metric.key);
                }
            }
        }
        return parts.join('');
    }

    public createAccountGroupEditCopy(accountGroup: AccountGroup|null): AccountGroup {
        if (accountGroup) {
            return Utils.deepCopy(accountGroup);
        }
        return {
            key: '',
            nameKey: '',
            name: I18nAdapter.translateForAllLoadedLanguages(),
            metrics: [],
        };
    }

    public async getBlankAccountMetric(): Promise<AccountingMetric> {
        const dataProviders = await this.getDataProviders();
        return {
            key: '',
            name: I18nAdapter.translateForAllLoadedLanguages(),
            nameKey: '',
            resultType: ResultType.Balance,
            unit: '€',
            licenseFeatures: [],
            formulas: dataProviders.map((key) => ({
                dataProviderKey: key,
                expression: '',
            })),
        };
    }
}
