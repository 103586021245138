import { Configuration, AccountingApi as AccountingRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type { LocalizationApi } from '@/modules/shared/adapter/LocalizationApi';
import type {
    AccountingApi,
    AccountGroupCreateRequest,
    AccountGroupRepresentation,
    AccountGroupUpdateRequest,
    AccountingFormulaValidationRequest,
    AccountingFormulaValidationResponse,
    AccountingMetricCreateRequest,
    AccountingMetricRepresentation,
    AccountingMetricUpdateRequest,
    AccountRepresentation,
    ShareholderRepresentation,
} from '@/modules/shared/adapter/AccountingApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedAccountingRestApi implements AccountingApi {

    private readonly authApi: AuthApi;
    private readonly i18nApi: LocalizationApi;
    private readonly accountsCache: IndexedDBRepository<string, AccountRepresentation>;
    private readonly accountGroupsCache: IndexedDBRepository<string, AccountGroupRepresentation>;
    private readonly shareholderCache: IndexedDBRepository<string, ShareholderRepresentation>;
    private readonly accountingApi: AccountingRestApi;
    private readonly demoCustomers: Map<string, string> = new Map<string, string>();

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi, i18n: LocalizationApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.authApi = apis.auth;
        this.i18nApi = apis.i18n;
        this.accountingApi = new AccountingRestApi(restApiConfig);
        this.accountsCache = new IndexedDBRepository<string, AccountRepresentation>(indexedDb, 'accounts');
        this.accountGroupsCache = new IndexedDBRepository<string, AccountGroupRepresentation>(indexedDb, 'account-groups');
        this.shareholderCache = new IndexedDBRepository<string, ShareholderRepresentation>(indexedDb, 'shareholder');

        const demoCustomerNames: string[] = (process.env.VUE_APP_DEMO_CUSTOMERS || '').split(', ');
        const demoCustomerShareholderNames: string[] = (process.env.VUE_APP_DEMO_SHAREHOLDERNAMES || '').split(', ');
        demoCustomerNames.forEach((name, index) => this.demoCustomers.set(name, demoCustomerShareholderNames[index]));
    }

    public async getAccounts(): Promise<AccountRepresentation[]> {
        return await this.accountsCache.findAllIfAnyCached() || this.fetchAccounts();
    }

    public async getAccountByKey(key: string): Promise<AccountRepresentation|undefined> {
        const cached = await this.accountsCache.findByKey(key);
        if (cached) {
            return cached;
        }
        const accounts = await this.fetchAccounts();
        return accounts.find((acc) => acc.key === key);
    }

    private async fetchAccounts(): Promise<AccountRepresentation[]> {
        return AsyncDebouncer.debounce<AccountRepresentation[]>('CachedAccountingRestApi.fetchAccounts', async () => {
            const accounts = await this.accountingApi.getAccounts();
            await this.accountsCache.saveAll(accounts);
            return accounts;
        });
    }

    public async parseFormula(formula: AccountingFormulaValidationRequest): Promise<AccountingFormulaValidationResponse> {
        try {
            return await this.accountingApi.parseAccountingFormula(formula);
        } catch (e: any) {
            const details = await e.clone().text();
            throw details || e;
        }
    }

    public async getAccountGroups(): Promise<AccountGroupRepresentation[]> {
        return await this.accountGroupsCache.findAllIfAnyCached() || this.fetchAccountGroups();
    }

    private async fetchAccountGroups(): Promise<AccountGroupRepresentation[]> {
        return AsyncDebouncer.debounce<AccountGroupRepresentation[]>('CachedAccountingRestApi.fetchAccountGroups', async () => {
            const accountGroups = await this.accountingApi.getAccountGroups();
            await this.accountGroupsCache.saveAll(accountGroups);
            return accountGroups;
        });
    }

    public async createAccountGroup(accountGroup: AccountGroupCreateRequest): Promise<AccountGroupRepresentation> {
        const savedAccountGroup = await this.accountingApi.createAccountGroup(accountGroup);
        // account groups have localized names, an update may require an i18n reload
        this.i18nApi.requestReload(accountGroup.lc);
        await this.accountGroupsCache.save(savedAccountGroup);
        return savedAccountGroup;
    }

    public async updateAccountGroup(accountGroupKey: string, accountGroupUpdateRequest: AccountGroupUpdateRequest): Promise<AccountGroupRepresentation> {
        const savedAccountGroup = await this.accountingApi.updateAccountGroup(accountGroupKey, accountGroupUpdateRequest);
        // account groups have localized names, an update may require an i18n reload
        this.i18nApi.requestReload(accountGroupUpdateRequest.lc);
        await this.accountGroupsCache.save(savedAccountGroup);
        return savedAccountGroup;
    }

    public async deleteAccountGroup(accountGroupKey: string): Promise<void> {
        await this.accountingApi.deleteAccountGroup(accountGroupKey);
        await this.accountGroupsCache.removeByKey(accountGroupKey);
    }

    public async createAccountingMetric(accountGroupKey: string, accountingMetricCreateRequest: AccountingMetricCreateRequest): Promise<AccountingMetricRepresentation> {
        const metric = await this.accountingApi.createAccountingMetric(accountGroupKey, accountingMetricCreateRequest);
        // accounting metrics have localized names, an update may require an i18n reload
        this.i18nApi.requestReload(accountingMetricCreateRequest.lc);
        await this.accountGroupsCache.removeAll();
        return metric;
    }

    public async updateAccountingMetric(accountingMetricKey: string, accountingMetricUpdateRequest: AccountingMetricUpdateRequest): Promise<AccountingMetricRepresentation> {
        const metric = await this.accountingApi.updateAccountingMetric(accountingMetricKey, accountingMetricUpdateRequest);
        // accounting metrics have localized names, an update may require an i18n reload
        this.i18nApi.requestReload(accountingMetricUpdateRequest.lc);
        await this.accountGroupsCache.removeAll();
        return metric;
    }

    public async deleteAccountingMetric(accountingMetricKey: string, force?: boolean): Promise<void> {
        await this.accountingApi.deleteAccountingMetric(accountingMetricKey, force);
        await this.accountGroupsCache.removeAll();
    }

    public async getShareholders(): Promise<ShareholderRepresentation[]> {
        return await this.shareholderCache.findAllIfAnyCached() || this.fetchShareholders();
    }

    public async getShareholdersByPortfolio(portfolioKey: string): Promise<ShareholderRepresentation[]> {
        // prefetch shareholder cached after querying, as the user will probably need them
        this.getShareholders();
        return AsyncDebouncer.debounce<ShareholderRepresentation[]>(`CachedAccountingRestApi.fetchShareholdersByPortfolio(${portfolioKey})`, async () => {
            let shareholders = await this.accountingApi.getShareholders(portfolioKey);
            const company = await this.authApi.getCompany();
            const mask = this.demoCustomers.get(company.key);
            if (mask) {
                shareholders = shareholders.map((shareholder) => CachedAccountingRestApi.maskShareholder(shareholder, mask));
            } else {
                shareholders = shareholders.map((shareholder) => ({
                    ...shareholder,
                    name: shareholder.name
                        // TODO fix encoding in backend/importer
                        .replace('M�rkisch', 'Märkisch')
                        .replace('S�d', 'Süd'),
                }));
            }
            return shareholders;
        });
    }

    private async fetchShareholders(): Promise<ShareholderRepresentation[]> {
        return AsyncDebouncer.debounce<ShareholderRepresentation[]>('CachedAccountingRestApi.fetchShareholders', async () => {
            let shareholders = await this.accountingApi.getShareholders();
            const company = await this.authApi.getCompany();
            const mask = this.demoCustomers.get(company.key);
            if (mask) {
                shareholders = shareholders.map((shareholder) => CachedAccountingRestApi.maskShareholder(shareholder, mask));
            } else {
                shareholders = shareholders.map((shareholder) => ({
                    ...shareholder,
                    name: shareholder.name
                        // TODO fix encoding in backend/importer
                        .replace('M�rkisch', 'Märkisch')
                        .replace('S�d', 'Süd'),
                }));
            }
            await this.shareholderCache.saveAll(shareholders);
            return shareholders;
        });
    }

    public async getShareholderByKey(key: string): Promise<ShareholderRepresentation|undefined> {
        const cached = await this.shareholderCache.findByKey(key);
        if (cached) {
            return cached;
        }
        const shareholders = await this.fetchShareholders();
        return shareholders.find((it) => it.key === key);
    }

    private static maskShareholder(shareholderRepresentation: ShareholderRepresentation, mask: string): ShareholderRepresentation {
        let hashStr = '';
        let hash = 0;
        for (let i = 0; i < shareholderRepresentation.key.length; i++) {
            const char = shareholderRepresentation.key.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << 5) - hash) + char;
            // eslint-disable-next-line no-bitwise
            hash &= hash; // Convert to 32bit integer
        }
        if (hash < 0) {
            hashStr = `1${(hash * -1).toString(16)}`;
        } else {
            hashStr = hash.toString(16);
        }
        shareholderRepresentation.name = `${mask}${hashStr.substring(0, 6)}`;
        return shareholderRepresentation;
    }
}
