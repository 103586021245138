import { i18n } from '@/plugins/i18n';
import Formatter from '@/assets/js/utils/Formatter';

type ValidationRule = (value: any) => boolean|string;

export default class Validator {

    private rules: Map<string, ValidationRule[]> = new Map<string, ValidationRule[]>();

    public static addRule(field: string, rules: ValidationRule[]): Validator {
        const validator = new Validator();
        return validator.addRule(field, rules);
    }

    public addRule(field: string, rules: ValidationRule[]): Validator {
        this.rules.set(field, rules.concat(this.rules.get(field) || []));
        return this;
    }

    public validate(object: any): {[key: string]: string[]} {
        const validationResult: {[key: string]: string[]} = {};
        if (!object) {
            validationResult.obj = ['object is null'];
            return validationResult;
        }
        [...this.rules.entries()].forEach((validator) => {
            const field: string = validator[0];
            const rules: ValidationRule[] = validator[1] || [];
            const value: any = object[field];
            const errors: string[] = rules
                .map((rule) => rule(value)) // execute rule
                .filter((result) => result !== true) // filter out non errors
                .map((error) => error.toString()); // map to string for typescript
            if (errors.length > 0) {
                validationResult[field] = errors;
            }
        });
        return validationResult;
    }
}

// eslint-disable-next-line no-control-regex,prefer-regex-literals
const regexEmail: RegExp = new RegExp('(?:[a-z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])');

function parseToMillis(value: any): number {
    const date = new Date(value);
    if (date && !Number.isNaN(date.getTime())) {
        return date.getTime();
    }
    const millis = parseInt(value, 10);
    if (!Number.isNaN(millis)) {
        return new Date(millis).getTime();
    }
    return NaN;
}

function currentTime(): number {
    return new Date().getTime();
}

function printDebug(value: any): boolean {
    console.log(parseToMillis(value));
    return true;
}

function valueNotSet(value: any) {
    return value === null || value === undefined;
}

function validNumber(value: any) {
    return typeof value === 'number' || typeof value === 'bigint' || !Number.isNaN(parseFloat(value));
}

function hasLessOrEqualDigitsThan(value: any, digits: number) {
    const str = value.toString();
    return !str.includes('.') || str.split('.')[1].length <= digits;
}

const Rules = {

    // Generic Validation Rules
    /** valid when value is not null or undefined */
    NotNull: (value: any) => (value !== null && value !== undefined) || i18n.t('validation.not-null').toString(),
    /** valid when value is neither null, undefined or and empty string */
    NotNullOrEmpty: (value: any) => (value !== null && value !== undefined && value !== '') || i18n.t('validation.not-empty').toString(),

    // Numeric Validation Rules
    /** valid when value is a valid number */
    ValidNumber: (value: any) => validNumber(value) || i18n.t('validation.valid-number').toString(),
    /** valid when value is undefined or greater than the provided compare number */
    GreaterThan: (compareTo: number) => (value: any) => (valueNotSet(value) || !validNumber(value) || value > compareTo) || i18n.t('validation.greater-than', { value: compareTo }).toString(),
    GreaterThanOrEqual: (compareTo: number) => (value: any) => (valueNotSet(value) || !validNumber(value) || value >= compareTo) || i18n.t('validation.greater-than-or-equal', { value: compareTo }).toString(),
    /** valid when value is undefined or lesser than the provided compare number */
    LesserThan: (compareTo: number) => (value: any) => (valueNotSet(value) || !validNumber(value) || value < compareTo) || i18n.t('validation.lesser-than', { value: compareTo }).toString(),
    LesserThanOrEqual: (compareTo: number) => (value: any) => (valueNotSet(value) || !validNumber(value) || value <= compareTo) || i18n.t('validation.lesser-than-or-equal', { value: compareTo }).toString(),
    // eslint-disable-next-line no-confusing-arrow
    MaxDecimalPlaces: (digits: number) => (value: any) => (valueNotSet(value) || hasLessOrEqualDigitsThan(value, digits)) || i18n.t('validation.max-digits', { value: digits }).toString(),
    NoDecimalPlaces: (value: any) => (valueNotSet(value) || hasLessOrEqualDigitsThan(value, 0)) || i18n.t('validation.no-digits').toString(),

    // Date Validation Rules
    /** valid when value is a valid date */
    ValidDate: (value: any) => (valueNotSet(value) || typeof value === 'number' || value instanceof Date) || i18n.t('validation.valid-date').toString(),
    /** valid when value is undefined or after the provided date */
    DateAfter: (compareDate: any) => (value: any) => (valueNotSet(value) || valueNotSet(compareDate) || parseToMillis(value) > parseToMillis(compareDate)) || i18n.t('validation.date-after', { value: Formatter.formatDate(parseToMillis(compareDate)) }).toString(),
    /** valid when value is undefined or before the provided date */
    DateBefore: (compareDate: any) => (value: any) => (valueNotSet(value) || valueNotSet(compareDate) || parseToMillis(value) < parseToMillis(compareDate)) || i18n.t('validation.date-before', { value: Formatter.formatDate(parseToMillis(compareDate)) }).toString(),
    /** valid when value is undefined or a date in the future */
    DateInFuture: (value: any) => (valueNotSet(value) || parseToMillis(value) > currentTime()) || i18n.t('validation.date-future').toString(),
    /** valid when value is undefined or a date in the past */
    DateInPast: (value: any) => (valueNotSet(value) || parseToMillis(value) < currentTime()) || i18n.t('validation.date-past').toString(),

    // Array Validation Rules
    /** valid when value is an array with one or more entries  */
    ArrayNotEmpty: (value: any) => (Array.isArray(value) && (value as []).length > 0) || i18n.t('validation.array-non-empty').toString(),
    /** valid when value is an array with at least the provided item count  */
    ArrayLengthGreaterThan: (minLength: number) => (value: any) => (Array.isArray(value) && value.length > minLength) || i18n.t('validation.array-min-length', { value: minLength }).toString(),

    ValueNotInBlacklist: (blacklist: any[], customMessage?: string) => (value: any) => !blacklist.find((it) => it === value) || customMessage || i18n.t('validation.invalid-input').toString(),

    ValueInWhitelist: (whitelist: any[], customMessage?: string) => (value: any) => whitelist.find((it) => it === value) || customMessage || i18n.t('validation.invalid-input').toString(),

    // Email Validation Rules
    /** valid when value is a valid email address */
    ValidEmail: (value: any) => regexEmail.test(value) || i18n.t('validation.email').toString(),

    /** valid when value is a valid email address or an empty value */
    ValidEmailOrEmpty: (value: any) => valueNotSet(value) || value === '' || regexEmail.test(value) || i18n.t('validation.email').toString(),
    // ...

    /** print value to console for debugging new rules */
    Debug: (value: any) => printDebug(value),
};

export { Rules };
