import Formatter from '@/assets/js/utils/Formatter';

export default class LoggingUtils {

    private static readonly messageStyle = 'background:transparent;color:inherit';
    private static disableFormatting: boolean = false;

    public static log(tag: string, message: string, tagColor: string = '#42a6c6') {
        if (LoggingUtils.disableFormatting) {
            console.log(message);
        } else {
            const tagStyle = `background:${tagColor};color:white;border-radius:2px`;
            console.log(`%c ${tag} %c ${message}`, tagStyle, this.messageStyle);
        }
    }

    public static throwLogsForHeadlessChromeDebugging() {
        LoggingUtils.disableFormatting = true;
        (console as any).stdlog = console.log.bind(console);
        (console as any).stderror = console.error.bind(console);
        (console as any).stdwarn = console.warn.bind(console);
        (console as any).stdinfo = console.info.bind(console);
        console.log = (...args: any[]) => {
            LoggingUtils.logErrorWithThrow('DEBUG', JSON.stringify(args[0]));
            (console as any).stdlog.apply(console, args);
        };
        console.info = (...args: any[]) => {
            LoggingUtils.logErrorWithThrow('INFO ', JSON.stringify(args[0]));
            (console as any).stdinfo.apply(console, args);
        };
        console.warn = (...args: any[]) => {
            LoggingUtils.logErrorWithThrow('WARN ', JSON.stringify(args[0]));
            (console as any).stdwarn.apply(console, args);
        };
        console.error = (...args: any[]) => {
            LoggingUtils.logErrorWithThrow('ERROR', JSON.stringify(args[0]));
            (console as any).stderror.apply(console, args);
        };
    }

    /**
     * console.log messages are not propagated correctly in headless chrome
     * this workaround allows us still pass log messages to the rust service while rendering headless
     */
    public static async logErrorWithThrow(level: string, message: string): Promise<void> {
        // remove " around string
        const logMessage = message.substring(1, message.length - 1);
        const time = Formatter.formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss');
        throw new Error(`dbg: ${time} ${level} ${logMessage}`);
    }
}
