export enum ReportGenerationInterval {
    Weekly = 'weekly',
    Monthly = 'monthly',
    Annual = 'annual',
}

export function mapReportGenerationInterval(interval: any): ReportGenerationInterval|undefined {
    switch (interval) {
        case 'weekly': return ReportGenerationInterval.Weekly;
        case 'monthly': return ReportGenerationInterval.Monthly;
        case 'annual': return ReportGenerationInterval.Annual;
        case null: return undefined;
        case undefined: return undefined;
        default: throw new Error(`Unsupported report generation interval ${interval}`);
    }
}
