import { Configuration, TemplatesApi as TemplateRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { DataChangedEvent } from '@/modules/shared/types';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type {
    WidgetTemplatesApi,
    WidgetTemplateRepresentation,
    TemplateCreateFromWidgetRequest,
    WidgetCreateRequest,
} from '@/modules/shared/adapter';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedWidgetTemplatesRestApi implements WidgetTemplatesApi {

    private readonly templatesApi: TemplateRestApi;
    private readonly widgetTemplatesCache: IndexedDBRepository<string, WidgetTemplateRepresentation>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.templatesApi = new TemplateRestApi(restApiConfig);
        this.widgetTemplatesCache = new IndexedDBRepository<string, WidgetTemplateRepresentation>(indexedDb, 'widget-templates');
    }

    public onWidgetTemplatesChange(cb: (event: DataChangedEvent) => Promise<void>): void {
        this.widgetTemplatesCache.addChangedListener((evt) => cb(evt));
    }

    public async getWidgetTemplates(): Promise<WidgetTemplateRepresentation[]> {
        return await this.widgetTemplatesCache.findAllIfAnyCached() || this.fetchWidgetTemplates();
    }

    public async createTemplateFromWidget(createFromWidgetRequest: TemplateCreateFromWidgetRequest): Promise<WidgetTemplateRepresentation> {
        const newWidgetTemplate = await this.templatesApi.createTemplateFromWidget(createFromWidgetRequest);
        if (await this.widgetTemplatesCache.count() > 0) {
            await this.widgetTemplatesCache.save(newWidgetTemplate);
        }
        return newWidgetTemplate;
    }

    public async removeWidgetTemplate(widgetTemplateKey: string): Promise<void> {
        await this.templatesApi.removeWidgetTemplate(widgetTemplateKey);
        await this.widgetTemplatesCache.removeByKey(widgetTemplateKey);
    }

    public async getWidgetFromTemplate(widgetTemplateKey: string): Promise<WidgetCreateRequest> {
        return this.templatesApi.getWidgetStubFromTemplate(widgetTemplateKey);
    }

    private async fetchWidgetTemplates(): Promise<WidgetTemplateRepresentation[]> {
        return AsyncDebouncer.debounce('CachedTemplatesRestApi.fetchWidgetTemplates', async () => {
            const templates = await this.templatesApi.getAllWidgetTemplates();
            await this.widgetTemplatesCache.saveAll(templates);
            return templates;
        });
    }
}
