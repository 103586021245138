import {
    Configuration,
    TemplatesApi as TemplateRestApi,
} from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { DataChangedEvent } from '@/modules/shared/types';
import { AuthApi } from '@/modules/shared/adapter';
import type {
    DashboardTemplatesApi,
    TemplateCreateFromDashboardRequest,
    DashboardTemplateUpdateRequest,
    DashboardTemplateRepresentation,
} from '@/modules/shared/adapter/DashboardTemplatesApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedDashboardTemplatesRestApi implements DashboardTemplatesApi {

    private readonly authApi: AuthApi;
    private readonly templatesApi: TemplateRestApi;
    private readonly dashboardTemplatesCache: IndexedDBRepository<string, DashboardTemplateRepresentation>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.authApi = apis.auth;
        this.templatesApi = new TemplateRestApi(restApiConfig);
        this.dashboardTemplatesCache = new IndexedDBRepository<string, DashboardTemplateRepresentation>(indexedDb, 'dashboard-templates');
    }

    public onDashboardTemplatesChange(cb: (event: DataChangedEvent) => Promise<void>): void {
        this.dashboardTemplatesCache.addChangedListener((evt) => cb(evt));
    }

    public async createDashboardTemplate(spec: TemplateCreateFromDashboardRequest): Promise<DashboardTemplateRepresentation> {
        const createdTemplate = await this.templatesApi.createTemplateFromDashboard(spec);
        if (await this.dashboardTemplatesCache.count() > 0) {
            await this.dashboardTemplatesCache.save(createdTemplate);
        }
        return createdTemplate;
    }

    public async deleteDashboardTemplate(dashboardTemplateKey: string): Promise<void> {
        await this.templatesApi.removeDashboardTemplate(dashboardTemplateKey);
        await this.dashboardTemplatesCache.removeByKey(dashboardTemplateKey);
    }

    public async getDashboardTemplates(): Promise<DashboardTemplateRepresentation[]> {
        return await this.dashboardTemplatesCache.findAllIfAnyCached() || this.fetchDashboardTemplates();
    }

    public async updateDashboardTemplate(dashboardTemplateKey: string, spec: DashboardTemplateUpdateRequest): Promise<DashboardTemplateRepresentation> {
        const userKey = await this.authApi.getUser().then((user) => user.key);
        const updatedTemplate = await this.templatesApi.updateDashboardTemplate(dashboardTemplateKey, spec);
        // user is not owner of template and template is no longer global
        if (!updatedTemplate.global && updatedTemplate.createdBy !== userKey) {
            await this.dashboardTemplatesCache.remove(updatedTemplate);
        } else if (await this.dashboardTemplatesCache.count() > 0) {
            await this.dashboardTemplatesCache.save(updatedTemplate);
        }
        return updatedTemplate;
    }

    private async fetchDashboardTemplates(): Promise<DashboardTemplateRepresentation[]> {
        return AsyncDebouncer.debounce('CachedTemplatesRestApi.fetchDashboardTemplates', async () => {
            const templates = await this.templatesApi.getAllDashboardTemplates();
            await this.dashboardTemplatesCache.saveAll(templates);
            return templates;
        });
    }
}
