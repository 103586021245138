import { Configuration, GeneratorRepresentation, ParksApi as ParksRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type { ParksApi, ParkRepresentation } from '@/modules/shared/adapter/ParksApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedParksRestApi implements ParksApi {

    private readonly parksApi: ParksRestApi;
    private readonly authApi: AuthApi;
    private readonly parksCache: IndexedDBRepository<string, ParkRepresentation>;
    private readonly demoCustomers: Map<string, string> = new Map<string, string>();

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.authApi = apis.auth;
        this.parksApi = new ParksRestApi(restApiConfig);
        this.parksCache = new IndexedDBRepository<string, ParkRepresentation>(indexedDb, 'parks');

        const demoCustomerNames: string[] = (process.env.VUE_APP_DEMO_CUSTOMERS || '').split(', ');
        const demoCustomerParkNames: string[] = (process.env.VUE_APP_DEMO_PARKNAMES || '').split(', ');
        demoCustomerNames.forEach((name, index) => this.demoCustomers.set(name, demoCustomerParkNames[index]));
    }

    public async getParks(): Promise<ParkRepresentation[]> {
        return await this.parksCache.findAllIfAnyCached() || this.fetchParks();
    }

    public async getParkByKey(key: string): Promise<ParkRepresentation|undefined> {
        const cached = await this.parksCache.findByKey(key);
        if (cached) {
            return cached;
        }
        const parks = await this.fetchParks();
        return parks.find((it) => it.key === key);
    }

    public async getParksByKeys(keys: string[]): Promise<ParkRepresentation[]> {
        const allParks = await this.getParks();
        return allParks.filter((gen) => keys.includes(gen.key));
    }

    private async fetchParks(): Promise<ParkRepresentation[]> {
        return AsyncDebouncer.debounce<ParkRepresentation[]>('CachedParksRestApi.fetchParks', async () => {
            let parks = await this.parksApi.getParks();
            const company = await this.authApi.getCompany();
            const mask = this.demoCustomers.get(company.key);
            if (mask) {
                parks = parks.map((park) => CachedParksRestApi.maskPark(park, mask));
            }
            await this.parksCache.saveAll(parks);
            return parks;
        });
    }

    private static maskPark(park: ParkRepresentation, mask: string): GeneratorRepresentation {
        let hashStr = '';
        let hash = 0;
        for (let i = 0; i < park.key.length; i++) {
            const char = park.key.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << 5) - hash) + char;
            // eslint-disable-next-line no-bitwise
            hash &= hash; // Convert to 32bit integer
        }
        if (hash < 0) {
            hashStr = `1${(hash * -1).toString(16)}`;
        } else {
            hashStr = hash.toString(16);
        }
        park.name = `${mask}${hashStr.substring(0, 6)}`;
        park.zipCode = '12345';
        return park;
    }
}
