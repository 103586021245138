import type { Widget, WidgetRepository } from '@/modules/ctx-report-preview';
import type { WidgetRepresentation, WidgetsApi } from '@/modules/shared/adapter';
import { WidgetUtils } from '@/components/widgets';
import { WidgetMapper } from '@/plugins/modules/ctx-dashboard/mapper/WidgetMapper';
import { AccountingApi, GeneratorsApi, MetricsApi, ParksApi, PortfoliosApi } from '@/modules/shared/adapter';
import { DashboardType, WidgetType } from '@/modules/shared/types';

export class WidgetRepositoryImpl implements WidgetRepository {

    private readonly widgetsApi: WidgetsApi;
    private readonly widgetMapper: WidgetMapper;

    constructor(apis: {
        widgets: WidgetsApi;
        metrics: MetricsApi,
        portfolios: PortfoliosApi,
        generators: GeneratorsApi,
        parks: ParksApi,
        accounting: AccountingApi,
    }) {
        this.widgetsApi = apis.widgets;
        this.widgetMapper = new WidgetMapper({
            metrics: apis.metrics,
            portfolios: apis.portfolios,
            generators: apis.generators,
            parks: apis.parks,
            accounting: apis.accounting,
        });
    }

    public async getWidgetsForDashboard(dashboardKey: string): Promise<Widget[]> {
        const widgets = await this.widgetsApi.getWidgetsForDashboard(dashboardKey);
        return Promise.all(widgets.map((widget) => this.mapWidgetToDomain(widget)));
    }

    public async getWidgetOrder(dashboardKey: string): Promise<string[]> {
        return this.widgetsApi.getWidgetOrder(DashboardType.Dashboard, dashboardKey);
    }

    private async mapWidgetToDomain(representation: WidgetRepresentation): Promise<Widget> {
        const widget: Widget = {
            key: representation.key,
            name: representation.title || representation.generatedTitle || '',
            supportsTableView: WidgetUtils.supportsTable(representation.type),
            supportsChartView: WidgetUtils.supportsChart(representation.type),
            type: representation.type as WidgetType,
            interval: representation.intervalName,
        };
        if (!widget.name) {
            // generated widget name may not yet be present in config for widgets, that have not been saved for some time
            console.log('Applying workaround to generate widget name');
            widget.name = await this.widgetMapper.mapWidgetToDomain(representation).then(WidgetUtils.getDefaultWidgetTitle);
        }
        return widget;
    }
}
