/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GeneratorStatusRepresentation,
    GeneratorStatusRepresentationFromJSON,
    GeneratorStatusRepresentationToJSON,
} from '../models';

export interface GetAllGeneratorStatusRequest {
    locale?: string;
}

/**
 * 
 */
export class GeneratorStatusApi extends runtime.BaseAPI {

    /**
     * Return all generator status
     */
    async getAllGeneratorStatusRaw(requestParameters: GetAllGeneratorStatusRequest): Promise<runtime.ApiResponse<Array<GeneratorStatusRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/generator-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeneratorStatusRepresentationFromJSON));
    }

    /**
     * Return all generator status
     */
    async getAllGeneratorStatus(locale?: string): Promise<Array<GeneratorStatusRepresentation>> {
        const response = await this.getAllGeneratorStatusRaw({ locale: locale });
        return await response.value();
    }

}
