enum LicenseFeature {
    DEFAULT = 'default',
    ADVANCED = 'advanced',
    TECHNICAL = 'technical',
    REPORTING = 'report',
    BENCHMARKING = 'benchmark',
    ALERTING = 'alert',
    PROGNOSIS = 'prognosis',
    ACCOUNTING = 'accounting',
}

function licenseFeatureFromString(representation: string): LicenseFeature|undefined {
    switch (representation) {
        case 'default': return LicenseFeature.DEFAULT;
        case 'advanced': return LicenseFeature.ADVANCED;
        case 'technical': return LicenseFeature.TECHNICAL;
        case 'report': return LicenseFeature.REPORTING;
        case 'benchmark': return LicenseFeature.BENCHMARKING;
        case 'alert': return LicenseFeature.ALERTING;
        case 'prognosis': return LicenseFeature.PROGNOSIS;
        case 'accounting': return LicenseFeature.ACCOUNTING;
        default: return undefined;
    }
}

export {
    licenseFeatureFromString,
    LicenseFeature as LicenseFeatureEnum,
};

export default LicenseFeature;
