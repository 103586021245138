import {
    GeneratorsApi as GeneratorsRestApi,
    PortfolioApi as PortfoliosRestApi,
    Configuration, SetGeneratorActiveStatusRequest,
} from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { DataChangedEvent } from '@/modules/shared/types';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type { GeneratorRepresentation, GeneratorsApi } from '@/modules/shared/adapter/GeneratorsApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedGeneratorsRestApi implements GeneratorsApi {

    private readonly generatorsApi: GeneratorsRestApi;
    private readonly portfoliosApi: PortfoliosRestApi;
    private readonly authApi: AuthApi;
    private readonly generatorsCache: IndexedDBRepository<string, GeneratorRepresentation>;
    private readonly generatorsPortfolioMapping: Map<string, string[]> = new Map<string, string[]>();
    private readonly demoCustomers: Map<string, string> = new Map<string, string>();

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.authApi = apis.auth;
        this.generatorsApi = new GeneratorsRestApi(restApiConfig);
        this.portfoliosApi = new PortfoliosRestApi(restApiConfig);
        this.generatorsCache = new IndexedDBRepository<string, GeneratorRepresentation>(indexedDb, 'generators');

        const demoCustomerNames: string[] = (process.env.VUE_APP_DEMO_CUSTOMERS || '').split(', ');
        const demoCustomerGeneratorNames: string[] = (process.env.VUE_APP_DEMO_GENERATORNAMES || '').split(', ');
        demoCustomerNames.forEach((name, index) => this.demoCustomers.set(name, demoCustomerGeneratorNames[index]));
    }

    public onGeneratorsChanged(cb: (event: DataChangedEvent) => Promise<void>): void {
        this.generatorsCache.addChangedListener((evt) => cb(evt));
    }

    public async getGeneratorByKey(generatorKey:string): Promise<GeneratorRepresentation|undefined> {
        if (await this.generatorsCache.count() === 0) {
            await this.fetchGenerators();
        }
        return this.generatorsCache.findByKey(generatorKey);
    }

    public async getGenerators(): Promise<GeneratorRepresentation[]> {
        return await this.generatorsCache.findAllIfAnyCached() || this.fetchGenerators();
    }

    public async getGeneratorsByPortfolio(portfolioKey: string): Promise<GeneratorRepresentation[]> {
        if (this.generatorsPortfolioMapping.has(portfolioKey)) {
            const generators = await this.generatorsCache.findAll();
            const generatorKeys = this.generatorsPortfolioMapping.get(portfolioKey) || [];
            return generators.filter((gen) => generatorKeys.includes(gen.key));
        }
        return this.fetchGenerators(portfolioKey);
    }

    public async getGeneratorsByKeys(keys: string[]): Promise<GeneratorRepresentation[]> {
        const allGenerators = await this.getGenerators();
        return allGenerators.filter((gen) => keys.includes(gen.key));
    }

    public async getGeneratorsByPark(parkKey: string): Promise<GeneratorRepresentation[]> {
        const allGenerators = await this.getGenerators();
        return allGenerators.filter((gen) => gen.parkKey === parkKey);
    }

    public async setGeneratorActiveStatus(spec: SetGeneratorActiveStatusRequest): Promise<GeneratorRepresentation | undefined> {
        await this.generatorsApi.setGeneratorActiveStatus(spec.key, spec.body);
        const generators = await this.fetchGenerators();
        return generators.find((generator) => generator.key === spec.key);
    }

    private async fetchGenerators(portfolioKey?: string): Promise<GeneratorRepresentation[]> {
        return AsyncDebouncer.debounce<GeneratorRepresentation[]>(`CachedGeneratorsRestApi.fetchGenerators(${portfolioKey})`, async () => {
            let generators = await this.generatorsApi.getGenerators(portfolioKey);
            const company = await this.authApi.getCompany();
            const mask = this.demoCustomers.get(company.key);
            if (mask) {
                generators = generators.map((generator) => CachedGeneratorsRestApi.maskGenerator(generator, mask));
            }
            if (portfolioKey) {
                this.generatorsPortfolioMapping.set(portfolioKey, generators.map((gen) => gen.key));
            }
            if (!portfolioKey || await this.generatorsCache.count() > 0) {
                // only update cache when we have already requested all generators (or did so now), as we otherwise
                // could not tell if the cache contains all generators available for the user
                await this.generatorsCache.saveAll(generators);
            }
            return generators;
        });
    }

    private static maskGenerator(generator: GeneratorRepresentation, mask: string): GeneratorRepresentation {
        let hashStr: string;
        let hash = 0;
        for (let i = 0; i < generator.key.length; i++) {
            const char = generator.key.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << 5) - hash) + char;
            // eslint-disable-next-line no-bitwise
            hash &= hash; // Convert to 32bit integer
        }
        if (hash < 0) {
            hashStr = `1${(hash * -1).toString(16)}`;
        } else {
            hashStr = hash.toString(16);
        }
        generator.name = `${mask}${hashStr.substring(0, 6)}`;
        return generator;
    }
}
