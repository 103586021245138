/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResourceFilterName,
    ResourceFilterNameFromJSON,
    ResourceFilterNameFromJSONTyped,
    ResourceFilterNameToJSON,
    ResourceFilterType,
    ResourceFilterTypeFromJSON,
    ResourceFilterTypeFromJSONTyped,
    ResourceFilterTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GeneratorFilterTypeRepresentation
 */
export interface GeneratorFilterTypeRepresentation {
    /**
     * 
     * @type {ResourceFilterName}
     * @memberof GeneratorFilterTypeRepresentation
     */
    name: ResourceFilterName;
    /**
     * 
     * @type {ResourceFilterType}
     * @memberof GeneratorFilterTypeRepresentation
     */
    inputtype: ResourceFilterType;
    /**
     * regex pattern for input
     * @type {string}
     * @memberof GeneratorFilterTypeRepresentation
     */
    pattern?: string;
    /**
     * whitelist for values, if empty => free input allowed
     * @type {Array<string>}
     * @memberof GeneratorFilterTypeRepresentation
     */
    whitelist?: Array<string>;
}

export function GeneratorFilterTypeRepresentationFromJSON(json: any): GeneratorFilterTypeRepresentation {
    return GeneratorFilterTypeRepresentationFromJSONTyped(json, false);
}

export function GeneratorFilterTypeRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorFilterTypeRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': ResourceFilterNameFromJSON(json['name']),
        'inputtype': ResourceFilterTypeFromJSON(json['inputtype']),
        'pattern': !exists(json, 'pattern') ? undefined : json['pattern'],
        'whitelist': !exists(json, 'whitelist') ? undefined : json['whitelist'],
    };
}

export function GeneratorFilterTypeRepresentationToJSON(value?: GeneratorFilterTypeRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': ResourceFilterNameToJSON(value.name),
        'inputtype': ResourceFilterTypeToJSON(value.inputtype),
        'pattern': value.pattern,
        'whitelist': value.whitelist,
    };
}


