
import { Vue, Component, Prop } from 'vue-property-decorator';
import Utils from '@/assets/js/utils/Utils';

@Component({})
export default class BaseDialog extends Vue {
    @Prop({ default: '' })
    public readonly dialogClass!: string;

    @Prop({ default: '' })
    public readonly dialogWrapperClass!: string;

    @Prop({ default: '' })
    public readonly title!: string;

    @Prop({ default: null })
    public readonly showOverlay!: boolean|null;

    @Prop({ default: true })
    public readonly showCloseButton!: boolean;

    private scrollY: string = '0';

    public created() {
        this.scrollY = Utils.disableScrolling();
    }

    public destroyed() {
        Utils.enableScrolling();
    }

    private back() {
        this.$emit('update:showOverlay', false);
        this.$emit('back');
    }

    private close() {
        this.$emit('close');
    }
}
