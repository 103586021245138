/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GaugeDataRepresentation
 */
export interface GaugeDataRepresentation {
    /**
     * 
     * @type {Array<number>}
     * @memberof GaugeDataRepresentation
     */
    values: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GaugeDataRepresentation
     */
    timesFrom: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GaugeDataRepresentation
     */
    timesTo: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GaugeDataRepresentation
     */
    dataIntegrity?: Array<number>;
}

export function GaugeDataRepresentationFromJSON(json: any): GaugeDataRepresentation {
    return GaugeDataRepresentationFromJSONTyped(json, false);
}

export function GaugeDataRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GaugeDataRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': json['values'],
        'timesFrom': json['timesFrom'],
        'timesTo': json['timesTo'],
        'dataIntegrity': !exists(json, 'dataIntegrity') ? undefined : json['dataIntegrity'],
    };
}

export function GaugeDataRepresentationToJSON(value?: GaugeDataRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': value.values,
        'timesFrom': value.timesFrom,
        'timesTo': value.timesTo,
        'dataIntegrity': value.dataIntegrity,
    };
}


