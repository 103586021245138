
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Tabs from '@/components/basics/Tabs.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import type { Dashboard, WidgetConfig, WidgetTemplate } from '@/modules/ctx-dashboard';
import ChartPreview from '@/components/illustrations/chart-previews/ChartPreview.vue';
import NotificationService from '@/assets/js/services/NotificationService';
import Checkbox from '@/modules/shared/components/input/Checkbox.vue';
import InputCheckbox from '@/modules/shared/components/form/InputCheckbox.vue';
import { Port as SharedPort } from '@/modules/shared/Port';
import { Port } from '@/modules/ctx-dashboard/Port';
import NoteBox from '@/components/basics/NoteBox.vue';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import ConfirmDlg from '@/modules/shared/components/dialogs/generic/ConfirmDlg.vue';

@Component({
    components: {
        ConfirmDlg,
        NoteBox,
        InputCheckbox,
        Checkbox,
        ChartPreview,
        LoadingAnimationSmall,
        Tabs,
    },
})
export default class PresetSelection extends Vue {

    @Prop({ required: true, default: '' })
    public readonly portfolioKey!: string;

    @Prop({ required: true, default: '' })
    public readonly dashboardKey!: string;

    @Prop({ required: false, default: false })
    public readonly allTabsValid!: boolean;

    @Prop({ required: false, default: false })
    public readonly skipValidWizardTabs!: boolean;

    private templates: WidgetTemplate[] = [];
    private selectedTemplate: WidgetTemplate|null = null;
    private categories: string[] = [];
    private selectedCategory: string = '';

    private dashboard: Dashboard|null = null;
    private widget: WidgetConfig|null = null;
    private loading: boolean = false;
    private saving: boolean = false;
    private loadingWidget: Promise<WidgetConfig|null>|null = null;
    private showDeleteConfirmDlg: boolean = false;

    private insertAtEnd: boolean = SharedPort.userSettings.getUserSettings().insertNewWidgetsPosition === 'end';

    public async created(): Promise<void> {
        this.loading = true;
        await Promise.all([
            this.fetchDashboard(),
            this.fetchTemplates(),
        ]);
        this.loading = false;
    }

    public mounted(): void {
        this.$nextTick(this.selectFirst);

    }

    @Watch('selectedCategory')
    private onCategoryChanged(): void {
        SharedPort.userSettings.updateUserSettings({ presetCategory: this.selectedCategory });
    }

    private setInsertAtEnd(insertAtEnd: boolean) {
        this.insertAtEnd = insertAtEnd;
        if (insertAtEnd) {
            SharedPort.userSettings.updateUserSettings({ insertNewWidgetsPosition: 'end' });
        } else {
            SharedPort.userSettings.updateUserSettings({ insertNewWidgetsPosition: 'start' });
        }
    }

    private get saveActionBlocked(): boolean {
        return this.loading || this.saving || this.selectedTemplate === null;
    }

    private get templatesForCategory(): WidgetTemplate[] {
        return this.templates
            .filter((t) => t.category === this.selectedCategory);
    }

    private selectFirst() {
        if (this.templatesForCategory[0]) {
            this.selectTemplate(this.templatesForCategory[0]);
        } else {
            this.selectedTemplate = null;
        }
    }

    private async selectTemplate(template: WidgetTemplate): Promise<void> {
        this.selectedTemplate = template;
        this.loadingWidget = this.getWidgetFromTemplate(template);
        await this.loadingWidget;
        this.$emit('update:widget', this.widget);
        this.$emit('update:skipValidWizardTabs', !this.selectedTemplate.isDefaultTemplate);
        this.$nextTick(this.$forceUpdate);
    }

    private async getWidgetFromTemplate(template: WidgetTemplate): Promise<WidgetConfig|null> {
        try {
            const widget: WidgetConfig = await Port.widgets.getWidgetFromTemplate(template, this.portfolioKey, this.dashboardKey);
            this.widget = widget;
            return widget;
        } catch (e: any) {
            NotificationService.serviceError(e);
            return null;
        }
    }

    private async deleteWidgetTemplate(template: WidgetTemplate) {
        try {
            this.loading = true;
            await Port.widgets.deleteWidgetTemplate(template);
            await this.fetchTemplates();
            this.showDeleteConfirmDlg = false;
            this.loading = false;
        } catch (e: any) {
            NotificationService.serviceError(e);
        }
    }

    private async fetchDashboard(): Promise<void> {
        this.dashboard = await Port.dashboards.getDashboard(this.dashboardKey);
    }

    private async fetchTemplates(): Promise<void> {
        this.templates = await Port.widgets.getWidgetTemplates();
        this.categories = this.templates
            .map((t) => t.category)
            .filter(ArrayUtils.removeDuplicates);
        this.selectedCategory = SharedPort.userSettings.getUserSettings().presetCategory;
        if (!this.selectedCategory || !this.categories.includes(this.selectedCategory)) {
            this.selectedCategory = this.categories[0];
        }
        this.selectFirst();
    }

    private async next(): Promise<void> {
        this.saving = true;
        if (this.loadingWidget) {
            await this.loadingWidget;
        }
        this.$emit('start');

    }

    private async save(): Promise<void> {
        this.saving = true;
        if (this.loadingWidget) {
            await this.loadingWidget;
        }
        this.$emit('save');
    }

    private cancel() {
        this.$emit('cancel');
    }
}
