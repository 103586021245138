import type { LocalizationRepository } from '@/modules/ctx-admin-metrics';
import { I18nAdapter } from '@/plugins/i18n';

export class LocalizationRepositoryImpl implements LocalizationRepository {

    public getLocales(): string[] {
        return I18nAdapter.locales;
    }

    public findKeysForMessage(message: string, lang: string): string[] {
        return I18nAdapter.findKeysForMessage(message, lang);
    }
}
