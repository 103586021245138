/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeSeries
 */
export interface TimeSeries {
    /**
     * x value of the request resource, corresponding x and y points are matched via index
     * @type {Array<number>}
     * @memberof TimeSeries
     */
    x: Array<number>;
    /**
     * y value of the request resource, corresponding x and y points are matched via index
     * @type {Array<number>}
     * @memberof TimeSeries
     */
    y: Array<number>;
}

export function TimeSeriesFromJSON(json: any): TimeSeries {
    return TimeSeriesFromJSONTyped(json, false);
}

export function TimeSeriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'x': json['x'],
        'y': json['y'],
    };
}

export function TimeSeriesToJSON(value?: TimeSeries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x': value.x,
        'y': value.y,
    };
}


