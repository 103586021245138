/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Aggregation,
    AggregationFromJSON,
    AggregationFromJSONTyped,
    AggregationToJSON,
    ResourceType,
    ResourceTypeFromJSON,
    ResourceTypeFromJSONTyped,
    ResourceTypeToJSON,
    WidgetResourceFilterRepresentation,
    WidgetResourceFilterRepresentationFromJSON,
    WidgetResourceFilterRepresentationFromJSONTyped,
    WidgetResourceFilterRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetResourceRepresentation
 */
export interface WidgetResourceRepresentation {
    /**
     * 
     * @type {string}
     * @memberof WidgetResourceRepresentation
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetResourceRepresentation
     */
    name: string;
    /**
     * 
     * @type {ResourceType}
     * @memberof WidgetResourceRepresentation
     */
    type: ResourceType;
    /**
     * 
     * @type {Aggregation}
     * @memberof WidgetResourceRepresentation
     */
    aggregationOverGenerators: Aggregation;
    /**
     * 
     * @type {string}
     * @memberof WidgetResourceRepresentation
     */
    config: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetResourceRepresentation
     */
    timeCompare?: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetResourceRepresentation
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetResourceRepresentation
     */
    resourceKey?: string;
    /**
     * 
     * @type {Array<WidgetResourceFilterRepresentation>}
     * @memberof WidgetResourceRepresentation
     */
    resourceFilters?: Array<WidgetResourceFilterRepresentation>;
}

export function WidgetResourceRepresentationFromJSON(json: any): WidgetResourceRepresentation {
    return WidgetResourceRepresentationFromJSONTyped(json, false);
}

export function WidgetResourceRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetResourceRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'type': ResourceTypeFromJSON(json['type']),
        'aggregationOverGenerators': AggregationFromJSON(json['aggregationOverGenerators']),
        'config': json['config'],
        'timeCompare': !exists(json, 'timeCompare') ? undefined : json['timeCompare'],
        'position': json['position'],
        'resourceKey': !exists(json, 'resourceKey') ? undefined : json['resourceKey'],
        'resourceFilters': !exists(json, 'resourceFilters') ? undefined : ((json['resourceFilters'] as Array<any>).map(WidgetResourceFilterRepresentationFromJSON)),
    };
}

export function WidgetResourceRepresentationToJSON(value?: WidgetResourceRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'name': value.name,
        'type': ResourceTypeToJSON(value.type),
        'aggregationOverGenerators': AggregationToJSON(value.aggregationOverGenerators),
        'config': value.config,
        'timeCompare': value.timeCompare,
        'position': value.position,
        'resourceKey': value.resourceKey,
        'resourceFilters': value.resourceFilters === undefined ? undefined : ((value.resourceFilters as Array<any>).map(WidgetResourceFilterRepresentationToJSON)),
    };
}


