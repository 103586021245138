// Vue Dependencies
import type { PluginObject } from 'vue';
import Vue from 'vue';
import type { RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';

// Module Dependencies
import { Port } from './Port';
import type { PortParams } from './Port';
import { route } from './views/account-groups/Route';

export interface Options extends PortParams {
    router: VueRouter;
    route?: Partial<RouteConfig>;
}

const plugin: PluginObject<Options> = {
    install(vue: typeof Vue, params?: Options) {
        if (!params) {
            console.error('Missing plugin params!');
            return;
        }
        Port.init({
            accountingRepository: params.accountingRepository,
        });

        // register route
        const mergedRoute = Object.assign(route, params.route);
        if (!params.router.getRoutes().find((r) => r.name === mergedRoute.name)) {
            params.router.addRoute(mergedRoute);
        } else {
            console.warn(`Route with name ${mergedRoute.name} already exists.`);
        }
    },
};

export { plugin };
