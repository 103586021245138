import type { Customer } from '@/modules/ctx-admin-customers/types';
import type { CustomerRepository } from '@/modules/ctx-admin-customers/adapter';
import NotificationService from '@/assets/js/services/NotificationService';
import { i18n } from '@/plugins/i18n';

export class CustomerService {

    private readonly customerRepository: CustomerRepository;

    constructor(params: {
        customerRepository: CustomerRepository;
    }) {
        this.customerRepository = params.customerRepository;
    }

    public onCustomersChange(cb: () => void): void {
        this.customerRepository.onCustomersChange(cb);
    }

    public async getCustomer(key: string): Promise<Customer|undefined> {
        try {
            return this.customerRepository.getCustomer(key);
        } catch (e: any) {
            NotificationService.serviceError(e);
            return undefined;
        }
    }

    public async getCustomers(): Promise<Customer[]> {
        try {
            const customers = await this.customerRepository.getCustomers();
            return customers.sort((a, b) => a.name.localeCompare(b.name));
        } catch (e: any) {
            NotificationService.serviceError(e);
            return [];
        }
    }

    public async saveCustomer(customer: Customer): Promise<void> {
        try {
            if (customer.key) {
                await this.customerRepository.updateCustomer(customer);
            } else {
                await this.customerRepository.createCustomer(customer);
            }
            NotificationService.success('notifications.saved', 'notifications.generic-saved');
        } catch (e: any) {
            NotificationService.serviceError(e);
        }
    }

    public newCustomer(): Customer {
        return {
            key: '',
            name: '',
            color: '',
            installedPower: 0,
            generatorCount: 0,
            license: '',
            heroImage: '',
            logo: '',
            portfolioKey: '',
        };
    }

    public async deleteCustomers(customers: Customer[]): Promise<void> {
        try {
            const deleteRequests = customers.map((customer) => this.customerRepository.deleteCustomer(customer.key));
            await Promise.all(deleteRequests);
        } catch (e: any) {
            NotificationService.serviceError(e);
        }
    }

    public async validateCustomer(customer: Customer): Promise<{ [key: string]: string[] }> {
        const errors: { [key: string]: string[] } = {};
        if (customer.name.trim().length === 0) {
            errors.name = (errors.name || []).concat(i18n.t('admin-customers.customer.name-required').toString());
        }
        if (customer.name.length > 45) {
            errors.name = (errors.name || []).concat(i18n.t('admin-customers.customer.name-max-length').toString());
        }
        const allCustomers = await this.customerRepository.getCustomers();
        const generatedKey = customer.name.toLowerCase().replace(/\s/g, ''); // remove all whitespace characters;
        if (allCustomers.find((cus) => cus.key !== customer.key && (cus.name.toLowerCase() === customer.name.toLowerCase()
                || cus.key === generatedKey))) {
            errors.name = (errors.name || []).concat(i18n.t('admin-customers.customer.duplicate').toString());
        }
        return errors;
    }
}
