/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PortfolioCreateRequest,
    PortfolioCreateRequestFromJSON,
    PortfolioCreateRequestToJSON,
    PortfolioRepresentation,
    PortfolioRepresentationFromJSON,
    PortfolioRepresentationToJSON,
    PortfolioTemplateRepresentation,
    PortfolioTemplateRepresentationFromJSON,
    PortfolioTemplateRepresentationToJSON,
    PortfolioUpdateRequest,
    PortfolioUpdateRequestFromJSON,
    PortfolioUpdateRequestToJSON,
} from '../models';

export interface AddGeneratorsToPortfolioRequest {
    key: string;
    requestBody?: Array<string>;
}

export interface CreatePortfolioRequest {
    portfolioCreateRequest: PortfolioCreateRequest;
}

export interface DeletePortfolioByKeyRequest {
    key: string;
}

export interface GetPortfolioByKeyRequest {
    key: string;
}

export interface RemoveGeneratorsRequest {
    key: string;
    requestBody?: Array<string>;
}

export interface UpdatePortfolioRequest {
    key: string;
    portfolioUpdateRequest: PortfolioUpdateRequest;
}

/**
 * 
 */
export class PortfolioApi extends runtime.BaseAPI {

    /**
     * Add a list of generators to a portfolio
     * Add generators
     */
    async addGeneratorsToPortfolioRaw(requestParameters: AddGeneratorsToPortfolioRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling addGeneratorsToPortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:write"]);
        }

        const response = await this.request({
            path: `/portfolios/by-key/{key}/generators`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a list of generators to a portfolio
     * Add generators
     */
    async addGeneratorsToPortfolio(key: string, requestBody?: Array<string>): Promise<void> {
        await this.addGeneratorsToPortfolioRaw({ key: key, requestBody: requestBody });
    }

    /**
     * Create new portfolios for the current user
     * Create portfolio
     */
    async createPortfolioRaw(requestParameters: CreatePortfolioRequest): Promise<runtime.ApiResponse<PortfolioRepresentation>> {
        if (requestParameters.portfolioCreateRequest === null || requestParameters.portfolioCreateRequest === undefined) {
            throw new runtime.RequiredError('portfolioCreateRequest','Required parameter requestParameters.portfolioCreateRequest was null or undefined when calling createPortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:write"]);
        }

        const response = await this.request({
            path: `/portfolios`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioCreateRequestToJSON(requestParameters.portfolioCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioRepresentationFromJSON(jsonValue));
    }

    /**
     * Create new portfolios for the current user
     * Create portfolio
     */
    async createPortfolio(portfolioCreateRequest: PortfolioCreateRequest): Promise<PortfolioRepresentation> {
        const response = await this.createPortfolioRaw({ portfolioCreateRequest: portfolioCreateRequest });
        return await response.value();
    }

    /**
     * Delete portfolio by key
     * Delete portfolio
     */
    async deletePortfolioByKeyRaw(requestParameters: DeletePortfolioByKeyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deletePortfolioByKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:write"]);
        }

        const response = await this.request({
            path: `/portfolios/by-key/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete portfolio by key
     * Delete portfolio
     */
    async deletePortfolioByKey(key: string): Promise<void> {
        await this.deletePortfolioByKeyRaw({ key: key });
    }

    /**
     * Get a single portfolio by key
     * Get portfolio
     */
    async getPortfolioByKeyRaw(requestParameters: GetPortfolioByKeyRequest): Promise<runtime.ApiResponse<PortfolioRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getPortfolioByKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:read"]);
        }

        const response = await this.request({
            path: `/portfolios/by-key/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioRepresentationFromJSON(jsonValue));
    }

    /**
     * Get a single portfolio by key
     * Get portfolio
     */
    async getPortfolioByKey(key: string): Promise<PortfolioRepresentation> {
        const response = await this.getPortfolioByKeyRaw({ key: key });
        return await response.value();
    }

    /**
     * Get all portfolio templates accessible for the current user
     * Get portfolio templates
     */
    async getPortfolioTemplatesRaw(): Promise<runtime.ApiResponse<Array<PortfolioTemplateRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:read"]);
        }

        const response = await this.request({
            path: `/portfolios/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioTemplateRepresentationFromJSON));
    }

    /**
     * Get all portfolio templates accessible for the current user
     * Get portfolio templates
     */
    async getPortfolioTemplates(): Promise<Array<PortfolioTemplateRepresentation>> {
        const response = await this.getPortfolioTemplatesRaw();
        return await response.value();
    }

    /**
     * Get all portfolios for the current user
     * Get portfolios
     */
    async getPortfoliosRaw(): Promise<runtime.ApiResponse<Array<PortfolioRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:read"]);
        }

        const response = await this.request({
            path: `/portfolios`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioRepresentationFromJSON));
    }

    /**
     * Get all portfolios for the current user
     * Get portfolios
     */
    async getPortfolios(): Promise<Array<PortfolioRepresentation>> {
        const response = await this.getPortfoliosRaw();
        return await response.value();
    }

    /**
     * Remove a list of Generators from a portfolio
     * Remove generators
     */
    async removeGeneratorsRaw(requestParameters: RemoveGeneratorsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling removeGenerators.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:write"]);
        }

        const response = await this.request({
            path: `/portfolios/by-key/{key}/generators/remove`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a list of Generators from a portfolio
     * Remove generators
     */
    async removeGenerators(key: string, requestBody?: Array<string>): Promise<void> {
        await this.removeGeneratorsRaw({ key: key, requestBody: requestBody });
    }

    /**
     * Update an existing portfolio
     */
    async updatePortfolioRaw(requestParameters: UpdatePortfolioRequest): Promise<runtime.ApiResponse<PortfolioRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updatePortfolio.');
        }

        if (requestParameters.portfolioUpdateRequest === null || requestParameters.portfolioUpdateRequest === undefined) {
            throw new runtime.RequiredError('portfolioUpdateRequest','Required parameter requestParameters.portfolioUpdateRequest was null or undefined when calling updatePortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:write"]);
        }

        const response = await this.request({
            path: `/portfolios/by-key/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioUpdateRequestToJSON(requestParameters.portfolioUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioRepresentationFromJSON(jsonValue));
    }

    /**
     * Update an existing portfolio
     */
    async updatePortfolio(key: string, portfolioUpdateRequest: PortfolioUpdateRequest): Promise<PortfolioRepresentation> {
        const response = await this.updatePortfolioRaw({ key: key, portfolioUpdateRequest: portfolioUpdateRequest });
        return await response.value();
    }

}
