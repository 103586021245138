import type { User } from '@/modules/ctx-admin-customers/types';
import type { UserRepository } from '@/modules/ctx-admin-customers/adapter';

export class UserService {

    private readonly userRepository: UserRepository;

    constructor(params: { userRepository: UserRepository }) {
        this.userRepository = params.userRepository;
    }

    public async getUser(companyKey: string, userKey: string): Promise<User|undefined> {
        return this.userRepository.getUser(companyKey, userKey);
    }
}
