/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardRepresentation
 */
export interface DashboardRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DashboardRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardRepresentation
     */
    portfolioKey: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardRepresentation
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardRepresentation
     */
    updated: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardRepresentation
     */
    widgetCount: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardRepresentation
     */
    order: number;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardRepresentation
     */
    automaticReportingActive?: boolean;
}

export function DashboardRepresentationFromJSON(json: any): DashboardRepresentation {
    return DashboardRepresentationFromJSONTyped(json, false);
}

export function DashboardRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'portfolioKey': json['portfolioKey'],
        'created': json['created'],
        'updated': json['updated'],
        'widgetCount': json['widgetCount'],
        'order': json['order'],
        'automaticReportingActive': !exists(json, 'automaticReportingActive') ? undefined : json['automaticReportingActive'],
    };
}

export function DashboardRepresentationToJSON(value?: DashboardRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'portfolioKey': value.portfolioKey,
        'created': value.created,
        'updated': value.updated,
        'widgetCount': value.widgetCount,
        'order': value.order,
        'automaticReportingActive': value.automaticReportingActive,
    };
}


