import { LicenseFeature } from '@/modules/shared/types';
import { UserRepository } from '@/modules/ctx-report-preview/adapter';

export class UserService {
    private readonly userRepository: UserRepository;

    constructor(params: {
        userRepository: UserRepository,
    }) {
        this.userRepository = params.userRepository;
    }

    public async getUserHasLicenseFeature(...licenseFeatures: LicenseFeature[]): Promise<boolean> {
        return this.userRepository.getUserHasLicenseFeature(...licenseFeatures);
    }
}
