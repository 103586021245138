import type { LocalizedLicenseFeature } from '@/modules/ctx-dashboard/types';
import type { LicenseRepository } from '@/modules/ctx-dashboard/adapter';
import type { LicenseFeature } from '@/modules/shared/types';

export class LicenseService {
    private readonly licenseRepository: LicenseRepository;

    constructor(params: {
        licenseRepository: LicenseRepository,
    }) {
        this.licenseRepository = params.licenseRepository;
    }

    public async getLicenseFeatures(): Promise<LocalizedLicenseFeature[]> {
        const licenseFeatures = await this.licenseRepository.getLicenseFeatures();
        return licenseFeatures.filter((feature) => feature.key !== 'default');
    }

    public async getRequiredLicenseFeaturesForMetrics(metricKeys: string[]): Promise<LicenseFeature[]> {
        return this.licenseRepository.getRequiredLicenseFeaturesForMetrics(metricKeys);
    }
}
