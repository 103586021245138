/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountGroupCreateRequest,
    AccountGroupCreateRequestFromJSON,
    AccountGroupCreateRequestToJSON,
    AccountGroupRepresentation,
    AccountGroupRepresentationFromJSON,
    AccountGroupRepresentationToJSON,
    AccountGroupUpdateRequest,
    AccountGroupUpdateRequestFromJSON,
    AccountGroupUpdateRequestToJSON,
    AccountRepresentation,
    AccountRepresentationFromJSON,
    AccountRepresentationToJSON,
    AccountingFormulaValidationRequest,
    AccountingFormulaValidationRequestFromJSON,
    AccountingFormulaValidationRequestToJSON,
    AccountingFormulaValidationResponse,
    AccountingFormulaValidationResponseFromJSON,
    AccountingFormulaValidationResponseToJSON,
    AccountingMetricCreateRequest,
    AccountingMetricCreateRequestFromJSON,
    AccountingMetricCreateRequestToJSON,
    AccountingMetricRepresentation,
    AccountingMetricRepresentationFromJSON,
    AccountingMetricRepresentationToJSON,
    AccountingMetricUpdateRequest,
    AccountingMetricUpdateRequestFromJSON,
    AccountingMetricUpdateRequestToJSON,
    ShareholderRepresentation,
    ShareholderRepresentationFromJSON,
    ShareholderRepresentationToJSON,
} from '../models';

export interface CreateAccountGroupRequest {
    accountGroupCreateRequest?: AccountGroupCreateRequest;
}

export interface CreateAccountingMetricRequest {
    groupKey: string;
    accountingMetricCreateRequest?: AccountingMetricCreateRequest;
}

export interface DeleteAccountGroupRequest {
    groupKey: string;
}

export interface DeleteAccountingMetricRequest {
    metricKey: string;
    force?: boolean;
}

export interface GetShareholdersRequest {
    portfolioKey?: string;
}

export interface ParseAccountingFormulaRequest {
    accountingFormulaValidationRequest?: AccountingFormulaValidationRequest;
}

export interface UpdateAccountGroupRequest {
    groupKey: string;
    accountGroupUpdateRequest?: AccountGroupUpdateRequest;
}

export interface UpdateAccountingMetricRequest {
    metricKey: string;
    accountingMetricUpdateRequest?: AccountingMetricUpdateRequest;
}

export interface UploadAccountActivitiesRequest {
    data?: Blob;
    formatHint?: string;
}

export interface UploadGeneratorAssignmentsRequest {
    data?: Blob;
}

/**
 * 
 */
export class AccountingApi extends runtime.BaseAPI {

    /**
     * Create a new account group
     * Create account group
     */
    async createAccountGroupRaw(requestParameters: CreateAccountGroupRequest): Promise<runtime.ApiResponse<AccountGroupRepresentation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/accountgroups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountGroupCreateRequestToJSON(requestParameters.accountGroupCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountGroupRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new account group
     * Create account group
     */
    async createAccountGroup(accountGroupCreateRequest?: AccountGroupCreateRequest): Promise<AccountGroupRepresentation> {
        const response = await this.createAccountGroupRaw({ accountGroupCreateRequest: accountGroupCreateRequest });
        return await response.value();
    }

    /**
     * Create a new accounting metric
     * Create accounting metric
     */
    async createAccountingMetricRaw(requestParameters: CreateAccountingMetricRequest): Promise<runtime.ApiResponse<AccountingMetricRepresentation>> {
        if (requestParameters.groupKey === null || requestParameters.groupKey === undefined) {
            throw new runtime.RequiredError('groupKey','Required parameter requestParameters.groupKey was null or undefined when calling createAccountingMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/accountgroups/{groupKey}/metrics`.replace(`{${"groupKey"}}`, encodeURIComponent(String(requestParameters.groupKey))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountingMetricCreateRequestToJSON(requestParameters.accountingMetricCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountingMetricRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new accounting metric
     * Create accounting metric
     */
    async createAccountingMetric(groupKey: string, accountingMetricCreateRequest?: AccountingMetricCreateRequest): Promise<AccountingMetricRepresentation> {
        const response = await this.createAccountingMetricRaw({ groupKey: groupKey, accountingMetricCreateRequest: accountingMetricCreateRequest });
        return await response.value();
    }

    /**
     * Delete an existing accounting group
     * Delete account group
     */
    async deleteAccountGroupRaw(requestParameters: DeleteAccountGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupKey === null || requestParameters.groupKey === undefined) {
            throw new runtime.RequiredError('groupKey','Required parameter requestParameters.groupKey was null or undefined when calling deleteAccountGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/accountgroups/{groupKey}`.replace(`{${"groupKey"}}`, encodeURIComponent(String(requestParameters.groupKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing accounting group
     * Delete account group
     */
    async deleteAccountGroup(groupKey: string): Promise<void> {
        await this.deleteAccountGroupRaw({ groupKey: groupKey });
    }

    /**
     * Delete an existing accounting metric. This is only allowed if the metric is not used or the force flag is set to true
     * Delete accounting metric
     */
    async deleteAccountingMetricRaw(requestParameters: DeleteAccountingMetricRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metricKey === null || requestParameters.metricKey === undefined) {
            throw new runtime.RequiredError('metricKey','Required parameter requestParameters.metricKey was null or undefined when calling deleteAccountingMetric.');
        }

        const queryParameters: any = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/metrics/{metricKey}`.replace(`{${"metricKey"}}`, encodeURIComponent(String(requestParameters.metricKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing accounting metric. This is only allowed if the metric is not used or the force flag is set to true
     * Delete accounting metric
     */
    async deleteAccountingMetric(metricKey: string, force?: boolean): Promise<void> {
        await this.deleteAccountingMetricRaw({ metricKey: metricKey, force: force });
    }

    /**
     * Get list of all accounting groups
     * Get account groups
     */
    async getAccountGroupsRaw(): Promise<runtime.ApiResponse<Array<AccountGroupRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/accountgroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountGroupRepresentationFromJSON));
    }

    /**
     * Get list of all accounting groups
     * Get account groups
     */
    async getAccountGroups(): Promise<Array<AccountGroupRepresentation>> {
        const response = await this.getAccountGroupsRaw();
        return await response.value();
    }

    /**
     * Get a list of all accounts
     * Get accounts
     */
    async getAccountsRaw(): Promise<runtime.ApiResponse<Array<AccountRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountRepresentationFromJSON));
    }

    /**
     * Get a list of all accounts
     * Get accounts
     */
    async getAccounts(): Promise<Array<AccountRepresentation>> {
        const response = await this.getAccountsRaw();
        return await response.value();
    }

    /**
     * Get a list of all shareholders
     * Get shareholders
     */
    async getShareholdersRaw(requestParameters: GetShareholdersRequest): Promise<runtime.ApiResponse<Array<ShareholderRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioKey !== undefined) {
            queryParameters['portfolioKey'] = requestParameters.portfolioKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/shareholders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShareholderRepresentationFromJSON));
    }

    /**
     * Get a list of all shareholders
     * Get shareholders
     */
    async getShareholders(portfolioKey?: string): Promise<Array<ShareholderRepresentation>> {
        const response = await this.getShareholdersRaw({ portfolioKey: portfolioKey });
        return await response.value();
    }

    /**
     * Validate a formula
     * Validate formula
     */
    async parseAccountingFormulaRaw(requestParameters: ParseAccountingFormulaRequest): Promise<runtime.ApiResponse<AccountingFormulaValidationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/formulas/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountingFormulaValidationRequestToJSON(requestParameters.accountingFormulaValidationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountingFormulaValidationResponseFromJSON(jsonValue));
    }

    /**
     * Validate a formula
     * Validate formula
     */
    async parseAccountingFormula(accountingFormulaValidationRequest?: AccountingFormulaValidationRequest): Promise<AccountingFormulaValidationResponse> {
        const response = await this.parseAccountingFormulaRaw({ accountingFormulaValidationRequest: accountingFormulaValidationRequest });
        return await response.value();
    }

    /**
     * Update an existing accounting group
     * Update account group
     */
    async updateAccountGroupRaw(requestParameters: UpdateAccountGroupRequest): Promise<runtime.ApiResponse<AccountGroupRepresentation>> {
        if (requestParameters.groupKey === null || requestParameters.groupKey === undefined) {
            throw new runtime.RequiredError('groupKey','Required parameter requestParameters.groupKey was null or undefined when calling updateAccountGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/accountgroups/{groupKey}`.replace(`{${"groupKey"}}`, encodeURIComponent(String(requestParameters.groupKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccountGroupUpdateRequestToJSON(requestParameters.accountGroupUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountGroupRepresentationFromJSON(jsonValue));
    }

    /**
     * Update an existing accounting group
     * Update account group
     */
    async updateAccountGroup(groupKey: string, accountGroupUpdateRequest?: AccountGroupUpdateRequest): Promise<AccountGroupRepresentation> {
        const response = await this.updateAccountGroupRaw({ groupKey: groupKey, accountGroupUpdateRequest: accountGroupUpdateRequest });
        return await response.value();
    }

    /**
     * Update an existing accounting metric
     * Update accounting metric
     */
    async updateAccountingMetricRaw(requestParameters: UpdateAccountingMetricRequest): Promise<runtime.ApiResponse<AccountingMetricRepresentation>> {
        if (requestParameters.metricKey === null || requestParameters.metricKey === undefined) {
            throw new runtime.RequiredError('metricKey','Required parameter requestParameters.metricKey was null or undefined when calling updateAccountingMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/accounting/metrics/{metricKey}`.replace(`{${"metricKey"}}`, encodeURIComponent(String(requestParameters.metricKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccountingMetricUpdateRequestToJSON(requestParameters.accountingMetricUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountingMetricRepresentationFromJSON(jsonValue));
    }

    /**
     * Update an existing accounting metric
     * Update accounting metric
     */
    async updateAccountingMetric(metricKey: string, accountingMetricUpdateRequest?: AccountingMetricUpdateRequest): Promise<AccountingMetricRepresentation> {
        const response = await this.updateAccountingMetricRaw({ metricKey: metricKey, accountingMetricUpdateRequest: accountingMetricUpdateRequest });
        return await response.value();
    }

    /**
     * Upload CSV with account activities
     * Upload account activities
     */
    async uploadAccountActivitiesRaw(requestParameters: UploadAccountActivitiesRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.data !== undefined) {
            formParams.append('data', requestParameters.data as any);
        }

        if (requestParameters.formatHint !== undefined) {
            formParams.append('formatHint', requestParameters.formatHint as any);
        }

        const response = await this.request({
            path: `/accounting/accounts/activities`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload CSV with account activities
     * Upload account activities
     */
    async uploadAccountActivities(data?: Blob, formatHint?: string): Promise<void> {
        await this.uploadAccountActivitiesRaw({ data: data, formatHint: formatHint });
    }

    /**
     * Upload CSV with generator assignments
     * Upload generator assignments
     */
    async uploadGeneratorAssignmentsRaw(requestParameters: UploadGeneratorAssignmentsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.data !== undefined) {
            formParams.append('data', requestParameters.data as any);
        }

        const response = await this.request({
            path: `/accounting/accounts/generator-assignments`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload CSV with generator assignments
     * Upload generator assignments
     */
    async uploadGeneratorAssignments(data?: Blob): Promise<void> {
        await this.uploadGeneratorAssignmentsRaw({ data: data });
    }

}
