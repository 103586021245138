import type { LossOfProductionMetricsRepository, LocalizationsRepository } from './adapter';
import { MetricsService } from './services/MetricsService';

export interface PortParams {
    lossOfProductionMetricsRepository: LossOfProductionMetricsRepository;
    localizationRepository: LocalizationsRepository;
}

export class Port {
    private static port: Port|null = null;

    private readonly metricsService: MetricsService;

    constructor(params: PortParams) {
        this.metricsService = new MetricsService({
            metricsRepository: params.lossOfProductionMetricsRepository,
            localizationRepository: params.localizationRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get metrics(): MetricsService {
        return Port.instance.metricsService;
    }
}
