import { v4 as generateUUID } from 'uuid';
import { Aggregation, Resolution, ResourceType, LicenseFeature } from '@/modules/shared/types';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';
import { Port } from '@/modules/shared/Port';
import Utils from '@/assets/js/utils/Utils';

export default class DistributionCostsPercent implements WidgetPreset {

    public readonly name: string = 'distribution-costs-percent';
    public readonly requireLicenseFeatures: LicenseFeature[] = [LicenseFeature.ACCOUNTING];
    public readonly type: WidgetType = WidgetType.Distribution;
    public readonly quickAdd: boolean = true;
    public readonly order: number = 60;
    public readonly hidden: boolean = true;
    public readonly metrics: string[] = [
        '8c2df0dc-4e7e-4ba3-ab0f-c3f1e779a744',
        '599f07b9-3a91-4e58-be59-d7dbdbb146d7',
        '580d9a5e-02df-4a29-9aa8-9c17450ca2c1',
        '407c0178-365b-45a4-9534-0e7a10537650',
        'cca82b28-40e6-4425-8381-a0cdc7840b75',
        '2bedcbea-d327-46eb-9fd2-b8634a7b56a7',
        'ba04ce88-8fb0-45af-a70b-e4ae5470d088',
        '970fad98-45eb-4d8b-a3d8-dfa0eb51591f',
    ];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        const resources = [{
            uuid: generateUUID().substring(0, 8),
            seriesName: '',
            resourceName: (await Port.portfolios.getPortfolioByKey(params.portfolio))?.name || '',
            type: ResourceType.Portfolio,
            resourceKey: params.portfolio,
            aggregationOverGenerators: Aggregation.Avg,
            config: {},
        }];
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {},
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'last-year',
            intervalTo: undefined,
            intervalFrom: undefined,
            resolution: Resolution.Automatic,
            axis: [{
                unit: '',
                name: '',
                config: {},
                metrics: this.metrics.map((metricKey) => ({
                    uuid: generateUUID().substring(0, 8),
                    metricKey: metricKey,
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Avg,
                    resources: Utils.deepCopy(resources),
                })),
            }],
        };
    }
}
