import { v4 as generateUUID } from 'uuid';
import { Aggregation, Resolution } from '@/clients/dashboardapi/v2';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';

export default class PowerCurve implements WidgetPreset {

    public readonly name: string = 'power-curve';
    public readonly type: WidgetType = WidgetType.PowerCurve;
    public readonly quickAdd: boolean = false;
    public readonly order: number = 20;
    public readonly metrics: string[] = [];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {},
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'this-month-complete',
            intervalTo: new Date(),
            intervalFrom: new Date(),
            resolution: Resolution.Min10,
            axis: [{
                unit: '',
                name: '',
                config: {},
                metrics: [{
                    uuid: generateUUID().substring(0, 8),
                    metricKey: '',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.None,
                    resources: [],
                }],
            }],
        };
    }
}
