import type { LicenseRepository, LocalizedLicenseFeature } from '@/modules/ctx-dashboard';
import type { LicenseApi, MetricsApi } from '@/modules/shared/adapter';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { LicenseFeature, licenseFeatureFromString } from '@/modules/shared/types';

export class LicenseRepositoryImpl implements LicenseRepository {

    private readonly licenseApi: LicenseApi;
    private readonly metricsApi: MetricsApi;

    constructor(apis: {
        license: LicenseApi,
        metrics: MetricsApi,
    }) {
        this.licenseApi = apis.license;
        this.metricsApi = apis.metrics;
    }

    public async getLicenseFeatures(): Promise<LocalizedLicenseFeature[]> {
        const features = await this.licenseApi.getFeatures();
        return features
            .map((it) => {
                const feature = licenseFeatureFromString(it.key);
                return feature ? { key: feature, name: it.name } : undefined;
            })
            .filter(ArrayUtils.filterUndefined);
    }

    public async getRequiredLicenseFeaturesForMetrics(metricKeys:string[]): Promise<LicenseFeature[]> {
        const metrics = await Promise.all(metricKeys.map((metricKey) => this.metricsApi.getMetricByKey(metricKey)));
        return metrics
            .filter(ArrayUtils.filterUndefined)
            .flatMap((metric) => metric.licenseFeatures || [])
            .filter(ArrayUtils.removeDuplicates)
            .map((key) => key as LicenseFeature);
    }
}
