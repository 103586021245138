// Vue Dependencies
import type { PluginObject } from 'vue';
import Vue from 'vue';
import type { RouteConfig } from 'vue-router';
import type VueRouter from 'vue-router';

// Module Dependencies
import { Port } from './Port';
import type { PortParams } from './Port';
import { route } from './views/metrics/Route';

export interface Options extends PortParams {
    router: VueRouter;
    route?: Partial<RouteConfig>;
}

const plugin: PluginObject<Options> = {
    install(vue: typeof Vue, params?: Options) {
        if (!params) {
            console.error('Missing plugin params!');
            return;
        }
        Port.init({
            metricsRepository: params.metricsRepository,
            localizationRepository: params.localizationRepository,
        });

        // register route
        const mergedRoute = Object.assign(route, params.route);
        params.router.addRoute(mergedRoute);
    },
};

export { plugin };
