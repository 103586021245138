import VueI18n from 'vue-i18n';
import { i18n } from '../plugin';

export abstract class MessageProvider {

    public abstract loadMessages(lang: string): Promise<void>;

    protected appendMessages(lang: string, messages: VueI18n.LocaleMessageObject) {
        const internalMessages = i18n.getLocaleMessage(lang);
        i18n.setLocaleMessage(lang, Object.assign(internalMessages, messages));
    }

}
