import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import type { GeneratorFilterTypeRepresentation } from '@/clients/dashboardapi/v2';
import Services from '@/assets/js/services/Services';
import { Port } from '@/modules/shared/Port';

interface BenchmarkingState {
    filters: null|GeneratorFilterTypeRepresentation[];
}

const moduleState: BenchmarkingState = {
    filters: null,
};

const moduleGetters: GetterTree<BenchmarkingState, any> = {
    filters(state: BenchmarkingState): GeneratorFilterTypeRepresentation[] {
        return state.filters || [];
    },
    findFilterByName(state: BenchmarkingState, getters): (name: string) => GeneratorFilterTypeRepresentation {
        return function (name: string): GeneratorFilterTypeRepresentation {
            return getters.filters.find((f: GeneratorFilterTypeRepresentation) => f.name === name);
        };
    },
};

const moduleMutations: MutationTree<BenchmarkingState> = {
    reset(state: BenchmarkingState) {
        // TODO should filters be reseted on logout?
    },
    setFilters(state: BenchmarkingState, params?: { filters: GeneratorFilterTypeRepresentation[] }) {
        state.filters = params?.filters || null;
    },
};

const moduleActions: ActionTree<BenchmarkingState, any> = {
    /**
     * Initialize module
     * @param context
     */
    async init(context): Promise<void> {
        await Promise.allSettled([
            context.dispatch('loadFilters'),
        ]);
    },

    /**
     * Reset module state
     * @param context
     */
    async clear(context): Promise<void> {
        context.commit('reset');
    },

    async loadFilters(context, params?: { lazy?: boolean }) {
        if (params?.lazy === true && context.state.filters !== null) {
            return;
        }
        const filters = await Port.configApi.getGeneratorFilterTypes();
        context.commit('setFilters', { filters: filters });
    },
};

const module: Module<BenchmarkingState, any> = {
    namespaced: true,
    state: moduleState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions,
};
export default module;
