import Utils from '@/assets/js/utils/Utils';

export class AsyncDebouncer {

    private static pendingRequests = new Map<string, Promise<any>>();

    public static debounce<T>(name: string, asyncCall: () => Promise<T>): Promise<T> {
        if (this.pendingRequests.has(name)) {
            const pendingPromise = this.pendingRequests.get(name) as Promise<T>;
            return pendingPromise.then((r) => Utils.deepCopy(r));
        }
        const promise = asyncCall()
            .then((res: T) => {
                this.pendingRequests.delete(name);
                return res;
            }).catch((err: any) => {
                this.pendingRequests.delete(name);
                throw err;
            });
        this.pendingRequests.set(name, promise);
        return promise;
    }
}
