/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountingMetricRepresentation,
    AccountingMetricRepresentationFromJSON,
    AccountingMetricRepresentationFromJSONTyped,
    AccountingMetricRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountGroupRepresentation
 */
export interface AccountGroupRepresentation {
    /**
     * 
     * @type {string}
     * @memberof AccountGroupRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof AccountGroupRepresentation
     */
    nameKey: string;
    /**
     * 
     * @type {Array<AccountingMetricRepresentation>}
     * @memberof AccountGroupRepresentation
     */
    metrics?: Array<AccountingMetricRepresentation>;
}

export function AccountGroupRepresentationFromJSON(json: any): AccountGroupRepresentation {
    return AccountGroupRepresentationFromJSONTyped(json, false);
}

export function AccountGroupRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountGroupRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'nameKey': json['nameKey'],
        'metrics': !exists(json, 'metrics') ? undefined : ((json['metrics'] as Array<any>).map(AccountingMetricRepresentationFromJSON)),
    };
}

export function AccountGroupRepresentationToJSON(value?: AccountGroupRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'nameKey': value.nameKey,
        'metrics': value.metrics === undefined ? undefined : ((value.metrics as Array<any>).map(AccountingMetricRepresentationToJSON)),
    };
}


