/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountRepresentation
 */
export interface AccountRepresentation {
    /**
     * 
     * @type {string}
     * @memberof AccountRepresentation
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof AccountRepresentation
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof AccountRepresentation
     */
    subnumber?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRepresentation
     */
    planType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRepresentation
     */
    dataProviderKey: string;
}

export function AccountRepresentationFromJSON(json: any): AccountRepresentation {
    return AccountRepresentationFromJSONTyped(json, false);
}

export function AccountRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'number': json['number'],
        'subnumber': !exists(json, 'subnumber') ? undefined : json['subnumber'],
        'name': json['name'],
        'planType': !exists(json, 'planType') ? undefined : json['planType'],
        'dataProviderKey': json['dataProviderKey'],
    };
}

export function AccountRepresentationToJSON(value?: AccountRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'number': value.number,
        'subnumber': value.subnumber,
        'name': value.name,
        'planType': value.planType,
        'dataProviderKey': value.dataProviderKey,
    };
}


