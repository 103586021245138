import type { Customer, CustomerRepository } from '@/modules/ctx-admin-customers';
import type { CompaniesApi, CompanyRepresentation } from '@/modules/shared/adapter/CompaniesApi';
import type { UsersApi } from '@/modules/shared/adapter/UsersApi';
import type { GeneratorRepresentation } from '@/clients/dashboardapi/v2';
import type { GeneratorsApi } from '@/modules/shared/adapter/GeneratorsApi';
import { Port } from '@/modules/shared/Port';

export class CustomerRepositoryImpl implements CustomerRepository {

    private readonly companiesApi: CompaniesApi;
    private readonly usersApi: UsersApi;
    private readonly generatorsApi: GeneratorsApi;

    constructor(apis: {
        companiesApi: CompaniesApi;
        usersApi: UsersApi;
        generatorsApi: GeneratorsApi;
    }) {
        this.companiesApi = apis.companiesApi;
        this.usersApi = apis.usersApi;
        this.generatorsApi = apis.generatorsApi;
    }

    public onCustomersChange(cb: () => void): void {
        this.companiesApi.onCompaniesChange(() => {
            cb();
            return Promise.resolve();
        });
    }

    public async createCustomer(customer: Customer): Promise<Customer> {
        const savedCompany = await this.companiesApi.createCompany({
            name: customer.name,
            color: customer.color,
            logo: customer.logo,
            heroImage: customer.heroImage,
        });
        return CustomerRepositoryImpl.mapCustomerToDomain(savedCompany, 0, 0);
    }

    public async deleteCustomer(customerKey: string): Promise<void> {
        await this.companiesApi.deleteCompany(customerKey);
    }

    public async getCustomers(): Promise<Customer[]> {
        const companies = await this.companiesApi.getCompanies();
        return Promise.all(companies.map(async (comp) => {
            const generators = await this.getGeneratorsForCompany(comp);
            const installedPower = generators.reduce((sum, gen) => sum + (gen.power || 0), 0);
            return CustomerRepositoryImpl.mapCustomerToDomain(comp, generators.length, installedPower);
        }));
    }

    public async getCustomer(key: string): Promise<Customer|undefined> {
        const company = await this.companiesApi.getCompany(key);
        if (!company) {
            return undefined;
        }
        const generators = await this.getGeneratorsForCompany(company);
        const installedPower = generators.reduce((sum, gen) => sum + (gen.power || 0), 0);
        return CustomerRepositoryImpl.mapCustomerToDomain(company, generators.length, installedPower);
    }

    public async updateCustomer(customer: Customer): Promise<Customer> {
        const savedCompany = await this.companiesApi.updateCompany(customer.key, {
            color: customer.color,
            logo: customer.logo,
            name: customer.name,
            heroImage: customer.heroImage,
            license: customer.license,
        });
        return CustomerRepositoryImpl.mapCustomerToDomain(savedCompany, customer.generatorCount, customer.installedPower);
    }

    private async getGeneratorsForCompany(company: CompanyRepresentation): Promise<GeneratorRepresentation[]> {
        const portfolio = await Port.portfolios.getPortfolioByKey(company.portfolioKey);
        return Port.generators.getGeneratorsByKeys(portfolio?.generatorKeys || []);
    }

    private static mapCustomerToDomain(company: CompanyRepresentation, generatorCount: number, installedPower: number): Customer {
        return {
            key: company.key,
            name: company.name,
            color: company.color || '',
            heroImage: company.heroImage || '',
            logo: company.logo || '',
            license: company.license || '',
            portfolioKey: company.portfolioKey,
            generatorCount: generatorCount,
            installedPower: installedPower,
        };
    }
}
