/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResultType {
    Balance = 'balance',
    Entry = 'entry'
}

export function ResultTypeFromJSON(json: any): ResultType {
    return ResultTypeFromJSONTyped(json, false);
}

export function ResultTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultType {
    return json as ResultType;
}

export function ResultTypeToJSON(value?: ResultType | null): any {
    return value as any;
}

