
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import GeneratorChooser from './GeneratorChooser.vue';

@Component({
    components: { GeneratorChooser, BaseDialog },
})
export default class GeneratorChooserDlg extends Vue {

    @Prop({ required: false, default: undefined })
    public readonly selected!: string[]|undefined;

    @Prop({ required: false, default: undefined })
    public readonly portfolioKey!: string|undefined;

    @Prop({ required: false, default: undefined })
    public readonly whitelist!: string[]|undefined;

    @Prop({ required: false, default: undefined })
    public readonly blacklist!: string[]|undefined;

    @Prop({ required: false, default: undefined })
    public readonly maxSelectable!: number|undefined;

    private submit(generators: string[]) {
        this.$emit('submit', generators);
    }

    private close() {
        this.$emit('close');
    }
}
