import { i18n } from '@/plugins/i18n';
import type { WidgetWizardStep, TabDataOptions, TabGeneralOptions } from '@/components/widget-wizard';
import { ResourceType } from '@/clients/dashboardapi/v2';
import { WidgetWizardTab } from '@/components/widget-wizard';

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.Time,
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: {
        enableDuplicateResources: false,
        resourceTypes: [ResourceType.Portfolio, ResourceType.Park, ResourceType.Generator, ResourceType.Shareholder],
        hint: i18n.t('widget-wizard.tab.data.hint-distinct').toString(),
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    time,
    data,
    general,
];
