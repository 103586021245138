/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WidgetTemplateRepresentation,
    WidgetTemplateRepresentationFromJSON,
    WidgetTemplateRepresentationFromJSONTyped,
    WidgetTemplateRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface DashboardTemplateRepresentation
 */
export interface DashboardTemplateRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateRepresentation
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardTemplateRepresentation
     */
    created: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardTemplateRepresentation
     */
    createdBy: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardTemplateRepresentation
     */
    requiredLicenseFeatures: Array<string>;
    /**
     * 
     * @type {Array<WidgetTemplateRepresentation>}
     * @memberof DashboardTemplateRepresentation
     */
    widgetTemplates: Array<WidgetTemplateRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardTemplateRepresentation
     */
    global?: boolean;
}

export function DashboardTemplateRepresentationFromJSON(json: any): DashboardTemplateRepresentation {
    return DashboardTemplateRepresentationFromJSONTyped(json, false);
}

export function DashboardTemplateRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardTemplateRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'created': json['created'],
        'createdBy': json['createdBy'],
        'requiredLicenseFeatures': json['requiredLicenseFeatures'],
        'widgetTemplates': ((json['widgetTemplates'] as Array<any>).map(WidgetTemplateRepresentationFromJSON)),
        'global': !exists(json, 'global') ? undefined : json['global'],
    };
}

export function DashboardTemplateRepresentationToJSON(value?: DashboardTemplateRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'description': value.description,
        'created': value.created,
        'createdBy': value.createdBy,
        'requiredLicenseFeatures': value.requiredLicenseFeatures,
        'widgetTemplates': ((value.widgetTemplates as Array<any>).map(WidgetTemplateRepresentationToJSON)),
        'global': value.global,
    };
}


