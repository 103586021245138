
import { Component, Prop, Vue } from 'vue-property-decorator';

let openMenu: Vue|null = null;

@Component({
    components: {},
})
export default class BubbleMenu extends Vue {

    @Prop({ default: false })
    public readonly showCloseButton!: boolean|string;

    @Prop({ default: null })
    public readonly anchor!: HTMLElement|null;

    @Prop({ default: 'left' })
    public readonly horizontalAnchorAlignment!: 'left' | 'center' | 'right';

    @Prop({ default: 'bottom' })
    public readonly verticalAnchorAlignment!: 'top' | 'bottom';

    @Prop({ default: 'bottom' })
    public readonly verticalAlignmentMobile!: 'top' | 'center' | 'bottom';

    private position: any = {};

    public created() {
        // Utils.disableScrolling();
        if (openMenu) {
            openMenu.$destroy();
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        openMenu = this;
        window.addEventListener('resize', this.close, { passive: true });
        window.addEventListener('scroll', this.close, { passive: true });
    }

    public mounted() {
        this.calculatePosition();
    }

    public destroyed() {
        window.removeEventListener('resize', this.close);
        window.removeEventListener('scroll', this.close);
        // Utils.enableScrolling();
        this.$emit('close');
    }

    private calculatePosition() {
        const menu = this.$refs.menu as HTMLElement;
        if (!this.anchor || !menu) {
            return;
        }
        const menuBoundingRect = menu.getBoundingClientRect();
        const anchorBoundingRect = this.anchor.getBoundingClientRect();
        this.position = {
            left: `${this.calcX(anchorBoundingRect, menuBoundingRect)}px`,
            top: `${this.calcY(anchorBoundingRect, menuBoundingRect)}px`,
        };
    }

    private calcX(anchorRect: DOMRect, menuRect: DOMRect): number {
        let preferred;
        switch (this.horizontalAnchorAlignment) {
            case 'left': preferred = anchorRect.left + anchorRect.width - menuRect.width; break;
            case 'center': preferred = anchorRect.left + anchorRect.width / 2 - menuRect.width / 2; break;
            case 'right': preferred = anchorRect.left + anchorRect.width; break;
            default: preferred = 0;
        }
        if (preferred < 0) {
            return 0;
        }
        // subtract 2 * 1rem padding
        const windowMax = window.innerWidth - 2 * this.convertRemToPixels(1);
        if (preferred + menuRect.width > windowMax) {
            return windowMax - menuRect.width;
        }
        return preferred;
    }

    private calcY(anchorRect: DOMRect, menuRect: DOMRect): number {
        let preferred;
        switch (this.verticalAnchorAlignment) {
            case 'bottom': preferred = anchorRect.top + anchorRect.height; break;
            case 'top': preferred = anchorRect.top - menuRect.height; break;
            default: preferred = 0;
        }
        if (preferred < 0) {
            return 0;
        }
        // subtract 2 * 1rem padding
        const windowMax = window.innerHeight - 2 * this.convertRemToPixels(1);
        if (preferred + menuRect.height > windowMax) {
            return windowMax - menuRect.height;
        }
        return preferred;
    }

    private convertRemToPixels(rem: number) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    private close() {
        this.$emit('close');
    }

}
