import type { LocalizationRepository, MetricsRepository } from '@/modules/ctx-admin-metrics/adapter';
import type { Metric, RawMetric } from '@/modules/ctx-admin-metrics/types';
import { MetricCategory } from '@/modules/shared/types';
import { i18n } from '@/plugins/i18n';
import NotificationService from '@/assets/js/services/NotificationService';

export class MetricsService {

    private readonly metricsRepository: MetricsRepository;
    private readonly localizationRepository: LocalizationRepository;

    constructor(params: {
        metricsRepository: MetricsRepository;
        localizationRepository: LocalizationRepository;
    }) {
        this.metricsRepository = params.metricsRepository;
        this.localizationRepository = params.localizationRepository;
    }

    public async getUnassignedRawMetrics(filter?: string): Promise<RawMetric[]> {
        try {
            const filterLc = filter?.toLowerCase();
            return this.metricsRepository.getRawMetrics()
                .then((rawMetrics) => rawMetrics
                    .filter((rawMetric) => rawMetric.assignedMetricKey === undefined)
                    .filter((rawMetric) => !filterLc
                        || rawMetric.name.toLowerCase().includes(filterLc)
                        || i18n.t(`metrics.${rawMetric.category}`).toString().toLowerCase().includes(filterLc)
                        || rawMetric.provider.toLowerCase().includes(filterLc))
                    .sort((a, b) => a.name.localeCompare(b.name)));
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async getMetricByKey(metricKey: string): Promise<Metric|undefined> {
        try {
            return await this.metricsRepository.getMetricByKey(metricKey);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async getEditableMetrics(filter?: string): Promise<Metric[]> {
        const dynamicMetrics = [
            MetricCategory.Accounting,
            MetricCategory.LossOfProduction,
            MetricCategory.LossOfRevenue,
        ];
        const metrics = await this.getMetrics(filter);
        return metrics.filter((metric) => !dynamicMetrics.includes(metric.category));
    }

    public async getMetrics(filter?: string): Promise<Metric[]> {
        try {
            let metrics = await this.metricsRepository.getMetrics();
            if (filter) {
                const filterLc = filter.toLowerCase();
                metrics = metrics.filter((metric) => MetricsService.matchesFilter(metric, filterLc));
            }
            return metrics.sort((a, b) => a.name[i18n.locale].localeCompare(b.name[i18n.locale]));
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async getChildMetrics(parentMetrikKey: string): Promise<Metric[]> {
        try {
            let metrics = await this.metricsRepository.getMetrics();
            metrics = metrics.filter((metric) => metric.parentMetricKey === parentMetrikKey);
            return metrics.sort((a, b) => a.name[i18n.locale].localeCompare(b.name[i18n.locale]));
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async getParentMetricMap(): Promise<Map<string, string>> {
        const map = new Map<string, string>();
        const allMetrics = await this.getMetrics();
        allMetrics
            .filter((it) => it.parentMetricKey)
            .forEach((it) => map.set(it.key, it.parentMetricKey!));
        return map;
    }

    public async linkChildMetric(parentMetricKey: string, childMetricKey: string): Promise<void> {
        try {
            const childMetric = await this.metricsRepository.getMetricByKey(childMetricKey);
            if (childMetric) {
                childMetric.parentMetricKey = parentMetricKey;
                await this.saveMetric(childMetric);
            }
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async unlinkChildMetric(parentMetricKey: string, childMetricKey: string): Promise<void> {
        try {
            const childMetric = await this.metricsRepository.getMetricByKey(childMetricKey);
            if (childMetric && childMetric.parentMetricKey === parentMetricKey) {
                childMetric.parentMetricKey = undefined;
                await this.saveMetric(childMetric);
            }
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    private static matchesFilter(metric: Metric, filter?: string): boolean {
        if (!filter) {
            return true;
        }
        const categoryName = i18n.t(`metrics.${metric.category}`).toString();
        if (categoryName.toLowerCase().includes(filter)) {
            return true;
        }
        if (Object.values(metric.name).join(';').toLowerCase().includes(filter)) {
            return true;
        }
        if (Object.values(metric.unit).join(';').toLowerCase().includes(filter)) {
            return true;
        }
        return false;
    }

    public async getAssignableMetrics(rawMetric: RawMetric, filter?: string): Promise<Metric[]> {
        try {
            return this.getMetrics(filter)
                .then((metrics) => metrics
                    .filter((it) => it.category === rawMetric.category)
                    .filter((it) => it.resolution === rawMetric.resolution)
                    .filter((it) => !it.providers.find((p) => p === rawMetric.provider)));
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public getMetricCategories(): MetricCategory[] {
        return [
            MetricCategory.Accounting,
            MetricCategory.Calculated,
            MetricCategory.Technical,
            MetricCategory.Commercial,
            MetricCategory.Availability,
            MetricCategory.Commission,
            MetricCategory.Prognosis,
            MetricCategory.Plan,
            MetricCategory.LossOfProduction,
            MetricCategory.LossOfRevenue,
        ];
    }

    public async getMetricsUnassignedForProvider(providerKey: string): Promise<Metric[]> {
        try {
            return this.getMetrics().then((metrics) => metrics
                // return only metrics that don't have the specified provider
                .filter((metric) => metric.providers.find((provider) => provider === providerKey) === undefined));
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async validateMetric(metric: Metric): Promise<{ [key: string]: string[] }> {
        const errors: { [key: string]: string[] } = {};
        if (metric.min && Number.isNaN(metric.min)) {
            errors.min = ['admin-metrics.validation.invalid-number'];
        }
        if (metric.max && Number.isNaN(metric.max)) {
            errors.max = ['admin-metrics.validation.invalid-number'];
        }

        const allMetrics = await this.metricsRepository.getMetrics();
        const translations = Object.entries(metric.name).map((kv) => ({ locale: kv[0], value: kv[1] }));
        translations.forEach((translation) => {
            const nameErrors = errors[`name.${translation.locale}`] || [];
            // validate missing translations
            if (translation.value.trim().length === 0) {
                nameErrors.push(i18n.t('admin-metrics.validation.required').toString());
            }
            // validate duplicate names
            if (allMetrics.find((it) => it.key !== metric.key && it.name[translation.locale] === translation.value)) {
                nameErrors.push(i18n.t('admin-metrics.validation.metric-duplicate').toString());
            }
            if (nameErrors.length > 0) {
                errors[`name.${translation.locale}`] = nameErrors;
            }
        });
        return errors;
    }

    public async createMetric(metric: Metric, rawMetric: RawMetric): Promise<void> {
        if (!await this.validateMetric(metric)) {
            throw new Error('metric-not-valid');
        }
        try {
            await this.metricsRepository.createMetric(metric, rawMetric);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async saveMetric(metric: Metric): Promise<void> {
        if (!await this.validateMetric(metric)) {
            throw new Error('metric-not-valid');
        }
        try {
            await this.metricsRepository.updateMetric(metric);
            if (metric.addChildMetrics) {
                await Promise.all(metric.addChildMetrics
                    .map((childMetrikKey) => this.linkChildMetric(metric.key, childMetrikKey)));
            }
            if (metric.removeChildMetrics) {
                await Promise.all(metric.removeChildMetrics
                    .map((childMetrikKey) => this.unlinkChildMetric(metric.key, childMetrikKey)));
            }
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async assignMetric(rawMetric: RawMetric, metric: Metric): Promise<void> {
        try {
            if (metric.key === '') {
                await this.createMetric(metric, rawMetric);
            } else {
                await this.metricsRepository.assignMetric(metric.key, rawMetric);
            }
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }
}
