/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResourceType {
    Park = 'park',
    Generator = 'generator',
    Generatorgroup = 'generatorgroup',
    Portfolio = 'portfolio',
    Anonymous = 'anonymous',
    Shareholder = 'shareholder',
    None = 'none'
}

export function ResourceTypeFromJSON(json: any): ResourceType {
    return ResourceTypeFromJSONTyped(json, false);
}

export function ResourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceType {
    return json as ResourceType;
}

export function ResourceTypeToJSON(value?: ResourceType | null): any {
    return value as any;
}

