import type { Dashboard } from '@/modules/ctx-report-preview';
import type { DashboardRepository } from '@/modules/ctx-report-preview/adapter';
import { DashboardsApi } from '@/modules/shared/adapter';

export class DashboardRepositoryImpl implements DashboardRepository {

    private readonly dashboardsApi: DashboardsApi;

    constructor(apis: {
        dashboards: DashboardsApi;
    }) {
        this.dashboardsApi = apis.dashboards;
    }

    public async getDashboardByKey(key: string): Promise<Dashboard|undefined> {
        const dashboard = await this.dashboardsApi.getDashboardByKey(key);
        if (dashboard) {
            return { key: dashboard.key, name: dashboard.name };
        }
        return undefined;
    }
}
