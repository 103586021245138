import _Vue from 'vue';
import Formatter from '@/assets/js/utils/Formatter';

const FormatterPlugin = {
    install(vue: typeof _Vue) {

        vue.prototype.$formatDate = Formatter.formatDate;
        vue.prototype.$formatDuration = Formatter.formatDuration;
        vue.prototype.$formatMaxDigits = Formatter.formatMaxDigits;
        vue.prototype.$formatMaxDigitsForUnit = Formatter.formatMaxDigitsForUnit;
        vue.prototype.$formatNumber = Formatter.formatNumber;
        vue.prototype.$formatPower = Formatter.formatPower;
        vue.prototype.$formatZipcode = Formatter.formatZipcode;
    },
};

_Vue.use(FormatterPlugin);

// add type definitions for typescript compiler
declare module 'vue/types/vue' {
    interface Vue {
        /**
         * Formats the time with the specified format. If no explicit format is passed 'dd.MM.yyyy' is used as default.
         * The formatter replaces supported placeholders with the values from the passed time. Time can be passed as any
         * type, that can be passed to new Date().
         *
         * Supported placeholders are:
         *
         * dd - 2 digits Day of month
         * MM - 2 digits Month
         * yyyy - full year
         * hh - 2 digits hour
         * mm - 2 digits minute
         * ss - 2 digits second
         *
         * @param time: the time to format as any type that can be used with new Date()
         * @param format: date time format, default: 'dd.MM.yyyy'
         */
        $formatDate: (time?: number|string|Date, format?: string, placeholder?: string) => string;

        /**
         * Formats the duration with the specified format. If no explicit format is passed 'hh:mm' is used as default.
         * The formatter replaces supported placeholders with the values from the passed time. Time can be passed as any
         * type, that can be passed to new Date().
         *
         * Supported placeholders are:
         *
         * hh - 2 digits hour
         * mm - 2 digits minute
         * ss - 2 digits second
         *
         * @param time: time in millis
         * @param format: format, default: 'hh:mm'
         */
        $formatDuration: (time?: number, format?: string, placeholder?: string) => string;

        /**
         * Crops the decimal places of the given number to a max count defined by the unit default. Fallback is 2.
         * @param number: numeric value
         * @param unit: max digits, default: 2
         * @param placeholder: placeholder to use when number is undefined
         */
        $formatMaxDigitsForUnit: (number?: number, unit?: string, placeholder?: string) => string;

        /**
         * Crops the decimal places of the given number to a max count. Default is 2.
         * @param number: numeric value
         * @param digits: max digits, default: 2
         */
        $formatMaxDigits: (number?: number, digits?: number, placeholder?: string) => string;

        /**
         * Formats a number using , for decimals and . for thousands.
         * example 4000.5 => 4.000,5
         * @param number: numeric value
         */
        $formatNumber: (number?: number, placeholder?: string) => string;

        /**
         * Formats a power metric using MW instead of kW if value is > 10.000
         * @param power: numeric value
         */
        $formatPower: (power?: number, placeholder?: string) => string;

        /**
         * Formats a zipcode to 5 digits, by adding 0s if the code is shorter
         * @param zipcode: the zipcode to format
         */
        $formatZipcode: (zipcode?: number|string, placeholder?: string) => string;
    }
}
