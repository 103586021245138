import type { AccountingRepository } from '@/modules/ctx-admin-accounting';
import { AccountingService } from '@/modules/ctx-admin-accounting/services/AccountingService';

export interface PortParams {
    accountingRepository: AccountingRepository;
}

export class Port {
    // services will be instantiated in plugin
    private static port: Port|null = null;

    private readonly accounting: AccountingService;

    constructor(params: PortParams) {
        this.accounting = new AccountingService({
            accountingRepository: params.accountingRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get accounting(): AccountingService {
        return Port.instance.accounting;
    }
}
