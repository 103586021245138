/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserCreateRequest,
    UserCreateRequestFromJSON,
    UserCreateRequestToJSON,
    UserRepresentation,
    UserRepresentationFromJSON,
    UserRepresentationToJSON,
    UserUpdateRequest,
    UserUpdateRequestFromJSON,
    UserUpdateRequestToJSON,
} from '../models';

export interface CreateUserRequest {
    userCreateRequest: UserCreateRequest;
}

export interface DeleteUserRequest {
    key: string;
}

export interface GetUserRequest {
    key: string;
}

export interface GetUsersRequest {
    companyKey?: string;
}

export interface UpdateUserRequest {
    key: string;
    userUpdateRequest?: UserUpdateRequest;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Create a new user for the specified company
     * Create a new user
     */
    async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<UserRepresentation>> {
        if (requestParameters.userCreateRequest === null || requestParameters.userCreateRequest === undefined) {
            throw new runtime.RequiredError('userCreateRequest','Required parameter requestParameters.userCreateRequest was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["users:manage"]);
        }

        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateRequestToJSON(requestParameters.userCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new user for the specified company
     * Create a new user
     */
    async createUser(userCreateRequest: UserCreateRequest): Promise<UserRepresentation> {
        const response = await this.createUserRaw({ userCreateRequest: userCreateRequest });
        return await response.value();
    }

    /**
     * Delete the user
     * Delete the user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["users:manage"]);
        }

        const response = await this.request({
            path: `/users/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the user
     * Delete the user
     */
    async deleteUser(key: string): Promise<void> {
        await this.deleteUserRaw({ key: key });
    }

    /**
     * Return the user with the specified id
     * Return user
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<UserRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["users:read"]);
        }

        const response = await this.request({
            path: `/users/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRepresentationFromJSON(jsonValue));
    }

    /**
     * Return the user with the specified id
     * Return user
     */
    async getUser(key: string): Promise<UserRepresentation> {
        const response = await this.getUserRaw({ key: key });
        return await response.value();
    }

    /**
     * Return all users
     * Return all users
     */
    async getUsersRaw(requestParameters: GetUsersRequest): Promise<runtime.ApiResponse<Array<UserRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.companyKey !== undefined) {
            queryParameters['companyKey'] = requestParameters.companyKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["users:read"]);
        }

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRepresentationFromJSON));
    }

    /**
     * Return all users
     * Return all users
     */
    async getUsers(companyKey?: string): Promise<Array<UserRepresentation>> {
        const response = await this.getUsersRaw({ companyKey: companyKey });
        return await response.value();
    }

    /**
     * Update the user
     * Update the user
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<UserRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["users:manage"]);
        }

        const response = await this.request({
            path: `/users/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateRequestToJSON(requestParameters.userUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRepresentationFromJSON(jsonValue));
    }

    /**
     * Update the user
     * Update the user
     */
    async updateUser(key: string, userUpdateRequest?: UserUpdateRequest): Promise<UserRepresentation> {
        const response = await this.updateUserRaw({ key: key, userUpdateRequest: userUpdateRequest });
        return await response.value();
    }

}
