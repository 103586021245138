/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Resolution,
    ResolutionFromJSON,
    ResolutionFromJSONTyped,
    ResolutionToJSON,
    WidgetAxisRepresentation,
    WidgetAxisRepresentationFromJSON,
    WidgetAxisRepresentationFromJSONTyped,
    WidgetAxisRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetRepresentation
 */
export interface WidgetRepresentation {
    /**
     * 
     * @type {string}
     * @memberof WidgetRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRepresentation
     */
    dashboardKey: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRepresentation
     */
    portfolioKey: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRepresentation
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRepresentation
     */
    generatedTitle: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRepresentation
     */
    preset: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRepresentation
     */
    type: string;
    /**
     * 
     * @type {Resolution}
     * @memberof WidgetRepresentation
     */
    resolution: Resolution;
    /**
     * 
     * @type {string}
     * @memberof WidgetRepresentation
     */
    config: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetRepresentation
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetRepresentation
     */
    intervalName: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetRepresentation
     */
    intervalFrom: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetRepresentation
     */
    intervalTo: number;
    /**
     * 
     * @type {Array<WidgetAxisRepresentation>}
     * @memberof WidgetRepresentation
     */
    axis: Array<WidgetAxisRepresentation>;
}

export function WidgetRepresentationFromJSON(json: any): WidgetRepresentation {
    return WidgetRepresentationFromJSONTyped(json, false);
}

export function WidgetRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'dashboardKey': json['dashboardKey'],
        'portfolioKey': json['portfolioKey'],
        'title': json['title'],
        'generatedTitle': json['generatedTitle'],
        'preset': json['preset'],
        'type': json['type'],
        'resolution': ResolutionFromJSON(json['resolution']),
        'config': json['config'],
        'position': json['position'],
        'intervalName': json['intervalName'],
        'intervalFrom': json['intervalFrom'],
        'intervalTo': json['intervalTo'],
        'axis': ((json['axis'] as Array<any>).map(WidgetAxisRepresentationFromJSON)),
    };
}

export function WidgetRepresentationToJSON(value?: WidgetRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'dashboardKey': value.dashboardKey,
        'portfolioKey': value.portfolioKey,
        'title': value.title,
        'generatedTitle': value.generatedTitle,
        'preset': value.preset,
        'type': value.type,
        'resolution': ResolutionToJSON(value.resolution),
        'config': value.config,
        'position': value.position,
        'intervalName': value.intervalName,
        'intervalFrom': value.intervalFrom,
        'intervalTo': value.intervalTo,
        'axis': ((value.axis as Array<any>).map(WidgetAxisRepresentationToJSON)),
    };
}


