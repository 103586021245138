import { v4 as generateUUID } from 'uuid';
import type {
    WidgetConfig,
    WidgetConfigAxis,
    WidgetConfigMetric,
    WidgetConfigResource,
    WidgetConfigResourceFilter,
    WidgetConfigConfig,
} from '@/modules/ctx-dashboard/types';
import { WidgetType } from '@/modules/ctx-dashboard/types';
import { MetricCategory, Resolution } from '@/modules/shared/types';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { i18n } from '@/plugins/i18n';
import type {
    AccountingApi,
    GeneratorsApi,
    MetricsApi,
    ParksApi,
    PortfoliosApi,
    WidgetAxisRepresentation,
    WidgetCreateRequest,
    WidgetMetricRepresentation,
    WidgetRepresentation,
    WidgetResourceFilterRepresentation,
    WidgetResourceRepresentation,
    WidgetUpdateRequest,
} from '@/modules/shared/adapter';
import { WidgetUtils } from '@/components/widgets';
import { ApexOptions } from '@/types/apexcharts';

interface DeprecatedWidgetConfigConfig {
    // migration from factor to percent for backward compatibility
    operatorForecastConfig?: {
        budgetModel: string;
        forecastModel: string;
        eismanFactor?: number; // moved to eismanFactorPercent
        eismanFactorPercent: number;
        monetary: boolean;
        futureCommissionModelBudget: 'fixed'|'extend';
        fixedCommissionRateBudget: number;
        futureCommissionModelForecast: 'fixed'|'extend';
        fixedCommissionRateForecast: number;
    };
    // apex options
    apexconfig?: Partial<ApexOptions>; // renamed to apexConfig
    // stacked charts
    stacked?: boolean; // moved to apexConfig.chart.stacked
    stackType?: 'normal'|'100%'; // moved to apexConfig.chart.stackType
    // note
    note?: string; // moved to noteConfig.note
    // map
    zoom?: number; // moved to mapConfig.zoom
    showStatus?: boolean; // moved to mapConfig.showStatus
    // table
    groupDataBy?: string; // moved to tableConfig.groupDataBy
    sortDataBy?: string; // moved to tableConfig.sortDataBy
    sortDirection?: 'asc'|'desc'; // moved to tableConfig.sortDirection
    // logs
    minimum?: number; // moved to logConfig.minimum
    events?: string[]; // moved to logConfig.events
    eventNames?: string[]; // moved to logConfig.eventNames
    eventNamesBlacklist?: string[]; // moved to logConfig.eventNamesBlacklist
    // chart
    hideNegativeValues?: boolean; // moved to chartConfig.hideNegativeValues
    useGlobalScale?: boolean; // moved to chartConfig.useGlobalScale
    // distribution
    bucketSize?: number; // moved to distribution.bucketSize
}

export class WidgetMapper {

    private readonly metricsApi: MetricsApi;
    private readonly portfoliosApi: PortfoliosApi;
    private readonly generatorsApi: GeneratorsApi;
    private readonly parksApi: ParksApi;
    private readonly accountingApi: AccountingApi;

    constructor(apis: {
        metrics: MetricsApi,
        portfolios: PortfoliosApi,
        generators: GeneratorsApi,
        parks: ParksApi,
        accounting: AccountingApi,
    }) {
        this.metricsApi = apis.metrics;
        this.portfoliosApi = apis.portfolios;
        this.parksApi = apis.parks;
        this.generatorsApi = apis.generators;
        this.accountingApi = apis.accounting;
    }

    public async mapWidgetsToDomain(widgetRepresentations: WidgetRepresentation[], favoritesView: boolean = false): Promise<WidgetConfig[]> {
        return Promise.all(widgetRepresentations.map((widget) => this.mapWidgetToDomain(widget, favoritesView)));
    }

    public async mapWidgetToDomain(widget: WidgetRepresentation, favoritesView: boolean = false): Promise<WidgetConfig> {
        const widgetConfig = WidgetMapper.parseWidgetConfig(widget.config);
        const futureAxis = widget.axis
            .map((it) => this.mapAxisToDomain(it, favoritesView ? undefined : widget.portfolioKey));
        let axis: WidgetConfigAxis[] = await Promise.all(futureAxis);
        axis = WidgetMapper.migrateMissingUUIDs(axis, widgetConfig.useSingleResourceList === true);
        return {
            key: widget.key,
            dashboardKey: widget.dashboardKey,
            portfolioKey: widget.portfolioKey,
            resolution: widget.resolution,
            type: widget.type as WidgetType,
            preset: widget.preset,
            title: widget.title,
            generatedTitle: widget.generatedTitle,
            intervalName: widget.intervalName,
            intervalFrom: widget.intervalFrom ? new Date(widget.intervalFrom) : undefined,
            intervalTo: widget.intervalTo ? new Date(widget.intervalTo) : undefined,
            config: widgetConfig,
            order: widget.position,
            axis: axis,
        };
    }

    public async mapWidgetCreateRequestToDomain(widgetTemplate: WidgetCreateRequest): Promise<WidgetConfig> {
        const axis = await Promise.all(widgetTemplate.axis.map((it) => this.mapAxisToDomain(it)));
        return {
            key: '',
            dashboardKey: '',
            portfolioKey: '',
            resolution: widgetTemplate.resolution || Resolution.Automatic,
            type: widgetTemplate.type as WidgetType,
            preset: widgetTemplate.preset,
            title: widgetTemplate.title,
            generatedTitle: widgetTemplate.generatedTitle,
            intervalName: widgetTemplate.intervalName,
            intervalFrom: widgetTemplate.intervalFrom ? new Date(widgetTemplate.intervalFrom) : undefined,
            intervalTo: widgetTemplate.intervalTo ? new Date(widgetTemplate.intervalTo) : undefined,
            config: WidgetMapper.parseWidgetConfig(widgetTemplate.config || '{}'),
            order: widgetTemplate.position || 0,
            axis: axis,
        };
    }

    /**
     * UUIDs for metrics and resources were added to enable table column referencing. These uuids are generated in
     * tabs that add metrics or resources and also support synced resources across all widget metrics. Therefore, the
     * service may return empty strings as uuids for older widgets. This function generates these missing uuids.
     *
     * @param axis
     * @param hasSingleResourceList
     * @private
     */
    private static migrateMissingUUIDs(axis: WidgetConfigAxis[], hasSingleResourceList: boolean): WidgetConfigAxis[] {
        // add missing resource keys
        const metrics = axis.flatMap((it) => it.metrics);
        const referenceMetricIndex = metrics.findIndex((metric) => metric.resources.length > 0);
        for (let metricIndex = 0; metricIndex < metrics.length; metricIndex++) {
            const metric = metrics[metricIndex];
            const resources = metric.resources;
            if (!metric.uuid) {
                metric.uuid = generateUUID().substring(0, 8);
            }

            for (let resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
                const resource = resources[resourceIndex];
                if (resource.uuid !== '') {
                    continue;
                }
                const referenceResource = metrics[referenceMetricIndex]?.resources[resourceIndex];
                if (metricIndex === referenceMetricIndex || !hasSingleResourceList || !referenceResource?.uuid) {
                    // generate new uuid
                    resource.uuid = generateUUID().substring(0, 8);
                } else {
                    // use uuid from reference metric
                    resource.uuid = referenceResource.uuid;
                }
            }
        }
        return axis;
    }

    private async mapAxisToDomain(axis: WidgetAxisRepresentation, portfolioKey?: string): Promise<WidgetConfigAxis> {
        const metrics = await Promise.all(axis.metrics.map((it) => this.mapMetricToDomain(it, portfolioKey)));
        const axisUnit = metrics
            .map((it) => it?.metricUnit || '')
            .filter(ArrayUtils.filterUndefined)
            .filter(ArrayUtils.removeDuplicates)
            .join(', ');
        return {
            name: axis.name || '',
            config: WidgetMapper.parseAxisConfig(axis.config),
            unit: axisUnit,
            metrics: metrics,
        };
    }

    private async mapMetricToDomain(widgetMetric: WidgetMetricRepresentation, portfolioKey?: string): Promise<WidgetConfigMetric> {
        const metric = await this.metricsApi.getMetricByKey(widgetMetric.metricKey);
        const resources = await Promise.all(widgetMetric.resources.map((it) => this.mapResourceToDomain(it, portfolioKey)));
        return {
            uuid: widgetMetric.uuid || '',
            metricKey: widgetMetric.metricKey || '',
            metricName: i18n.t(metric?.name || '').toString(),
            metricCategory: metric?.category || MetricCategory.Technical,
            metricUnit: metric?.unit || '',
            aggregationOverTime: widgetMetric.aggregationOverTime,
            resources: resources,
        };
    }

    private async mapResourceToDomain(resource: WidgetResourceRepresentation, portfolioKey?: string): Promise<WidgetConfigResource> {
        const filters: WidgetConfigResourceFilter[]|undefined = resource.resourceFilters?.map(WidgetMapper.mapResourceFilterToDomain);
        const resourceName = await WidgetUtils.getDefaultResourceName({
            resourceType: resource.type,
            resourceKey: resource.resourceKey,
            resourceFilters: filters,
            portfolioKey: portfolioKey,
            seriesName: resource.name,
        });
        const resourceConfig = WidgetMapper.parseResourceConfig(resource.config);
        return {
            uuid: resource.uuid || '',
            seriesName: resource.name,
            type: resource.type,
            aggregationOverGenerators: resource.aggregationOverGenerators,
            timeOverride: resource.timeCompare,
            config: resourceConfig,
            resourceKey: resource.resourceKey || generateUUID(),
            resourceName: resourceName,
            resourceFilters: resource.resourceFilters?.map(WidgetMapper.mapResourceFilterToDomain),
        };
    }

    private static mapResourceFilterToDomain(filter: WidgetResourceFilterRepresentation): WidgetConfigResourceFilter {
        return {
            filterName: filter.name,
            filterValue: filter.filter,
        };
    }

    private static parseAxisConfig(configString: string): any {
        try {
            return JSON.parse(configString);
        } catch (e) {
            return {};
        }
    }

    private static parseResourceConfig(configString: string): any {
        try {
            return JSON.parse(configString);
        } catch (e) {
            return {};
        }
    }

    private static parseWidgetConfig(configString: string): WidgetConfigConfig {
        try {
            const parsedConfig: WidgetConfigConfig & DeprecatedWidgetConfigConfig = JSON.parse(configString);
            const mappedConfig: WidgetConfigConfig = {};
            if (parsedConfig.powerCurveConfig !== undefined) {
                mappedConfig.powerCurveConfig = parsedConfig.powerCurveConfig;
            }
            if (parsedConfig.operatorForecastConfig !== undefined) {
                if (parsedConfig.operatorForecastConfig.eismanFactorPercent === undefined && parsedConfig.operatorForecastConfig.eismanFactor !== undefined) {
                    console.log('Migrating eismanFactor to eismanFactorPercent');
                    parsedConfig.operatorForecastConfig.eismanFactorPercent = parsedConfig.operatorForecastConfig.eismanFactor * 100;
                    delete parsedConfig.operatorForecastConfig.eismanFactor;
                }
                mappedConfig.operatorForecastConfig = parsedConfig.operatorForecastConfig;
            }
            if (parsedConfig.apexConfig !== undefined) {
                mappedConfig.apexConfig = parsedConfig.apexConfig;
            }
            if (parsedConfig.noteConfig !== undefined) {
                mappedConfig.noteConfig = parsedConfig.noteConfig;
            }
            if (parsedConfig.tableConfig !== undefined) {
                mappedConfig.tableConfig = parsedConfig.tableConfig;
            }
            if (parsedConfig.chartConfig !== undefined) {
                mappedConfig.chartConfig = parsedConfig.chartConfig;
            }
            if (parsedConfig.mapConfig !== undefined) {
                mappedConfig.mapConfig = parsedConfig.mapConfig;
            }
            if (parsedConfig.logConfig !== undefined) {
                mappedConfig.logConfig = parsedConfig.logConfig;
            }
            if (parsedConfig.distributionConfig !== undefined) {
                mappedConfig.distributionConfig = parsedConfig.distributionConfig;
            }
            if (parsedConfig.useSingleResourceList !== undefined) {
                mappedConfig.useSingleResourceList = parsedConfig.useSingleResourceList;
            }
            if (parsedConfig.dataLimit !== undefined) {
                mappedConfig.dataLimit = parsedConfig.dataLimit;
            }

            // apply migrations
            if (parsedConfig.apexconfig !== undefined) {
                console.log('Migrating apexconfig to apexConfig');
                mappedConfig.apexConfig = parsedConfig.apexconfig;
            }
            if (parsedConfig.stacked !== undefined || parsedConfig.stackType !== undefined) {
                console.log('Migrating stacked to apexConfig.chart.stacked');
                if (!mappedConfig.apexConfig) {
                    mappedConfig.apexConfig = {};
                }
                if (!mappedConfig.apexConfig.chart) {
                    mappedConfig.apexConfig.chart = {};
                }
                mappedConfig.apexConfig.chart.stacked = parsedConfig.stacked;
                mappedConfig.apexConfig.chart.stackType = parsedConfig.stackType;
            }
            if (parsedConfig.note !== undefined) {
                console.log('Migrating noteConfig');
                mappedConfig.noteConfig = {
                    note: parsedConfig.note,
                };
            }
            if (parsedConfig.zoom !== undefined || parsedConfig.showStatus !== undefined) {
                console.log('Migrating mapConfig');
                mappedConfig.mapConfig = {
                    zoom: parsedConfig.zoom,
                    showStatus: parsedConfig.showStatus,
                };
            }
            if (parsedConfig.groupDataBy !== undefined || parsedConfig.sortDataBy !== undefined || parsedConfig.sortDirection !== undefined) {
                console.log('Migrating tableConfig');
                mappedConfig.tableConfig = {
                    groupDataBy: parsedConfig.groupDataBy,
                    sortDataBy: parsedConfig.sortDataBy,
                    sortDirection: parsedConfig.sortDirection,
                    formatRules: mappedConfig.tableConfig?.formatRules || [],
                };
            }
            if (parsedConfig.minimum !== undefined || parsedConfig.events !== undefined || parsedConfig.eventNames !== undefined || parsedConfig.eventNamesBlacklist !== undefined) {
                console.log('Migrating logConfig');
                mappedConfig.logConfig = {
                    minimum: parsedConfig.minimum,
                    events: parsedConfig.events,
                    eventNames: parsedConfig.eventNames,
                    eventNamesBlacklist: parsedConfig.eventNamesBlacklist,
                };
            }
            if (parsedConfig.hideNegativeValues !== undefined || parsedConfig.useGlobalScale !== undefined) {
                console.log('Migrating chartConfig');
                mappedConfig.chartConfig = {
                    hideNegativeValues: parsedConfig.hideNegativeValues,
                    useGlobalScale: parsedConfig.useGlobalScale,
                };
            }
            if (parsedConfig.bucketSize !== undefined) {
                console.log('Migrating distributionConfig');
                mappedConfig.distributionConfig = {
                    bucketSize: parsedConfig.bucketSize,
                };
            }
            return mappedConfig;
        } catch (e: any) {
            return {};
        }
    }

    public mapDomainToCreateRequest(widget: WidgetConfig): WidgetCreateRequest {
        return {
            preset: widget.preset,
            type: widget.type,
            title: widget.title,
            generatedTitle: widget.generatedTitle,
            resolution: widget.resolution,
            config: JSON.stringify(widget.config),
            position: widget.order,
            intervalName: widget.intervalName,
            intervalFrom: widget.intervalFrom?.getTime ? widget.intervalFrom.getTime() : undefined,
            intervalTo: widget.intervalTo?.getTime ? widget.intervalTo.getTime() : undefined,
            axis: widget.axis.map(WidgetMapper.mapAxisToRepresentation),
        };
    }

    public mapDomainToUpdateRequest(widget: WidgetConfig): WidgetUpdateRequest {
        return {
            title: widget.title,
            generatedTitle: widget.generatedTitle,
            resolution: widget.resolution,
            config: JSON.stringify(widget.config),
            position: widget.order,
            intervalName: widget.intervalName,
            intervalFrom: widget.intervalFrom?.getTime ? widget.intervalFrom.getTime() : undefined,
            intervalTo: widget.intervalTo?.getTime ? widget.intervalTo.getTime() : undefined,
            axis: widget.axis.map(WidgetMapper.mapAxisToRepresentation),
        };
    }

    private static mapAxisToRepresentation(axis: WidgetConfigAxis, index: number): WidgetAxisRepresentation {
        return {
            config: JSON.stringify(axis.config),
            name: axis.name,
            position: index * 10,
            metrics: axis.metrics.map(WidgetMapper.mapMetricToRepresentation),
        };
    }

    private static mapMetricToRepresentation(metric: WidgetConfigMetric, index: number): WidgetMetricRepresentation {
        return {
            uuid: metric.uuid,
            aggregationOverTime: metric.aggregationOverTime,
            metricKey: metric.metricKey,
            position: index * 10,
            resources: metric.resources.map(WidgetMapper.mapResourceToRepresentation),
        };
    }

    private static mapResourceToRepresentation(resource: WidgetConfigResource, index: number): WidgetResourceRepresentation {
        return {
            uuid: resource.uuid,
            aggregationOverGenerators: resource.aggregationOverGenerators,
            config: JSON.stringify(resource.config),
            name: resource.seriesName,
            position: index * 10,
            timeCompare: resource.timeOverride,
            type: resource.type,
            resourceKey: resource.resourceKey,
            resourceFilters: resource.resourceFilters?.map(WidgetMapper.mapResourceFilterToRepresentation),
        };
    }

    private static mapResourceFilterToRepresentation(filter: WidgetConfigResourceFilter): WidgetResourceFilterRepresentation {
        return {
            name: filter.filterName,
            filter: filter.filterValue,
        };
    }
}
