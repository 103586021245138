import type { GeneratorsRepository, Generator } from '@/modules/ctx-admin-data-imports';
import { GeneratorRepresentation, GeneratorsApi, ParksApi } from '@/modules/shared/adapter';

export class GeneratorsRepositoryImpl implements GeneratorsRepository {

    private readonly generatorsApi: GeneratorsApi;
    private readonly parksApi: ParksApi;

    constructor(apis: {
        generators: GeneratorsApi,
        parks: ParksApi,
    }) {
        this.parksApi = apis.parks;
        this.generatorsApi = apis.generators;
    }

    public async getGenerators(): Promise<Generator[]> {
        const generators = await this.generatorsApi.getGenerators();
        return Promise.all(generators.map(async (gen) => {
            const park = await this.parksApi.getParkByKey(gen.parkKey || '');
            return {
                key: gen.key,
                name: gen.name,
                parkKey: gen.parkKey || '',
                parkName: park?.name || '',
                active: gen.active || false,
            };
        }));
    }

    public async getGeneratorsByKeys(generatorKeys: string[]): Promise<Generator[]> {
        const generators = await this.generatorsApi.getGeneratorsByKeys(generatorKeys);
        return Promise.all(generators.map(async (gen) => {
            const park = await this.parksApi.getParkByKey(gen.parkKey || '');
            return {
                key: gen.key,
                name: gen.name,
                parkKey: gen.parkKey || '',
                parkName: park?.name || '',
                active: gen.active || false,
            };
        }));
    }

    public async getGeneratorByKey(generatorKey: string): Promise<Generator> {
        const gen = await this.generatorsApi.getGeneratorByKey(generatorKey);
        if (!gen) {
            throw new Error('unknown generator');
        }
        const park = await this.parksApi.getParkByKey(gen.parkKey || '');
        if (!park) {
            throw new Error('unknown park');
        }
        return {
            key: gen.key,
            name: gen.name,
            parkKey: gen.parkKey || '',
            parkName: park.name,
            active: gen.active || false,
        };
    }

    public async setGeneratorActiveStatus(generator: Generator): Promise<GeneratorRepresentation | undefined> {
        return await this.generatorsApi.setGeneratorActiveStatus({
            key: generator.key,
            body: generator.active,
        });
    }
}
