/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReImportType,
    ReImportTypeFromJSON,
    ReImportTypeFromJSONTyped,
    ReImportTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeleteReImportRequest
 */
export interface DeleteReImportRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteReImportRequest
     */
    generatorKeys: Array<string>;
    /**
     * 
     * @type {ReImportType}
     * @memberof DeleteReImportRequest
     */
    type: ReImportType;
}

export function DeleteReImportRequestFromJSON(json: any): DeleteReImportRequest {
    return DeleteReImportRequestFromJSONTyped(json, false);
}

export function DeleteReImportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteReImportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generatorKeys': json['generatorKeys'],
        'type': ReImportTypeFromJSON(json['type']),
    };
}

export function DeleteReImportRequestToJSON(value?: DeleteReImportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generatorKeys': value.generatorKeys,
        'type': ReImportTypeToJSON(value.type),
    };
}


