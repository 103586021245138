export default class FileUtils {

    /**
     * Save a file downloaded by http request
     * source: https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
     *
     * @param blob binary data
     * @param filename name of the file
     */
    public static downloadFile(blob: Blob, filename: string) {
        let mimeType = '';
        if (filename.endsWith('.pdf')) {
            mimeType = 'application/pdf';
        } else if (filename.endsWith('.csv')) {
            mimeType = 'text/csv';
        } else if (filename.endsWith('.zip')) {
            mimeType = 'application/zip';
        } else {
            console.error('Filetype not supported! Please use one of the following types: .pdf, .csv or .zip');
            return;
        }

        // it is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob], { type: mimeType });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(newBlob);
            return;
        }
        // for other browsers:
        // create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(() => {
            // for Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    public static downloadTextAsFile(filename: string, content: string) {
        const element = document.createElement('a');
        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
}
