/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WidgetMetricRepresentation,
    WidgetMetricRepresentationFromJSON,
    WidgetMetricRepresentationFromJSONTyped,
    WidgetMetricRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetAxisRepresentation
 */
export interface WidgetAxisRepresentation {
    /**
     * 
     * @type {string}
     * @memberof WidgetAxisRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetAxisRepresentation
     */
    config: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetAxisRepresentation
     */
    position: number;
    /**
     * may be empty for some configs, tables can define n metrics
     * @type {Array<WidgetMetricRepresentation>}
     * @memberof WidgetAxisRepresentation
     */
    metrics: Array<WidgetMetricRepresentation>;
}

export function WidgetAxisRepresentationFromJSON(json: any): WidgetAxisRepresentation {
    return WidgetAxisRepresentationFromJSONTyped(json, false);
}

export function WidgetAxisRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetAxisRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'config': json['config'],
        'position': json['position'],
        'metrics': ((json['metrics'] as Array<any>).map(WidgetMetricRepresentationFromJSON)),
    };
}

export function WidgetAxisRepresentationToJSON(value?: WidgetAxisRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'config': value.config,
        'position': value.position,
        'metrics': ((value.metrics as Array<any>).map(WidgetMetricRepresentationToJSON)),
    };
}


