export enum Layout {
    CoverPage = 'layout-cover',
    TableOfContent = 'layout-toc',
    Summary = 'layout-summary',
    Layout1 = 'layout-1',
    Layout2a = 'layout-2a',
    Layout2b = 'layout-2b',
    Layout3a = 'layout-3a',
    Layout3b = 'layout-3b',
    Layout3c = 'layout-3c',
    Layout3d = 'layout-3d',
    Layout4 = 'layout-4',
}

export function layoutFromString(value: string): Layout {
    switch (value) {
        case 'layout-cover': return Layout.CoverPage;
        case 'layout-toc': return Layout.TableOfContent;
        case 'layout-summary': return Layout.Summary;
        case 'layout-1': return Layout.Layout1;
        case 'layout-2a': return Layout.Layout2a;
        case 'layout-2b': return Layout.Layout2b;
        case 'layout-3a': return Layout.Layout3a;
        case 'layout-3b': return Layout.Layout3b;
        case 'layout-3c': return Layout.Layout3c;
        case 'layout-3d': return Layout.Layout3d;
        case 'layout-4': return Layout.Layout4;
        default:
            console.warn(`Unknown layout: ${value}, using default Layout-1`);
            return Layout.Layout1;
    }
}
