/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioUpdateRequest
 */
export interface PortfolioUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PortfolioUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioUpdateRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioUpdateRequest
     */
    order?: number;
}

export function PortfolioUpdateRequestFromJSON(json: any): PortfolioUpdateRequest {
    return PortfolioUpdateRequestFromJSONTyped(json, false);
}

export function PortfolioUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function PortfolioUpdateRequestToJSON(value?: PortfolioUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'order': value.order,
    };
}


