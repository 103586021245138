import type { UserRepository, WidgetRepository } from '@/modules/ctx-dashboard/adapter';
import type { WidgetConfig, WidgetTemplate } from '@/modules/ctx-dashboard/types';
import { DashboardType } from '@/modules/ctx-dashboard';
import NotificationService from '@/assets/js/services/NotificationService';
import { i18n } from '@/plugins/i18n';
import { WidgetPreset } from '@/components/widget-wizard';
import { LicenseFeature, ResourceType, Role } from '@/modules/shared/types';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { WidgetUtils } from '@/components/widgets';
import { WidgetDataUtils } from '@/components/widgets/utils/WidgetDataUtils';
import type { FlattenedResource } from '@/components/widgets/types/FlattenedResource';
import { LicenseRepository } from '@/modules/ctx-dashboard/adapter';

export class WidgetService {

    private readonly licenseRepository: LicenseRepository;
    private readonly userRepository: UserRepository;
    private readonly widgetRepository: WidgetRepository;
    private readonly widgetTemplatesPath: string = '@/components/widgets/presets';
    private readonly widgetTemplateCategoryOrder: string[] = [
        'custom',
        'performance',
        'forecast',
        'report',
        'ai',
        'default',
        'commercial',
        'feelgood',
    ];

    constructor(params: {
        widgetRepository: WidgetRepository;
        userRepository: UserRepository;
        licenseRepository: LicenseRepository;
    }) {
        this.widgetRepository = params.widgetRepository;
        this.userRepository = params.userRepository;
        this.licenseRepository = params.licenseRepository;
    }

    public async getWidgetsForDashboard(dashboardKey: string): Promise<WidgetConfig[]> {
        try {
            const widgets = await this.widgetRepository.getWidgetsForDashboard(dashboardKey);
            const widgetOrder = await this.widgetRepository.getWidgetOrder(DashboardType.Dashboard, dashboardKey);
            return widgets.sort((a, b) => {
                const indexA = widgetOrder.indexOf(a.key);
                const indexB = widgetOrder.indexOf(b.key);
                if (indexA === -1 && indexB === -1) return 0;
                if (indexA === -1) return 1;
                if (indexB === -1) return -1;
                return indexA - indexB;
            });
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async createWidget(widget: WidgetConfig): Promise<void> {
        this.widgetRepository.createWidget(widget)
            .catch((e: any) => NotificationService.serviceError(e));
    }

    public async updateWidget(widget: WidgetConfig): Promise<void> {
        this.widgetRepository.updateWidget(widget)
            .catch((e: any) => NotificationService.serviceError(e));
    }

    public async deleteWidget(widget: WidgetConfig): Promise<void> {
        this.widgetRepository.deleteWidget(widget)
            .catch((e: any) => NotificationService.serviceError(e));
    }

    public async copyWidget(widget: WidgetConfig, targetDashboardKey: string): Promise<void> {
        this.widgetRepository.copyWidget(widget, targetDashboardKey)
            .catch((e: any) => NotificationService.serviceError(e));
    }

    public async getWidgetOrder(dashboardType: DashboardType, dashboardKey: string|null): Promise<string[]> {
        return this.widgetRepository.getWidgetOrder(dashboardType, dashboardKey)
            .catch((e) => []);
    }

    public async getFavoriteWidgetOrder(portfolioKey: string|null): Promise<string[]> {
        if (portfolioKey !== null) {
            return this.getWidgetOrder(DashboardType.PortfolioFavorites, portfolioKey);
        }
        return this.getWidgetOrder(DashboardType.Favorites, null);
    }

    public async setWidgetOrder(dashboardType: DashboardType, dashboardKey: string|null, widgetKeys: string[]): Promise<void> {
        return this.widgetRepository.setWidgetOrder(dashboardType, dashboardKey, widgetKeys);
    }

    public async getFavoriteWidgets(portfolioKey: string|null): Promise<WidgetConfig[]> {
        try {
            if (portfolioKey) {
                return this.widgetRepository.getFavoriteWidgetsForPortfolio(portfolioKey);
            }
            return this.widgetRepository.getFavoriteWidgets();
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async addWidgetToFavorites(widget: WidgetConfig): Promise<void> {
        try {
            await Promise.all([
                this.widgetRepository.addWidgetToFavorites(widget.key),
                this.insertAtEntOfWidgetOrder(DashboardType.PortfolioFavorites, widget.portfolioKey, widget.key),
                this.insertAtEntOfWidgetOrder(DashboardType.Favorites, null, widget.key),
            ]);
        } catch (e: any) {
            NotificationService.serviceError(e);
        }
    }

    public async removeWidgetFromFavorites(widget: WidgetConfig): Promise<void> {
        try {
            await this.widgetRepository.removeWidgetFromFavorites(widget.key);
        } catch (e: any) {
            NotificationService.serviceError(e);
        }
    }

    public async isFavoriteWidget(widgetKey: string): Promise<boolean> {
        return this.widgetRepository.isFavoriteWidget(widgetKey);
    }

    private async insertAtEntOfWidgetOrder(dashboardType: DashboardType, dashboardKey: string|null, widgetKey: string): Promise<void> {
        let widgets = await this.getWidgetOrder(dashboardType, dashboardKey);
        // if the widget was already marked as favorite in the past, remove it from the order and add it again to the end
        widgets = widgets.filter((wk) => wk !== widgetKey);
        widgets.push(widgetKey);
        await this.setWidgetOrder(dashboardType, dashboardKey, widgets);
    }

    public async getWidgetFromTemplate(widgetTemplate: WidgetTemplate, portfolioKey: string, dashboardKey: string): Promise<WidgetConfig> {
        if (widgetTemplate.isDefaultTemplate) {
            const preset = this.loadWidgetPreset(widgetTemplate.key);
            if (!preset) {
                throw new Error('Preset not found');
            }
            const widget: WidgetConfig = await preset.createWidget({ portfolio: portfolioKey, dashboard: dashboardKey });
            widget.portfolioKey = portfolioKey;
            widget.dashboardKey = dashboardKey;
            return widget;
        }
        const widget = await this.widgetRepository.getWidgetFromTemplate(widgetTemplate);
        widget.portfolioKey = portfolioKey;
        widget.dashboardKey = dashboardKey;
        const portfolioName = await WidgetUtils.getDefaultResourceName({
            resourceType: ResourceType.Portfolio,
            portfolioKey: portfolioKey,
        });
        widget.axis
            .flatMap((axis) => axis.metrics)
            .flatMap((metric) => metric.resources)
            .forEach((resource) => {
                if (resource.type === ResourceType.Portfolio) {
                    resource.resourceKey = portfolioKey;
                    resource.resourceName = portfolioName;
                }
                // if (resource.type === ResourceType.Park) => replace with all Parks of portfolio
                // if (resource.type === ResourceType.Generator) => replace with all Generators of portfolio
            });

        return widget;
    }

    public async canCreateGlobalTemplates(): Promise<boolean> {
        return await this.userRepository.getUserRole() === Role.HALVAR_ADMIN;
    }

    public getResourcesCroppedOnTemplateCreation(widget: WidgetConfig): FlattenedResource[] {
        return WidgetDataUtils.flattenResources(widget)
            .filter((resource) => ![ResourceType.Portfolio, ResourceType.Anonymous, ResourceType.None].includes(resource.resourceType));
    }

    public async createDefaultTemplateFromWidget(widget: WidgetConfig): Promise<WidgetTemplate> {
        let title = widget.title || widget.generatedTitle || WidgetUtils.getDefaultWidgetTitle(widget);
        if (title.includes(' [')) {
            title = title.substring(0, title.indexOf(' ['));
        }
        const metricKeys = widget.axis
            .flatMap((axis) => axis.metrics)
            .map((metric) => metric.metricKey)
            .filter(ArrayUtils.removeDuplicates);

        const requiredLicenseFeatures: LicenseFeature[] = await this.licenseRepository.getRequiredLicenseFeaturesForMetrics(metricKeys);
        const containsAnonDatasource = widget.axis
            .flatMap((axis) => axis.metrics)
            .flatMap((metric) => metric.resources)
            .find((resource) => resource.type === ResourceType.Anonymous) !== undefined;
        if (containsAnonDatasource) {
            requiredLicenseFeatures.push(LicenseFeature.BENCHMARKING);
        }

        return {
            key: '',
            title: title,
            titleLocalized: {
                de: title,
                en: title,
            },
            type: widget.type,
            metricKeys: metricKeys,
            preset: widget.preset,
            requiredLicenseFeatures: requiredLicenseFeatures,
            category: 'custom',
            order: 0,
            isGlobal: false,
            isOneClickWidget: false,
            isDefaultTemplate: false,
            isDeletableByUser: true,
        };
    }

    public async getWidgetTemplateCategoriesForNewTemplates(): Promise<string[]> {
        // widget templates cannot be added to the categories 'default' (base widgets only) or 'custom' (private templates)
        return this.widgetTemplateCategoryOrder
            .filter((cat) => cat !== 'default')
            .filter((cat) => cat !== 'custom');
    }

    public async getWidgetTemplates(): Promise<WidgetTemplate[]> {
        const defaultTemplates = await this.loadDefaultTemplates();
        const customTemplates = await this.widgetRepository.getWidgetTemplates();
        const userLicenseFeatures = await this.userRepository.getUserLicenseFeatures();
        const userRole = await this.userRepository.getUserRole();
        return defaultTemplates.concat(customTemplates)
            .map((template) => {
                template.isDeletableByUser = template.category === 'custom'
                    || (userRole === Role.HALVAR_ADMIN && !template.isDefaultTemplate);
                return template;
            })
            .filter((template) => template.requiredLicenseFeatures.every((feature) => userLicenseFeatures.includes(feature)))
            .sort((a, b) => 0
                || this.widgetTemplateCategoryOrder.indexOf(a.category) - this.widgetTemplateCategoryOrder.indexOf(b.category)
                // WPXD-2600 disabled, because server side order seems to be random
                // || a.order - b.order
                || a.title.localeCompare(b.title));
    }

    private async loadDefaultTemplates(): Promise<WidgetTemplate[]> {
        const templates: WidgetTemplate[] = [];
        const modules = require.context('@/components/widgets/presets', true, /\/.*\/.*\.ts/i);
        modules.keys().forEach((path) => {
            const category = path.split('/')[1];
            const preset: WidgetPreset|undefined = this.loadWidgetPreset(path);
            if (preset) {
                templates.push({
                    key: path,
                    title: i18n.t(`widgets.title.for-preset.${preset.name}`).toString(),
                    preset: preset.name,
                    type: preset.type,
                    isOneClickWidget: preset.quickAdd,
                    requiredLicenseFeatures: preset.requireLicenseFeatures || [],
                    metricKeys: preset.metrics,
                    order: preset.order || 0,
                    category: category,
                    isDefaultTemplate: true,
                    isGlobal: true,
                });
            }
        });
        return templates;
    }

    private loadWidgetPreset(path: string): WidgetPreset|undefined {
        const modules = require.context('@/components/widgets/presets', true, /\/.*\/.*\.ts/i);
        const module = modules(path);
        if (module.default) {
            const PresetModule = module.default;
            const preset = new PresetModule();
            if (!preset.hidden) {
                return preset;
            }
        }
        return undefined;
    }

    public async validateWidgetTemplate(partialTemplate: Partial<WidgetTemplate>): Promise<{[key: string]: string[]}> {
        const errors: {[key: string]: string[]} = {};
        if (partialTemplate.isGlobal) {
            if (await this.userRepository.getUserRole() !== Role.HALVAR_ADMIN) {
                errors.global = ['no-admin'];
            }
            if (!partialTemplate.category) {
                errors.category = ['should-not-be-null'];
            }
            if (partialTemplate.category === 'custom') {
                errors.category = ['should-not-be-custom'];
            }
            if (!partialTemplate.titleLocalized?.de) {
                errors['titleLocalized.de'] = ['should-not-be-empty'];
            }
            if (!partialTemplate.titleLocalized?.en) {
                errors['titleLocalized.en'] = ['should-not-be-empty'];
            }
        }
        return errors;
    }

    public async createWidgetTemplate(widget: WidgetConfig, partialTemplate: WidgetTemplate): Promise<WidgetTemplate> {
        try {
            partialTemplate.isDefaultTemplate = false;
            if (!await this.canCreateGlobalTemplates()) {
                partialTemplate.isGlobal = false;
            }
            if (!partialTemplate.isGlobal) {
                partialTemplate.category = 'custom';
                partialTemplate.titleLocalized = undefined;
            }
            // set template order
            const templates = await this.getWidgetTemplates();
            const order = templates
                .filter((template) => template.category === partialTemplate.category)
                .map((template) => template.order)
                .reduce(ArrayUtils.reduceToHighest, 0);
            partialTemplate.order = order + 10;
            partialTemplate.title = partialTemplate.title || widget.title || widget.generatedTitle || '';
            return this.widgetRepository.createWidgetTemplate(widget.key, partialTemplate);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async deleteWidgetTemplate(widgetTemplate: WidgetTemplate): Promise<void> {
        if (widgetTemplate.isDefaultTemplate) {
            throw new Error('Cannot delete default template!');
        }
        const isAdmin = true;
        if (widgetTemplate.category !== 'custom' && !isAdmin) {
            throw new Error('Cannot delete global template! Insufficient permission!');
        }
        return this.widgetRepository.deleteWidgetTemplate(widgetTemplate);
    }
}
