/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnonRequestFilter,
    AnonRequestFilterFromJSON,
    AnonRequestFilterFromJSONTyped,
    AnonRequestFilterToJSON,
    ResourceType,
    ResourceTypeFromJSON,
    ResourceTypeFromJSONTyped,
    ResourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SimpleDataRequestResource
 */
export interface SimpleDataRequestResource {
    /**
     * 
     * @type {ResourceType}
     * @memberof SimpleDataRequestResource
     */
    resourceType: ResourceType;
    /**
     * 
     * @type {string}
     * @memberof SimpleDataRequestResource
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDataRequestResource
     */
    portfolioKey: string;
    /**
     * 
     * @type {Array<AnonRequestFilter>}
     * @memberof SimpleDataRequestResource
     */
    filter?: Array<AnonRequestFilter>;
}

export function SimpleDataRequestResourceFromJSON(json: any): SimpleDataRequestResource {
    return SimpleDataRequestResourceFromJSONTyped(json, false);
}

export function SimpleDataRequestResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleDataRequestResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceType': ResourceTypeFromJSON(json['resourceType']),
        'resourceKey': !exists(json, 'resourceKey') ? undefined : json['resourceKey'],
        'portfolioKey': json['portfolioKey'],
        'filter': !exists(json, 'filter') ? undefined : ((json['filter'] as Array<any>).map(AnonRequestFilterFromJSON)),
    };
}

export function SimpleDataRequestResourceToJSON(value?: SimpleDataRequestResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceType': ResourceTypeToJSON(value.resourceType),
        'resourceKey': value.resourceKey,
        'portfolioKey': value.portfolioKey,
        'filter': value.filter === undefined ? undefined : ((value.filter as Array<any>).map(AnonRequestFilterToJSON)),
    };
}


