import type { RouteConfig } from 'vue-router';
import { Role, LicenseFeature } from '@/modules/shared/types';

const route: RouteConfig = {
    path: '/:customer/portfolios/:portfolioKey/dashboards/:dashboardKey/report',
    name: 'portfolio-dashboard-report',
    component: () => import(/* webpackChunkName: "ctx-report-preview" */ './ReportPreviewView.vue'),
    meta: {
        role: Role.USER,
        license: [LicenseFeature.REPORTING],
        missingLicenseRedirect: 'portfolio-dashboard',
        authenticated: true,
        navbar: true,
        footer: true,
        settingsTabs: false,
        portfolioTabs: false,
        onboarding: false,
        notifications: true,
    },
};

export { route };
