import { Generator, GeneratorRepository } from '@/modules/ctx-report-preview';
import { GeneratorsApi, ParksApi } from '@/modules/shared/adapter';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { i18n } from '@/plugins/i18n';

export class GeneratorRepositoryImpl implements GeneratorRepository {

    private readonly generatorsApi: GeneratorsApi;
    private readonly parksApi: ParksApi;

    constructor(apis: {
        generators: GeneratorsApi;
        parks: ParksApi;
    }) {
        this.generatorsApi = apis.generators;
        this.parksApi = apis.parks;
    }

    public async getGeneratorsByKeys(generatorKeys: string[]): Promise<Generator[]> {
        const generatorRepresentations = await this.generatorsApi.getGeneratorsByKeys(generatorKeys);
        const parkKeys = generatorRepresentations
            .map((generatorRepresentation) => generatorRepresentation.parkKey!)
            .filter(ArrayUtils.removeDuplicates);
        const parkRepresentations = await this.parksApi.getParksByKeys(parkKeys);
        return generatorRepresentations.map((representation) => {
            const park = parkRepresentations.find((it) => it.key === representation.parkKey);
            return ({
                key: representation.key,
                name: representation.name,
                parkKey: representation.parkKey || '',
                parkName: park?.name || '',
                zipcode: park?.zipCode || '',
                model: representation.model || representation.manufacturer || i18n.t('generators.unknown-model').toString(),
                hubHeight: representation.hubHeight,
                lat: representation.latitude,
                long: representation.longitude,
                installationDate: representation.installationDate ? new Date(representation.installationDate) : undefined,
                power: representation.power,
            });
        });
    }
}
