/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountingFormulaValidationResponse
 */
export interface AccountingFormulaValidationResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingFormulaValidationResponse
     */
    accounts: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingFormulaValidationResponse
     */
    positions: Array<string>;
}

export function AccountingFormulaValidationResponseFromJSON(json: any): AccountingFormulaValidationResponse {
    return AccountingFormulaValidationResponseFromJSONTyped(json, false);
}

export function AccountingFormulaValidationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingFormulaValidationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accounts': json['accounts'],
        'positions': json['positions'],
    };
}

export function AccountingFormulaValidationResponseToJSON(value?: AccountingFormulaValidationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accounts': value.accounts,
        'positions': value.positions,
    };
}


