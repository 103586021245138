import { Configuration, CompaniesApi as CompaniesRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { DataChangedEvent } from '@/modules/shared/types';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type {
    CompaniesApi,
    CompanyRepresentation,
    CompanyCreateRequest,
    CompanyUpdateRequest,
} from '@/modules/shared/adapter/CompaniesApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedCompaniesRestApi implements CompaniesApi {

    private readonly companiesApi: CompaniesRestApi;
    private readonly companiesCache: IndexedDBRepository<string, CompanyRepresentation>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.companiesApi = new CompaniesRestApi(restApiConfig);
        this.companiesCache = new IndexedDBRepository<string, CompanyRepresentation>(indexedDb, 'companies');
    }

    public onCompaniesChange(cb: (event: DataChangedEvent) => Promise<void>): void {
        this.companiesCache.addChangedListener((evt) => cb(evt));
    }

    public async getCompany(key: string): Promise<CompanyRepresentation|undefined> {
        return await this.companiesCache.findByKey(key) || this.fetchCompany(key);
    }

    private async fetchCompany(key: string): Promise<CompanyRepresentation|undefined> {
        const companies = await this.fetchCompanies();
        return companies.find((comp) => comp.key === key);
        // TODO cache my only be set when saving the complete list...
        // return AsyncDebouncer.debounce<CompanyRepresentation>(`CachedCustomerRestApi.fetchCompany(${key})`, async () => {
        //     const company = await this.companiesApi.getCompany(key);
        //     await this.companiesCache.save(company);
        //     return company;
        // });
    }

    public async getCompanies(): Promise<CompanyRepresentation[]> {
        return await this.companiesCache.findAllIfAnyCached() || this.fetchCompanies();
    }

    public async getCompaniesWithAccessToGenerators(generatorKeys: string[]): Promise<CompanyRepresentation[]> {
        return this.companiesApi.getCompanies(generatorKeys);
    }

    private fetchCompanies(): Promise<CompanyRepresentation[]> {
        return AsyncDebouncer.debounce<CompanyRepresentation[]>('CachedCustomerRestApi.fetchCompanies', async () => {
            const companies = await this.companiesApi.getCompanies();
            await this.companiesCache.saveAll(companies);
            return companies;
        });
    }

    public async createCompany(companyCreateSpec: CompanyCreateRequest): Promise<CompanyRepresentation> {
        const company = await this.companiesApi.createCompany(companyCreateSpec);
        await this.companiesCache.save(company);
        return company;
    }

    public async updateCompany(companyKey: string, companyUpdateSpec: CompanyUpdateRequest): Promise<CompanyRepresentation> {
        const company = await this.companiesApi.updateCompany(companyKey, companyUpdateSpec);
        await this.companiesCache.save(company);
        return company;
    }

    public async deleteCompany(companyKey: string): Promise<void> {
        await this.companiesApi.deleteCompany(companyKey);
        await this.companiesCache.removeByKey(companyKey);
    }
}
