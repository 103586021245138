/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssignRawMetricRequest,
    AssignRawMetricRequestFromJSON,
    AssignRawMetricRequestToJSON,
    CoverageRepresentation,
    CoverageRepresentationFromJSON,
    CoverageRepresentationToJSON,
    CoverageRequest,
    CoverageRequestFromJSON,
    CoverageRequestToJSON,
    CreateMetricRequest,
    CreateMetricRequestFromJSON,
    CreateMetricRequestToJSON,
    MetricRepresentation,
    MetricRepresentationFromJSON,
    MetricRepresentationToJSON,
    RawMetricRepresentation,
    RawMetricRepresentationFromJSON,
    RawMetricRepresentationToJSON,
    UpdateMetricRequest,
    UpdateMetricRequestFromJSON,
    UpdateMetricRequestToJSON,
} from '../models';

export interface AssignRawMetricOperationRequest {
    rawkey: string;
    dpkey: string;
    assignRawMetricRequest: AssignRawMetricRequest;
}

export interface CreateNewMetricRequest {
    createMetricRequest: CreateMetricRequest;
}

export interface GetAllRawMetricsRequest {
    assigned?: boolean;
    dataprovider?: string;
}

export interface GetCoverageForAllMetricsRequest {
    coverageRequest: CoverageRequest;
}

export interface UpdateMetricOperationRequest {
    key: string;
    updateMetricRequest: UpdateMetricRequest;
}

/**
 * 
 */
export class MetricsApi extends runtime.BaseAPI {

    /**
     * Assign a raw metric to a Halvar metric to activate its import.
     * Assign raw metric
     */
    async assignRawMetricRaw(requestParameters: AssignRawMetricOperationRequest): Promise<runtime.ApiResponse<RawMetricRepresentation>> {
        if (requestParameters.rawkey === null || requestParameters.rawkey === undefined) {
            throw new runtime.RequiredError('rawkey','Required parameter requestParameters.rawkey was null or undefined when calling assignRawMetric.');
        }

        if (requestParameters.dpkey === null || requestParameters.dpkey === undefined) {
            throw new runtime.RequiredError('dpkey','Required parameter requestParameters.dpkey was null or undefined when calling assignRawMetric.');
        }

        if (requestParameters.assignRawMetricRequest === null || requestParameters.assignRawMetricRequest === undefined) {
            throw new runtime.RequiredError('assignRawMetricRequest','Required parameter requestParameters.assignRawMetricRequest was null or undefined when calling assignRawMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/raw-metrics/{rawkey}/dataprovider/{dpkey}/assign`.replace(`{${"rawkey"}}`, encodeURIComponent(String(requestParameters.rawkey))).replace(`{${"dpkey"}}`, encodeURIComponent(String(requestParameters.dpkey))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignRawMetricRequestToJSON(requestParameters.assignRawMetricRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RawMetricRepresentationFromJSON(jsonValue));
    }

    /**
     * Assign a raw metric to a Halvar metric to activate its import.
     * Assign raw metric
     */
    async assignRawMetric(rawkey: string, dpkey: string, assignRawMetricRequest: AssignRawMetricRequest): Promise<RawMetricRepresentation> {
        const response = await this.assignRawMetricRaw({ rawkey: rawkey, dpkey: dpkey, assignRawMetricRequest: assignRawMetricRequest });
        return await response.value();
    }

    /**
     * Create a new metric for later assignment to raw data provider metrics.
     * Create metric
     */
    async createNewMetricRaw(requestParameters: CreateNewMetricRequest): Promise<runtime.ApiResponse<MetricRepresentation>> {
        if (requestParameters.createMetricRequest === null || requestParameters.createMetricRequest === undefined) {
            throw new runtime.RequiredError('createMetricRequest','Required parameter requestParameters.createMetricRequest was null or undefined when calling createNewMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/metrics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMetricRequestToJSON(requestParameters.createMetricRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new metric for later assignment to raw data provider metrics.
     * Create metric
     */
    async createNewMetric(createMetricRequest: CreateMetricRequest): Promise<MetricRepresentation> {
        const response = await this.createNewMetricRaw({ createMetricRequest: createMetricRequest });
        return await response.value();
    }

    /**
     * Return all metric objects for the current user
     */
    async getAllMetricsRaw(): Promise<runtime.ApiResponse<Array<MetricRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/metrics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetricRepresentationFromJSON));
    }

    /**
     * Return all metric objects for the current user
     */
    async getAllMetrics(): Promise<Array<MetricRepresentation>> {
        const response = await this.getAllMetricsRaw();
        return await response.value();
    }

    /**
     * Return all raw metrics (metric definitions that are defined by data provider).
     */
    async getAllRawMetricsRaw(requestParameters: GetAllRawMetricsRequest): Promise<runtime.ApiResponse<Array<RawMetricRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.assigned !== undefined) {
            queryParameters['assigned'] = requestParameters.assigned;
        }

        if (requestParameters.dataprovider !== undefined) {
            queryParameters['dataprovider'] = requestParameters.dataprovider;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/raw-metrics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RawMetricRepresentationFromJSON));
    }

    /**
     * Return all raw metrics (metric definitions that are defined by data provider).
     */
    async getAllRawMetrics(assigned?: boolean, dataprovider?: string): Promise<Array<RawMetricRepresentation>> {
        const response = await this.getAllRawMetricsRaw({ assigned: assigned, dataprovider: dataprovider });
        return await response.value();
    }

    /**
     * Return merged coverage for all metrics of the given generators.
     * Return metrics coverage.
     */
    async getCoverageForAllMetricsRaw(requestParameters: GetCoverageForAllMetricsRequest): Promise<runtime.ApiResponse<Array<CoverageRepresentation>>> {
        if (requestParameters.coverageRequest === null || requestParameters.coverageRequest === undefined) {
            throw new runtime.RequiredError('coverageRequest','Required parameter requestParameters.coverageRequest was null or undefined when calling getCoverageForAllMetrics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["data:read"]);
        }

        const response = await this.request({
            path: `/coverages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CoverageRequestToJSON(requestParameters.coverageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CoverageRepresentationFromJSON));
    }

    /**
     * Return merged coverage for all metrics of the given generators.
     * Return metrics coverage.
     */
    async getCoverageForAllMetrics(coverageRequest: CoverageRequest): Promise<Array<CoverageRepresentation>> {
        const response = await this.getCoverageForAllMetricsRaw({ coverageRequest: coverageRequest });
        return await response.value();
    }

    /**
     * Update an existing metric.
     * Update metric
     */
    async updateMetricRaw(requestParameters: UpdateMetricOperationRequest): Promise<runtime.ApiResponse<MetricRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateMetric.');
        }

        if (requestParameters.updateMetricRequest === null || requestParameters.updateMetricRequest === undefined) {
            throw new runtime.RequiredError('updateMetricRequest','Required parameter requestParameters.updateMetricRequest was null or undefined when calling updateMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/metrics/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMetricRequestToJSON(requestParameters.updateMetricRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricRepresentationFromJSON(jsonValue));
    }

    /**
     * Update an existing metric.
     * Update metric
     */
    async updateMetric(key: string, updateMetricRequest: UpdateMetricRequest): Promise<MetricRepresentation> {
        const response = await this.updateMetricRaw({ key: key, updateMetricRequest: updateMetricRequest });
        return await response.value();
    }

}
