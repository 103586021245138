export class IndexedDB {

    public static async getConnection(databaseName: string, storeNames: string[], version: number = 1): Promise<IDBDatabase> {
        return new Promise<IDBDatabase>((resolve) => {
            const request = window.indexedDB.open(databaseName, version);
            request.onsuccess = () => resolve(request.result);
            request.onerror = async () => {
                await IndexedDB.deleteDatabase(databaseName);
                const retry = await IndexedDB.getConnection(databaseName, storeNames, version);
                resolve(retry);
            };
            request.onupgradeneeded = () => {
                const database = request.result;
                // drop all stores
                const stores = database.objectStoreNames;
                for (let i = 0; i < stores.length; i++) {
                    const store = stores.item(i);
                    if (store) {
                        database.deleteObjectStore(store);
                    }
                }
                // recreate stores
                storeNames.forEach((storeName) => database.createObjectStore(storeName, { keyPath: 'key' }));
            };
        });
    }

    public static async deleteDatabase(databaseName: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const deleteRequest = window.indexedDB.deleteDatabase(databaseName);
            deleteRequest.onsuccess = () => resolve();
            deleteRequest.onerror = () => reject();
        });
    }
}
