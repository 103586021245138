
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Port } from '@/modules/shared/Port';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import GeneratorStatusList from './GeneratorStatusList.vue';

@Component({
    components: { GeneratorStatusList },
})
export default class GeneratorStatusChooser extends Vue {

    @Prop({ required: false, default: () => [] })
    public readonly selected!: string[];

    @Prop({ required: false, default: undefined })
    public readonly whitelist!: string[]|undefined;

    @Prop({ required: false, default: undefined })
    public readonly blacklist!: string[]|undefined;

    @Prop({ required: false, default: null })
    public readonly maxSelectable!: number|null;

    @Prop({ required: false, default: false })
    public readonly directUpdate!: boolean;

    @Prop({ required: false, default: true })
    public readonly multiSelection!: boolean;

    @Prop({ required: false, default: false })
    public readonly removeAction!: boolean;

    @Prop({ required: false, default: false })
    public readonly showDataProvider!: boolean;

    @Ref('filterinput')
    private readonly filterinput: HTMLInputElement|undefined;

    private totalCount: number = -1;
    private count: number = -1;
    private selectedMutable: string[] = [];
    private filter: string = '';
    private dataProviderFilter: string = '';
    private dataProviders: string[] = [];

    public created(): void {
        this.fetchDataProviders();
    }

    public mounted(): void {
        this.updateSelected();
        this.onCountChanged();
    }

    private async fetchDataProviders(): Promise<void> {
        const generatorStatus = await Port.generatorStatus.getGeneratorStatus();
        this.dataProviders = generatorStatus
            .map((status) => status.dataProviderKey)
            .filter(ArrayUtils.removeDuplicates);
    }

    @Watch('count')
    private onCountChanged(): void {
        if (this.totalCount === -1) {
            this.totalCount = this.count;
            this.$nextTick(() => this.filterinput?.focus());
        }
    }

    @Watch('selected')
    private updateSelected(): void {
        this.selectedMutable = [...this.selected];
    }

    private onSelectionChanged(selected: string[]): void {
        if (this.directUpdate) {
            this.$emit('update:selected', selected);
        } else {
            this.selectedMutable = selected;
        }
    }

    private submit(): void {
        this.$emit('update:selected', this.selectedMutable);
        this.$emit('submit', this.selectedMutable);
    }

    private cancel(): void {
        this.$emit('cancel');
    }
}
