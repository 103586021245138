
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import LoadingAnimation from '@/modules/shared/components/loading-animation/LoadingAnimation.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';

@Component({
    name: 'TextInputDlg',
    components: { LoadingAnimationSmall, LoadingAnimation, BaseDialog },
})
export default class TextInputDlg extends Vue {

    @Prop({ default: null })
    public readonly title!: string|null;

    @Prop({ default: null })
    public readonly message!: string|null;

    @Prop({ default: '' })
    public readonly value!: string;

    @Prop({ default: false })
    public readonly loading!: boolean;

    @Prop({ default: () => ({}) })
    public readonly validation!: {
        required: boolean;
        maxlength: number;
        minlength: number;
        pattern: string;
        validate: (input: string) => string|null|Promise<string|null>;
    };

    @Ref('input')
    private readonly input!: HTMLInputElement;

    private mutableValue: string = '';
    private error: string|null = null;
    private valid: boolean = false;
    private loadingInternal: boolean = false;

    public mounted() {
        this.mutableValue = this.value;
        this.loadingInternal = this.loading;
        this.input.focus();
        this.validate();
    }

    @Watch('mutableValue')
    private onMutableValueChanged() {
        this.$nextTick(() => this.validate());
    }

    @Watch('loading')
    private setLoading() {
        this.loadingInternal = this.loading;
    }

    private async validate(): Promise<void> {
        this.valid = await this.isValid();
    }

    private async isValid(): Promise<boolean> {
        this.error = null;
        const inputValid = this.input && this.input.checkValidity();
        if (!inputValid) {
            this.error = this.$t('validation.invalid-input').toString();
            return false;
        }
        if (this.validation.validate) {
            this.error = await this.validation.validate(this.mutableValue.trim());
            return this.error === null;
        }
        return true;
    }

    private submit() {
        if (this.valid && !this.loadingInternal) {
            this.loadingInternal = true;
            this.$emit('input', this.mutableValue.trim());
            this.$emit('submit', this.mutableValue.trim());
        }
    }

    private close() {
        this.$emit('close');
    }
}
