/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardCreateRequest
 */
export interface DashboardCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardCreateRequest
     */
    portfolioKey: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardCreateRequest
     */
    order: number;
}

export function DashboardCreateRequestFromJSON(json: any): DashboardCreateRequest {
    return DashboardCreateRequestFromJSONTyped(json, false);
}

export function DashboardCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'portfolioKey': json['portfolioKey'],
        'order': json['order'],
    };
}

export function DashboardCreateRequestToJSON(value?: DashboardCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'portfolioKey': value.portfolioKey,
        'order': value.order,
    };
}


