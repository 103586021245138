import { Configuration, I18nApi as I18nRestApi } from '@/clients/dashboardapi/v2';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type { LocalizationApi } from '@/modules/shared/adapter/LocalizationApi';
import type { DataChangedEvent } from '@/modules/shared/types';
import { AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class LocalizationRestApi implements LocalizationApi {

    private readonly i18nApi: I18nRestApi;
    private readonly changeListeners: ((event: DataChangedEvent) => Promise<void>)[] = [];

    constructor(apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.i18nApi = new I18nRestApi(restApiConfig);
    }

    public loadTextsForLanguage(locale: string): Promise<{ [p: string]: string }> {
        return this.i18nApi.getI18NForLanguage(locale);
    }

    public async updateText(locale: string, key: string, value: string): Promise<void> {
        await this.i18nApi.updateI18nItemForLanguage(locale, key, value);
        this.requestReload(locale);
    }

    public async updateTexts(key: string, texts: {[key: string]: string}): Promise<void> {
        const translations = Object.entries(texts).map((it) => ({ lc: it[0], text: it[1] }));
        // WPXD-2601 submitting these requests in parallel causes errors in backend
        for (let i = 0; i < translations.length; i++) {
            const it = translations[i];
            // eslint-disable-next-line no-await-in-loop
            await this.i18nApi.updateI18nItemForLanguage(it.lc, key, it.text);
        }
        // await Promise.all(translations.map((it) => this.i18nApi.updateI18nItemForLanguage(it.lc, key, it.text)));
        this.requestReload(translations.map((it) => it.lc));
    }

    public onChange(cb: (event: DataChangedEvent) => Promise<void>): void {
        this.changeListeners.push(cb);
    }

    public requestReload(locales?: string|string[]): void {
        let lcKeys: string[] = [];
        if (locales) {
            lcKeys = Array.isArray(locales) ? locales as string[] : [locales];
        }
        this.changeListeners.forEach((cb) => cb({ action: 'update', keys: lcKeys }));
    }
}
