import { Resolution } from '@/modules/shared/types';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';

export default class TableAvailability implements WidgetPreset {

    public readonly name: string = 'availability';
    public readonly type: WidgetType = WidgetType.Availability;
    public readonly quickAdd: boolean = false;
    public readonly order: number = 0;
    public readonly metrics: string[] = [];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {
                useSingleResourceList: true,
            },
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'this-year-complete',
            intervalTo: new Date(),
            intervalFrom: new Date(),
            resolution: Resolution.Monthly,
            axis: [{
                unit: '',
                name: '',
                config: {},
                metrics: [],
            }],
        };
    }
}
