import { Resolution } from '@/modules/shared/types';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';

export default class CustomNumber implements WidgetPreset {

    public readonly name: string = 'custom-number';
    public readonly type: WidgetType = WidgetType.Number;
    public readonly quickAdd: boolean = false;
    public readonly order: number = 50;
    public readonly metrics: string[] = [];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {},
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'this-month-complete',
            intervalTo: new Date(),
            intervalFrom: new Date(),
            resolution: Resolution.Automatic,
            axis: [{
                unit: '',
                name: '',
                metrics: [],
                config: {},
            }],
        };
    }
}
