
import { Component, Prop, Vue } from 'vue-property-decorator';
import BubbleMenu from '@/components/basics/BubbleMenu.vue';
import { LicenseFeature, Role } from '@/modules/shared/types';
import { CompanyRepresentation, UserRepresentation } from '@/clients/dashboardapi/v2';
import { Port } from '@/modules/shared/Port';
import LicenseGuard from '@/modules/shared/components/util/LicenseGuard.vue';
import RoleGuard from '@/modules/shared/components/util/RoleGuard.vue';

@Component({
    components: { RoleGuard, LicenseGuard, BubbleMenu },
})
export default class MenuUser extends Vue {

    @Prop({ default: null })
    public readonly anchor!: HTMLElement|null;

    private readonly LicenseFeature = LicenseFeature;
    private readonly Role = Role;

    private user: UserRepresentation|null = null;
    private accountPage: string = '';
    private userCompany: CompanyRepresentation|null = null;

    public created(): void {
        this.fetchUser();
        this.fetchUserCompany();
    }

    private resetOnboarding() {
        this.$store.commit('App/setOnboardingProgress', { progress: 0 });
        this.$emit('close');
    }

    private loadLanguage(lang: string) {
        this.$messages.changeLanguage(lang);
        this.$emit('close');
    }

    private close() {
        this.$emit('close');
    }

    private async fetchUser(): Promise<void> {
        this.user = await Port.auth.getUser();
        this.accountPage = await Port.auth.getAccountSettingsUrl(this.$i18n.locale);
    }

    private async fetchUserCompany(): Promise<void> {
        this.userCompany = await Port.auth.getCompany();
    }
}
