/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DataRequestResource,
    DataRequestResourceFromJSON,
    DataRequestResourceFromJSONTyped,
    DataRequestResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface GaugeDataRequest
 */
export interface GaugeDataRequest {
    /**
     * 
     * @type {Array<DataRequestResource>}
     * @memberof GaugeDataRequest
     */
    resources: Array<DataRequestResource>;
}

export function GaugeDataRequestFromJSON(json: any): GaugeDataRequest {
    return GaugeDataRequestFromJSONTyped(json, false);
}

export function GaugeDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GaugeDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resources': ((json['resources'] as Array<any>).map(DataRequestResourceFromJSON)),
    };
}

export function GaugeDataRequestToJSON(value?: GaugeDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resources': ((value.resources as Array<any>).map(DataRequestResourceToJSON)),
    };
}


