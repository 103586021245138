
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MetricCategory, Resolution } from '@/modules/shared/types';
import type { MetricRepresentation } from '@/clients/dashboardapi/v2';
import AutocompleteSelectBox from '@/modules/shared/components/input/AutocompleteSelectBox.vue';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import { Port } from '@/modules/shared/Port';

@Component({
    components: { AutocompleteSelectBox },
})
export default class MetricChooserCmb extends Vue {

    @Prop({ default: null })
    public readonly metricKey!: string|null;

    @Prop({ default: null })
    public readonly metric!: MetricRepresentation|null;

    @Prop({ default: null })
    public readonly customMetrics!: MetricRepresentation[]|null;

    @Prop({ default: null })
    public readonly filter!: ((metric: MetricRepresentation) => boolean)|null;

    @Prop({ default: null })
    public readonly placeholder!: string|null;

    @Prop({ default: null })
    public readonly invalidPlaceholder!: string|null;

    @Prop({ default: null })
    public readonly coveredInPortfolio!: string|null;

    @Prop({ default: false })
    public readonly disabled!: boolean|string;

    @Prop({ default: null })
    public readonly errors!: string[]|null;

    @Prop({ default: true })
    public readonly showErrors!: boolean;

    private loading: boolean = true;
    private allMetrics: MetricRepresentation[] = [];
    private coveredMetrics: MetricRepresentation[]|null = null;

    public created(): void {
        this.fetchMetrics();
        this.fetchCoveredMetrics();
    }

    private get selectedMetric(): string|null {
        if (this.metric) {
            return this.metric.key;
        }
        if (this.metricKey) {
            return this.metricKey;
        }
        return null;
    }

    private metricChanged(metricKey: string|undefined) {
        const metric = this.filteredMetrics.find((m) => m.key === metricKey);
        this.$emit('input:metric', metric);
        this.$emit('input:metricKey', metric?.key);
        this.$emit('change:metric', metric);
        this.$emit('change:metricKey', metric?.key);
    }

    private async fetchMetrics(): Promise<void> {
        this.loading = true;
        this.allMetrics = (this.customMetrics || []).concat(await Port.metrics.getMetrics(false));
        this.loading = false;
    }

    private async fetchCoveredMetrics(): Promise<void> {
        if (this.coveredInPortfolio) {
            const portfolio = await Port.portfolios.getPortfolioByKey(this.coveredInPortfolio);
            this.coveredMetrics = await Port.metrics.getMetricsWithCoverage(portfolio?.generatorKeys || []);
        }
    }

    private get filteredMetrics(): MetricRepresentation[] {
        const metrics: MetricRepresentation[] = this.customMetrics
            || this.coveredMetrics
            || this.allMetrics;
        if (this.filter) {
            const matchesFilter = this.filter;
            return metrics.filter((metric) => matchesFilter(metric));
        }
        return metrics;
    }

    private get options(): SelectBoxOption[] {
        const emptyOption: SelectBoxOption = { value: undefined, displayName: '' };
        const metricOptions: SelectBoxOption[] = this.filteredMetrics
            .map((metric: MetricRepresentation) => {
                const option: SelectBoxOption = {
                    displayName: this.$t(metric.name!).toString(),
                    value: metric.key,
                    category: `metrics.${this.mapCategory(metric)}`,
                    order: metric.priority,
                };
                if (metric.minResolution !== Resolution.Min10) {
                    option.displayName = `${option.displayName} (${this.$t(`metrics.${metric.minResolution}`)})`;
                }
                return option;
            })
            .sort((a: SelectBoxOption, b: SelectBoxOption) => this.categoryWeight(a.category) - this.categoryWeight(b.category)
                || a.displayName.localeCompare(b.displayName));
        return [emptyOption].concat(metricOptions);
    }

    private mapCategory(metric: MetricRepresentation): MetricCategory {
        if (metric.category === MetricCategory.LossOfProduction || metric.category === MetricCategory.LossOfRevenue) {
            return MetricCategory.Calculated;
        }
        return metric.category;
    }

    private categoryWeight(category?: string): number {
        switch (category) {
            case 'metrics.technical': return 0;
            case 'metrics.calculated': return 10;
            case 'metrics.accounting': return 20;
            case 'metrics.availability': return 30;
            case 'metrics.commercial': return 40;
            default: return 50;
        }
    }
}
