/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChartDataRange,
    ChartDataRangeFromJSON,
    ChartDataRangeFromJSONTyped,
    ChartDataRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChartDataRepresentation
 */
export interface ChartDataRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ChartDataRepresentation
     */
    metricKey: string;
    /**
     * x value of the request resource, corresponding x and y points are matched via index
     * @type {Array<number>}
     * @memberof ChartDataRepresentation
     */
    x: Array<number>;
    /**
     * y value of the request resource, corresponding x and y points are matched via index
     * @type {Array<number>}
     * @memberof ChartDataRepresentation
     */
    y: Array<number>;
    /**
     * optional, currently only used for turbit power deviations
     * @type {Array<ChartDataRange>}
     * @memberof ChartDataRepresentation
     */
    ranges?: Array<ChartDataRange>;
}

export function ChartDataRepresentationFromJSON(json: any): ChartDataRepresentation {
    return ChartDataRepresentationFromJSONTyped(json, false);
}

export function ChartDataRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartDataRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metricKey': json['metricKey'],
        'x': json['x'],
        'y': json['y'],
        'ranges': !exists(json, 'ranges') ? undefined : ((json['ranges'] as Array<any>).map(ChartDataRangeFromJSON)),
    };
}

export function ChartDataRepresentationToJSON(value?: ChartDataRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metricKey': value.metricKey,
        'x': value.x,
        'y': value.y,
        'ranges': value.ranges === undefined ? undefined : ((value.ranges as Array<any>).map(ChartDataRangeToJSON)),
    };
}


