import type { PortfolioTemplate, TemplatesRepository } from '@/modules/ctx-portfolios';
import { DashboardsApi, PortfolioTemplateRepresentation, PortfolioTemplatesApi } from '@/modules/shared/adapter';

export class TemplatesRepositoryImpl implements TemplatesRepository {

    private readonly dashboardsApi: DashboardsApi;
    private readonly portfolioTemplatesApi: PortfolioTemplatesApi;

    constructor(apis: {
        dashboards: DashboardsApi,
        portfolioTemplates: PortfolioTemplatesApi,
    }) {
        this.dashboardsApi = apis.dashboards;
        this.portfolioTemplatesApi = apis.portfolioTemplates;
    }

    public onChange(cb: () => Promise<void>): void {
        this.portfolioTemplatesApi.onChange(cb);
    }

    public async getTemplates(): Promise<PortfolioTemplate[]> {
        const templates = await this.portfolioTemplatesApi.getPortfolioTemplates();
        return Promise.all(templates.map((it) => this.mapPortfolioTemplateToDomain(it)));
    }

    public async createTemplate(name: string, description: string, portfolioKey: string, companyKey?: string): Promise<PortfolioTemplate> {
        const template = await this.portfolioTemplatesApi.createPortfolioTemplate({
            name: name,
            description: description,
            sourcekey: portfolioKey,
            companyKey: companyKey,
        });
        return this.mapPortfolioTemplateToDomain(template);
    }

    public async updateTemplate(template: PortfolioTemplate): Promise<PortfolioTemplate> {
        const updatedTemplate = await this.portfolioTemplatesApi.updatePortfolioTemplate(template.key, {
            name: template.name,
            description: template.description,
        });
        return this.mapPortfolioTemplateToDomain(updatedTemplate);
    }

    public async deleteTemplate(template: PortfolioTemplate): Promise<void> {
        return this.portfolioTemplatesApi.deletePortfolioTemplate(template.key);
    }

    private async mapPortfolioTemplateToDomain(representation: PortfolioTemplateRepresentation): Promise<PortfolioTemplate> {
        return {
            key: representation.key,
            name: representation.name,
            description: representation.description || '',
            createdAt: representation.created,
            createdByCompany: representation.companyKey || 'halvar',
            updatedAt: representation.updated,
            generators: representation.generatorKeys || [],
        };
    }
}
