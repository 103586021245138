import type { MetricsRepository } from '@/modules/ctx-admin-data-imports/adapter';
import type { ImportRequestBatch, Metric } from '@/modules/ctx-admin-data-imports/types';
import { MetricCategory, ReImportType, Resolution } from '@/modules/shared/types';

export class MetricService {

    private readonly metricsRepository: MetricsRepository;

    constructor(params: {
        metricsRepository: MetricsRepository;
    }) {
        this.metricsRepository = params.metricsRepository;
    }

    public async getMetricWhitelist(type: ReImportType): Promise<string[]> {
        if (![ReImportType.Min10, ReImportType.Daily, ReImportType.Monthly].includes(type)) {
            return [];
        }
        let metrics = await this.metricsRepository.getMetrics();
        metrics = metrics.filter((it) => it.categoryKey === MetricCategory.Technical);
        metrics = metrics.filter((it) => (it.resolution === Resolution.Min10 && type === ReImportType.Min10)
            || (it.resolution === Resolution.Daily && type === ReImportType.Daily)
            || (it.resolution === Resolution.Monthly && type === ReImportType.Monthly));
        // TODO only metrics with linked raw metric
        return metrics.map((it) => it.key);
    }

    public async resolveMetrics(request: ImportRequestBatch): Promise<Metric[]> {
        if (request.metricKeys.length > 0) {
            return this.resolveMetricsByKeys(request.metricKeys);
        }
        return this.resolveMetricsByReimportType(request.type);
    }

    public async resolveMetricsByReimportType(type: ReImportType): Promise<Metric[]> {
        const metrics = await this.metricsRepository.getMetrics();
        return metrics.filter((it) => (it.resolution === Resolution.Min10 && type === ReImportType.Min10)
            || (it.resolution === Resolution.Daily && type === ReImportType.Daily)
            || (it.resolution === Resolution.Monthly && type === ReImportType.Monthly)
            || (it.category === MetricCategory.Availability && type === ReImportType.Availability));
    }

    public async resolveMetricsByKeys(metricKeys: string[]): Promise<Metric[]> {
        return this.metricsRepository.getMetricsByKeys(metricKeys);
    }
}
