/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShareholderRepresentation
 */
export interface ShareholderRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ShareholderRepresentation
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof ShareholderRepresentation
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof ShareholderRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShareholderRepresentation
     */
    dataProviderKey: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareholderRepresentation
     */
    generatorKeys: Array<string>;
}

export function ShareholderRepresentationFromJSON(json: any): ShareholderRepresentation {
    return ShareholderRepresentationFromJSONTyped(json, false);
}

export function ShareholderRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareholderRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'number': json['number'],
        'name': json['name'],
        'dataProviderKey': json['dataProviderKey'],
        'generatorKeys': json['generatorKeys'],
    };
}

export function ShareholderRepresentationToJSON(value?: ShareholderRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'number': value.number,
        'name': value.name,
        'dataProviderKey': value.dataProviderKey,
        'generatorKeys': value.generatorKeys,
    };
}


