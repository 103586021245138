/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResourceFilterType {
    Text = 'text',
    Tags = 'tags',
    Range = 'range',
    Select = 'select'
}

export function ResourceFilterTypeFromJSON(json: any): ResourceFilterType {
    return ResourceFilterTypeFromJSONTyped(json, false);
}

export function ResourceFilterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceFilterType {
    return json as ResourceFilterType;
}

export function ResourceFilterTypeToJSON(value?: ResourceFilterType | null): any {
    return value as any;
}

