/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowercurveDataRepresentation
 */
export interface PowercurveDataRepresentation {
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    windspeed: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    weight: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    powerAct: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    gainAct: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    powerRef: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    gainRef: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    filteredWeight: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    filteredPowerAct: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    filteredGainAct: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRepresentation
     */
    filteredPowerRef: Array<number>;
}

export function PowercurveDataRepresentationFromJSON(json: any): PowercurveDataRepresentation {
    return PowercurveDataRepresentationFromJSONTyped(json, false);
}

export function PowercurveDataRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowercurveDataRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'windspeed': json['windspeed'],
        'weight': json['weight'],
        'powerAct': json['powerAct'],
        'gainAct': json['gainAct'],
        'powerRef': json['powerRef'],
        'gainRef': json['gainRef'],
        'filteredWeight': json['filteredWeight'],
        'filteredPowerAct': json['filteredPowerAct'],
        'filteredGainAct': json['filteredGainAct'],
        'filteredPowerRef': json['filteredPowerRef'],
    };
}

export function PowercurveDataRepresentationToJSON(value?: PowercurveDataRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'windspeed': value.windspeed,
        'weight': value.weight,
        'powerAct': value.powerAct,
        'gainAct': value.gainAct,
        'powerRef': value.powerRef,
        'gainRef': value.gainRef,
        'filteredWeight': value.filteredWeight,
        'filteredPowerAct': value.filteredPowerAct,
        'filteredGainAct': value.filteredGainAct,
        'filteredPowerRef': value.filteredPowerRef,
    };
}


