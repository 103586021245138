/* eslint-disable func-names */
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import type {
    CompanyRepresentation,
    LicenseFeatureRepresentation,
    LicenseRepresentation,
    UserRepresentation,
} from '@/clients/dashboardapi/v2';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { Role } from '@/modules/shared/types';
import { Port } from '@/modules/shared/Port';

interface UserState {
    user: null|UserRepresentation;
    userRoles: null|string[];
    userCompany: null|CompanyRepresentation;
    license: null|LicenseRepresentation;
    customer: null|string;
    knownCustomers: string[];
}

const moduleState: UserState = {
    user: null,
    userRoles: null,
    userCompany: null,
    license: null,
    customer: null,
    knownCustomers: [],
};

const moduleGetters: GetterTree<UserState, any> = {
    userLoaded(state: UserState) {
        return state.user !== null;
    },
    user(state: UserState): UserRepresentation {
        return state.user || {} as UserRepresentation;
    },
    fullName(state: UserState): string {
        return `${state.user?.firstname} ${state.user?.lastname}`;
    },
    roles(state: UserState): string[] {
        return state.userRoles || [];
    },
    permissions(state: UserState): string[] {
        return state.userRoles || [];
    },
    company(state: UserState): CompanyRepresentation {
        return state.userCompany || {} as CompanyRepresentation;
    },
    lastCustomer(state: UserState): string {
        return state.knownCustomers[0] || '';
    },
    customer(state: UserState): string {
        return state.customer || '';
    },
    licenseFeatures(state: UserState): LicenseFeatureRepresentation[] {
        return state.license?.features
            .filter((feature: LicenseFeatureRepresentation) => feature.expiresAtSeconds * 1000 > new Date().getTime()) || [];
    },

    // ----------------
    // getter functions
    // ----------------

    hasRole(state: UserState, getters): (role: Role) => boolean {
        return function (role: Role): boolean {
            return getters.roles.includes(role);
        };
    },
    hasPermission(state: UserState, getters): (role: Role) => boolean {
        return function (role: Role): boolean {
            return getters.permissions.includes(role);
        };
    },
    hasFeatureLicense(state: UserState, getters): (featureKey: string) => boolean {
        return function (featureKey: string): boolean {
            return state.customer === 'halvar'
                || getters.licenseFeatures.find((feature: LicenseFeatureRepresentation) => feature.key === featureKey) !== undefined;
        };
    },
    hasAllFeatureLicenses(state: UserState, getters): (featureKeys: string[]) => boolean {
        return function (featureKeys: string[]): boolean {
            return featureKeys.find((key) => !getters.hasFeatureLicense(key)) === undefined;
        };
    },
};

const moduleMutations: MutationTree<UserState> = {
    reset(state: UserState) {
        state.user = null;
        state.userRoles = null;
        state.userCompany = null;
        state.license = null;
        state.customer = null;
    },
    setUser(state: UserState, params?: { user: UserRepresentation|null }) {
        state.user = params?.user || null;
    },
    setUserRoles(state: UserState, params?: { roles: string[]|null }) {
        state.userRoles = params?.roles || null;
    },
    setUserCompany(state: UserState, params?: { company: CompanyRepresentation|null }) {
        state.userCompany = params?.company || null;
        // save known customers only after login success
        if (params?.company && params.company.key) {
            state.customer = params.company.key;
            // add current company as first index and then remove duplicates from the list
            state.knownCustomers = ArrayUtils.distinct([params.company.key].concat(state.knownCustomers));
        }
    },
    setCustomer(state: UserState, params?: { customer: string|null }) {
        // this may only be set when the user successfully logged in
        state.customer = params?.customer || null;
    },
    setLicense(state: UserState, params?: { license: LicenseRepresentation | null }) {
        // this may only be set when the user successfully logged in
        state.license = params?.license || null;
    },
};

const moduleActions: ActionTree<UserState, any> = {
    /**
     * Initialize module
     * @param context
     */
    async init(context): Promise<void> {
        context.commit('setUser', { user: await Port.auth.getUser() });
        context.commit('setUserRoles', { roles: await Port.auth.getRoles() });
        context.commit('setUserCompany', { company: await Port.auth.getCompany() });
        await Promise.allSettled([
            context.dispatch('loadLicense'),
        ]);
    },

    /**
     * Reset module state
     * @param context
     */
    async clear(context): Promise<void> {
        context.commit('reset');
    },

    async loadLicense(context): Promise<void> {
        const licenseKey = context.getters.company.license;
        const license = await Port.license.getLicense(licenseKey);
        context.commit('setLicense', { license: license });
    },
};

const module: Module<UserState, any> = {
    namespaced: true,
    state: moduleState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions,
};
export default module;
