import type { CompaniesRepository, Company } from '@/modules/ctx-documents';
import { CompaniesApi } from '@/modules/shared/adapter';

export class CompaniesRepositoryImpl implements CompaniesRepository {
    private readonly companiesApi: CompaniesApi;

    constructor(apis: {
        companies: CompaniesApi;
    }) {
        this.companiesApi = apis.companies;
    }

    public async getCompanies(): Promise<Company[]> {
        const companies = await this.companiesApi.getCompanies();
        return companies.map((it) => ({
            key: it.key,
            name: it.name,
            logo: it.logo,
            color: it.color,
        }));
    }

    public async getCompaniesWithAccessToGenerators(generatorKeys: string[]): Promise<Company[]> {
        const companies = await this.companiesApi.getCompaniesWithAccessToGenerators(generatorKeys);
        return companies.map((it) => ({
            key: it.key,
            name: it.name,
            logo: it.logo,
            color: it.color,
            licenseKey: it.license,
        }));
    }
}
