
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WidgetType } from '@/modules/shared/types';
import { Port } from '@/modules/shared/Port';

import PreviewChartMixed from './chart-mixed.svg';
import PreviewChartScatter from './chart-scatter.svg';
import PreviewChartBars from './chart-bars.svg';
import PreviewChartLine from './chart-line.svg';
import PreviewChartDonut from './chart-donut.svg';
import PreviewChartNumber from './chart-number.vue';
import PreviewChartMap from './chart-map.vue';
import PreviewChartHeatmap from './chart-heatmap.svg';
import PreviewChartTable from './chart-table.svg';
import PreviewChartForecast from './chart-forecast.svg';
import PreviewChartWindrose from './chart-windrose.vue';
import PreviewChartNote from './chart-note.svg';
import PreviewChartBarsStacked from './chart-bars-stacked.svg';
import PreviewChartTimeStacked from './chart-time-stacked.svg';

@Component({
    components: {
        PreviewChartTimeStacked,
        PreviewChartBarsStacked,
        PreviewChartMixed,
        PreviewChartScatter,
        PreviewChartBars,
        PreviewChartLine,
        PreviewChartDonut,
        PreviewChartNumber,
        PreviewChartMap,
        PreviewChartHeatmap,
        PreviewChartTable,
        PreviewChartForecast,
        PreviewChartWindrose,
        PreviewChartNote,
    },
})
export default class ChartPreview extends Vue {

    @Prop({ required: true })
    public readonly type!: WidgetType;

    @Prop({ required: true })
    public readonly metrics!: string[];

    private readonly WidgetType = WidgetType;

    private unit: string = '';

    public created(): void {
        this.fetchUnit();
    }

    private async fetchUnit(): Promise<void> {
        const metricKey = this.metrics[0];
        const metric = await Port.metrics.getMetricByKey(metricKey);
        this.unit = metric?.unit || '';
    }
}
