
import { Component, Vue, Watch } from 'vue-property-decorator';
import MenuUser from '@/components/partials/navigation/MenuUser.vue';
import MenuPortfolios from '@/components/partials/navigation/MenuPortfolios.vue';
import { DashboardRepresentation } from '@/clients/dashboardapi/v2';
import { ReportConfigDlg } from '@/modules/ctx-report-preview';
import MissingLicenseDlg from '@/modules/shared/components/dialogs/generic/MissingLicenseDlg.vue';
import { LicenseFeature } from '@/modules/shared/types';
import HalvarLogoText from '@/components/icons/logo-text.svg';
import { Port } from '@/modules/shared/Port';

@Component({
    components: { HalvarLogoText, MissingLicenseDlg, ReportConfigDlg, MenuPortfolios, MenuUser },
})
export default class NavigationBar extends Vue {

    private showMissingLicenseDlg: boolean = false;
    private showUserMenu: boolean = false;
    private showPortfoliosMenu: boolean = false;
    private showReportConfigDlg: boolean = false;
    private dashboardForReport: DashboardRepresentation|null = null;

    @Watch('$route')
    private onRouteChanged() {
        this.showUserMenu = false;
        this.showPortfoliosMenu = false;
    }

    private async createReport(dashboardKey: string): Promise<void> {
        this.dashboardForReport = await Port.dashboards.getDashboardByKey(dashboardKey) || null;
        this.showReportConfigDlg = true;
        this.showUserMenu = false;
        this.showPortfoliosMenu = false;
    }

    private onLicenseError(license: LicenseFeature) {
        this.showUserMenu = false;
        this.showMissingLicenseDlg = true;
    }
}
