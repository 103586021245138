/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
    /**
     * the unique username within the company
     * @type {string}
     * @memberof UserCreateRequest
     */
    username: string;
    /**
     * the password for the new user
     * @type {string}
     * @memberof UserCreateRequest
     */
    password?: string;
    /**
     * email of the user
     * @type {string}
     * @memberof UserCreateRequest
     */
    email: string;
    /**
     * the unique identifier for the users company
     * @type {string}
     * @memberof UserCreateRequest
     */
    companyKey: string;
    /**
     * optional users first name
     * @type {string}
     * @memberof UserCreateRequest
     */
    firstname?: string;
    /**
     * optional users last name
     * @type {string}
     * @memberof UserCreateRequest
     */
    lastname?: string;
    /**
     * optional avatar URI
     * @type {string}
     * @memberof UserCreateRequest
     */
    image?: string;
}

export function UserCreateRequestFromJSON(json: any): UserCreateRequest {
    return UserCreateRequestFromJSONTyped(json, false);
}

export function UserCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'email': json['email'],
        'companyKey': json['companyKey'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function UserCreateRequestToJSON(value?: UserCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'email': value.email,
        'companyKey': value.companyKey,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'image': value.image,
    };
}


