import {
    Configuration,
    PortfolioApi as PortfolioRestApi,
    TemplatesApi as TemplatesRestApi,
} from '@/clients/dashboardapi/v2';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { DataChangedEvent } from '@/modules/shared/types';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type {
    PortfoliosApi,
    PortfolioRepresentation,
    PortfolioCreateRequest,
    PortfolioUpdateRequest,
    PortfolioCreateFromTemplateRequest,
} from '@/modules/shared/adapter/PortfoliosApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedPortfoliosRestApi implements PortfoliosApi {

    private readonly portfoliosApi: PortfolioRestApi;
    private readonly templatesApi: TemplatesRestApi;
    private readonly portfoliosCache: IndexedDBRepository<string, PortfolioRepresentation>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.portfoliosApi = new PortfolioRestApi(restApiConfig);
        this.templatesApi = new TemplatesRestApi(restApiConfig);
        this.portfoliosCache = new IndexedDBRepository<string, PortfolioRepresentation>(indexedDb, 'portfolios');
    }

    public onChange(cb: (event: DataChangedEvent) => Promise<void>): void {
        this.portfoliosCache.addChangedListener((evt) => cb(evt));
    }

    public async getPortfolios(): Promise<PortfolioRepresentation[]> {
        const portfolios = await this.portfoliosCache.findAllIfAnyCached() || await this.fetchPortfolios();
        // A portfolio with company or user key is a company or user main portfolio used to define which generators
        // a user has access to. Therefore, we don't want to show this portfolio for the current user.
        return portfolios
            .filter((it) => it.companyKey === undefined && it.userKey === undefined)
            .sort((a, b) => a.order - b.order);
    }

    private async fetchPortfolios(): Promise<PortfolioRepresentation[]> {
        return AsyncDebouncer.debounce<PortfolioRepresentation[]>('CachedPortfoliosRestApi.fetchPortfolios', async () => {
            const portfolios = await this.portfoliosApi.getPortfolios();
            await this.portfoliosCache.saveAll(portfolios);
            return portfolios;
        });
    }

    public async getPortfolioByKey(key: string): Promise<PortfolioRepresentation|undefined> {
        // no portfolios cached, loading all
        if (await this.portfoliosCache.count() === 0) {
            await this.fetchPortfolios();
        }
        const cached = await this.portfoliosCache.findByKey(key);
        if (cached) {
            return cached;
        }
        const portfolio = await this.portfoliosApi.getPortfolioByKey(key);
        await this.portfoliosCache.save(portfolio);
        return portfolio;
    }

    public async createPortfolio(portfolioCreateSpec: PortfolioCreateRequest): Promise<PortfolioRepresentation> {
        const portfolio = await this.portfoliosApi.createPortfolio(portfolioCreateSpec);
        if (await this.portfoliosCache.count() > 0) {
            // only update cache when we have already requested all portfolios, as we otherwise
            // could not tell if the cache contains all portfolios available for the user
            await this.portfoliosCache.save(portfolio);
        }
        return portfolio;
    }

    public async createPortfolioFromTemplate(templateKey: string, portfolioCreateSpec: PortfolioCreateFromTemplateRequest): Promise<PortfolioRepresentation> {
        const portfolio = await this.templatesApi.createPortfolioFromTemplate(templateKey, portfolioCreateSpec);
        if (await this.portfoliosCache.count() > 0) {
            // only update cache when we have already requested all portfolios, as we otherwise
            // could not tell if the cache contains all portfolios available for the user
            await this.portfoliosCache.save(portfolio);
        }
        return portfolio;
    }

    public async updatePortfolio(key: string, portfolioUpdateSpec: PortfolioUpdateRequest): Promise<PortfolioRepresentation> {
        const portfolio = await this.portfoliosApi.updatePortfolio(key, portfolioUpdateSpec);
        if (await this.portfoliosCache.count() > 0) {
            // only update cache when we have already requested all portfolios, as we otherwise
            // could not tell if the cache contains all portfolios available for the user
            await this.portfoliosCache.save(portfolio);
        }
        return portfolio;
    }

    public async addGeneratorsToPortfolio(portfolioKey: string, generatorKeys: string[]): Promise<void> {
        await this.portfoliosApi.addGeneratorsToPortfolio(portfolioKey, generatorKeys);
        const portfolio = await this.portfoliosCache.findByKey(portfolioKey);
        if (portfolio) {
            portfolio.generatorKeys = (portfolio.generatorKeys || [])
                .concat(generatorKeys)
                .filter(ArrayUtils.removeDuplicates);
            await this.portfoliosCache.save(portfolio);
        }
    }

    public async removeGeneratorsFromPortfolio(portfolioKey: string, generatorKeys: string[]): Promise<void> {
        await this.portfoliosApi.removeGenerators(portfolioKey, generatorKeys);
        const portfolio = await this.portfoliosCache.findByKey(portfolioKey);
        if (portfolio) {
            portfolio.generatorKeys = (portfolio.generatorKeys || [])
                .filter((key) => !generatorKeys.includes(key));
            await this.portfoliosCache.save(portfolio);
        }
    }

    public async deletePortfolioByKey(key: string): Promise<void> {
        await this.portfoliosApi.deletePortfolioByKey(key);
        await this.portfoliosCache.removeByKey(key);
    }
}
