import { ReportRetryRequest, ReportsApi } from '@/modules/shared/adapter';
import { ReportsRepository } from '@/modules/ctx-documents/adapter/ReportsRepository';

export class ReportRepositoryImpl implements ReportsRepository {
    private readonly reportsApi: ReportsApi;

    constructor(apis: {
        reports: ReportsApi;
    }) {
        this.reportsApi = apis.reports;
    }

    public async retryReport(reportRetryRequest: ReportRetryRequest): Promise<void> {
        return await this.reportsApi.retryReport(reportRetryRequest);
    }
}
