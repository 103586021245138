import type { Company } from '@/modules/ctx-documents/types';
import type { CompaniesRepository, LicenseRepository } from '@/modules/ctx-documents/adapter';

export class CompanyService {

    private readonly companiesRepository: CompaniesRepository;
    private readonly licenseRepository: LicenseRepository;

    constructor(params: {
        companiesRepository: CompaniesRepository;
        licenseRepository: LicenseRepository;
    }) {
        this.companiesRepository = params.companiesRepository;
        this.licenseRepository = params.licenseRepository;
    }

    public async getCompanies(): Promise<Company[]> {
        return this.companiesRepository.getCompanies();
    }

    public async getCompaniesWithAccessToGenerators(generatorKeys: string[]): Promise<Company[]> {
        const companies = await this.companiesRepository.getCompaniesWithAccessToGenerators(generatorKeys);
        return companies.sort((a, b) => a.name.localeCompare(b.name));
    }
}
