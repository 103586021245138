import Vue from 'vue';
import type VueRouter from 'vue-router';
import { Module, ModuleOptions } from '@/modules/ctx-admin-metrics';
import { Port } from '@/modules/shared/Port';
import { MetricsRepositoryImpl } from './MetricsRepositoryImpl';
import { LocalizationRepositoryImpl } from './LocalizationRepositoryImpl';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        router: router,
        metricsRepository: new MetricsRepositoryImpl({ metrics: Port.metrics, license: Port.license }),
        localizationRepository: new LocalizationRepositoryImpl(),
    });
}
