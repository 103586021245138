import type { RouteConfig } from 'vue-router';
import { Role } from '@/modules/shared/types';

const route: RouteConfig = {
    path: '/:customer/data-imports',
    name: 'data-imports',
    component: () => import(/* webpackChunkName: "ctx-data-reimports" */ './PendingReimportsView.vue'),
    meta: {
        role: Role.HALVAR_ADMIN,
        license: [],
        authenticated: true,
        navbar: true,
        footer: true,
        settingsTabs: true,
        settingsTabsOrder: 100,
        settingsTabsGroup: 'halvar-admin',
        portfolioTabs: false,
        onboarding: true,
        notifications: true,
    },
};

export { route };
