import type { ImporterRepository, PendingImport, ImportRequest } from '@/modules/ctx-admin-data-imports';
import type { GeneratorsApi, ImportsApi, ParksApi } from '@/modules/shared/adapter';
import { ReImportType } from '@/clients/dashboardapi/v2';

export class ImporterRepositoryImpl implements ImporterRepository {

    private readonly importsApi: ImportsApi;
    private readonly parksApi: ParksApi;
    private readonly generatorApi: GeneratorsApi;

    constructor(apis: {
        imports: ImportsApi;
        generators: GeneratorsApi;
        parks: ParksApi;
    }) {
        this.importsApi = apis.imports;
        this.generatorApi = apis.generators;
        this.parksApi = apis.parks;
    }

    public onImportsChange(cb:() => void): void {
        this.importsApi.onImportsChange(() => {
            cb();
            return Promise.resolve();
        });
    }

    public async getPendingImports(): Promise<PendingImport[]> {
        const pendingImports = (await this.importsApi.getPendingDataReImports())
            .map(async (it) => {
                const generator = await this.generatorApi.getGeneratorByKey(it.generatorKey || '');
                const park = await this.parksApi.getParkByKey(generator?.parkKey || '');
                return {
                    created: new Date(it.created),
                    from: new Date(it.timeFrom),
                    to: new Date(it.timeTo),
                    types: it.requestedMetricTypes,
                    pendingMetricsCount: it.pendingMetricsCount,
                    generatorKey: it.generatorKey || '',
                    generatorName: generator?.name || '',
                    generatorParkKey: park?.key || '',
                    generatorParkName: park?.name || '',
                    cancelable: true, // TODO can every import request be canceled?
                    canceling: false,
                };
            });
        return Promise.all(pendingImports);
    }

    public async createImportRequest(importRequest: ImportRequest): Promise<void> {
        await this.importsApi.createDataReImport({
            type: importRequest.type,
            timeFrom: importRequest.timeFrom.getTime(),
            timeTo: importRequest.timeTo.getTime(),
            generatorKey: importRequest.generatorKey,
            metricKeys: importRequest.metricKeys,
        });
    }

    public async deleteDataReImports(type: ReImportType, generatorKeys: string[]): Promise<void> {
        await this.importsApi.deleteDataReImports({
            type: type,
            generatorKeys: generatorKeys,
        });
    }
}
