/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResourceFilterName {
    Zipcode = 'zipcode',
    Manufacturer = 'manufacturer',
    Type = 'type',
    InstalledPower = 'installed_power',
    GeneratorKeys = 'generator_keys'
}

export function ResourceFilterNameFromJSON(json: any): ResourceFilterName {
    return ResourceFilterNameFromJSONTyped(json, false);
}

export function ResourceFilterNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceFilterName {
    return json as ResourceFilterName;
}

export function ResourceFilterNameToJSON(value?: ResourceFilterName | null): any {
    return value as any;
}

