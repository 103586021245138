
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GeneratorChooserDlg } from '@/modules/shared/components/choosers';
import ConfirmDlg from '@/modules/shared/components/dialogs/generic/ConfirmDlg.vue';
import TextInputDlg from '@/modules/shared/components/dialogs/generic/TextInputDlg.vue';
import LoadingAnimation from '@/modules/shared/components/loading-animation/LoadingAnimation.vue';
import Utils from '@/assets/js/utils/Utils';
import NotificationService from '@/assets/js/services/NotificationService';
import GenericTable, { TableColumn } from '@/modules/shared/components/table/GenericTable.vue';
import LoadingAnimationBig from '@/modules/shared/components/loading-animation/LoadingAnimationBig.vue';
import { Port } from '@/modules/shared/Port';
import { Events } from '@/modules/shared';

interface GeneratorTableEntry {
    key: string;
    name: string;
    parkName: string;
    installationDate?: number;
    power?: number;
    hubHeight?: number;
    serialNumber?: string;
    zipCode?: string;
    type: string;
    model: string;
}

@Component({
    components: {
        LoadingAnimationBig,
        GenericTable,
        LoadingAnimation,
        TextInputDlg,
        GeneratorChooserDlg,
        ConfirmDlg,
    },
})
export default class PortfolioGenerators extends Vue {

    @Prop({ required: true })
    private readonly portfolioKey!: string;

    private searchText: string = '';
    private showGeneratorChooseDialog: boolean = false;
    private selectedGenerators: GeneratorTableEntry[] = [];
    private generators: GeneratorTableEntry[] = [];
    private loading: boolean = false;

    public created(): void {
        this.fetchGenerators();
        Events.instance.on('generators--changed', this.fetchGenerators);
        Events.instance.on('portfolios--changed', this.fetchGenerators);
    }

    private get tableColumns(): TableColumn[] {
        return [
            // { title: 'generators.type', field: 'type', class: 'w-16 hidden lg:table-cell' },
            { title: 'generators.model', field: 'name', class: 'w-full xs:w-3/4 sm:w-2/5 xl:w-1/5' },
            { title: 'generators.park', field: 'parkName', class: 'hidden w-2/5 sm:table-cell lg:w-1/5 ' },
            { title: 'generators.installation-date', field: 'installationDate', class: 'hidden w-1/5 lg:table-cell', render: (value) => this.$formatDate(value), placeholder: '?' },
            { title: 'generators.power', field: 'power', placeholder: '? kw', render: (value) => this.$formatPower(value), class: 'hidden w-1/4 xs:table-cell sm:w-1/5' },
            { title: 'generators.hub-height', field: 'hubHeight', placeholder: this.$t('unknown').toString(), render: (value: string) => `${value} m`, class: 'hidden w-1/5 xl:table-cell' },
            { title: 'generators.serial-number', field: 'serialNumber', placeholder: this.$t('unknown').toString(), class: 'hidden w-1/5 xl:table-cell' },
        ];
    }

    private get filteredGenerators(): GeneratorTableEntry[] {
        return this.sortedGenerators
            .filter((generator: GeneratorTableEntry) => Utils.anyValueMatchesSearchTerm(generator, this.searchText.trim().toLowerCase()));
    }

    private get sortedGenerators(): GeneratorTableEntry[] {
        return this.generators
            .sort((a, b) => (a.parkName || '').localeCompare(b.parkName || '')
                || (a.name || '').localeCompare(b.name || ''));
    }

    private updateSelected(selected: GeneratorTableEntry[]) {
        this.selectedGenerators = selected;
    }

    @Watch('portfolioKey')
    private async fetchGenerators(): Promise<void> {
        this.loading = true;
        const portfolio = await Port.portfolios.getPortfolioByKey(this.portfolioKey);
        const generators = await Port.generators.getGeneratorsByKeys(portfolio?.generatorKeys || []);
        this.generators = await Promise.all(generators.map(async (gen) => {
            const park = await Port.parks.getParkByKey(gen.parkKey!);
            return {
                key: gen.key,
                name: gen.name,
                parkName: park?.name || '',
                hubHeight: gen.hubHeight,
                installationDate: gen.installationDate,
                power: gen.power,
                serialNumber: gen.serialNumber,
                type: 'wind',
                zipCode: park?.zipCode,
                model: gen.model || '',
            };
        }));
        this.loading = false;
    }

    private async removeGenerators() {
        try {
            const keys: string[] = this.selectedGenerators.map((generator: GeneratorTableEntry) => generator.key);
            await Port.portfolios.removeGeneratorsFromPortfolio(this.portfolioKey, keys);
        } catch (e: any) {
            switch (e.status) {
                case 404: NotificationService.error('Portfolio nicht gefunden', e); break;
                case 401: NotificationService.error('Keine Berechtigung', e); break;
                case 500: NotificationService.error('Unerwarteter Serverfehler', e); break;
                default: NotificationService.error('Unbekannter Fehler', e); break;
            }
        }
    }

    private async addGenerators(generatorKeys: string[]) {
        this.showGeneratorChooseDialog = false;
        try {
            await Port.portfolios.addGeneratorsToPortfolio(this.portfolioKey, generatorKeys);
        } catch (e: any) {
            switch (e.status) {
                case 404: NotificationService.error('Portfolio nicht gefunden', e); break;
                case 401: NotificationService.error('Keine Berechtigung', e); break;
                case 500: NotificationService.error('Unerwarteter Serverfehler', e); break;
                default: NotificationService.error('Unbekannter Fehler', e); break;
            }
        }
    }
}
