/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportConfigCreateRequest,
    ReportConfigCreateRequestFromJSON,
    ReportConfigCreateRequestToJSON,
    ReportConfigRepresentation,
    ReportConfigRepresentationFromJSON,
    ReportConfigRepresentationToJSON,
    ReportConfigUpdateRequest,
    ReportConfigUpdateRequestFromJSON,
    ReportConfigUpdateRequestToJSON,
    ReportCreateRequest,
    ReportCreateRequestFromJSON,
    ReportCreateRequestToJSON,
    ReportRepresentation,
    ReportRepresentationFromJSON,
    ReportRepresentationToJSON,
    ReportRetryRequest,
    ReportRetryRequestFromJSON,
    ReportRetryRequestToJSON,
} from '../models';

export interface CreateReportConfigRequest {
    reportConfigCreateRequest?: ReportConfigCreateRequest;
}

export interface GetReportConfigRequest {
    reportConfigKey: string;
}

export interface GetReportConfigsRequest {
    dashboardKey?: string;
}

export interface RetryReportRequest {
    reportRetryRequest?: ReportRetryRequest;
}

export interface StartReportGenerationRequest {
    reportCreateRequest?: ReportCreateRequest;
}

export interface UpdateReportConfigRequest {
    reportConfigKey: string;
    reportConfigUpdateRequest?: ReportConfigUpdateRequest;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     * Create a new report config
     * Create a new report config
     */
    async createReportConfigRaw(requestParameters: CreateReportConfigRequest): Promise<runtime.ApiResponse<ReportConfigRepresentation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["reports:manage"]);
        }

        const response = await this.request({
            path: `/report-configs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportConfigCreateRequestToJSON(requestParameters.reportConfigCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportConfigRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new report config
     * Create a new report config
     */
    async createReportConfig(reportConfigCreateRequest?: ReportConfigCreateRequest): Promise<ReportConfigRepresentation> {
        const response = await this.createReportConfigRaw({ reportConfigCreateRequest: reportConfigCreateRequest });
        return await response.value();
    }

    /**
     * Get the report config with the specified key
     * Get the report config with the specified key
     */
    async getReportConfigRaw(requestParameters: GetReportConfigRequest): Promise<runtime.ApiResponse<ReportConfigRepresentation>> {
        if (requestParameters.reportConfigKey === null || requestParameters.reportConfigKey === undefined) {
            throw new runtime.RequiredError('reportConfigKey','Required parameter requestParameters.reportConfigKey was null or undefined when calling getReportConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["reports:manage"]);
        }

        const response = await this.request({
            path: `/report-configs/{reportConfigKey}`.replace(`{${"reportConfigKey"}}`, encodeURIComponent(String(requestParameters.reportConfigKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportConfigRepresentationFromJSON(jsonValue));
    }

    /**
     * Get the report config with the specified key
     * Get the report config with the specified key
     */
    async getReportConfig(reportConfigKey: string): Promise<ReportConfigRepresentation> {
        const response = await this.getReportConfigRaw({ reportConfigKey: reportConfigKey });
        return await response.value();
    }

    /**
     * Get all report configs matching the filter parameters
     * Get all report configs matching the filter parameters
     */
    async getReportConfigsRaw(requestParameters: GetReportConfigsRequest): Promise<runtime.ApiResponse<Array<ReportConfigRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.dashboardKey !== undefined) {
            queryParameters['dashboardKey'] = requestParameters.dashboardKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["reports:manage"]);
        }

        const response = await this.request({
            path: `/report-configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportConfigRepresentationFromJSON));
    }

    /**
     * Get all report configs matching the filter parameters
     * Get all report configs matching the filter parameters
     */
    async getReportConfigs(dashboardKey?: string): Promise<Array<ReportConfigRepresentation>> {
        const response = await this.getReportConfigsRaw({ dashboardKey: dashboardKey });
        return await response.value();
    }

    /**
     * Retry a report by document key
     * Retry a report
     */
    async retryReportRaw(requestParameters: RetryReportRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["reports:manage"]);
        }

        const response = await this.request({
            path: `/reports/retries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportRetryRequestToJSON(requestParameters.reportRetryRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Retry a report by document key
     * Retry a report
     */
    async retryReport(reportRetryRequest?: ReportRetryRequest): Promise<void> {
        await this.retryReportRaw({ reportRetryRequest: reportRetryRequest });
    }

    /**
     * Start generating the report with the spec
     * Start generating a report
     */
    async startReportGenerationRaw(requestParameters: StartReportGenerationRequest): Promise<runtime.ApiResponse<ReportRepresentation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["reports:manage"]);
        }

        const response = await this.request({
            path: `/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportCreateRequestToJSON(requestParameters.reportCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportRepresentationFromJSON(jsonValue));
    }

    /**
     * Start generating the report with the spec
     * Start generating a report
     */
    async startReportGeneration(reportCreateRequest?: ReportCreateRequest): Promise<ReportRepresentation> {
        const response = await this.startReportGenerationRaw({ reportCreateRequest: reportCreateRequest });
        return await response.value();
    }

    /**
     * Update the report config with the specified key
     * Update the report config with the specified key
     */
    async updateReportConfigRaw(requestParameters: UpdateReportConfigRequest): Promise<runtime.ApiResponse<ReportConfigRepresentation>> {
        if (requestParameters.reportConfigKey === null || requestParameters.reportConfigKey === undefined) {
            throw new runtime.RequiredError('reportConfigKey','Required parameter requestParameters.reportConfigKey was null or undefined when calling updateReportConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["reports:manage"]);
        }

        const response = await this.request({
            path: `/report-configs/{reportConfigKey}`.replace(`{${"reportConfigKey"}}`, encodeURIComponent(String(requestParameters.reportConfigKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportConfigUpdateRequestToJSON(requestParameters.reportConfigUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportConfigRepresentationFromJSON(jsonValue));
    }

    /**
     * Update the report config with the specified key
     * Update the report config with the specified key
     */
    async updateReportConfig(reportConfigKey: string, reportConfigUpdateRequest?: ReportConfigUpdateRequest): Promise<ReportConfigRepresentation> {
        const response = await this.updateReportConfigRaw({ reportConfigKey: reportConfigKey, reportConfigUpdateRequest: reportConfigUpdateRequest });
        return await response.value();
    }

}
