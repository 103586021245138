import type { Dashboard } from '@/modules/ctx-dashboard/types';
import type { DashboardRepository, LicenseRepository } from '@/modules/ctx-dashboard/adapter';
import { i18n } from '@/plugins/i18n';
import NotificationService from '@/assets/js/services/NotificationService';

export class DashboardService {

    private readonly dashboardRepository: DashboardRepository;
    private readonly licenseRepository: LicenseRepository;

    constructor(params: {
        dashboardRepository: DashboardRepository;
        licenseRepository: LicenseRepository;
    }) {
        this.dashboardRepository = params.dashboardRepository;
        this.licenseRepository = params.licenseRepository;
    }

    public onDashboardsChange(cb: () => Promise<void>): void {
        this.dashboardRepository.onDashboardsChange(cb);
    }

    public async getDashboard(dashboardKey: string): Promise<Dashboard> {
        return this.dashboardRepository.getDashboard(dashboardKey);
    }

    public async getDashboards(): Promise<Dashboard[]> {
        return this.dashboardRepository.getDashboards();
    }

    public async getDashboardsForPortfolio(portfolioKey: string): Promise<Dashboard[]> {
        const dashboards = await this.dashboardRepository.getDashboardsForPortfolio(portfolioKey);
        return dashboards.sort((a, b) => a.order - b.order);
    }

    public async renameDashboard(dashboard: Dashboard, newName: string): Promise<Dashboard> {
        try {
            dashboard.name = newName;
            return await this.dashboardRepository.updateDashboard(dashboard);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async createDashboard(portfolioKey: string, name: string, templateKey?: string): Promise<Dashboard> {
        try {
            if (templateKey) {
                return await this.dashboardRepository.createDashboard(portfolioKey, name, templateKey);
            }
            return await this.dashboardRepository.createDashboard(portfolioKey, name);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async deleteDashboard(dashboard: Dashboard): Promise<void> {
        try {
            await this.dashboardRepository.deleteDashboard(dashboard);
            NotificationService.success('Dashboard gelöscht', 'Das Dashboard wurde gelöscht');
        } catch (e: any) {
            NotificationService.serviceError(e);
        }
    }

    public reorderDashboards(dashboards: Dashboard[]): { reorderedDashboards: Dashboard[], updatedDashboards: Promise<Dashboard[]> } {
        const changedDashboards: Dashboard[] = [];
        dashboards.forEach((it: Dashboard, index: number) => {
            const oldOrder = it.order;
            it.order = index * 10;
            if (oldOrder !== it.order) {
                changedDashboards.push(it);
            }
        });
        // update async and return result earlier for quick ui feedback
        const dashboardUpdateRequests = changedDashboards.map((it) => this.dashboardRepository.updateDashboard(it));
        return { reorderedDashboards: dashboards, updatedDashboards: Promise.all(dashboardUpdateRequests) };
    }

    public async validateDashboardName(name: string, portfolioKey: string, dashboardKey?: string): Promise<string|null> {
        if (!name) {
            return i18n.t('validation.not-null').toString();
        }
        const dashboards = await this.getDashboardsForPortfolio(portfolioKey);
        if (dashboards.find((it) => it.key !== dashboardKey && it.name === name)) {
            return i18n.t('dialogs.dashboard.create.conflict', { name: name }).toString();
        }
        return null;
    }

    public async validateDashboard(dashboard: Partial<Dashboard>): Promise<{[key: string]: string[]}> {
        const errors: {[key: string]: string[]} = {};
        if (!dashboard.name || dashboard.name.trim().length === 0) {
            errors.name = [i18n.t('validation.not-null').toString()];
        }
        const dashboards = await this.getDashboardsForPortfolio(dashboard.portfolioKey || '');
        if (dashboards.find((it) => it.key !== dashboard.key && it.name === dashboard.name)) {
            errors.name = [i18n.t('dialogs.dashboard.create.conflict', { name: dashboard.name }).toString()];
        }
        return errors;
    }
}
