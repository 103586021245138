/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportRepresentation
 */
export interface ReportRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ReportRepresentation
     */
    reportConfigKey: string;
    /**
     * 
     * @type {string}
     * @memberof ReportRepresentation
     */
    reportId: string;
    /**
     * 
     * @type {number}
     * @memberof ReportRepresentation
     */
    queuedAt: number;
}

export function ReportRepresentationFromJSON(json: any): ReportRepresentation {
    return ReportRepresentationFromJSONTyped(json, false);
}

export function ReportRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportConfigKey': json['reportConfigKey'],
        'reportId': json['reportId'],
        'queuedAt': json['queuedAt'],
    };
}

export function ReportRepresentationToJSON(value?: ReportRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportConfigKey': value.reportConfigKey,
        'reportId': value.reportId,
        'queuedAt': value.queuedAt,
    };
}


