/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DashboardCreateRequest,
    DashboardCreateRequestFromJSON,
    DashboardCreateRequestToJSON,
    DashboardRepresentation,
    DashboardRepresentationFromJSON,
    DashboardRepresentationToJSON,
    DashboardUpdateRequest,
    DashboardUpdateRequestFromJSON,
    DashboardUpdateRequestToJSON,
    WidgetCopyRequest,
    WidgetCopyRequestFromJSON,
    WidgetCopyRequestToJSON,
    WidgetCreateRequest,
    WidgetCreateRequestFromJSON,
    WidgetCreateRequestToJSON,
    WidgetFavoriteRequest,
    WidgetFavoriteRequestFromJSON,
    WidgetFavoriteRequestToJSON,
    WidgetRepresentation,
    WidgetRepresentationFromJSON,
    WidgetRepresentationToJSON,
    WidgetUpdateRequest,
    WidgetUpdateRequestFromJSON,
    WidgetUpdateRequestToJSON,
} from '../models';

export interface AddWidgetToFavoritesRequest {
    widgetFavoriteRequest: WidgetFavoriteRequest;
}

export interface CopyWidgetRequest {
    dashboardKey: string;
    widgetKey: string;
    widgetCopyRequest: WidgetCopyRequest;
}

export interface CreateDashboardRequest {
    dashboardCreateRequest: DashboardCreateRequest;
}

export interface CreateWidgetRequest {
    key: string;
    widgetCreateRequest: WidgetCreateRequest;
}

export interface DeleteDashboardRequest {
    key: string;
}

export interface DeleteWidgetRequest {
    dashboardKey: string;
    widgetKey: string;
}

export interface GetDashboardByKeyRequest {
    key: string;
}

export interface GetDashboardsRequest {
    portfolioKey?: string;
}

export interface GetFavoriteWidgetsRequest {
    portfolioKey?: string;
}

export interface GetWidgetByIdRequest {
    dashboardKey: string;
    widgetKey: string;
}

export interface GetWidgetOrderRequest {
    type: string;
    key: string;
}

export interface GetWidgetsRequest {
    key: string;
}

export interface RemoveWidgetFromFavoritesRequest {
    key: string;
}

export interface SetWidgetOrderRequest {
    type: string;
    key: string;
    requestBody?: Array<string>;
}

export interface UpdateDashboardRequest {
    key: string;
    dashboardUpdateRequest: DashboardUpdateRequest;
}

export interface UpdateWidgetRequest {
    dashboardKey: string;
    widgetKey: string;
    widgetUpdateRequest: WidgetUpdateRequest;
}

/**
 * 
 */
export class DashboardsApi extends runtime.BaseAPI {

    /**
     * Create a new widget
     * Create a new widget
     */
    async addWidgetToFavoritesRaw(requestParameters: AddWidgetToFavoritesRequest): Promise<runtime.ApiResponse<WidgetRepresentation>> {
        if (requestParameters.widgetFavoriteRequest === null || requestParameters.widgetFavoriteRequest === undefined) {
            throw new runtime.RequiredError('widgetFavoriteRequest','Required parameter requestParameters.widgetFavoriteRequest was null or undefined when calling addWidgetToFavorites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/favorites/widgets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetFavoriteRequestToJSON(requestParameters.widgetFavoriteRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new widget
     * Create a new widget
     */
    async addWidgetToFavorites(widgetFavoriteRequest: WidgetFavoriteRequest): Promise<WidgetRepresentation> {
        const response = await this.addWidgetToFavoritesRaw({ widgetFavoriteRequest: widgetFavoriteRequest });
        return await response.value();
    }

    /**
     * Copy a widget to dashboard of portfolio
     * Copy a widget
     */
    async copyWidgetRaw(requestParameters: CopyWidgetRequest): Promise<runtime.ApiResponse<WidgetRepresentation>> {
        if (requestParameters.dashboardKey === null || requestParameters.dashboardKey === undefined) {
            throw new runtime.RequiredError('dashboardKey','Required parameter requestParameters.dashboardKey was null or undefined when calling copyWidget.');
        }

        if (requestParameters.widgetKey === null || requestParameters.widgetKey === undefined) {
            throw new runtime.RequiredError('widgetKey','Required parameter requestParameters.widgetKey was null or undefined when calling copyWidget.');
        }

        if (requestParameters.widgetCopyRequest === null || requestParameters.widgetCopyRequest === undefined) {
            throw new runtime.RequiredError('widgetCopyRequest','Required parameter requestParameters.widgetCopyRequest was null or undefined when calling copyWidget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/dashboards/{dashboardKey}/widgets/{widgetKey}/copies`.replace(`{${"dashboardKey"}}`, encodeURIComponent(String(requestParameters.dashboardKey))).replace(`{${"widgetKey"}}`, encodeURIComponent(String(requestParameters.widgetKey))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetCopyRequestToJSON(requestParameters.widgetCopyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetRepresentationFromJSON(jsonValue));
    }

    /**
     * Copy a widget to dashboard of portfolio
     * Copy a widget
     */
    async copyWidget(dashboardKey: string, widgetKey: string, widgetCopyRequest: WidgetCopyRequest): Promise<WidgetRepresentation> {
        const response = await this.copyWidgetRaw({ dashboardKey: dashboardKey, widgetKey: widgetKey, widgetCopyRequest: widgetCopyRequest });
        return await response.value();
    }

    /**
     * Create a new dashboard
     * Create dashboard
     */
    async createDashboardRaw(requestParameters: CreateDashboardRequest): Promise<runtime.ApiResponse<DashboardRepresentation>> {
        if (requestParameters.dashboardCreateRequest === null || requestParameters.dashboardCreateRequest === undefined) {
            throw new runtime.RequiredError('dashboardCreateRequest','Required parameter requestParameters.dashboardCreateRequest was null or undefined when calling createDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/dashboards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardCreateRequestToJSON(requestParameters.dashboardCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new dashboard
     * Create dashboard
     */
    async createDashboard(dashboardCreateRequest: DashboardCreateRequest): Promise<DashboardRepresentation> {
        const response = await this.createDashboardRaw({ dashboardCreateRequest: dashboardCreateRequest });
        return await response.value();
    }

    /**
     * Create a new widget for a dashboard
     * Create widget
     */
    async createWidgetRaw(requestParameters: CreateWidgetRequest): Promise<runtime.ApiResponse<WidgetRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling createWidget.');
        }

        if (requestParameters.widgetCreateRequest === null || requestParameters.widgetCreateRequest === undefined) {
            throw new runtime.RequiredError('widgetCreateRequest','Required parameter requestParameters.widgetCreateRequest was null or undefined when calling createWidget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/dashboards/{key}/widgets`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetCreateRequestToJSON(requestParameters.widgetCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetRepresentationFromJSON(jsonValue));
    }

    /**
     * Create a new widget for a dashboard
     * Create widget
     */
    async createWidget(key: string, widgetCreateRequest: WidgetCreateRequest): Promise<WidgetRepresentation> {
        const response = await this.createWidgetRaw({ key: key, widgetCreateRequest: widgetCreateRequest });
        return await response.value();
    }

    /**
     * Delete a dashboard
     * Delete a dashboard
     */
    async deleteDashboardRaw(requestParameters: DeleteDashboardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/dashboards/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a dashboard
     * Delete a dashboard
     */
    async deleteDashboard(key: string): Promise<void> {
        await this.deleteDashboardRaw({ key: key });
    }

    /**
     * Delete a widget by key
     * Delete widget
     */
    async deleteWidgetRaw(requestParameters: DeleteWidgetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dashboardKey === null || requestParameters.dashboardKey === undefined) {
            throw new runtime.RequiredError('dashboardKey','Required parameter requestParameters.dashboardKey was null or undefined when calling deleteWidget.');
        }

        if (requestParameters.widgetKey === null || requestParameters.widgetKey === undefined) {
            throw new runtime.RequiredError('widgetKey','Required parameter requestParameters.widgetKey was null or undefined when calling deleteWidget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/dashboards/{dashboardKey}/widgets/{widgetKey}`.replace(`{${"dashboardKey"}}`, encodeURIComponent(String(requestParameters.dashboardKey))).replace(`{${"widgetKey"}}`, encodeURIComponent(String(requestParameters.widgetKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a widget by key
     * Delete widget
     */
    async deleteWidget(dashboardKey: string, widgetKey: string): Promise<void> {
        await this.deleteWidgetRaw({ dashboardKey: dashboardKey, widgetKey: widgetKey });
    }

    /**
     * Return a single dashboard by key
     * Return a single dashboard
     */
    async getDashboardByKeyRaw(requestParameters: GetDashboardByKeyRequest): Promise<runtime.ApiResponse<DashboardRepresentation>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getDashboardByKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/dashboards/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardRepresentationFromJSON(jsonValue));
    }

    /**
     * Return a single dashboard by key
     * Return a single dashboard
     */
    async getDashboardByKey(key: string): Promise<DashboardRepresentation> {
        const response = await this.getDashboardByKeyRaw({ key: key });
        return await response.value();
    }

    /**
     * Return all dashboards for the current users.
     * Return all dashboards
     */
    async getDashboardsRaw(requestParameters: GetDashboardsRequest): Promise<runtime.ApiResponse<Array<DashboardRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioKey !== undefined) {
            queryParameters['portfolioKey'] = requestParameters.portfolioKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DashboardRepresentationFromJSON));
    }

    /**
     * Return all dashboards for the current users.
     * Return all dashboards
     */
    async getDashboards(portfolioKey?: string): Promise<Array<DashboardRepresentation>> {
        const response = await this.getDashboardsRaw({ portfolioKey: portfolioKey });
        return await response.value();
    }

    /**
     * Get widgets marked as favorites
     * Get widgets marked as favorites
     */
    async getFavoriteWidgetsRaw(requestParameters: GetFavoriteWidgetsRequest): Promise<runtime.ApiResponse<Array<WidgetRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioKey !== undefined) {
            queryParameters['portfolioKey'] = requestParameters.portfolioKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/favorites/widgets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WidgetRepresentationFromJSON));
    }

    /**
     * Get widgets marked as favorites
     * Get widgets marked as favorites
     */
    async getFavoriteWidgets(portfolioKey?: string): Promise<Array<WidgetRepresentation>> {
        const response = await this.getFavoriteWidgetsRaw({ portfolioKey: portfolioKey });
        return await response.value();
    }

    /**
     * Get a single widget by key
     * Get widget
     */
    async getWidgetByIdRaw(requestParameters: GetWidgetByIdRequest): Promise<runtime.ApiResponse<WidgetRepresentation>> {
        if (requestParameters.dashboardKey === null || requestParameters.dashboardKey === undefined) {
            throw new runtime.RequiredError('dashboardKey','Required parameter requestParameters.dashboardKey was null or undefined when calling getWidgetById.');
        }

        if (requestParameters.widgetKey === null || requestParameters.widgetKey === undefined) {
            throw new runtime.RequiredError('widgetKey','Required parameter requestParameters.widgetKey was null or undefined when calling getWidgetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/dashboards/{dashboardKey}/widgets/{widgetKey}`.replace(`{${"dashboardKey"}}`, encodeURIComponent(String(requestParameters.dashboardKey))).replace(`{${"widgetKey"}}`, encodeURIComponent(String(requestParameters.widgetKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetRepresentationFromJSON(jsonValue));
    }

    /**
     * Get a single widget by key
     * Get widget
     */
    async getWidgetById(dashboardKey: string, widgetKey: string): Promise<WidgetRepresentation> {
        const response = await this.getWidgetByIdRaw({ dashboardKey: dashboardKey, widgetKey: widgetKey });
        return await response.value();
    }

    /**
     * Get the widget order for a board
     * Get the widget order for a board
     */
    async getWidgetOrderRaw(requestParameters: GetWidgetOrderRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getWidgetOrder.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getWidgetOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/widgetorder/{type}/{key}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get the widget order for a board
     * Get the widget order for a board
     */
    async getWidgetOrder(type: string, key: string): Promise<Array<string>> {
        const response = await this.getWidgetOrderRaw({ type: type, key: key });
        return await response.value();
    }

    /**
     * Get widgets from a dashboard by dashboard key
     * Get widgets
     */
    async getWidgetsRaw(requestParameters: GetWidgetsRequest): Promise<runtime.ApiResponse<Array<WidgetRepresentation>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getWidgets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/dashboards/{key}/widgets`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WidgetRepresentationFromJSON));
    }

    /**
     * Get widgets from a dashboard by dashboard key
     * Get widgets
     */
    async getWidgets(key: string): Promise<Array<WidgetRepresentation>> {
        const response = await this.getWidgetsRaw({ key: key });
        return await response.value();
    }

    /**
     * Remove a widget by key from favorites
     * Remove a widget
     */
    async removeWidgetFromFavoritesRaw(requestParameters: RemoveWidgetFromFavoritesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling removeWidgetFromFavorites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/favorites/widgets/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a widget by key from favorites
     * Remove a widget
     */
    async removeWidgetFromFavorites(key: string): Promise<void> {
        await this.removeWidgetFromFavoritesRaw({ key: key });
    }

    /**
     * Set the widget order for a board
     * Set the widget order for a board
     */
    async setWidgetOrderRaw(requestParameters: SetWidgetOrderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling setWidgetOrder.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling setWidgetOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/widgetorder/{type}/{key}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the widget order for a board
     * Set the widget order for a board
     */
    async setWidgetOrder(type: string, key: string, requestBody?: Array<string>): Promise<void> {
        await this.setWidgetOrderRaw({ type: type, key: key, requestBody: requestBody });
    }

    /**
     * Update a dashboard
     * Update a dashboard
     */
    async updateDashboardRaw(requestParameters: UpdateDashboardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDashboard.');
        }

        if (requestParameters.dashboardUpdateRequest === null || requestParameters.dashboardUpdateRequest === undefined) {
            throw new runtime.RequiredError('dashboardUpdateRequest','Required parameter requestParameters.dashboardUpdateRequest was null or undefined when calling updateDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/dashboards/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardUpdateRequestToJSON(requestParameters.dashboardUpdateRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a dashboard
     * Update a dashboard
     */
    async updateDashboard(key: string, dashboardUpdateRequest: DashboardUpdateRequest): Promise<void> {
        await this.updateDashboardRaw({ key: key, dashboardUpdateRequest: dashboardUpdateRequest });
    }

    /**
     * Update a widget of a dashboard
     * Update widget
     */
    async updateWidgetRaw(requestParameters: UpdateWidgetRequest): Promise<runtime.ApiResponse<WidgetRepresentation>> {
        if (requestParameters.dashboardKey === null || requestParameters.dashboardKey === undefined) {
            throw new runtime.RequiredError('dashboardKey','Required parameter requestParameters.dashboardKey was null or undefined when calling updateWidget.');
        }

        if (requestParameters.widgetKey === null || requestParameters.widgetKey === undefined) {
            throw new runtime.RequiredError('widgetKey','Required parameter requestParameters.widgetKey was null or undefined when calling updateWidget.');
        }

        if (requestParameters.widgetUpdateRequest === null || requestParameters.widgetUpdateRequest === undefined) {
            throw new runtime.RequiredError('widgetUpdateRequest','Required parameter requestParameters.widgetUpdateRequest was null or undefined when calling updateWidget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:write"]);
        }

        const response = await this.request({
            path: `/dashboards/{dashboardKey}/widgets/{widgetKey}`.replace(`{${"dashboardKey"}}`, encodeURIComponent(String(requestParameters.dashboardKey))).replace(`{${"widgetKey"}}`, encodeURIComponent(String(requestParameters.widgetKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetUpdateRequestToJSON(requestParameters.widgetUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetRepresentationFromJSON(jsonValue));
    }

    /**
     * Update a widget of a dashboard
     * Update widget
     */
    async updateWidget(dashboardKey: string, widgetKey: string, widgetUpdateRequest: WidgetUpdateRequest): Promise<WidgetRepresentation> {
        const response = await this.updateWidgetRaw({ dashboardKey: dashboardKey, widgetKey: widgetKey, widgetUpdateRequest: widgetUpdateRequest });
        return await response.value();
    }

}
