import type { AuthApi, LicenseApi } from '@/modules/shared/adapter';
import { LicenseFeature, Role, licenseFeatureFromString } from '@/modules/shared/types';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';

export class DefaultUserRepository {
    private readonly licenseApi: LicenseApi;
    private readonly authApi: AuthApi;

    constructor(apis: {
        license: LicenseApi,
        auth: AuthApi,
    }) {
        this.licenseApi = apis.license;
        this.authApi = apis.auth;
    }

    public async getUserKey(): Promise<string> {
        const user = await this.authApi.getUser();
        return user.key;
    }

    public async getUserCompanyKey(): Promise<string> {
        const user = await this.authApi.getUser();
        return user.companyKey;
    }

    public async getUserHasLicenseFeature(...licenseFeatures: LicenseFeature[]): Promise<boolean> {
        const features = await this.getUserLicenseFeatures();
        return licenseFeatures.find((it) => !features.includes(it)) === undefined;
    }

    public async getUserLicenseFeatures(): Promise<LicenseFeature[]> {
        const userCompany = await this.authApi.getCompany();
        if (userCompany.key === 'halvar') {
            return Object.values(LicenseFeature);
        }
        if (!userCompany.license) {
            return [];
        }
        const license = await this.licenseApi.getLicense(userCompany.license);
        return license.features
            .filter((feature) => feature.expiresAtSeconds * 1000 > Date.now())
            .map((it) => licenseFeatureFromString(it.key))
            .filter(ArrayUtils.filterUndefined);
    }

    public async getUserRole(): Promise<Role> {
        const roles = await this.authApi.getRoles();
        if (roles.includes(Role.HALVAR_ADMIN)) {
            return Role.HALVAR_ADMIN;
        }
        if (roles.includes(Role.COMPANY_ADMIN)) {
            return Role.COMPANY_ADMIN;
        }
        return Role.USER;
    }
}
