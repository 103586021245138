import type { User } from '@/modules/ctx-users/types';
import type { UserRepository } from '@/modules/ctx-users/adapter';
import NotificationService from '@/assets/js/services/NotificationService';
import { i18n } from '@/plugins/i18n';
import Validator, { Rules } from '@/assets/js/utils/Validator';

export class UserService {

    private readonly userRepository: UserRepository;

    constructor(params: { userRepository: UserRepository }) {
        this.userRepository = params.userRepository;
    }

    public async getUser(companyKey: string, userKey: string): Promise<User|undefined> {
        return this.userRepository.getUser(companyKey, userKey);
    }

    public async getUsersForCompany(companyKey: string, onlyAdmins: boolean = false): Promise<User[]> {
        const signedInUser = await this.userRepository.getSignedInUser();
        const users = await this.userRepository.getUsers(companyKey);
        return users
            .filter((u) => u.key !== signedInUser.key)
            .filter((u) => !onlyAdmins || u.role === 'admin');
    }

    public async createUser(user: User): Promise<User> {
        try {
            return this.userRepository.createUser(user);
        } catch (e: any) {
            if (e.status === 400) {
                NotificationService.error(
                    i18n.t('error.username-already-exists.title').toString(),
                    i18n.t('error.username-already-exists.details').toString(),
                );
            } else {
                NotificationService.serviceError(e);
            }
            throw e;
        }
    }

    public async updateUser(user: User): Promise<User> {
        try {
            return await this.userRepository.updateUser(user);
        } catch (e: any) {
            if (e.status === 400) {
                NotificationService.error(
                    i18n.t('error.username-already-exists.title').toString(),
                    i18n.t('error.username-already-exists.details').toString(),
                );
            } else {
                NotificationService.serviceError(e);
            }
            throw e;
        }
    }

    public generateRandomPassword(): string {
        const length = 16;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';
        for (let i = 0; i < length; i++) {
            password += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return password;
    }

    public async promoteToAdmin(user: User) {
        await this.userRepository.promoteToAdmin(user);
    }

    public async deleteUser(user: User): Promise<void> {
        await this.userRepository.deleteUser(user.key);
    }

    public async deleteUsers(users: User[]): Promise<void> {
        try {
            await Promise.all(users.map((user) => this.deleteUser(user)));
        } catch (e: any) {
            NotificationService.serviceError(e);
        }
    }

    public async validateUser(userEdit: User, originalUser: User|undefined): Promise<{ [key: string]: string[] }> {
        const allUsers = await this.userRepository.getUsers(userEdit.companyKey);
        const validationResult = Validator
            .addRule('username', [Rules.NotNullOrEmpty, (value) => this.usernameDoesNotExist(value, originalUser, allUsers)])
            .addRule('firstname', [Rules.NotNullOrEmpty])
            .addRule('lastname', [Rules.NotNullOrEmpty])
            .addRule('email', [Rules.NotNullOrEmpty, Rules.ValidEmail, (value) => this.emailDoesNotExist(value, originalUser, allUsers)])
            .addRule('password', [(value) => this.passwordValid(value, originalUser)])
            // dont validate password here!
            .validate(userEdit);
        return validationResult;
    }

    private usernameDoesNotExist(value: string, originalUser: User|undefined, allUsers: User[]): boolean|string {
        if (originalUser && originalUser.key && value === originalUser.username) {
            // don't validate when we are editing an existing user
            return true;
        }
        if (allUsers.find((user) => user.username.toLowerCase().trim() === value.toLowerCase().trim())) {
            return i18n.t('validation.username-exists').toString();
        }
        return true;
    }

    private emailDoesNotExist(value: string, originalUser: User|undefined, allUsers: User[]): boolean|string {
        if (originalUser && originalUser.key && value === originalUser.email) {
            // don't validate when we are editing an existing user
            return true;
        }
        console.log(allUsers.map((usr) => usr.email));
        if (allUsers.find((user) => (user.email || '').toLowerCase().trim() === value.toLowerCase().trim())) {
            return i18n.t('validation.email-exists').toString();
        }
        return true;
    }

    private passwordValid(value: string|undefined, originalUser: User|undefined): boolean|string {
        if (originalUser) {
            // don't validate when we are editing an existing user
            return true;
        }
        if (!value || value.length < 5) {
            return i18n.t('validation.password-min-length', { count: 5 }).toString();
        }
        return true;
    }
}
