import type { Metric, MetricsRepository } from '@/modules/ctx-admin-data-imports';
import { MetricsApi } from '@/modules/shared/adapter';
import { i18n } from '@/plugins/i18n';

export class MetricsRepositoryImpl implements MetricsRepository {

    private readonly metricsApi: MetricsApi;

    constructor(apis: {
        metricsApi: MetricsApi;
    }) {
        this.metricsApi = apis.metricsApi;
    }

    public async getMetrics(): Promise<Metric[]> {
        const metrics = await this.metricsApi.getMetrics();
        return metrics.map((it) => ({
            key: it.key,
            name: i18n.t(it.name).toString(),
            categoryKey: it.category,
            category: i18n.t(`metrics.${it.category}`).toString(),
            resolution: it.minResolution,
        }));
    }

    public async getMetricsByKeys(keys: string[]): Promise<Metric[]> {
        const metrics = await this.metricsApi.getMetrics();
        return metrics
            .filter((it) => keys.includes(it.key))
            .map((it) => ({
                key: it.key,
                name: i18n.t(it.name).toString(),
                categoryKey: it.category,
                category: i18n.t(`metrics.${it.category}`).toString(),
                resolution: it.minResolution,
            }));
    }
}
