import _Vue from 'vue';
import { i18n } from './plugin';
import { Adapter, I18nChangedEventType } from './Adapter';
import type { I18nChangedEvent } from './Adapter';

const adapter = new Adapter();

const MessagesPlugin = {
    install(vue: typeof _Vue) {
        const locales = (process.env.VUE_APP_I18N_LOCALES || 'de').split(',');
        vue.prototype.$messages = {
            changeLanguage: (lang: string) => adapter.changeLanguage(lang),
            loadLanguage: (lang: string) => adapter.loadMessages(lang),
            locales: locales,
        };
    },
};
_Vue.use(MessagesPlugin);

// add type definitions for typescript compiler
declare module 'vue/types/vue' {
    interface Vue {
        $messages: {
            changeLanguage: (lang: string) => Promise<void>;
            loadLanguage: (lang: string) => Promise<void>;
            locales: string[];
        };
    }
}

export { i18n };
export { adapter as I18nAdapter, I18nChangedEventType, I18nChangedEvent };
