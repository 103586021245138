import type SelectBoxOption from '@/assets/js/models/SelectBoxOption';

export interface TabTableOptions {
    defaultGroupingType: string;
    dataGroupingTypes: string[];
    dataGroupingInfo: string;
    dataSortingOptions: SelectBoxOption[];
    defaultSorting: string;
    defaultSortingDirection: 'asc'|'desc';
    enableFormatting: boolean;
    showTableViewHint: boolean;
}

const defaultTabOptions: TabTableOptions = {
    defaultGroupingType: '',
    dataGroupingTypes: [],
    dataGroupingInfo: '',
    dataSortingOptions: [],
    defaultSorting: '',
    defaultSortingDirection: 'asc',
    enableFormatting: false,
    showTableViewHint: false,
};

export { defaultTabOptions };
