
import { Vue, Component, Prop } from 'vue-property-decorator';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';

@Component({
    components: { LoadingAnimationSmall },
})
export default class WidgetLoading extends Vue {

    @Prop({ default: null })
    public readonly title!: string|null;
}
