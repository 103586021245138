import { ResourceType } from '@/modules/shared/types';
import type { TabDataOptions, TabGeneralOptions, WidgetWizardStep } from '@/components/widget-wizard';
import { WidgetWizardTab } from '@/components/widget-wizard';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import { TabTableOptions } from '@/components/widget-wizard/components/tabs/table/TabTableOptions';

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.Time,
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: {
        maxDataSourcesTotal: 1,
        forceSingleResourceList: true,
        resourceTypes: [
            ResourceType.Portfolio,
            ResourceType.Park,
            ResourceType.Generator,
            ResourceType.Shareholder,
            ResourceType.Generatorgroup,
        ],
    },
};

const table: WidgetWizardStep<TabTableOptions> = {
    name: 'widget-wizard.tab.table',
    tab: WidgetWizardTab.Table,
    params: (widget) => {
        let sortingOptions: SelectBoxOption[] = [];
        if (widget.config.operatorForecastConfig?.monetary === true) {
            sortingOptions = [
                { value: '0', displayName: 'time' },
                { value: '1', displayName: 'widgets.operator-forecast.monetary.budget' },
                { value: '2', displayName: 'widgets.operator-forecast.monetary.forecast' },
                { value: '3', displayName: 'widgets.operator-forecast.monetary.isGross' },
                { value: '4', displayName: 'widgets.operator-forecast.monetary.isNet' },
                { value: '5', displayName: 'widgets.operator-forecast.monetary.einsman' },
                { value: '6', displayName: 'widgets.operator-forecast.monetary.isPlusFc' },
                { value: '7', displayName: 'widgets.operator-forecast.monetary.isVsBud' },
                { value: '8', displayName: 'widgets.operator-forecast.monetary.isVsFc' },
                { value: '9', displayName: 'widgets.operator-forecast.monetary.commission-plan' },
                { value: '10', displayName: 'widgets.operator-forecast.monetary.commission-is' },
            ];
        } else {
            sortingOptions = [
                { value: '0', displayName: 'time' },
                { value: '1', displayName: 'widgets.operator-forecast.technical.budget' },
                { value: '2', displayName: 'widgets.operator-forecast.technical.forecast' },
                { value: '3', displayName: 'widgets.operator-forecast.technical.isGross' },
                { value: '4', displayName: 'widgets.operator-forecast.technical.isNet' },
                { value: '5', displayName: 'widgets.operator-forecast.technical.einsman' },
                { value: '6', displayName: 'widgets.operator-forecast.technical.isPlusFc' },
                { value: '7', displayName: 'widgets.operator-forecast.technical.isVsBud' },
                { value: '8', displayName: 'widgets.operator-forecast.technical.isVsFc' },
                { value: '9', displayName: 'widgets.operator-forecast.technical.loss-rev-service' },
                { value: '10', displayName: 'widgets.operator-forecast.technical.loss-rev-misc' },
            ];
        }
        return {
            defaultSorting: '0',
            dataSortingOptions: sortingOptions,
            showTableViewHint: true,
        };
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

const operatorForecast: WidgetWizardStep = {
    name: 'widget-wizard.tab.operator-forecast',
    tab: WidgetWizardTab.OperatorForecast,
};

export const Steps: WidgetWizardStep[] = [
    operatorForecast,
    time,
    data,
    table,
    general,
];
