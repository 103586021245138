
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import { Port } from '@/modules/ctx-dashboard/Port';
import { WidgetConfig, WidgetTemplate } from '@/modules/ctx-dashboard';
import { Aggregation, ResourceType } from '@/modules/shared/types';
import InputText from '@/modules/shared/components/form/InputText.vue';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import InputMultiSelect from '@/modules/shared/components/form/InputMultiSelect.vue';
import InputSelect from '@/modules/shared/components/form/InputSelect.vue';
import InputCheckbox from '@/modules/shared/components/form/InputCheckbox.vue';
import Tabs from '@/components/basics/Tabs.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import NoteBox from '@/components/basics/NoteBox.vue';
import type { FlattenedResource } from '@/components/widgets/types/FlattenedResource';

enum Tab {
    Details = 'ctx-dashboard.create-widget-template.tab.details',
    DataSources = 'ctx-dashboard.create-widget-template.tab.data-sources',
    Share = 'ctx-dashboard.create-widget-template.tab.sharing',
}

@Component({
    components: {
        NoteBox,
        LoadingAnimationSmall,
        Tabs,
        InputCheckbox,
        InputSelect,
        InputMultiSelect,
        InputText,
        BaseDialog,
    },
})
export default class CreateWidgetTemplateDlg extends Vue {

    @Prop({ required: true })
    public readonly widget!: WidgetConfig;

    private readonly locales: string[] = this.$messages.locales;
    private readonly Tab = Tab;
    private readonly Aggregation = Aggregation;
    private readonly ResourceType = ResourceType;
    private activeTab: Tab = Tab.Details;

    private canCreateGlobalTemplates: boolean = false;
    private errors: {[key: string]: string[]} = {};
    private valid: boolean = false;
    private saving: boolean = false;
    private categoryOptions: SelectBoxOption[] = [];
    private licenseFeatureOptions: SelectBoxOption[] = [];
    private readonly datasourceOptions: SelectBoxOption[] = [
        { value: undefined, displayName: this.$t('none').toString() },
        { value: ResourceType.Portfolio, displayName: this.$tc('portfolio', 1).toString() },
        // { value: ResourceType.Park, displayName: this.$tc('park', 2).toString() },
        // { value: ResourceType.Generator, displayName: this.$tc('generator', 2).toString() },
    ];
    private readonly aggregationOptions: SelectBoxOption<Aggregation>[] = [
        { value: Aggregation.Sum, displayName: 'aggregation.sum' },
        { value: Aggregation.Avg, displayName: 'aggregation.avg' },
        { value: Aggregation.Min, displayName: 'aggregation.min' },
        { value: Aggregation.Max, displayName: 'aggregation.max' },
    ];
    private widgetTemplate: WidgetTemplate|null = null;
    private croppedResources: FlattenedResource[] = [];

    public async created(): Promise<void> {
        this.widgetTemplate = await Port.widgets.createDefaultTemplateFromWidget(this.widget);
        this.croppedResources = Port.widgets.getResourcesCroppedOnTemplateCreation(this.widget);
        Port.widgets.canCreateGlobalTemplates()
            .then((canCreateGlobalTemplates) => {
                this.canCreateGlobalTemplates = canCreateGlobalTemplates;
                if (canCreateGlobalTemplates) {
                    this.fetchCategories();
                    this.fetchLicenseFeatures();
                }
            });
    }

    private get tabs(): Tab[] {
        const tabs = [Tab.Details];
        // if (this.widgetTemplate?.defaultDataSources && this.widgetTemplate.defaultDataSources.length > 0) {
        //     tabs.push(Tab.DataSources);
        // }
        if (this.canCreateGlobalTemplates) {
            tabs.push(Tab.Share);
        }
        return tabs;
    }

    private async fetchCategories(): Promise<void> {
        const categories = await Port.widgets.getWidgetTemplateCategoriesForNewTemplates();
        this.categoryOptions = categories
            .filter((cat) => cat !== 'custom')
            .map((cat) => ({
                displayName: this.$t(`widget-wizard.preset.category.${cat}`).toString(),
                value: cat,
            }));
        if (this.widgetTemplate) {
            this.widgetTemplate.category = this.categoryOptions[0].value;
        }
    }

    private async fetchLicenseFeatures(): Promise<void> {
        const licenseFeatures = await Port.license.getLicenseFeatures();
        this.licenseFeatureOptions = licenseFeatures.map((feature) => ({
            value: feature.key,
            displayName: feature.name,
        }));
    }

    @Watch('widgetTemplate')
    private async validate(): Promise<void> {
        if (this.widgetTemplate) {
            if (this.widgetTemplate.isGlobal) {
                if (!this.widgetTemplate.titleLocalized) {
                    this.widgetTemplate.titleLocalized = {};
                }
            }
            this.errors = await Port.widgets.validateWidgetTemplate(this.widgetTemplate);
            this.valid = Object.keys(this.errors).length === 0;
        }
    }

    private async submit(): Promise<void> {
        try {
            this.saving = true;
            if (this.widgetTemplate) {
                if (this.widgetTemplate.titleLocalized) {
                    this.widgetTemplate.title = this.widgetTemplate.titleLocalized[this.$i18n.locale] || '';
                }
                await Port.widgets.createWidgetTemplate(this.widget, this.widgetTemplate);
                this.$emit('close');
            }
        } finally {
            this.saving = false;
        }
    }

    private close(): void {
        this.$emit('close');
    }
}
