
import { Vue, Prop, Component, Ref } from 'vue-property-decorator';

@Component({})
export default class Dropdown extends Vue {

    @Prop({ required: false, default: null })
    public readonly anchor!: HTMLElement|null;

    @Prop({ required: false, default: null })
    public readonly position!: { x: number; y: number }|null;

    @Prop({ required: false, default: '100vw' })
    public readonly maxWidth!: string;

    @Prop({ required: false, default: '10rem' })
    public readonly minWidth!: string;

    @Prop({ required: false, default: '15rem' })
    public readonly maxHeight!: string;

    @Prop({ required: false, default: null })
    public readonly height!: string|null;

    @Prop({ required: false, default: 10 })
    public readonly windowBorder!: number;

    @Prop({ required: false, default: null })
    public readonly dropdownClass!: string|null;

    @Ref('dropdown')
    private readonly dropdown?: HTMLElement;

    private dropdownStyle = {
        top: '0px',
        left: '0px',
        width: '10rem',
        maxHeight: '10rem',
        height: 'auto',
    };

    public mounted() {
        window.addEventListener('scroll', this.updatePositionY, { passive: true });
        window.addEventListener('resize', this.updatePosition, { passive: true });
        this.$nextTick(this.updatePosition);
    }

    public destroyed() {
        window.removeEventListener('scroll', this.updatePositionY);
        window.removeEventListener('resize', this.updatePosition);
    }

    private async updatePosition(): Promise<void> {
        if (this.anchor) {
            const rect = this.anchor.getBoundingClientRect();
            this.dropdownStyle = {
                top: `${rect.y + rect.height}px`,
                left: `${rect.x}px`,
                width: `clamp(${this.minWidth}, ${rect.width}px, ${this.maxWidth})`,
                maxHeight: this.height || this.maxHeight,
                height: this.height || 'auto',
            };
        } else if (this.position) {
            this.dropdownStyle = {
                top: `${this.position.y}px`,
                left: `${this.position.x}px`,
                width: this.minWidth,
                maxHeight: this.height || this.maxHeight,
                height: this.height || 'auto',
            };
        }
        await this.$nextTick();
        await this.$nextTick();
        this.moveToVisible();
    }

    private async updatePositionY(): Promise<void> {
        if (this.anchor) {
            const rect = this.anchor.getBoundingClientRect();
            this.dropdownStyle.top = `${rect.y + rect.height}px`;
        } else if (this.position) {
            this.dropdownStyle.top = `${this.position.y}px`;
        }
        await this.$nextTick();
        await this.$nextTick();
        this.moveToVisible();
    }

    private moveToVisible(): void {
        // render dropdown and then move it up, so everything is visible
        if (!this.dropdown) {
            return;
        }
        const dropdownRect = this.dropdown.getBoundingClientRect();
        const windowWidth = window.visualViewport?.width || window.innerWidth;
        const windowHeight = window.visualViewport?.height || window.innerHeight;
        if (dropdownRect.bottom > windowHeight - this.windowBorder) {
            this.dropdownStyle = {
                ...this.dropdownStyle,
                top: `${windowHeight - this.windowBorder - dropdownRect.height}px`,
            };
        }
        if (dropdownRect.right > windowWidth - this.windowBorder) {
            this.dropdownStyle = {
                ...this.dropdownStyle,
                left: `${windowWidth - this.windowBorder - dropdownRect.width}px`,
            };
        }
    }

    private close(): void {
        this.$emit('close');
    }
}
