import { i18n } from '@/plugins/i18n';
import type { WidgetWizardStep, TabDataOptions, TabGeneralOptions, TabMapOptions } from '@/components/widget-wizard';
import { ResourceType } from '@/modules/shared/types';
import { WidgetWizardTab } from '@/components/widget-wizard';

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: {
        enableDuplicateResources: false,
        resourceTypes: [ResourceType.Portfolio, ResourceType.Park, ResourceType.Generator],
        hint: i18n.t('widget-wizard.tab.data.hint-distinct').toString(),
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

const map: WidgetWizardStep<TabMapOptions> = {
    name: 'widget-wizard.tab.map',
    tab: WidgetWizardTab.Map,
    params: {
        enableShowStatusOption: false,
    },
};

export const Steps: WidgetWizardStep[] = [
    map,
    data,
    general,
];
