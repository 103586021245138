// Vue Dependencies
import type { PluginObject } from 'vue';
import Vue from 'vue';
import type { RouteConfig } from 'vue-router';
import type VueRouter from 'vue-router';

// Module Dependencies
import type { PortParams } from './Port';
import { Port } from './Port';
import { route as RouteDocuments } from './views/documents/Route';

export interface Options extends PortParams {
    router: VueRouter;
    routes?: Partial<RouteConfig>[];
}

const plugin: PluginObject<Options> = {
    install(vue: typeof Vue, params?: Options) {
        if (!params) {
            console.error('Missing plugin params!');
            return;
        }
        Port.init(params);

        // register routes
        const routes = [
            RouteDocuments,
        ];
        routes.forEach((route) => {
            const routeOverride = (params.routes || []).find((r) => r.name === route.name);
            const mergedRoute = Object.assign(route, routeOverride);
            params.router.addRoute(mergedRoute);
        });
    },
};

export { plugin };
