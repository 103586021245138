/**
 * Utils class to provide commonly used helper functions for arrays
 */
import MathUtils from '@/assets/js/utils/MathUtils';

export default class ArrayUtils {

    /**
     * Filter duplicates from the array. If a comparator is set, this function is used to define if a is a duplicate
     * of b. Otherwise simply creates a Set.
     *
     * <br>Usage examples:
     * <br>const a = { title:'a',text:'text_a' };
     * <br>const b = { title:'a',text:'text_b' };
     * <br>const c = { title:'c',text:'text_c' };
     * <br>ArrayUtils.distinct([a,b,b,c])
     * <br> -> [a,b,c]
     * <br>ArrayUtils.distinct([a,b,b,c], (a,b) => a.title === b.title)
     * <br> -> [a,c]
     *
     * @param array the array
     * @param comparator optional compare function
     */
    public static distinct<T>(array: T[], comparator?: (a: T, b: T) => boolean): T[] {
        if (comparator) {
            return array.filter((value: T, index: number, self: T[]) => self.findIndex((value2) => comparator(value, value2)) === index);
        }
        return [...new Set(array)];
    }

    /**
     * Groups the array by the result of the key function.
     *
     * Usage example:
     * <br>const a = { title:'a',text:'text_a' };
     * <br>const b = { title:'a',text:'text_b' };
     * <br>const c = { title:'c',text:'text_c' };
     * <br>ArrayUtils.groupBy([a,b,c], (item) => item.title)
     * <br> -> { 'a': [a,b], 'b': [c] }
     *
     * @param array the array
     * @param key the key to group by, value should be a string
     */
    public static groupBy<T>(array: T[], key: (item: T) => string): { [key: string]: T[] } {
        return array.reduce((groups: any, item: T) => {
            const k: any = key(item);
            (groups[k] = groups[k] || []).push(item);
            return groups;
        }, {});
    }

    public static removeDuplicates<T>(value: T, index: number, array: T[]): boolean {
        return array.indexOf(value) === index;
    }

    /**
     * Switch the arrays rows and columns
     * @param array
     */
    public static rotateArray<T>(array: T[][]): T[][] {
        if (array.length === 0) {
            return array;
        }
        const rowCount = array.length;
        const columnCount = MathUtils.max(array.map((row) => row?.length || 0));
        const result: T[][] = Array<T[]>(columnCount);
        for (let r = 0; r < array.length; r++) {
            const row = array[r];
            if (!row) {
                continue;
            }
            for (let c = 0; c < row.length; c++) {
                if (!result[c]) {
                    result[c] = Array<T>(rowCount);
                }
                result[c][r] = row[c];
            }
        }
        return result;
    }

    public static filterUndefined<T>(item: T|undefined): item is T {
        return !!item;
    }

    public static reduceToHighest(previousValue: number, currentValue: number, currentIndex: number, array: number[]): number {
        return Math.max(previousValue || 0, currentValue || 0);
    }

    public static reduceToLowest(previousValue: number, currentValue: number, currentIndex: number, array: number[]): number {
        return Math.min(previousValue || 0, currentValue || 0);
    }
}
