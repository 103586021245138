import { ImporterRepository, ImportRequest, PendingImport } from '@/modules/ctx-admin-data-imports';
import Validator, { Rules } from '@/assets/js/utils/Validator';
import NotificationService from '@/assets/js/services/NotificationService';
import { ImportRequestBatch } from '@/modules/ctx-admin-data-imports/types/ImportRequestBatch';
import DateUtils from '@/assets/js/utils/DateUtils';
import { i18n } from '@/plugins/i18n';
import Utils from '@/assets/js/utils/Utils';

export class ImporterService {

    private readonly importerRepository: ImporterRepository;

    constructor(params: {
        importerRepository: ImporterRepository;
    }) {
        this.importerRepository = params.importerRepository;
    }

    public onPendingImportsChange(cb: () => void): void {
        return this.importerRepository.onImportsChange(cb);
    }

    public async getPendingImports(): Promise<PendingImport[]> {
        const imports = await this.importerRepository.getPendingImports();
        return imports.sort((a, b) => b.created.getTime() - a.created.getTime()
            || b.generatorParkName.localeCompare(a.generatorParkName)
            || b.generatorName.localeCompare(a.generatorName));
    }

    public async cancelImportRequest(request: PendingImport): Promise<void> {
        try {
            const requests = request.types
                .map((type) => this.importerRepository.deleteDataReImports(type, [request.generatorKey]));
            await Promise.all(requests);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async createImportRequest(importRequest: ImportRequestBatch, updateProgressCb?: (progress: number) => void, hasBeenCanceled?: () => boolean): Promise<void> {
        importRequest.timeFrom.setHours(0);
        importRequest.timeFrom.setMinutes(0);
        importRequest.timeTo.setHours(0);
        importRequest.timeTo.setMinutes(0);
        importRequest.timeTo = DateUtils.add(importRequest.timeTo, { days: 1 });

        const validationErrors = this.validateImportRequest(importRequest);
        if (Object.keys(validationErrors).length > 0) {
            console.error(validationErrors);
            throw new Error('Invalid request!');
        }

        const requests: ImportRequest[] = importRequest.generators.map((generatorKey) => ({
            type: importRequest.type,
            timeFrom: importRequest.timeFrom,
            timeTo: importRequest.timeTo,
            generatorKey: generatorKey,
            metricKeys: importRequest.metricKeys,
        }));

        try {
            for (let i = 0; i < requests.length; i++) {
                // eslint-disable-next-line no-await-in-loop
                await this.submitRequest(requests[i]);
                if (updateProgressCb) {
                    updateProgressCb(i / requests.length);
                }
                if (hasBeenCanceled && hasBeenCanceled()) {
                    return;
                }
                // give the ui some time to update
                // eslint-disable-next-line no-await-in-loop
                await Utils.wait(10);
            }
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    private async submitRequest(request: ImportRequest, retryCount = 0): Promise<void> {
        try {
            await this.importerRepository.createImportRequest(request);
        } catch (e) {
            if (retryCount < 3) {
                // retry up to 3 time
                await this.submitRequest(request, retryCount + 1);
            } else {
                console.error(e);
                throw e;
            }
        }
    }

    public validateImportRequest(importRequest: ImportRequestBatch): {[key: string]: string[]} {
        const errors = Validator
            .addRule('timeFrom', [Rules.NotNullOrEmpty])
            .addRule('timeTo', [Rules.NotNullOrEmpty, Rules.DateAfter(importRequest.timeFrom)])
            .addRule('generators', [Rules.ArrayNotEmpty])
            .validate(importRequest);

        // TODO temporary disabled, should be enabled again after reimport is finished
        // const daysDiff = DateUtils.getDaysBetweenDates(importRequest.timeFrom, importRequest.timeTo);
        // if (daysDiff > 31 * 3) {
        //     errors.timeFrom = (errors.timeFrom || []).concat(i18n.t('ctx-admin-data-imports.time-range.validation.max').toString());
        // }
        return errors;
    }
}
