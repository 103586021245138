/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChartDataRange
 */
export interface ChartDataRange {
    /**
     * 
     * @type {number}
     * @memberof ChartDataRange
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartDataRange
     */
    max?: number;
}

export function ChartDataRangeFromJSON(json: any): ChartDataRange {
    return ChartDataRangeFromJSONTyped(json, false);
}

export function ChartDataRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartDataRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
    };
}

export function ChartDataRangeToJSON(value?: ChartDataRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min': value.min,
        'max': value.max,
    };
}


