/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReImportType {
    Log = 'log',
    Availability = 'availability',
    Min10 = 'min10',
    Daily = 'daily',
    Monthly = 'monthly'
}

export function ReImportTypeFromJSON(json: any): ReImportType {
    return ReImportTypeFromJSONTyped(json, false);
}

export function ReImportTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReImportType {
    return json as ReImportType;
}

export function ReImportTypeToJSON(value?: ReImportType | null): any {
    return value as any;
}

