import Vue from 'vue';
import type VueRouter from 'vue-router';
import { Module } from '@/modules/ctx-admin-customers';
import type { ModuleOptions } from '@/modules/ctx-admin-customers';
import { CustomerRepositoryImpl } from '@/plugins/modules/ctx-admin-customers/CustomerRepositoryImpl';
import { LicenseRepositoryImpl } from '@/plugins/modules/ctx-admin-customers/LicenseRepositoryImpl';
import { Port } from '@/modules/shared/Port';
import { UserRepositoryImpl } from '@/plugins/modules/ctx-admin-customers/UserRepositoryImpl';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        router: router,
        customerRepository: new CustomerRepositoryImpl({
            companiesApi: Port.companies,
            usersApi: Port.users,
            generatorsApi: Port.generators,
        }),
        licenseRepository: new LicenseRepositoryImpl({
            license: Port.license,
        }),
        userRepository: new UserRepositoryImpl({
            users: Port.users,
        }),
    });
}
