import { ReportsRepository } from '@/modules/ctx-documents/adapter/ReportsRepository';

export class ReportService {
    private readonly reportsRepository: ReportsRepository;

    constructor(params: {
        reportsRepository: ReportsRepository;
    }) {
        this.reportsRepository = params.reportsRepository;
    }

    public async retryReport(documentKey: string) {
        return await this.reportsRepository.retryReport({ documentKey });
    }
}
