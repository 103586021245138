import type { GeneratorsRepository, Generator } from '@/modules/ctx-documents';
import { GeneratorsApi, ParksApi } from '@/modules/shared/adapter';

export class GeneratorsRepositoryImpl implements GeneratorsRepository {
    private readonly generatorsApi: GeneratorsApi;
    private readonly parksApi: ParksApi;

    constructor(apis: {
        generators: GeneratorsApi;
        parks: ParksApi;
    }) {
        this.generatorsApi = apis.generators;
        this.parksApi = apis.parks;
    }

    public async getGenerators(): Promise<Generator[]> {
        const representations = await this.generatorsApi.getGenerators();
        return Promise.all(representations.map(async (it) => ({
            key: it.key,
            name: it.name,
            parkKey: it.parkKey || '',
            parkName: (await this.parksApi.getParkByKey(it.parkKey || ''))?.name || '',
        })));
    }
}
