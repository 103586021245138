import { Configuration, DashboardsApi as DashboardsRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import { DashboardType } from '@/modules/shared/types';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type { UserSettingsApi } from '@/modules/shared/adapter/UserSettingsApi';
import type {
    WidgetCopyRequest,
    WidgetCreateRequest,
    WidgetRepresentation,
    WidgetsApi,
    WidgetUpdateRequest,
} from '@/modules/shared/adapter/WidgetsApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

interface DashboardWidgetMapping {
    key: string;
    widgets: WidgetRepresentation[];
}

export class CachedWidgetsRestApi implements WidgetsApi {

    private readonly authApi: AuthApi;
    private readonly settingsApi: UserSettingsApi;
    private readonly dashboardApi: DashboardsRestApi;
    private readonly widgetsCache: IndexedDBRepository<string, DashboardWidgetMapping>;
    private favoriteWidgets: WidgetRepresentation[]|null = null;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi, settings: UserSettingsApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.dashboardApi = new DashboardsRestApi(restApiConfig);
        this.widgetsCache = new IndexedDBRepository<string, DashboardWidgetMapping>(indexedDb, 'widgets');
        this.authApi = apis.auth;
        this.settingsApi = apis.settings;
    }

    // public async removeFavoritesOfDashboard(dashboardKey: string): Promise<void> {
    public async invalidateFavorites(): Promise<void> {
        this.favoriteWidgets = null;
        await this.widgetsCache.removeByKey('favorites');
        await this.fetchFavorites();
    }

    public async deleteWidget(dashboardKey: string, widgetKey: string): Promise<void> {
        await this.dashboardApi.deleteWidget(dashboardKey, widgetKey);
        // remove from dashboard cache
        const cachedDashboard = await this.widgetsCache.findByKey(dashboardKey);
        if (cachedDashboard) {
            // remove old widget
            const updatedDashboardWidgets = cachedDashboard.widgets.filter((w) => w.key !== widgetKey);
            // add updated widget
            await this.widgetsCache.save({ key: dashboardKey, widgets: updatedDashboardWidgets });
        }
        // remove from favorites cache
        const favorites = (await this.getFavoriteWidgets())
            .filter((w) => w.key !== widgetKey);
        await this.setFavoriteWidgets(favorites);
    }

    public async getFavoriteWidgets(): Promise<WidgetRepresentation[]> {
        if (this.favoriteWidgets) {
            return this.favoriteWidgets;
        }
        const cached = await this.widgetsCache.findByKey('favorites');
        return cached?.widgets || this.fetchFavorites();
    }

    public async setFavoriteWidgets(widgets: WidgetRepresentation[]): Promise<void> {
        this.favoriteWidgets = widgets;
        await this.widgetsCache.save({ key: 'favorites', widgets: widgets });
    }

    public async getFavoriteWidgetsForPortfolio(portfolioKey: string): Promise<WidgetRepresentation[]> {
        const favorites = await this.getFavoriteWidgets();
        return favorites.filter((widget) => widget.portfolioKey === portfolioKey);
    }

    public async getWidgetsForDashboard(dashboardKey: string): Promise<WidgetRepresentation[]> {
        const cached = await this.widgetsCache.findByKey(dashboardKey);
        return cached?.widgets || this.fetchWidgets(dashboardKey);
    }

    public async createWidget(dashboardKey: string, createWidgetSpec: WidgetCreateRequest): Promise<WidgetRepresentation> {
        const widget = await this.dashboardApi.createWidget(dashboardKey, createWidgetSpec);
        const widgetOrder = await this.updateWidgetOrderAfterInsert(dashboardKey, widget.key);
        const cachedWidgetsForDashboard = await this.widgetsCache.findByKey(dashboardKey);
        if (cachedWidgetsForDashboard) {
            cachedWidgetsForDashboard.widgets.push(widget);
            await this.widgetsCache.save(cachedWidgetsForDashboard);
        }
        return widget;
    }

    public async copyWidget(dashboardKey: string, widgetKey: string, copyRequest: WidgetCopyRequest): Promise<void> {
        const newWidget = await this.dashboardApi.copyWidget(dashboardKey, widgetKey, copyRequest);
        const widgetOrder = await this.updateWidgetOrderAfterInsert(copyRequest.dashboardKey, newWidget.key);
        const cachedWidgetsForDashboard = await this.widgetsCache.findByKey(copyRequest.dashboardKey);
        // updateWidgetOrderAfterInsert may load the widgets from service, in that case the new widget is already
        // present in the widget list, and we don't want to add it again
        if (cachedWidgetsForDashboard && !cachedWidgetsForDashboard.widgets.find((w) => w.key === newWidget.key)) {
            cachedWidgetsForDashboard.widgets.push(newWidget);
            await this.widgetsCache.save(cachedWidgetsForDashboard);
        }
    }

    private async updateWidgetOrderAfterInsert(dashboardKey: string, newWidgetKey: string): Promise<string[]> {
        let order: string[] = await this.getWidgetOrder(DashboardType.Dashboard, dashboardKey);
        const unorderedWidgetsForDashboard: string[] = (await this.getWidgetsForDashboard(dashboardKey))
            .map((widget) => widget.key)
            .filter((key) => key !== newWidgetKey && !order.includes(key));
        // add missing widgets to order
        order = order.concat(unorderedWidgetsForDashboard);
        if (this.settingsApi.getUserSettings().insertNewWidgetsPosition === 'end') {
            order.push(newWidgetKey);
        } else {
            order.unshift(newWidgetKey);
        }
        await this.setWidgetOrder(DashboardType.Dashboard, dashboardKey, order);
        return order;
    }

    public async updateWidget(dashboardKey: DashboardType, widgetKey: string, updateSpec: WidgetUpdateRequest): Promise<WidgetRepresentation> {
        const updatedWidget = await this.dashboardApi.updateWidget(dashboardKey, widgetKey, updateSpec);
        // update widget in dashboard cache
        const cachedDashboard = await this.widgetsCache.findByKey(dashboardKey);
        if (cachedDashboard) {
            const updatedDashboardWidgets = cachedDashboard.widgets
                .filter((w) => w.key !== widgetKey) // remove old widget
                .concat(updatedWidget); // add updated widget
            await this.widgetsCache.save({ key: dashboardKey, widgets: updatedDashboardWidgets });
        }
        // update widget in favorites cache
        if (await this.isFavoriteWidget(widgetKey)) {
            const favoriteWidgets = (await this.getFavoriteWidgets())
                .filter((w) => w.key !== widgetKey) // remove old widget
                .concat(updatedWidget); // add updated widget
            await this.setFavoriteWidgets(favoriteWidgets);
        }
        return updatedWidget;
    }

    public async isFavoriteWidget(widgetKey: string): Promise<boolean> {
        const favorites = await this.getFavoriteWidgets();
        return favorites.find((f) => f.key === widgetKey) !== undefined;
    }

    public async addWidgetToFavorites(widgetKey: string): Promise<void> {
        const updatedWidget = await this.dashboardApi.addWidgetToFavorites({ key: widgetKey });
        const favorites = (await this.getFavoriteWidgets())
            .filter((w) => w.key !== widgetKey)
            .concat(updatedWidget);
        await this.setFavoriteWidgets(favorites);
    }

    public async removeWidgetFromFavorites(widgetKey: string): Promise<void> {
        await this.dashboardApi.removeWidgetFromFavorites(widgetKey);
        const favorites = (await this.getFavoriteWidgets())
            .filter((w) => w.key !== widgetKey);
        await this.setFavoriteWidgets(favorites);
    }

    public async getWidgetOrder(orderType: DashboardType, orderKey: string|null): Promise<string[]> {
        let order: string[] = [];
        if (orderKey === null) {
            const user = await this.authApi.getUser();
            order = await this.fetchWidgetOrder(DashboardType.Favorites, user.key);
        } else {
            order = await this.fetchWidgetOrder(orderType, orderKey);
        }
        if (order.length === 0) {
            let widgets: WidgetRepresentation[] = [];
            if (orderKey === DashboardType.Favorites) {
                widgets = await this.getFavoriteWidgets();
            } else if (orderKey && orderType === DashboardType.Dashboard) {
                widgets = await this.getWidgetsForDashboard(orderKey);
            } else if (orderKey && orderType === DashboardType.PortfolioFavorites) {
                widgets = await this.getFavoriteWidgetsForPortfolio(orderKey);
            }
            if (widgets.length > 0) {
                console.warn(`No widget order found for ${orderType} ${orderKey}. Persisting current order.`);
                order = widgets.map((widget) => widget.key);
                await this.setWidgetOrder(orderType, orderKey, order);
            }
        }
        return order;
    }

    public async setWidgetOrder(orderType: DashboardType, orderKey: string|null, widgetKeys: string[]): Promise<void> {
        if (orderKey === null) {
            const user = await this.authApi.getUser();
            await this.dashboardApi.setWidgetOrder(DashboardType.Favorites, user.key, widgetKeys);
        } else {
            await this.dashboardApi.setWidgetOrder(orderType, orderKey, widgetKeys);
        }
    }

    public async setWidgetDisplayMode(widgetKey: string, mode: string): Promise<void> {
        const displayModes = JSON.parse(localStorage.getItem('halvar-widget-display-modes') || '{}');
        displayModes[widgetKey] = mode;
        localStorage.setItem('halvar-widget-display-modes', JSON.stringify(displayModes));
    }

    public async getWidgetDisplayMode(widgetKey: string): Promise<string|undefined> {
        const displayModes = JSON.parse(localStorage.getItem('halvar-widget-display-modes') || '{}');
        return displayModes[widgetKey] || undefined;
    }

    private async fetchFavorites(): Promise<WidgetRepresentation[]> {
        return AsyncDebouncer.debounce('CachedWidgetsRestApi.fetchFavorites', async () => {
            const widgets = await this.dashboardApi.getFavoriteWidgets();
            await this.setFavoriteWidgets(widgets);
            return widgets;
        });
    }

    private async fetchWidgets(dashboardKey: string): Promise<WidgetRepresentation[]> {
        return AsyncDebouncer.debounce(`CachedWidgetsRestApi.fetchWidgets(${dashboardKey})`, async () => {
            const widgets = await this.dashboardApi.getWidgets(dashboardKey);
            await this.widgetsCache.save({ key: dashboardKey, widgets: widgets });
            return widgets;
        });
    }

    private async fetchWidgetOrder(orderType: DashboardType, orderKey: string): Promise<string[]> {
        return AsyncDebouncer.debounce(`CachedWidgetsRestApi.fetchWidgetOrder(${orderType},${orderKey})`, () => this.dashboardApi.getWidgetOrder(orderType, orderKey));
    }
}
