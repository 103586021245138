/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardUpdateRequest
 */
export interface DashboardUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardUpdateRequest
     */
    order?: number;
}

export function DashboardUpdateRequestFromJSON(json: any): DashboardUpdateRequest {
    return DashboardUpdateRequestFromJSONTyped(json, false);
}

export function DashboardUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function DashboardUpdateRequestToJSON(value?: DashboardUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'order': value.order,
    };
}


