import type {
    LocalizationRepository,
    MetricsRepository,
} from '@/modules/ctx-admin-metrics/adapter';
import { MetricsService } from '@/modules/ctx-admin-metrics/services/MetricsService';

export interface PortParams {
    metricsRepository: MetricsRepository;
    localizationRepository: LocalizationRepository;
}

export class Port {
    // services will be instantiated in plugin
    private static port: Port|null = null;

    private readonly metrics: MetricsService;

    constructor(params: PortParams) {
        this.metrics = new MetricsService({
            metricsRepository: params.metricsRepository,
            localizationRepository: params.localizationRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get metrics(): MetricsService {
        return Port.instance.metrics;
    }
}
