
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import Dropdown from '@/modules/shared/components/util/Dropdown.vue';

@Component({
    components: { Dropdown },
})
export default class SelectBox extends Vue {

    @Prop({})
    public readonly value: any;

    @Prop({ default: [] })
    public readonly options!: SelectBoxOption[];

    @Prop({ default: '' })
    public readonly placeholder!: string;

    @Prop({ default: false })
    public readonly disabled!: boolean|string;

    @Ref('wrapper')
    private readonly wrapper!: HTMLElement;

    private selectedOption: SelectBoxOption|null = null;
    private showDropdown: boolean = false;

    public mounted(): void {
        this.onValueChanged();
    }

    @Watch('value')
    @Watch('options')
    private onValueChanged() {
        if (typeof this.value === 'object') {
            const valueStr = JSON.stringify(this.value);
            this.selectedOption = this.options.find((option) => JSON.stringify(option.value) === valueStr) || null;
        } else {
            this.selectedOption = this.options.find((option) => option.value === this.value) || null;
        }
    }

    private get isDisabled(): boolean {
        return this.disabled === true || this.disabled === 'true' || this.options.length === 0;
    }

    private get displayName(): string {
        if (this.selectedOption) {
            return this.selectedOption.displayName;
        }
        return '';
    }

    private toggleDropdown(show?: boolean) {
        if (this.isDisabled) {
            this.showDropdown = false;
        } else if (show !== undefined) {
            this.showDropdown = show;
        } else {
            this.showDropdown = !this.showDropdown;
        }
    }

    private selectOption(option: SelectBoxOption): void {
        this.showDropdown = false;
        if (this.value !== option.value) {
            this.selectedOption = option;
            this.$emit('input', option.value);
            this.$emit('change', option.value);
        }
    }
}
