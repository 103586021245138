/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CoverageRequest
 */
export interface CoverageRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CoverageRequest
     */
    generatorKeys: Array<string>;
}

export function CoverageRequestFromJSON(json: any): CoverageRequest {
    return CoverageRequestFromJSONTyped(json, false);
}

export function CoverageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoverageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generatorKeys': json['generatorKeys'],
    };
}

export function CoverageRequestToJSON(value?: CoverageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generatorKeys': value.generatorKeys,
    };
}


