import { Configuration, PortfolioApi as PortfolioRestApi, TemplatesApi as TemplatesRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { DataChangedEvent } from '@/modules/shared/types';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type {
    PortfolioRepresentation,
    PortfolioTemplatesApi,
    PortfolioUpdateRequest,
    PortfolioTemplateRepresentation,
    TemplateCreateFromPortfolioRequest,
} from '@/modules/shared/adapter';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedPortfolioTemplatesRestApi implements PortfolioTemplatesApi {

    private readonly portfoliosApi: PortfolioRestApi;
    private readonly templatesApi: TemplatesRestApi;
    private readonly portfolioTemplatesCache: IndexedDBRepository<string, PortfolioTemplateRepresentation>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.portfoliosApi = new PortfolioRestApi(restApiConfig);
        this.templatesApi = new TemplatesRestApi(restApiConfig);
        this.portfolioTemplatesCache = new IndexedDBRepository<string, PortfolioTemplateRepresentation>(indexedDb, 'portfolio-templates');
    }

    public onChange(cb: (event: DataChangedEvent) => Promise<void>): void {
        this.portfolioTemplatesCache.addChangedListener((evt) => cb(evt));
    }

    public async getPortfolioTemplates(): Promise<PortfolioTemplateRepresentation[]> {
        return await this.portfolioTemplatesCache.findAllIfAnyCached() || this.fetchPortfolioTemplates();
    }

    private async fetchPortfolioTemplates(): Promise<PortfolioTemplateRepresentation[]> {
        return AsyncDebouncer.debounce<PortfolioTemplateRepresentation[]>('CachedPortfoliosRestApi.fetchPortfolioTemplates', async () => {
            const templates = await this.portfoliosApi.getPortfolioTemplates();
            await this.portfolioTemplatesCache.saveAll(templates);
            return templates;
        });
    }

    public async createPortfolioTemplate(templateCreateSpec: TemplateCreateFromPortfolioRequest): Promise<PortfolioTemplateRepresentation> {
        const template = await this.templatesApi.createTemplateFromPortfolio(templateCreateSpec);
        if (await this.portfolioTemplatesCache.count() > 0) {
            // only update cache when we have already requested all portfolios, as we otherwise
            // could not tell if the cache contains all portfolios available for the user
            await this.portfolioTemplatesCache.save(template);
        }
        return template;
    }

    public async updatePortfolioTemplate(templateKey: string, templateUpdateSpec: PortfolioUpdateRequest): Promise<PortfolioRepresentation> {
        const template = await this.portfoliosApi.updatePortfolio(templateKey, templateUpdateSpec);
        if (await this.portfolioTemplatesCache.count() > 0) {
            // only update cache when we have already requested all portfolios, as we otherwise
            // could not tell if the cache contains all portfolios available for the user
            await this.portfolioTemplatesCache.save(template);
        }
        return template;
    }

    public async deletePortfolioTemplate(templateKey: string): Promise<void> {
        await this.portfoliosApi.deletePortfolioByKey(templateKey);
        await this.portfolioTemplatesCache.removeByKey(templateKey);
    }
}
