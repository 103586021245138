import type {
    AuthApi,
    DashboardTemplateRepresentation,
    MetricsApi,
    UserRepresentation,
    UsersApi,
} from '@/modules/shared/adapter';
import type { DashboardTemplate } from '@/modules/ctx-dashboard';
import { LicenseFeature } from '@/modules/shared/types';

export class DashboardTemplateMapper {

    private readonly metricsApi: MetricsApi;
    private readonly authApi: AuthApi;
    private readonly usersApi: UsersApi;

    constructor(apis: {
        metrics: MetricsApi,
        auth: AuthApi,
        users: UsersApi,
    }) {
        this.metricsApi = apis.metrics;
        this.authApi = apis.auth;
        this.usersApi = apis.users;
    }

    public async mapDashboardTemplateToDomain(representation: DashboardTemplateRepresentation): Promise<DashboardTemplate> {
        const user = await this.authApi.getUser();
        // only creators for own company are visible, if user is of another company, this will be undefined
        let creator: UserRepresentation|undefined;
        if (await this.authApi.isCompanyAdmin()) {
            try {
                creator = await this.usersApi.getUser(user.companyKey, representation.createdBy);
            } catch (e) {
                // logged in user cannot access the user list
            }
        }
        return {
            key: representation.key,
            name: representation.name,
            description: representation.description || '',
            widgetsCount: representation.widgetTemplates.length,
            created: representation.created,
            createdBy: creator ? `${creator.firstname} ${creator.lastname}` : 'Halvar',
            isOwner: user.key === representation.createdBy,
            global: representation.global === true,
            requiredLicenseFeatures: representation.requiredLicenseFeatures.map((f) => f as LicenseFeature),
        };
    }
}
