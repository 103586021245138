
import { Component, Vue } from 'vue-property-decorator';
import HalvarLogoText from '@/components/icons/logo-text.svg';

@Component({
    components: {
        HalvarLogoText,
    },
})
export default class OnboardingTabStart extends Vue {

}
