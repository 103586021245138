import Vue from 'vue';
import type VueRouter from 'vue-router';
import { Module, ModuleOptions } from '@/modules/ctx-portfolios';
import { Port } from '@/modules/shared/Port';
import { PortfolioRepositoryImpl } from './PortfolioRepositoryImpl';
import { TemplatesRepositoryImpl } from './TemplatesRepositoryImpl';
import { DefaultUserRepository } from '../default/DefaultUserRepository';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        router: router,
        portfolioRepository: new PortfolioRepositoryImpl({
            portfolios: Port.portfolios,
            dashboards: Port.dashboards,
        }),
        templatesRepository: new TemplatesRepositoryImpl({
            portfolioTemplates: Port.portfolioTemplates,
            dashboards: Port.dashboards,
        }),
        userRepository: new DefaultUserRepository({
            auth: Port.auth,
            license: Port.license,
        }),
    });
}
