/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResourceFilterName,
    ResourceFilterNameFromJSON,
    ResourceFilterNameFromJSONTyped,
    ResourceFilterNameToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetResourceFilterRepresentation
 */
export interface WidgetResourceFilterRepresentation {
    /**
     * 
     * @type {ResourceFilterName}
     * @memberof WidgetResourceFilterRepresentation
     */
    name: ResourceFilterName;
    /**
     * comma separated list | single value | range a-b
     * @type {string}
     * @memberof WidgetResourceFilterRepresentation
     */
    filter: string;
}

export function WidgetResourceFilterRepresentationFromJSON(json: any): WidgetResourceFilterRepresentation {
    return WidgetResourceFilterRepresentationFromJSONTyped(json, false);
}

export function WidgetResourceFilterRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetResourceFilterRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': ResourceFilterNameFromJSON(json['name']),
        'filter': json['filter'],
    };
}

export function WidgetResourceFilterRepresentationToJSON(value?: WidgetResourceFilterRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': ResourceFilterNameToJSON(value.name),
        'filter': value.filter,
    };
}


