
import { Component, Vue, Watch } from 'vue-property-decorator';
import Notifications from '@/components/partials/Notifications.vue';
import NavigationBar from '@/components/partials/navigation/NavigationBar.vue';
import SettingsTabs from '@/components/partials/navigation/SettingsTabs.vue';
import PortfolioTabs from '@/modules/ctx-dashboard/components/dashboards/PortfolioTabs.vue';
import LoggingUtils from '@/assets/js/utils/LoggingUtils';
import OnboardingDlg from '@/modules/shared/components/dialogs/onboarding/OnboardingDlg.vue';
import HalvarFooter from '@/components/partials/HalvarFooter.vue';
import LoadingAppOverlay from '@/components/partials/overlays/LoadingAppOverlay.vue';
import FullscreenErrorMessage from '@/components/partials/overlays/FullscreenErrorMessage.vue';
import type { LicenseFeature } from '@/modules/shared/types';
import { Port } from '@/modules/shared/Port';
import { I18nAdapter } from '@/plugins/i18n';
import { Events } from '@/modules/shared';
import Tooltip from '@/modules/shared/components/globals/Tooltip.vue';

@Component({
    components: {
        Tooltip,
        FullscreenErrorMessage,
        LoadingAppOverlay,
        HalvarFooter,
        OnboardingDlg,
        PortfolioTabs,
        SettingsTabs,
        NavigationBar,
        Notifications,
    },
})
export default class App extends Vue {

    private refreshTokenJobRunning: boolean = false;

    private loadingI18n: boolean = false;
    private loadingLicense: boolean = false;
    private loggedIn: boolean|null = null;
    private serviceError: Response|Error|string|null = null;
    private licenseFeatures: LicenseFeature[] = [];

    public mounted(): void {
        // mainly for hot reloading
        this.$router.onReady(() => {
            Port.auth.authenticate().then((loggedIn) => this.loggedIn = loggedIn);
        });
        Events.instance.on<Response|Error|string>('service-unavailable', (err) => this.serviceError = err);
        Events.instance.on('authenticated', () => this.serviceError = null);
        Events.instance.on('login', () => this.loggedIn = true);
        Events.instance.on('logout', () => this.loggedIn = false);
    }

    private get viewConfig(): any {
        return this.$route.meta || {};
    }

    @Watch('loggedIn')
    private async onLoggedInChanged() {
        if (this.loggedIn) {
            LoggingUtils.log('app', 'User is now logged in', '#495961');
            this.serviceError = null;
            this.fetchLicense();
            this.fetchI18n();
            await this.$store.dispatch('init', { portfolioKey: this.$route.params.portfolioKey });
        } else {
            LoggingUtils.log('app', 'User is now logged out', '#495961');
            this.$store.dispatch('clearData');
            if (!this.$route.name || this.$route.meta?.authenticated) {
                this.redirectToLogin();
            }
        }
    }

    @Watch('$route.fullPath')
    private onRouteChanged() {
        if (!this.loggedIn && this.$route.meta?.authenticated) {
            this.redirectToLogin();
        }
    }

    private get appLoading(): boolean {
        return this.loadingLicense
            || this.loadingI18n
            || (this.loggedIn === null && this.viewConfig.authenticated);
    }

    private async fetchLicense(): Promise<void> {
        if (this.loadingLicense) {
            return;
        }
        this.loadingLicense = true;
        this.licenseFeatures = await Port.auth.getLicenseFeatures();
        this.loadingLicense = false;
    }

    private async fetchI18n(): Promise<void> {
        if (this.loadingI18n) {
            return;
        }
        this.loadingI18n = true;
        await I18nAdapter.loadMessages(this.$i18n.locale);
        this.loadingI18n = false;
    }

    private redirectToLogin(): void {
        if (this.$route.name !== 'login' && this.$route.name !== 'company-select') {
            const redirectFrom = encodeURI(this.$route.fullPath);
            const customer = this.$route.params.customer;
            this.$router.push({ name: 'login', params: { customer: customer }, query: { redirect: redirectFrom } });
        }
    }
}
