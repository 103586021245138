import { i18n } from '@/plugins/i18n';
import { LicenseFeature, ResourceType } from '@/modules/shared/types';
import type {
    TabAxisOptions,
    TabDataOptions,
    TabGeneralOptions,
    TabMetricsOptions,
    WidgetWizardStep,
} from '@/components/widget-wizard';
import { WidgetWizardTab } from '@/components/widget-wizard';
import { TimeCompare } from '@/components/widgets/utils/TimeCompareUtils';
import { WidgetUtils } from '../../utils/WidgetUtils';

const metrics: WidgetWizardStep<TabMetricsOptions> = {
    name: 'widget-wizard.tab.metrics',
    tab: WidgetWizardTab.Metrics,
    features: [LicenseFeature.ADVANCED],
    params: (widget) => {
        const params: Partial<TabMetricsOptions> = {
            axisTitles: ['X-Achse', 'Primäre Y-Achse', 'Sekundäre Y-Achse'],
            minMetrics: [1, 1, 0],
            maxMetrics: [1, 10, 10],
        };
        // If the user has any axis with more than one metric, we have to show the metric name in the legend.
        // E.g. 'Bad Berleburg - Windgeschwindigkeit (Durchschnitt, Durchschnitt)'
        // Therefore only one datasource is allowed in this case.
        if (WidgetUtils.hasMultipleDistinctResources(widget)) {
            params.maxMetrics = [1, 1, 1];
            params.notice = i18n.t('widget-wizard.tab.metrics.limit').toString();
        }
        return params;
    },
};

const axis: WidgetWizardStep<TabAxisOptions> = {
    name: 'widget-wizard.tab.axis',
    tab: WidgetWizardTab.Axis,
    features: [LicenseFeature.ADVANCED],
    params: (widgetConfig) => ({
        axisTitles: ['X-Achse', 'Primäre Y-Achse', 'Sekundäre Y-Achse'],
        enableChartType: false,
        enableGlobalScale: widgetConfig.axis[1].metrics.length > 0 && widgetConfig.axis[2].metrics.length > 0,
    }),
};

const resolution: WidgetWizardStep = {
    name: 'widget-wizard.tab.resolution',
    tab: WidgetWizardTab.Resolution,
};

const time: WidgetWizardStep = {
    name: 'widget-wizard.tab.time',
    tab: WidgetWizardTab.Time,
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: (widget) => {
        const params: Partial<TabDataOptions> = {
            maxDataSourcesTotal: 10,
            maxDataSourcesPerAxis: [0, 10, 10],
            resourceTypes: [
                ResourceType.Portfolio,
                ResourceType.Park,
                ResourceType.Generator,
                ResourceType.Shareholder,
                ResourceType.Generatorgroup,
                ResourceType.Anonymous,
            ],
        };

        // If the user has any axis with more than one metric, we have to show the metric name in the legend.
        // E.g. 'Bad Berleburg - Windgeschwindigkeit (Durchschnitt, Durchschnitt)'
        // Therefore only one datasource is allowed in this case.
        if (WidgetUtils.hasAxisWithMultipleMetrics(widget)) {
            params.maxDataSourcesTotal = 1;
            params.maxDataSourcesPerAxis = [0, 1, 1];
            params.forceSingleResourceList = true;
            params.notices = (params.notices || []).concat([i18n.t('widget-wizard.tab.data.limit').toString()]);
        }
        params.availableTimeOverrides = [
            TimeCompare.Years1,
            TimeCompare.Years2,
            TimeCompare.Years3,
            TimeCompare.Years4,
            TimeCompare.Months1,
            TimeCompare.Weeks1,
        ];
        return params;
    },
};

const dataAggregation: WidgetWizardStep = {
    name: 'widget-wizard.tab.aggregation-datasources',
    tab: WidgetWizardTab.AggregationDatasources,
    visible: (widget) => WidgetUtils.getDataSourcesNeedingAggregation(widget).length > 0,
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    metrics,
    axis,
    time,
    resolution,
    data,
    dataAggregation,
    general,
];
