import Vue from 'vue';
import type VueRouter from 'vue-router';
import { Module, ModuleOptions } from '@/modules/ctx-admin-functions';
import { Port } from '@/modules/shared/Port';
import { AdminRepositoryImpl } from './AdminRepositoryImpl';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        router: router,
        adminRepository: new AdminRepositoryImpl({ data: Port.data }),
    });
}
