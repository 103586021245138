/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowercurveDataRequest
 */
export interface PowercurveDataRequest {
    /**
     * 
     * @type {number}
     * @memberof PowercurveDataRequest
     */
    timeFrom: number;
    /**
     * 
     * @type {number}
     * @memberof PowercurveDataRequest
     */
    timeTo: number;
    /**
     * 
     * @type {number}
     * @memberof PowercurveDataRequest
     */
    windInterval?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PowercurveDataRequest
     */
    filterNegativePower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PowercurveDataRequest
     */
    filterNegativeWind?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PowercurveDataRequest
     */
    filterNoPowerValues?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PowercurveDataRequest
     */
    filterRefCurveTolerance?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRequest
     */
    hours?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowercurveDataRequest
     */
    generatorStatus?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowercurveDataRequest
     */
    windDirections?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PowercurveDataRequest
     */
    windDirectionSteps?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PowercurveDataRequest
     */
    generatorKeys: Array<string>;
}

export function PowercurveDataRequestFromJSON(json: any): PowercurveDataRequest {
    return PowercurveDataRequestFromJSONTyped(json, false);
}

export function PowercurveDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowercurveDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeFrom': json['timeFrom'],
        'timeTo': json['timeTo'],
        'windInterval': !exists(json, 'windInterval') ? undefined : json['windInterval'],
        'filterNegativePower': !exists(json, 'filterNegativePower') ? undefined : json['filterNegativePower'],
        'filterNegativeWind': !exists(json, 'filterNegativeWind') ? undefined : json['filterNegativeWind'],
        'filterNoPowerValues': !exists(json, 'filterNoPowerValues') ? undefined : json['filterNoPowerValues'],
        'filterRefCurveTolerance': !exists(json, 'filterRefCurveTolerance') ? undefined : json['filterRefCurveTolerance'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'generatorStatus': !exists(json, 'generatorStatus') ? undefined : json['generatorStatus'],
        'windDirections': !exists(json, 'windDirections') ? undefined : json['windDirections'],
        'windDirectionSteps': !exists(json, 'windDirectionSteps') ? undefined : json['windDirectionSteps'],
        'generatorKeys': json['generatorKeys'],
    };
}

export function PowercurveDataRequestToJSON(value?: PowercurveDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeFrom': value.timeFrom,
        'timeTo': value.timeTo,
        'windInterval': value.windInterval,
        'filterNegativePower': value.filterNegativePower,
        'filterNegativeWind': value.filterNegativeWind,
        'filterNoPowerValues': value.filterNoPowerValues,
        'filterRefCurveTolerance': value.filterRefCurveTolerance,
        'hours': value.hours,
        'generatorStatus': value.generatorStatus,
        'windDirections': value.windDirections,
        'windDirectionSteps': value.windDirectionSteps,
        'generatorKeys': value.generatorKeys,
    };
}


