/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GeneratorRepresentation,
    GeneratorRepresentationFromJSON,
    GeneratorRepresentationToJSON,
} from '../models';

export interface GetGeneratorsRequest {
    portfolioKey?: string;
}

export interface SetGeneratorActiveStatusRequest {
    key: string;
    body: boolean;
}

/**
 * 
 */
export class GeneratorsApi extends runtime.BaseAPI {

    /**
     * Get all generators for the current user
     * Get generators
     */
    async getGeneratorsRaw(requestParameters: GetGeneratorsRequest): Promise<runtime.ApiResponse<Array<GeneratorRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioKey !== undefined) {
            queryParameters['portfolioKey'] = requestParameters.portfolioKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["portfolios:read"]);
        }

        const response = await this.request({
            path: `/generators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeneratorRepresentationFromJSON));
    }

    /**
     * Get all generators for the current user
     * Get generators
     */
    async getGenerators(portfolioKey?: string): Promise<Array<GeneratorRepresentation>> {
        const response = await this.getGeneratorsRaw({ portfolioKey: portfolioKey });
        return await response.value();
    }

    /**
     * Update generator active flag
     * Update generator active flag
     */
    async setGeneratorActiveStatusRaw(requestParameters: SetGeneratorActiveStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling setGeneratorActiveStatus.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setGeneratorActiveStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["generators:write"]);
        }

        const response = await this.request({
            path: `/data/generators/{key}/active-status`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update generator active flag
     * Update generator active flag
     */
    async setGeneratorActiveStatus(key: string, body: boolean): Promise<void> {
        await this.setGeneratorActiveStatusRaw({ key: key, body: body });
    }

}
