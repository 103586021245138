import type { User, UserRepository } from '@/modules/ctx-users';
import { AuthApi, UserRepresentation, UsersApi } from '@/modules/shared/adapter';

export class UserRepositoryImpl implements UserRepository {

    private readonly usersApi: UsersApi;
    private readonly authApi: AuthApi;

    constructor(apis: {
        users: UsersApi,
        auth: AuthApi
    }) {
        this.usersApi = apis.users;
        this.authApi = apis.auth;
    }

    public async getUser(companyKey: string, userKey: string): Promise<User|undefined> {
        const users = await this.getUsers(companyKey);
        return users.find((user) => user.key === userKey);
    }

    public async getSignedInUser(): Promise<User> {
        const user = await this.authApi.getUser();
        const admins = await this.usersApi.getAdmins(user.companyKey);
        return UserRepositoryImpl.mapUserToDomain(user, (userKey: string) => admins.find((admin) => admin.key === userKey) !== undefined);
    }

    public async getUsers(companyKey: string): Promise<User[]> {
        const users = await this.usersApi.getUsers(companyKey);
        const admins = await this.usersApi.getAdmins(companyKey);
        return users.map((user) => UserRepositoryImpl.mapUserToDomain(user, (userKey: string) => admins.find((admin) => admin.key === userKey) !== undefined));
    }

    public async createUser(user: User): Promise<User> {
        const newUser = await this.usersApi.createUser({
            companyKey: user.companyKey,
            email: user.email,
            image: user.image,
            firstname: user.firstname,
            lastname: user.lastname,
            username: user.username,
            password: user.password,
        });
        if (user.role === 'admin') {
            await this.usersApi.createCompanyAdmin(user.companyKey, newUser.key);
        }
        return UserRepositoryImpl.mapUserToDomain(newUser, () => user.role === 'admin');
    }

    public async updateUser(user: User): Promise<User> {
        const updatedUser = await this.usersApi.updateUser(user.key, {
            email: user.email,
            lastname: user.lastname,
            firstname: user.firstname,
        });
        return UserRepositoryImpl.mapUserToDomain(updatedUser, () => user.role === 'admin');
    }

    public async deleteUser(userKey: string): Promise<void> {
        await this.usersApi.deleteUser(userKey);
    }

    public async promoteToAdmin(user: User): Promise<void> {
        await this.usersApi.createCompanyAdmin(user.companyKey, user.key);
    }

    public static mapUserToDomain(representation: UserRepresentation, isAdmin: (userKey: string) => boolean): User {
        return {
            username: representation.username,
            image: representation.image,
            firstname: representation.firstname || '',
            lastname: representation.lastname || '',
            email: representation.email,
            companyKey: representation.companyKey,
            key: representation.key,
            companyName: representation.companyName || '',
            portfolioKey: representation.portfolioKey || '',
            role: isAdmin(representation.key) ? 'admin' : 'user',
            password: undefined,
        };
    }
}
