import type { ParksRepository, Park } from '@/modules/ctx-documents';
import { ParksApi } from '@/modules/shared/adapter';

export class ParksRepositoryImpl implements ParksRepository {
    private readonly parksApi: ParksApi;

    constructor(apis: {
        parks: ParksApi;
    }) {
        this.parksApi = apis.parks;
    }

    public async getParks(): Promise<Park[]> {
        const representations = await this.parksApi.getParks();
        return representations.map((it) => ({
            key: it.key,
            name: it.name,
        }));
    }
}
