import { Configuration, AuthApi as AuthRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type { LicenseApi, FeatureRepresentation, LicenseRepresentation } from '@/modules/shared/adapter/LicenseApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class CachedLicenseRestApi implements LicenseApi {

    private readonly authApi: AuthRestApi;
    private readonly featuresCache: IndexedDBRepository<string, FeatureRepresentation>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });

        this.authApi = new AuthRestApi(restApiConfig);
        this.featuresCache = new IndexedDBRepository<string, FeatureRepresentation>(indexedDb, 'features');
    }

    public async getFeatures(): Promise<FeatureRepresentation[]> {
        return await this.featuresCache.findAllIfAnyCached() || this.fetchFeatures();
    }

    private async fetchFeatures(): Promise<FeatureRepresentation[]> {
        return AsyncDebouncer.debounce<FeatureRepresentation[]>('CachedLicenseRestApi.fetchFeatures', async () => {
            const features = await this.authApi.getFeatures();
            await this.featuresCache.saveAll(features);
            return features;
        });
    }

    public async getLicense(key: string): Promise<LicenseRepresentation> {
        return AsyncDebouncer.debounce<LicenseRepresentation>(`CachedLicenseRestApi.getLicense(${key})`, async () => {
            const license = this.authApi.getLicense(key);
            return license;
        });
    }
}
