

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ChartNumberParametered extends Vue {

    @Prop({ default: 'MW' })
    public readonly unit!: string;

    public get value(): string {
        switch (this.unit) {
            case 'm/s': return '7,23';
            case 'KW':
            case 'kW': return '1.680';
            case 'kWh': return '3.290';
            case '%': return '98,1';
            case 'rpm':
            case '°':
            case '°C':
            case 'kVAh':
            case 'V':
            case 'A':
            case 'Hz':
            case 'h':
            case 'mm/h':
            case 'g/m3':
            case 'bar':
            case 'EUR':
            case '€':
            case 'ct/kWh':
            case 'kg':
            case 'km':
            default: return '32,84';
        }
    }
}

