import _Vue from 'vue';

const breakpoints = require('@/assets/js/config/Breakpoints.json');
const colors = require('@/assets/js/config/Colors.json');

const ConfigPlugin = {
    install(vue: typeof _Vue) {
        const globalData = new _Vue({
            data: {
                $md: false,
            },
        });

        vue.prototype.$breakpoints = breakpoints;
        vue.prototype.$colors = colors;

        vue.mixin({
            computed: {
                $mx: { get: () => globalData.$data.$xs },
                $sm: { get: () => globalData.$data.$sm },
                $md: { get: () => globalData.$data.$md },
                $lg: { get: () => globalData.$data.$lg },
                $xl: { get: () => globalData.$data.$xl },
            },
        });
        this.calcBreakpoints(globalData);
        window.addEventListener('resize', () => this.calcBreakpoints(globalData), { passive: true });
    },

    calcBreakpoints(vue: _Vue) {
        vue.$data.$xs = window.innerWidth > breakpoints.xs;
        vue.$data.$sm = window.innerWidth > breakpoints.sm;
        vue.$data.$md = window.innerWidth > breakpoints.md;
        vue.$data.$lg = window.innerWidth > breakpoints.lg;
        vue.$data.$xl = window.innerWidth > breakpoints.xl;
    },
};

_Vue.use(ConfigPlugin);

// add type definitions for typescript compiler
declare module 'vue/types/vue' {
    interface Vue {
        $breakpoints: any;
        $colors: any;
        $xs: boolean;
        $sm: boolean;
        $md: boolean;
        $lg: boolean;
        $xl: boolean;
    }
}
