import { v4 as generateUUID } from 'uuid';
import { Aggregation, Resolution, ResourceType, LicenseFeature } from '@/modules/shared/types';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import type { WidgetPreset } from '@/components/widget-wizard';
import { Port } from '@/modules/shared/Port';
import Utils from '@/assets/js/utils/Utils';

export default class TimeChartProceedsAndCosts implements WidgetPreset {

    public readonly name: string = 'time-balance-overview';
    public readonly requireLicenseFeatures: LicenseFeature[] = [LicenseFeature.ACCOUNTING];
    public readonly type: WidgetType = WidgetType.TimeChart;
    public readonly quickAdd: boolean = true;
    public readonly order: number = 60;
    public readonly hidden: boolean = true;
    public readonly metrics: string[] = [
        'b130b3e6-906f-40a1-8c3b-2dd70c8ec9b8',
        '46c23c95-2bd8-400f-9080-c4055a6648a8',
        '66cb1658-d01b-4dc7-8beb-1400ef8d47d7',
    ];

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        // TODO should be shareholder
        const resources = [{
            uuid: generateUUID().substring(0, 8),
            seriesName: '',
            resourceName: (await Port.portfolios.getPortfolioByKey(params.portfolio))?.name || '',
            type: ResourceType.Portfolio,
            resourceKey: params.portfolio,
            aggregationOverGenerators: Aggregation.Sum,
            config: {},
        }];
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {},
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: 'this-year',
            intervalTo: undefined,
            intervalFrom: undefined,
            resolution: Resolution.Monthly,
            axis: [{
                unit: '',
                name: '',
                config: {
                    type: 'line',
                },
                metrics: [{
                    uuid: generateUUID().substring(0, 8),
                    metricKey: 'b130b3e6-906f-40a1-8c3b-2dd70c8ec9b8',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Sum,
                    resources: Utils.deepCopy(resources),
                }, {
                    uuid: generateUUID().substring(0, 8),
                    metricKey: '46c23c95-2bd8-400f-9080-c4055a6648a8',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Sum,
                    resources: Utils.deepCopy(resources),
                }],
            }, {
                unit: '',
                name: '',
                config: {
                    type: 'area',
                },
                metrics: [{
                    uuid: generateUUID().substring(0, 8),
                    metricKey: '66cb1658-d01b-4dc7-8beb-1400ef8d47d7',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Sum,
                    resources: Utils.deepCopy(resources),
                }],
            }],
        };
    }
}
