
import { Component, Vue } from 'vue-property-decorator';
import type { TranslateResult } from 'vue-i18n';
import Tabs from '@/components/basics/Tabs.vue';
import LoadingAnimation from '@/modules/shared/components/loading-animation/LoadingAnimation.vue';
import { Role, LicenseFeature } from '@/modules/shared/types';
import MissingLicenseDlg from '@/modules/shared/components/dialogs/generic/MissingLicenseDlg.vue';

interface SettingsTab {
    title: string | TranslateResult;
    route: string;
    role?: Role;
    license?: LicenseFeature[];
    order: number;
    category?: string;
}

@Component({
    components: { MissingLicenseDlg, LoadingAnimation, Tabs },
})
export default class SettingsTabs extends Vue {

    private showMissingLicenseDlg: boolean = false;

    private get disabledTabs(): SettingsTab[] {
        return this.tabsForUserRole.filter((tab) => tab.license && !this.$store.getters['User/hasAllFeatureLicenses'](tab.license));
    }

    private get tabsForUserRole(): SettingsTab[] {
        return this.tabs
            .filter((tab) => !tab.role || this.$store.getters['User/hasRole'](tab.role))
            .filter((tab) => tab.category === this.activeTab?.category);
    }

    private get tabs(): SettingsTab[] {
        return this.$router.getRoutes()
            .filter((route) => route.meta.settingsTabs)
            .map((route) => ({
                title: this.$t(`tab.${route.name}`),
                route: route.name!,
                role: route.meta.role,
                license: route.meta.license,
                order: route.meta.settingsTabsOrder || 100,
                category: route.meta.settingsTabsGroup,
            }))
            .sort((a, b) => a.order - b.order);
    }

    private get activeTab(): SettingsTab|null {
        return this.tabs.find((tab) => tab.route === this.$route.name) || null;
    }

    private navigate(tab: SettingsTab) {
        this.$router.push({ name: tab.route });
    }
}
