/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Resolution,
    ResolutionFromJSON,
    ResolutionFromJSONTyped,
    ResolutionToJSON,
    TemplateVisibility,
    TemplateVisibilityFromJSON,
    TemplateVisibilityFromJSONTyped,
    TemplateVisibilityToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetTemplateRepresentation
 */
export interface WidgetTemplateRepresentation {
    /**
     * 
     * @type {string}
     * @memberof WidgetTemplateRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetTemplateRepresentation
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetTemplateRepresentation
     */
    preset: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetTemplateRepresentation
     */
    title: string;
    /**
     * 
     * @type {Resolution}
     * @memberof WidgetTemplateRepresentation
     */
    resolution: Resolution;
    /**
     * 
     * @type {string}
     * @memberof WidgetTemplateRepresentation
     */
    intervalName: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetTemplateRepresentation
     */
    intervalFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetTemplateRepresentation
     */
    intervalTo?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetTemplateRepresentation
     */
    metricKeys: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WidgetTemplateRepresentation
     */
    category: string;
    /**
     * 
     * @type {TemplateVisibility}
     * @memberof WidgetTemplateRepresentation
     */
    visibility?: TemplateVisibility;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetTemplateRepresentation
     */
    requiredLicenseFeatures?: Array<string>;
}

export function WidgetTemplateRepresentationFromJSON(json: any): WidgetTemplateRepresentation {
    return WidgetTemplateRepresentationFromJSONTyped(json, false);
}

export function WidgetTemplateRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetTemplateRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'type': json['type'],
        'preset': json['preset'],
        'title': json['title'],
        'resolution': ResolutionFromJSON(json['resolution']),
        'intervalName': json['intervalName'],
        'intervalFrom': !exists(json, 'intervalFrom') ? undefined : json['intervalFrom'],
        'intervalTo': !exists(json, 'intervalTo') ? undefined : json['intervalTo'],
        'metricKeys': json['metricKeys'],
        'category': json['category'],
        'visibility': !exists(json, 'visibility') ? undefined : TemplateVisibilityFromJSON(json['visibility']),
        'requiredLicenseFeatures': !exists(json, 'requiredLicenseFeatures') ? undefined : json['requiredLicenseFeatures'],
    };
}

export function WidgetTemplateRepresentationToJSON(value?: WidgetTemplateRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'type': value.type,
        'preset': value.preset,
        'title': value.title,
        'resolution': ResolutionToJSON(value.resolution),
        'intervalName': value.intervalName,
        'intervalFrom': value.intervalFrom,
        'intervalTo': value.intervalTo,
        'metricKeys': value.metricKeys,
        'category': value.category,
        'visibility': TemplateVisibilityToJSON(value.visibility),
        'requiredLicenseFeatures': value.requiredLicenseFeatures,
    };
}


