import {
    Configuration,
    DocumentsApi as GeneratedDocumentsRestApi,
} from '@/clients/dashboardapi/v2';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type { DocumentsApi, DocumentRepresentation, GetDocumentsRequest } from '@/modules/shared/adapter/DocumentsApi';
import { AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class DocumentsRestApi implements DocumentsApi {

    private readonly documentsDownloadApi: GeneratedDocumentsRestApi;
    private readonly documentsBatchDownloadApi: GeneratedDocumentsRestApi;
    private readonly documentsApi: GeneratedDocumentsRestApi;

    constructor(apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
            headers: { 'Accept': 'application/json' },
        });
        this.documentsApi = new GeneratedDocumentsRestApi(restApiConfig);

        const downloadApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
            headers: { 'Accept': 'application/pdf' },
        });
        this.documentsDownloadApi = new GeneratedDocumentsRestApi(downloadApiConfig);

        const downloadBatchApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
            headers: { 'Accept': 'application/zip' },
        });
        this.documentsBatchDownloadApi = new GeneratedDocumentsRestApi(downloadBatchApiConfig);
    }

    public async getDocuments(filter?: GetDocumentsRequest): Promise<DocumentRepresentation[]> {
        return this.documentsApi.getDocuments(
            filter?.chunkSize,
            filter?.chunkOffset,
            filter?.text,
            filter?.from,
            filter?.until,
            filter?.allGenerators,
            filter?.allTags,
            filter?.category,
        );
    }

    public async getDocumentByKey(key: string): Promise<DocumentRepresentation> {
        return this.documentsApi.getDocument(key);
    }

    public async deleteDocumentWarning(docKey: string): Promise<void> {
        return this.documentsApi.deleteDocumentWarning(docKey);
    }

    public async deleteDocument(docKey: string): Promise<void> {
        return this.documentsApi.deleteDocument(docKey);
    }

    public async assignCompany(docKey: string, companyKey: string): Promise<void> {
        return this.documentsApi.assignCompany(docKey, { companyKey: companyKey });
    }

    public async removeCompany(docKey: string, companyKey: string): Promise<void> {
        return this.documentsApi.removeCompany(docKey, companyKey);
    }

    public async getAssignedCompanyKeys(docKey: string): Promise<string[]> {
        const representations = await this.documentsApi.getAssignedCompanyKeys(docKey);
        return representations.map((it) => it.companyKey);
    }

    public async getDocumentAsPdf(docKey: string): Promise<Blob> {
        return this.documentsDownloadApi.downloadDocument(docKey);
    }

    public async getDocumentsAsZip(docKeys: string[]): Promise<Blob> {
        return this.documentsBatchDownloadApi.downloadDocuments(docKeys);
    }

    public async getDocumentTypes(): Promise<string[]> {
        return this.documentsApi.getDocumentCategories();
    }

    public async retryDocument(docKey: string): Promise<void> {
        return this.documentsApi.retry(docKey);
    }
}
