import type { UserRepository } from '@/modules/ctx-documents';
import type { Role } from '@/modules/shared/types';

export class UserService {
    private readonly userRepository: UserRepository;

    constructor(params: {
        userRepository: UserRepository;
    }) {
        this.userRepository = params.userRepository;
    }

    public async getUserRole(): Promise<Role> {
        return this.userRepository.getUserRole();
    }
}
