import DefaultI18nRepository from '@/plugins/modules/default/DefaultI18nRepository';
import Vue from 'vue';
import type VueRouter from 'vue-router';
import { Module, ModuleOptions } from '@/modules/ctx-documents';
import { Port } from '@/modules/shared/Port';
import { DefaultUserRepository } from '@/plugins/modules/default/DefaultUserRepository';
import { ReportRepositoryImpl } from '@/plugins/modules/ctx-documents/ReportsRepositoryImpl';
import { DocumentsRepositoryImpl } from './DocumentsRepositoryImpl';
import { ParksRepositoryImpl } from './ParksRepositoryImpl';
import { GeneratorsRepositoryImpl } from './GeneratorsRepositoryImpl';
import { CompaniesRepositoryImpl } from './CompaniesRepositoryImpl';
import { LicenseRepositoryImpl } from './LicenseRepositoryImpl';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        routes: [],
        router: router,
        documentsRepository: new DocumentsRepositoryImpl({
            documents: Port.documents,
            companies: Port.companies,
            users: Port.users,
            auth: Port.auth,
        }),
        parksRepository: new ParksRepositoryImpl({
            parks: Port.parks,
        }),
        generatorsRepository: new GeneratorsRepositoryImpl({
            generators: Port.generators,
            parks: Port.parks,
        }),
        companiesRepository: new CompaniesRepositoryImpl({
            companies: Port.companies,
        }),
        userRepository: new DefaultUserRepository({
            license: Port.license,
            auth: Port.auth,
        }),
        licenseRepository: new LicenseRepositoryImpl({
            license: Port.license,
        }),
        i18nRepository: new DefaultI18nRepository({
            localizations: Port.localizations,
        }),
        reportsRepository: new ReportRepositoryImpl({
            reports: Port.reports,
        }),
    });
}
