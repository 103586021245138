import { Configuration, DataApi as DataRestApi } from '@/clients/dashboardapi/v2';
import type { DataChangedEvent } from '@/modules/shared/types';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type {
    ImportsApi,
    ReImportMarkerRepresentation,
    ReImportRequest,
    DeleteReImportRequest,
} from '@/modules/shared/adapter/ImportsApi';
import { AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

export class ImportsRestApi implements ImportsApi {

    private readonly dataApi: DataRestApi;
    private readonly changeListeners: ((event: DataChangedEvent) => Promise<void>)[] = [];

    constructor(apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.dataApi = new DataRestApi(restApiConfig);
    }

    public onImportsChange(cb: (event: DataChangedEvent) => Promise<void>): void {
        this.changeListeners.push(cb);
    }

    public async createDataReImport(reImportRequest: ReImportRequest): Promise<void> {
        await this.dataApi.createDataReImport(reImportRequest);
        this.changeListeners.forEach((cb) => cb({ action: 'update', keys: [reImportRequest.generatorKey] }));
    }

    public async getPendingDataReImports(): Promise<ReImportMarkerRepresentation[]> {
        return this.dataApi.getPendingDataReImports();
    }

    public async deleteDataReImports(request: DeleteReImportRequest): Promise<void> {
        await this.dataApi.deleteDataReImports(request);
        this.changeListeners.forEach((cb) => cb({ action: 'remove', keys: request.generatorKeys }));
    }
}
