import { Port } from '@/modules/shared/Port';
import { MessageProvider } from './MessageProvider';

export class ServiceMessageProvider extends MessageProvider {

    public async loadMessages(lang: string): Promise<void> {
        try {
            const messages: { [key: string]: string } = await Port.localizations.loadTextsForLanguage(lang);
            this.appendMessages(lang, messages);
        } catch (e: any) {
            console.warn(`Failed to load service messages for language ${lang}`);
        }
    }
}
