import type { TemplatesRepository, UserRepository } from '@/modules/ctx-portfolios/adapter';
import type { PortfolioTemplate } from '@/modules/ctx-portfolios/types';
import NotificationService from '@/assets/js/services/NotificationService';
import { i18n } from '@/plugins/i18n';
import { Role, LicenseFeature } from '@/modules/shared/types';

export class TemplatesService {

    private readonly templatesRepository: TemplatesRepository;
    private readonly userRepository: UserRepository;

    constructor(params: {
        templatesRepository: TemplatesRepository,
        userRepository: UserRepository,
    }) {
        this.templatesRepository = params.templatesRepository;
        this.userRepository = params.userRepository;
    }

    public onChange(cb: () => Promise<void>): void {
        this.templatesRepository.onChange(cb);
    }

    public async getEditablePortfolioTemplates(): Promise<PortfolioTemplate[]> {
        try {
            const userCompany = await this.userRepository.getUserCompanyKey();
            const templates = await this.templatesRepository.getTemplates();
            return templates.filter((t) => t.createdByCompany === userCompany);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async getPortfolioTemplates(): Promise<PortfolioTemplate[]> {
        try {
            return await this.templatesRepository.getTemplates();
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async hasRoleToCreatePortfolioTemplates(): Promise<boolean> {
        const role = await this.userRepository.getUserRole();
        return role === Role.COMPANY_ADMIN || role === Role.HALVAR_ADMIN;
    }

    public async hasLicenseFeatureToCreatePortfolioTemplates(): Promise<boolean> {
        return this.userRepository.getUserHasLicenseFeature(LicenseFeature.ADVANCED);
    }

    public async createPortfolioTemplate(name: string, description: string, portfolioKey: string): Promise<void> {
        try {
            let companyKey: string|undefined = await this.userRepository.getUserCompanyKey();
            if (companyKey === process.env.VUE_APP_ADMIN_COMPANY) {
                companyKey = undefined;
            }
            await this.templatesRepository.createTemplate(name, description, portfolioKey, companyKey);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async updatePortfolioTemplate(template: PortfolioTemplate): Promise<void> {
        try {
            await this.templatesRepository.updateTemplate(template);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async validatePortfolioTemplate(template: Partial<PortfolioTemplate>): Promise<{[key: string]: string[]}> {
        const userCompany = await this.userRepository.getUserCompanyKey();
        const errors: {[key: string]: string[]} = {};
        if (!template.name || template.name.trim().length === 0) {
            errors.name = [i18n.t('ctx-portfolios.validation.template.name-required').toString()];
        }
        const templates = await this.getPortfolioTemplates();
        if (templates.find((it) => it.name === template.name && it.key !== template.key && it.createdByCompany === userCompany)) {
            errors.name = [i18n.t('ctx-portfolios.validation.template.name-duplicate', { name: template.name }).toString()];
        }
        return errors;
    }

    public async deletePortfolioTemplate(template: PortfolioTemplate): Promise<void> {
        try {
            await this.templatesRepository.deleteTemplate(template);
        } catch (e: any) {
            NotificationService.serviceError(e);
            throw e;
        }
    }
}
