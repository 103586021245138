/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Resolution,
    ResolutionFromJSON,
    ResolutionFromJSONTyped,
    ResolutionToJSON,
    SimpleDataRequestResource,
    SimpleDataRequestResourceFromJSON,
    SimpleDataRequestResourceFromJSONTyped,
    SimpleDataRequestResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface PieDataRequest
 */
export interface PieDataRequest {
    /**
     * 
     * @type {number}
     * @memberof PieDataRequest
     */
    timeFrom: number;
    /**
     * 
     * @type {number}
     * @memberof PieDataRequest
     */
    timeTo: number;
    /**
     * 
     * @type {Resolution}
     * @memberof PieDataRequest
     */
    resolution: Resolution;
    /**
     * 
     * @type {string}
     * @memberof PieDataRequest
     */
    metricKey: string;
    /**
     * 
     * @type {number}
     * @memberof PieDataRequest
     */
    threshold?: number;
    /**
     * 
     * @type {Array<SimpleDataRequestResource>}
     * @memberof PieDataRequest
     */
    resources: Array<SimpleDataRequestResource>;
}

export function PieDataRequestFromJSON(json: any): PieDataRequest {
    return PieDataRequestFromJSONTyped(json, false);
}

export function PieDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PieDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeFrom': json['timeFrom'],
        'timeTo': json['timeTo'],
        'resolution': ResolutionFromJSON(json['resolution']),
        'metricKey': json['metricKey'],
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
        'resources': ((json['resources'] as Array<any>).map(SimpleDataRequestResourceFromJSON)),
    };
}

export function PieDataRequestToJSON(value?: PieDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeFrom': value.timeFrom,
        'timeTo': value.timeTo,
        'resolution': ResolutionToJSON(value.resolution),
        'metricKey': value.metricKey,
        'threshold': value.threshold,
        'resources': ((value.resources as Array<any>).map(SimpleDataRequestResourceToJSON)),
    };
}


