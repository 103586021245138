
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import LoadingAnimation from '@/modules/shared/components/loading-animation/LoadingAnimation.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';

@Component({
    components: { LoadingAnimationSmall, LoadingAnimation },
})
export default class Tabs<T> extends Vue {

    @Prop({ default: () => [] })
    public readonly tabs!: T[];

    @Prop({ default: () => [] })
    public readonly disabledTabs!: T[];

    @Prop({ default: '' })
    public readonly tabbarClass!: string;

    @Prop({ default: null })
    public readonly value!: T|null;

    @Prop({ default: null })
    public readonly focusTab!: T|null;

    @Prop({ default: false })
    public readonly loading!: boolean;

    @Ref('tabbar')
    private readonly tabbar!: HTMLDivElement|undefined;

    private activeTab: T|null = null;
    private showLeftScrollIndicator: boolean = false;
    private showRightScrollIndicator: boolean = false;

    public created(): void {
        this.activeTab = this.value;
    }

    public mounted(): void {
        if (this.activeTab !== null) {
            this.$nextTick(() => this.scrollToVisible(this.selectedIndex));
        } else {
            // this.selectTab(0);
        }
        this.showScrollIndicators();
        setTimeout(() => this.showScrollIndicators(), 10);
        window.addEventListener('resize', this.onResize, { passive: true });
    }

    private get selectedIndex(): number {
        return this.tabs.findIndex((tab) => tab === this.activeTab);
    }

    private onResize() {
        this.showScrollIndicators();
        const index = this.tabs.findIndex((tab) => tab === this.activeTab);
        this.$nextTick(() => this.scrollToVisible(index));
    }

    @Watch('value')
    private onValueChanged() {
        this.activeTab = this.value;
        this.$nextTick(() => this.scrollToVisible(this.selectedIndex));
    }

    @Watch('tabs')
    @Watch('loading')
    private async showScrollIndicators() {
        await this.$nextTick();
        if (this.tabbar) {
            const buffer = 2;
            const scrollStart = this.tabbar.scrollLeft;
            const scrollEnd = scrollStart + this.tabbar.offsetWidth + buffer;
            this.showLeftScrollIndicator = scrollStart > buffer;
            this.showRightScrollIndicator = scrollEnd < this.tabbar.scrollWidth;
        }
    }

    private isDisabled(tab: any) {
        return this.disabledTabs.includes(tab);
    }

    private selectTab(index: number) {
        if (this.activeTab !== this.tabs[index]) {
            this.activeTab = this.tabs[index];
            this.$emit('input', this.activeTab);
            this.$emit('tabchanged', this.activeTab);
            this.$nextTick(() => this.scrollToVisible(index));
        }
    }

    private scrollToVisible(index: number) {
        const tabbar = this.$refs.tabbar as HTMLElement|undefined;
        const tabs = this.$refs.tabs as HTMLElement[]|undefined;
        if (tabbar && tabs && tabs[index]) {
            const tab = tabs[index];
            const tabStart = tab.offsetLeft - tabbar.offsetLeft;
            const scrollWidth = tabbar.getBoundingClientRect().width;
            tabbar.scroll({ behavior: 'smooth', left: tabStart - scrollWidth / 2 });
        }
    }

    private selectPrevious() {
        if (this.selectedIndex > 0) {
            this.selectTab(this.selectedIndex - 1);
        }
    }

    private selectNext() {
        if (this.selectedIndex < this.tabs.length - 1) {
            this.selectTab(this.selectedIndex + 1);
        }
    }

    private back() {
        this.$emit('input:focus', null);
    }
}
