import type { LicenseFeature, LicenseRepository } from '@/modules/ctx-admin-customers';
import type { FeatureRepresentation } from '@/clients/dashboardapi/v2';
import type { LicenseApi } from '@/modules/shared/adapter/LicenseApi';

export class LicenseRepositoryImpl implements LicenseRepository {

    private readonly licenseApi: LicenseApi;

    constructor(apis: {
        license: LicenseApi;
    }) {
        this.licenseApi = apis.license;
    }

    public async getLicenseFeatures(): Promise<LicenseFeature[]> {
        const features = await this.licenseApi.getFeatures();
        return features.map(LicenseRepositoryImpl.mapLicenseFeature);
    }

    private static mapLicenseFeature(feature: FeatureRepresentation): LicenseFeature {
        return {
            key: feature.key,
            name: feature.name,
        };
    }

}
