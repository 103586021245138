export enum WidgetWizardTab {
    // generic tabs
    Metrics = 'metrics',
    Axis = 'axis',
    Data = 'data',
    Resolution = 'resolution',
    AggregationTime = 'aggregation-time',
    AggregationDatasources = 'aggregation-datasources',
    Time = 'time',
    General = 'general',

    // special tabs
    Availability = 'availability',
    Commissions = 'comissions',
    Distribution = 'distribution',
    TimeNumber = 'time-number',
    PowerCurve = 'powercurve',
    LogEvents = 'log-events',
    Note = 'note',
    OperatorForecast = 'operator-forecast',
    Map = 'map',
    Table = 'table',
}
