import NotificationService from '@/assets/js/services/NotificationService';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import DateUtils from '@/assets/js/utils/DateUtils';
import Formatter from '@/assets/js/utils/Formatter';
import TimeIntervals from '@/assets/js/utils/TimeIntervals';
import Validator, { Rules } from '@/assets/js/utils/Validator';
import type { ReportConfigRepository, ReportLayoutGenerator } from '@/modules/ctx-report-preview/adapter';
import type { ReportConfig } from '@/modules/ctx-report-preview/types';
import { Layout, ReportType } from '@/modules/ctx-report-preview/types';
import { i18n } from '@/plugins/i18n';

export class ReportService {
    private readonly reportConfigRepository: ReportConfigRepository;
    private readonly reportLayoutGenerator: ReportLayoutGenerator;

    constructor(params: {
        reportConfigRepository: ReportConfigRepository;
        reportLayoutGenerator: ReportLayoutGenerator;
    }) {
        this.reportConfigRepository = params.reportConfigRepository;
        this.reportLayoutGenerator = params.reportLayoutGenerator;
    }

    public async getReportConfig(dashboardKey: string, currentLocale: string): Promise<ReportConfig> {
        let report = await this.reportConfigRepository.getReportConfig(dashboardKey);
        if (!report) {
            report = await this.reportConfigRepository.createReportConfig(dashboardKey, currentLocale);
        }
        report.defaultTitle = await this.getAutomaticReportTitle(report);
        report.defaultSubTitle = await this.getAutomaticReportSubtitle(report);
        if (report.layouts.length === 0) {
            // generate layouts
            report.layouts = await this.reportLayoutGenerator.generateLayout(dashboardKey);
            report.layoutGenerated = true;
        } else {
            const layoutsWithoutWidgets = [Layout.CoverPage, Layout.TableOfContent, Layout.Summary];
            report.layouts = report.layouts
                .filter((l) => layoutsWithoutWidgets.includes(l.layout) || l.widgets.find((w) => w !== undefined));
        }
        return report;
    }

    public async saveReportConfig(reportConfig: ReportConfig): Promise<ReportConfig> {
        try {
            const report = await this.reportConfigRepository.saveReportConfig(reportConfig);
            report.defaultTitle = await this.getAutomaticReportTitle(report);
            report.defaultSubTitle = await this.getAutomaticReportSubtitle(report);
            return report;
        } catch (e) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async saveReportLayout(reportConfig: ReportConfig): Promise<ReportConfig> {
        try {
            // remove empty layouts
            reportConfig.layouts = reportConfig.layouts.filter((layout) => layout.widgets.filter(ArrayUtils.filterUndefined).length > 0);
            const report = await this.reportConfigRepository.saveReportLayout(reportConfig);
            report.defaultTitle = await this.getAutomaticReportTitle(report);
            report.defaultSubTitle = await this.getAutomaticReportSubtitle(report);
            return report;
        } catch (e) {
            NotificationService.serviceError(e);
            throw e;
        }
    }

    public async generateReport(reportConfig: ReportConfig, language: string, referenceDate?: Date): Promise<void> {
        try {
            await this.reportConfigRepository.generateAndStoreReport(reportConfig.key, language, referenceDate);
        } catch (e: any) {
            NotificationService.serviceError(e);
        }
    }

    public async getAutomaticReportTitle(report: ReportConfig): Promise<string> {
        switch (report.reportType) {
            case ReportType.Month: return i18n.t('ctx-reporting.title.monthly', { portfolio: report.portfolioName }).toString();
            case ReportType.LastMonth: return i18n.t('ctx-reporting.title.monthly', { portfolio: report.portfolioName }).toString();
            case ReportType.PenultimateMonth: return i18n.t('ctx-reporting.title.monthly', { portfolio: report.portfolioName }).toString();
            case ReportType.LastYear: return i18n.t('ctx-reporting.title.annual', { portfolio: report.portfolioName }).toString();
            case ReportType.Year: return i18n.t('ctx-reporting.title.annual', { portfolio: report.portfolioName }).toString();
            default: return i18n.t('ctx-reporting.title.default', { portfolio: report.portfolioName }).toString();
        }
    }

    public printToPdf(): void {
        window.print();
    }

    public async getAutomaticReportSubtitle(report: ReportConfig): Promise<string> {
        const referenceDate = TimeIntervals.getReferenceDate().date;
        switch (report.reportType) {
            case ReportType.Month: {
                return Formatter.formatDate(referenceDate, 'MMMM yyyy');
            }
            case ReportType.Year: {
                return Formatter.formatDate(referenceDate, 'yyyy');
            }
            case ReportType.LastMonth: {
                const date = DateUtils.subtract(referenceDate, { months: 1 });
                return Formatter.formatDate(date, 'MMMM yyyy');
            }
            case ReportType.PenultimateMonth: {
                const date = DateUtils.subtract(referenceDate, { months: 2 });
                return Formatter.formatDate(date, 'MMMM yyyy');
            }
            case ReportType.LastYear: {
                const date = DateUtils.subtract(referenceDate, { years: 1 });
                return Formatter.formatDate(date, 'yyyy');
            }
            default: return report.dashboardName;
        }
    }

    public validateEmail(email: string, reportConfig: ReportConfig): string[] {
        return Validator
            .addRule('email', [
                Rules.ValidEmailOrEmpty,
                Rules.ValueNotInBlacklist(reportConfig.reportEmails, i18n.t('ctx-reporting.validation.duplicate-email').toString()),
            ])
            .validate({ email: email })
            .email || [];
    }

    public validateReportConfig(reportConfig: ReportConfig): {[key: string]: string[]} {
        const validator = Validator
            .addRule('title', [])
            .addRule('subTitle', []);

        if (reportConfig.generateAutomatically) {
            validator
                .addRule('generateAt', [Rules.NotNullOrEmpty, Rules.ValidDate])
                .addRule('generateInterval', [Rules.NotNullOrEmpty])
                .addRule('generateForLanguage', [Rules.NotNullOrEmpty]);
        }
        return validator.validate(reportConfig);
    }

    public calculatePageScale(paddingPx: number = 0): number {
        // outerWidth is smaller on mobile
        // innerWidth is smaller on desktop with open devtools
        // outerWidth is 0 on headless chrome, so we need a fallback to innerwidth here
        const availableWidth = (Math.min(window.outerWidth, window.innerWidth) || window.innerWidth) - paddingPx;
        let preferredWidth = 297;
        const pageWithHelper = document.getElementById('mm-to-pixel-helper') as HTMLElement;
        if (pageWithHelper) {
            preferredWidth = pageWithHelper.clientWidth * 1.1;
        }

        let scale = 1;
        if (preferredWidth > availableWidth) {
            scale = availableWidth / preferredWidth;
        }
        return scale;
    }
}
