import Utils from '@/assets/js/utils/Utils';
import type { LayoutConfig } from '@/modules/ctx-report-preview/types';
import { Layout } from '@/modules/ctx-report-preview/types';
import type { WidgetRepository } from '../WidgetRepository';
import type { ReportLayoutGenerator } from '../ReportLayoutGenerator';

export class SimpleReportLayoutGenerator implements ReportLayoutGenerator {

    private readonly widgetsRepository: WidgetRepository;

    constructor(params: {
        widgetsRepository: WidgetRepository,
    }) {
        this.widgetsRepository = params.widgetsRepository;
    }

    public async generateLayout(dashboardKey: string): Promise<LayoutConfig[]> {
        console.log('Generating report layout with simple layout generator');
        let widgets = await this.widgetsRepository.getWidgetsForDashboard(dashboardKey);
        const widgetOrder = await this.widgetsRepository.getWidgetOrder(dashboardKey);
        widgets = widgets.sort((a, b) => widgetOrder.indexOf(a.key) - widgetOrder.indexOf(b.key));
        const layouts: LayoutConfig[] = [
            { key: 'cover', layout: Layout.CoverPage, widgets: [], position: 0, editable: false },
            { key: 'toc', layout: Layout.TableOfContent, widgets: [], position: 10, editable: false },
            { key: 'summary', layout: Layout.Summary, widgets: [], position: 20, editable: false },
        ];
        widgets.forEach((widget, index) => {
            if (widget.supportsChartView) {
                const page = layouts.length * 10;
                layouts.push({
                    key: Utils.generateUUID(),
                    layout: Layout.Layout1,
                    position: page,
                    editable: true,
                    widgets: [{
                        page: page,
                        position: 0,
                        widgetKey: widget.key,
                        displayMode: 'chart',
                        widgetName: widget.name,
                        widgetType: widget.type,
                        interval: widget.interval,
                    }],
                });
            }
            if (widget.supportsTableView) {
                const page = layouts.length * 10;
                layouts.push({
                    key: Utils.generateUUID(),
                    layout: Layout.Layout1,
                    position: layouts.length * 10,
                    editable: true,
                    widgets: [{
                        page: page,
                        position: 0,
                        widgetKey: widget.key,
                        displayMode: 'table',
                        widgetName: widget.name,
                        widgetType: widget.type,
                        interval: widget.interval,
                    }],
                });
            }
        });

        layouts.forEach((layout) => layout.hash = Utils.hash(layout));
        return layouts;
    }
}
