import type { GeneratorRepository, Generator } from '@/modules/ctx-dashboard';
import type { GeneratorRepresentation, GeneratorsApi, ParksApi, PortfoliosApi } from '@/modules/shared/adapter';

export class GeneratorRepositoryImpl implements GeneratorRepository {

    private readonly generatorsApi: GeneratorsApi;
    private readonly parksApi: ParksApi;
    private readonly portfoliosApi: PortfoliosApi;

    constructor(apis: {
        generators: GeneratorsApi,
        parks: ParksApi,
        portfolios: PortfoliosApi,
    }) {
        this.generatorsApi = apis.generators;
        this.parksApi = apis.parks;
        this.portfoliosApi = apis.portfolios;
    }

    public async getGenerators(portfolioKey: string|null): Promise<Generator[]> {
        let generators: GeneratorRepresentation[] = [];
        if (portfolioKey) {
            const portfolio = await this.portfoliosApi.getPortfolioByKey(portfolioKey);
            generators = await this.generatorsApi.getGeneratorsByKeys(portfolio?.generatorKeys || []);
        } else {
            generators = await this.generatorsApi.getGenerators();
        }
        return Promise.all(generators.map((gen) => this.mapGeneratorToDomain(gen)));
    }

    public async getGeneratorByKey(generatorKey: string): Promise<Generator|undefined> {
        const generator = await this.generatorsApi.getGeneratorByKey(generatorKey);
        return generator ? this.mapGeneratorToDomain(generator) : undefined;
    }

    public onGeneratorsChange(cb: () => Promise<void>): void {
        this.generatorsApi.onGeneratorsChanged(cb);
    }

    private async mapGeneratorToDomain(representation: GeneratorRepresentation): Promise<Generator> {
        const park = await this.parksApi.getParkByKey(representation.parkKey!);
        return {
            key: representation.key,
            name: representation.name,
            parkName: park?.name || '',
        };
    }
}
