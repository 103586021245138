
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Utility Vue component to detect scroll intersection of a div
 */
@Component({})
export default class IntersectionObserverComponent extends Vue {

    @Prop({ default: 'div' })
    public readonly tag!: string;

    private observer: IntersectionObserver = new IntersectionObserver(this.intersect);

    public mounted() {
        this.observer.observe(this.rootElement);
    }

    public destroyed() {
        this.observer.disconnect();
    }

    private get rootElement() {
        return this.$refs.root as HTMLElement;
    }

    private intersect() {
        this.$emit('intersect', this.rootElement);
    }
}
