
import { Component, Vue } from 'vue-property-decorator';
import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import OnboardingTabStart from '@/modules/shared/components/dialogs/onboarding/OnboardingTabStart.vue';
import OnboardingTabText from '@/modules/shared/components/dialogs/onboarding/OnboardingTabText.vue';
import OnboardingTabFinish from '@/modules/shared/components/dialogs/onboarding/OnboardingTabFinish.vue';

@Component({
    components: {
        OnboardingTabText,
        OnboardingTabStart,
        OnboardingTabFinish,
        BaseDialog,
    },
})
export default class OnboardingDlg extends Vue {

    private get tabs(): any[] {
        return [
            { component: 'onboarding-tab-start' },
            { component: 'onboarding-tab-text', params: { title: 'onboarding.tab-2.title', message: 'onboarding.tab-2.message', illustration: 'illustration-onboarding-2' } },
            { component: 'onboarding-tab-text', params: { title: 'onboarding.tab-3.title', message: 'onboarding.tab-3.message', illustration: 'illustration-onboarding-3' } },
            { component: 'onboarding-tab-text', params: { title: 'onboarding.tab-4.title', message: 'onboarding.tab-4.message', illustration: 'illustration-onboarding-4' } },
            { component: 'onboarding-tab-finish' },
        ];
    }

    private get currentTab(): number {
        return this.$store.getters['App/onboardingProgress'];
    }

    private get showDialog(): boolean {
        return this.currentTab < this.tabs.length;
    }

    private updateCurrentTab(tab: number) {
        this.$store.commit('App/setOnboardingProgress', { progress: tab });
    }

    private back() {
        const tab = Math.max(this.currentTab - 1, 0);
        this.$store.commit('App/setOnboardingProgress', { progress: tab });
    }

    private next() {
        const tab = Math.min(this.currentTab + 1, this.tabs.length - 1);
        this.$store.commit('App/setOnboardingProgress', { progress: tab });
    }

    private close() {
        this.$store.commit('App/setOnboardingProgress', { progress: this.tabs.length });
    }
}
